import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Message from '../../models/message'
import MessageText from './message-text'
import { hideCharacters } from '../../../../utils/utils'

const UserMessage = ({ message }) => {
  const {
    text,
    input: { type },
  } = message

  return (
    <Grid container justify="flex-end">
      <MessageText text={type === 'password' ? hideCharacters(text) : text} />
    </Grid>
  )
}

UserMessage.propTypes = {
  message: PropTypes.instanceOf(Message),
}

export default UserMessage

import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import BulbIcon from '../../assets/blub.svg'

const useStyles = makeStyles(theme => ({
  '@keyframes fadein': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@-moz-keyframes fadein': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@-webkit-keyframes fadein': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@-ms-keyframes fadein': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  btnWrapper: {
    padding: '0',
    display: 'block',
    color: theme.palette.background.lightPink,
    marginTop: '15px',

    '& button': {
      minWidth: '0',
      background: theme.palette.secondary.main,
      color: theme.palette.background.default,
      lineHeight: '16px',
      padding: '0',
      textAlign: 'center',
      transform: 'scale(.7)',
      position: 'relative',
      left: '10px',

      '&:last-child': {
        left: 'auto',
        right: '10px',
      },
    },
  },
  header: {
    fontSize: '14px',
    fontFamily: 'RobotoBold',
    color: theme.palette.text.darkGrey,
    padding: '0 10px',
    marginBottom: '10px',
    fontStyle: 'normal',
  },
  sliderWrapper: {
    maxWidth: '300px',
    borderRadius: '9px',
    color: 'black',
    padding: '25px',
    textAlign: 'center',
    fontSize: '13px',
    fontFamily: 'Roboto',
    fontWeight: '300',
    fontStyle: 'italic',
    background: theme.palette.background.lightPink,
    animation: 'fadein 2s',
    position: 'absolute',
    right: 'calc(50% - 640px)',
    top: '308px',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      maxWidth: '100%',
      marginTop: '30px',
    },
    '& > div': {
      background: 'transparent',
    },
  },
  iconWrapper: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-15px',
    top: '-15px',
  },
}))

export default function TextSlider({ steps }) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = steps.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.sliderWrapper}>
      <div className={classes.iconWrapper}>
        <img src={BulbIcon} alt="Bulb" />
      </div>
      <Paper square elevation={0} className={classes.header}>
        {steps[activeStep].label}
      </Paper>
      <Box>{steps[activeStep].description}</Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        className={classes.btnWrapper}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </div>
  )
}

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TipBox from '../../../components/tip-box/tip-box'
import ApiariInput from '../../../components/inputs/input'
import { ProviderFormField } from '../become-a-provider-styles'
import MaskedInput from '../../../components/inputs/masked-input'

const useStyles = makeStyles(theme => ({
  optional: {
    background: theme.palette.text.lightBlue,
    color: theme.palette.background.default,
    marginLeft: '20px',
    fontSize: '10px',
    fontFamily: 'RobotoBold',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '24px',
    display: 'inline-flex',
    letterSpacing: '1.43px',
    borderRadius: '12px',
    padding: '0 18px',
  },
  accountWrapper: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    background: theme.palette.background.lightPink,
    padding: '18px',
    borderRadius: '9px',
    '& > span': {
      marginLeft: '14px',
      display: 'flex',
      fontSize: '12px',
      lineHeight: '1.33',
      flexDirection: 'column',
      '& > span': {
        fontFamily: 'RobotoBold',
        marginBottom: '5px',
      },
    },
  },
}))

const PayForm = props => {
  const classes = useStyles()
  const { isSubmitting } = props

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 14, md: 18 }}
            fontFamily="Roboto"
            color="text.darkGrey"
            mb={3.5}
          >
            Apiari guarantees payment for all the sessions you've worked. We
            make payments each week via Zelle. Enter your information now or
            after you accept your first booking request.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 18 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            What’s your Zelle account?{' '}
            <span className={classes.optional}>OPTIONAL</span>
          </Box>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Box px={1} mb={3.5}>
            <TipBox
              heading="Don't have Zelle yet?"
              subHeading="Most banks have this app and
                it takes a minute to set up online. Check with your bank for
                more info. You can send your Zelle info later when you have it."
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              label="Enter Your Zelle Email Address"
              type="email"
              name="zelleEmail"
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} mb={5} mt={2}>
            - OR -
          </Box>
        </Grid>
        <Grid item sm={4} xs={6}>
          <Box px={1}>
            <MaskedInput
              disabled={isSubmitting}
              name="zellePhoneNumber"
              mask="999-999-9999"
              maskChar="_"
            >
              <ProviderFormField
                component={ApiariInput}
                name="zellePhoneNumber"
                label="Zelle Phone Number"
                variant="standard"
              />
            </MaskedInput>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(PayForm)

import React from 'react'
import { useObserver } from 'mobx-react-lite'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link } from '@reach/router'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PersonOutline from '@material-ui/icons/PersonOutline'
import MembershipIcon from '@material-ui/icons/CardMembershipOutlined'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Typography from '@material-ui/core/Typography'
import storeContext from '../../stores'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
  avatar: {
    height: 30,
    width: 30,
    fontSize: '0.7rem',
  },
}))

const HeaderProfile = ({ onLogout, role }) => {
  const store = React.useContext(storeContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles({ expanded: Boolean(anchorEl) })

  return useObserver(() => (
    <>
      <Box onClick={e => setAnchorEl(e.currentTarget)} className={classes.root}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Avatar className={classes.avatar} alt="avatar">
              {store.auth.user?.initials}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Box maxWidth={120}>
              <Typography component="p" variant="inherit" noWrap>
                {store.auth.user?.firstName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </Grid>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="menu"
      >
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={Link}
          to="account"
        >
          <ListItemIcon>
            <PersonOutline />
          </ListItemIcon>
          <Typography>My Profile</Typography>
        </MenuItem>
        {role === 'provider' && (
          <MenuItem
            onClick={() => setAnchorEl(null)}
            component={Link}
            to="my-membership"
          >
            <ListItemIcon>
              <MembershipIcon />
            </ListItemIcon>
            <Typography>My Membership</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            store.auth.logOutAction()
            onLogout()
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <Typography>Log Out</Typography>
        </MenuItem>
      </Menu>
    </>
  ))
}

export default HeaderProfile

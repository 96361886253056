import React from 'react'
import { onPatch, types } from 'mobx-state-tree'
import UiModel from './models/ui-model'
import AuthModel from './models/auth-model'
import EventsModel from './models/events-model'
import UsersModel from './models/users-model'
import ReconnectingWebSocket from 'reconnecting-websocket'

const StoreModel = types
  .model('RootStoreModel', {
    auth: AuthModel,
    users: UsersModel,
    ui: UiModel,
    events: EventsModel,
  })
  .volatile(() => ({
    ws: types.frozen(),
  }))
  .actions(self => ({
    initializeWebSocket() {
      const rws = new ReconnectingWebSocket(process.env.REACT_APP_WS_URL)
      rws.addEventListener('close', () => {
        rws.reconnect()
      })
      self.ws = rws
    },
    afterCreate() {
      self.auth.reAuthenticateAction()
      self.initializeWebSocket()
    },
  }))

const store = StoreModel.create({
  auth: {},
  ui: { calendar: {} },
  users: {},
  events: {},
})

if (process.env.NODE_ENV === 'development') {
  window.store = store
}

onPatch(store, storePatch => {
  if (process.env.NODE_ENV === 'development') {
    console.log('storePatch = ', storePatch)
  }
})

export default React.createContext(store)

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import ApiariUpload from '../../../components/inputs/upload'
import Upload from '../../../assets/cloud-upload.svg'

const useStyles = makeStyles(theme => ({
  imageUpload: {
    height: '220px',
  },
  photoUpload: {
    marginBottom: '50px',
    '& .MuiBox-root .MuiFormHelperText-root': {
      marginBottom: '0',
      marginTop: '20px',
    },
  },
}))

const IdentificationForm = props => {
  const classes = useStyles()
  const { setFieldValue, touched, errors, values } = props
  return (
    <Box maxWidth={600} m="0 auto" className={classes.photoUpload}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={1.5}
          >
            Please upload a photo of a valid government-issued picture
            identification.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            color="text.lightGrey"
            mb={3.5}
          >
            E.g. Driver's license, passport, or state ID. We will be using this
            only as a proof of your identity, and will not share it with anyone
            else!
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} className={classes.imageUpload}>
            <ApiariUpload
              showPreview
              text="Upload Photo"
              file={values.identification}
              icon={<img src={Upload} alt="upload" />}
              inputProps={{
                name: 'identification',
                onChange: file => setFieldValue('identification', file, false),
                accept: 'image/*',
              }}
              onRemove={() => {
                setFieldValue('identification', null, false)
              }}
            />
          </Box>
          <Box px={1}>
            {touched.identification && !!errors.identification && (
              <FormHelperText>{errors.identification}</FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(IdentificationForm)

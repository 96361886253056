import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  '@keyframes wave': {
    '0%, 60%, 100%': {
      transform: 'initial',
    },
    '30%': {
      transform: 'translateY(-5px)',
    },
  },
  dot: {
    borderRadius: '50%',
    background: '#AFAFAF',
    animation: '$wave 1s linear infinite',
    '&:nth-child(2)': {
      animationDelay: '-0.9s',
    },
    '&:nth-child(3)': {
      animationDelay: '-0.7s',
    },
  },
}))

const Typing = () => {
  const classes = useStyles()
  return (
    <Box display="inline-flex">
      <Box width={8} height={8} className={classes.dot} mr={0.5} />
      <Box width={8} height={8} className={classes.dot} mr={0.5} />
      <Box width={8} height={8} className={classes.dot} />
    </Box>
  )
}

export default Typing

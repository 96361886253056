import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ApiariButton from '../../../../../components/inputs/button'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiButtonBase-root': {
      marginRight: '4px',
      padding: '9px',
      border: 'none',
      fontSize: '12px',
      lineHeight: '1.2em',
      fontWeight: 700,
      fontFamily: 'inherit',
      borderRadius: '10px',
      outline: `1px solid ${theme.palette.primary.main}`,
      textTransform: 'none',
    },
    '& button:not(.active-button)': {
      color: theme.palette.text.darkGrey,
    },
  },
}))

const ButtonSwitch = ({ options, label, onChange, initialValue }) => {
  const classes = useStyles()
  const [active, setActive] = useState(initialValue || options[0].value)

  function handleChange(value) {
    setActive(value)
    onChange(value)
  }

  return (
    <div className={classes.root}>
      {label && <label className="MuiInputLabel-root">{label}</label>}
      <div>
        {options.map(({ text, value }) => (
          <ApiariButton
            className={value === active ? 'active-button' : ''}
            variant={value === active ? 'contained' : 'outlined'}
            key={value}
            onClick={() => handleChange(value)}
          >
            {text}
          </ApiariButton>
        ))}
      </div>
    </div>
  )
}

ButtonSwitch.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  initialValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default ButtonSwitch

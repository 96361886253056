import React from 'react'
import { Field, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ApiariInput from '../../components/inputs/input'
import ApiariButton from '../../components/inputs/button'

const SetPasswordForm = props => {
  const { isSubmitting, errors } = props

  return (
    <Grid component={Form} container direction="column" noValidate spacing={2}>
      <Typography color="error" variant="caption" align="center">
        {errors.setPassword}
      </Typography>
      <Grid item>
        <Field
          component={ApiariInput}
          name="password"
          type="password"
          label="Password"
        />
      </Grid>
      <Grid item>
        <ApiariButton busy={isSubmitting} fullWidth type="submit">
          Set Password
        </ApiariButton>
      </Grid>
    </Grid>
  )
}

export default SetPasswordForm

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'

const HideButton = ({ isDescended, handleDescension, handleAscension }) => {
  return (
    <button
      title={
        isDescended
          ? 'Ascend provider back in search'
          : 'Descend provider in search'
      }
      className="action-button hide-button"
      onClick={() => {
        isDescended ? handleAscension() : handleDescension()
      }}
    >
      {isDescended ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </button>
  )
}

export default HideButton

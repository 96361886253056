import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'

const titleWidth = 150

const ListItemField = ({ title, children, last, ...rest }) => {
  return (
    <Box
      p={2}
      display="flex"
      {...(!last && {
        borderBottom: [1, 1, 0],
        style: { borderColor: 'rgba(224, 224, 224, 1)' },
      })}
      {...rest}
    >
      {title && (
        <Hidden mdUp>
          <Box
            width={titleWidth}
            flex="none"
            fontWeight={700}
            fontFamily="camptonBold"
          >
            {title}
          </Box>
        </Hidden>
      )}
      <Box width={{ xs: `calc(100% - ${titleWidth}px)`, md: '100%' }}>
        {children}
      </Box>
    </Box>
  )
}

export default ListItemField

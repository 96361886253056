import React from 'react'
import DetailsItem from '../details-item'
import Box from '@material-ui/core/Box'
import { PETS_OPTIONS, SMOKING_OPTIONS } from '../../../../utils/config'
import ApiariButton from '../../../../components/inputs/button'
import Service from '../../../../components/service/service'
import useUpdateUser from '../../../../hooks/use-update-user'
import { SmallInput, SmallSelect } from '../../../account/account-page'
import Link from '@material-ui/core/Link'

function getInitialState(user) {
  return {
    pets: user.petsString,
    specialNeeds: user.specialNeeds,
    smokingHousehold: user.smokingHousehold,
    address: {
      streetAddress: user.address?.streetAddress || '',
      zipcode: user.address?.zipcode || '',
      state: user.address?.state || '',
      city: user.address?.city || '',
    },
  }
}

const formatForApi = ({ pets, ...state }) => ({
  ...state,
  ...PETS_OPTIONS.find(i => i.value === pets).cond,
})

const ManageSession = props => {
  const { instance, isViewerCustomer, onSave, setEditingEnabled } = props
  const { status, customer } = instance

  const meta = useUpdateUser(customer, getInitialState, onSave, formatForApi)
  const serviceNameMap = instance.provider.getServiceNamesHumanMap()
  const serviceNameHuman =
    serviceNameMap[instance.serviceName] || instance.serviceName

  const phoneNumber = isViewerCustomer
    ? instance.provider.phoneNumber
    : instance.customer.phoneNumber

  React.useEffect(() => {
    setEditingEnabled(!meta.hasChanges)
  }, [meta.hasChanges]) // eslint-disable-line

  return (
    <>
      <Box position="absolute" right={10} top={8}>
        <Link
          color="primary"
          underline="none"
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={onSave}
        >
          Cancel
        </Link>
        <ApiariButton
          busy={meta.submitting}
          disabled={!meta.hasChanges}
          onClick={() => meta.handleSave()}
        >
          save
        </ApiariButton>
      </Box>
      {isViewerCustomer ? (
        <DetailsItem title="Provider">
          {instance.provider.firstName}
        </DetailsItem>
      ) : (
        <DetailsItem title="Customer">{customer.firstName}</DetailsItem>
      )}
      <DetailsItem title="Service">
        <Service service={serviceNameHuman} />
      </DetailsItem>
      <DetailsItem title="Status">{status.toUpperCase()}</DetailsItem>
      {['childcare', 'nightNurse', 'childcarePlus', 'virtual'].includes(
        instance.serviceName,
      ) ? (
        <>
          <DetailsItem title="Children">{customer.childrenDisplay}</DetailsItem>
          <DetailsItem title="Special Needs">
            <SmallInput
              value={meta.state.specialNeeds}
              onChange={e =>
                meta.handleChange(e, (b, specialNeeds) => ({
                  ...b,
                  specialNeeds,
                }))
              }
              autoFocus
            />
          </DetailsItem>
        </>
      ) : (
        <></>
      )}
      <DetailsItem title="Address">
        <SmallInput
          value={meta.state.address.streetAddress}
          onChange={e =>
            meta.handleChange(e, (b, streetAddress) => ({
              ...b,
              address: { ...b.address, streetAddress },
            }))
          }
          autoFocus
        />
      </DetailsItem>
      <DetailsItem title="State">
        <SmallInput
          value={meta.state.address.state}
          onChange={e =>
            meta.handleChange(e, (b, state) => ({
              ...b,
              address: { ...b.address, state },
            }))
          }
        />
      </DetailsItem>
      <DetailsItem title="City">
        <SmallInput
          value={meta.state.address.city}
          onChange={e =>
            meta.handleChange(e, (b, city) => ({
              ...b,
              address: { ...b.address, city },
            }))
          }
        />
      </DetailsItem>
      <DetailsItem title="Zip code">
        <SmallInput
          value={meta.state.address.zipcode}
          onChange={e =>
            meta.handleChange(e, (b, zipcode) => ({
              ...b,
              address: { ...b.address, zipcode },
            }))
          }
        />
      </DetailsItem>
      <DetailsItem title="Pets">
        <SmallSelect
          variant="outlined"
          options={PETS_OPTIONS}
          value={meta.state.pets}
          onChange={e =>
            meta.handleChange(e, (b, pets) => ({
              ...b,
              pets,
            }))
          }
        />
      </DetailsItem>
      <DetailsItem title="Smoking">
        <SmallSelect
          variant="outlined"
          options={SMOKING_OPTIONS}
          value={meta.state.smokingHousehold}
          onChange={e =>
            meta.handleChange(e, (b, smokingHousehold) => ({
              ...b,
              smokingHousehold,
            }))
          }
        />
      </DetailsItem>
      {instance.customer.phoneNumber ? (
        <DetailsItem title="Contact Number">{phoneNumber}</DetailsItem>
      ) : (
        <></>
      )}
    </>
  )
}

export default ManageSession

import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

const LikeButton = ({ isLiked, handleLike, handleDislike }) => {
  return (
    <button
      title={isLiked ? 'Dislike' : 'Like'}
      className="action-button like-button"
      onClick={() => {
        isLiked ? handleDislike() : handleLike()
      }}
    >
      {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </button>
  )
}

export default LikeButton

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
// import Alert from '@material-ui/lab/Alert';
import ApiariInput from '../../../components/inputs/input'
import { ProviderFormField } from '../become-a-provider-styles'
import MaskedInput from '../../../components/inputs/masked-input'

const useStyles = makeStyles(theme => ({
  iconSec: {
    color: theme.palette.text.dGrey,
  },
  loginText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const AccountCreationForm = props => {
  const classes = useStyles()
  const { isSubmitting, id, gotoLogin } = props
  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box px={1} mb={3}>
            <Typography>
              Already have an account?{' '}
              <a
                className={classes.loginText}
                href={null}
                onClick={() => gotoLogin()}
              >
                Login here
              </a>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            Basic Information
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              name="firstName"
              label="First Name"
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              name="lastName"
              label="Last Name"
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={8}>
          <Box px={1}>
            <MaskedInput
              disabled={isSubmitting}
              name="phoneNumber"
              mask="999-999-9999"
              maskChar="_"
              sanitizeValue
            >
              <ProviderFormField
                component={ApiariInput}
                name="phoneNumber"
                label="Cell phone"
                variant="standard"
              />
            </MaskedInput>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting || !!id}
              name="email"
              label="E-mail Address"
              type="email"
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={20}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Secure Password
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting || !!id}
              name="password"
              label="Password"
              type="Password"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.iconSec} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting || !!id}
              name="confirmPassword"
              label="Confirm Password"
              type="Password"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.iconSec} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(AccountCreationForm)

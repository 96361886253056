import React from 'react'
import ApiariInput from '../../../../components/inputs/input'
import ApiariButton from '../../../../components/inputs/button'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

const ManageNotes = props => {
  const { value: initValue, onChange, setEditingEnabled, onSave } = props

  const [value, setValue] = React.useState(initValue)
  const [busy, setBusy] = React.useState(false)

  React.useEffect(() => {
    setEditingEnabled(value === initValue)
  }, [value, initValue]) // eslint-disable-line

  const handleChange = e => setValue(e.target.value)

  return (
    <>
      <Box position="absolute" right={10} top={8}>
        <Link
          color="primary"
          underline="none"
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={onSave}
        >
          Cancel
        </Link>
        <ApiariButton
          busy={busy}
          disabled={value === initValue}
          onClick={() => {
            setBusy(true)
            onChange(value)
          }}
        >
          save
        </ApiariButton>
      </Box>
      <ApiariInput
        value={value}
        onChange={handleChange}
        multiline
        rows={10}
        autoFocus
      />
    </>
  )
}

export default ManageNotes

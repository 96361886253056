import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { ErrorMessage, getIn } from 'formik'
import FormHelperText from '@material-ui/core/FormHelperText'
// import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles'
import ApiariInput from '../../../components/inputs/input'
import { ProviderFormField } from '../become-a-provider-styles'
import MaskedInput from '../../../components/inputs/masked-input'
import { INTERESTS } from '../../../utils/config'

const useStyles = makeStyles(theme => ({
  refSubHeading: {
    color: theme.palette.text.lightGrey,
  },
}))

const ReferencesForm = props => {
  const classes = useStyles()
  const {
    isSubmitting,
    values: { references, interests },
    errors,
    touched,
  } = props

  // HACK: something to do with Formik and the Array causes touched to all be true first entry
  if (references[0].name === '' && references[1].name === '') {
    touched.references = [
      { name: false, phoneNumber: false, email: false },
      { name: false, phoneNumber: false, email: false },
      { name: false, phoneNumber: false, email: false },
    ]
  }

  const getServiceName = () => {
    if (
      (interests.length === 1 &&
        (interests[0] === 'childcare' || interests[0] === 'housekeeping')) ||
      interests.length === INTERESTS.length
    ) {
      return ' childcare'
    }
    if (interests.length === 1 && interests[0] === 'childcarePlus') {
      return ' enrichment'
    }
    if (
      interests.length === 2 &&
      interests.includes('childcare') &&
      interests.includes('childcarePlus')
    ) {
      return ' childcare / teaching'
    }
    if (
      (interests.length === 1 && interests.includes('night_nurse')) ||
      (interests.length === 2 &&
        interests.includes('childcare') &&
        interests.includes('night_nurse'))
    ) {
      return ' childcare / night nanny'
    }
    return ' childcare'
  }

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            Share your professional references that we can contact about your
            {getServiceName()} experience.
          </Box>
        </Grid>
        {references?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Box
                px={1}
                fontSize={{ xs: 16, md: 20 }}
                fontFamily="Roboto"
                color="text.darkGrey"
                style={{
                  fontWeight: '300',
                }}
                mb={3.5}
                mt={{ xs: 1, md: 3 }}
              >
                Reference {index + 1}{' '}
                {index === 2 && (
                  <span className={classes.refSubHeading}>(Optional)</span>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={1}>
                <ProviderFormField
                  component={ApiariInput}
                  disabled={isSubmitting}
                  name={`references.${index}.name`}
                  label="Name"
                  variant="standard"
                  error={
                    getIn(errors, `references.${index}.name`) &&
                    getIn(touched, `references.${index}.name`)
                  }
                />
                {!!getIn(errors, `references.${index}.name`) &&
                  getIn(touched, `references.${index}.name`) && (
                    <ErrorMessage
                      component={FormHelperText}
                      name={`references.${index}.name`}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Box px={1}>
                <MaskedInput
                  disabled={isSubmitting}
                  name={`references.${index}.phoneNumber`}
                  mask="999-999-9999"
                  maskChar="_"
                  sanitizeValue
                >
                  <ProviderFormField
                    component={ApiariInput}
                    name={`references.${index}.phoneNumber`}
                    label="Phone Number"
                    variant="standard"
                    error={
                      getIn(errors, `references.${index}.phoneNumber`) &&
                      getIn(touched, `references.${index}.phoneNumber`)
                    }
                  />
                </MaskedInput>
                {!!getIn(errors, `references.${index}.phoneNumber`) &&
                  getIn(touched, `references.${index}.phoneNumber`) && (
                    <ErrorMessage
                      component={FormHelperText}
                      name={`references.${index}.phoneNumber`}
                    />
                  )}
              </Box>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box px={1}>
                <ProviderFormField
                  component={ApiariInput}
                  disabled={isSubmitting}
                  label={
                    <span>
                      E-mail Address <strong>(optional)</strong>
                    </span>
                  }
                  type="email"
                  name={`references.${index}.email`}
                  variant="standard"
                  error={
                    getIn(errors, `references.${index}.email`) &&
                    getIn(touched, `references.${index}.email`)
                  }
                />
                {!!getIn(errors, `references.${index}.email`) &&
                  getIn(touched, `references.${index}.email`) && (
                    <ErrorMessage
                      component={FormHelperText}
                      name={`references.${index}.email`}
                    />
                  )}
              </Box>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}

export default React.memo(ReferencesForm)

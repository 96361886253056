import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import ApiariButton from '../../../components/inputs/button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const DeleteAccountConfirmationModal = props => {
  const {
    handleClose,
    title,
    message,
    handleConfirm,
    note,
    apiRequestInProgress,
  } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>
      <Box component={DialogContent} mb={note ? 0 : 4}>
        {message}
      </Box>
      {note && (
        <Box mx={3} mb={2} color="text.grey">
          <Typography variant="caption">*{note}</Typography>
        </Box>
      )}
      <DialogActions>
        <ApiariButton color="secondary" onClick={handleClose}>
          Cancel
        </ApiariButton>
        <ApiariButton
          style={{ background: '#d32f2f', color: 'white' }}
          busy={apiRequestInProgress}
          onClick={handleConfirm}
        >
          Confirm
        </ApiariButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAccountConfirmationModal

import React from 'react'
import ManageNotes from './manage-notes'
import { observer } from 'mobx-react-lite'

const NotesDetails = observer(props => {
  const { instance, editing, onSave, setEditingEnabled } = props

  const handleChange = note => {
    instance.updateNote(note).then(onSave)
  }

  if (!editing) return instance.notes

  return (
    <ManageNotes
      setEditingEnabled={setEditingEnabled}
      value={instance.notes || ''}
      onSave={onSave}
      onChange={handleChange}
    />
  )
})

export default NotesDetails

import Box from '@material-ui/core/Box'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { makeStyles } from '@material-ui/core'
import ApiariButton from '../inputs/button'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      minWidth: '375px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,

      '& h2': {
        textAlign: 'center',
        fontSize: '18px',
        lineHeight: '1.4em',
        fontFamily: theme.typography.camptonBold,
      },
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      width: '100%',

      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
}))

const ConfirmationModal = ({
  title,
  note,
  message,
  handleConfirm,
  handleClose,
  confirmationButton,
  closeButton,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="xs"
      fullScreen={fullScreen}
      classes={classes}
    >
      <Box p={{ xs: 2, md: 4 }}>
        <DialogTitle>{title}</DialogTitle>
        <Box component={DialogContent} mb={note ? 0 : 4}>
          {message}
        </Box>
        {note && (
          <Box mx={3} mb={2} color="text.grey">
            <Typography variant="caption">*{note}</Typography>
          </Box>
        )}
        <DialogActions>
          <Box
            display="flex"
            flexDirection={{ xs: 'column-reverse', md: 'row' }}
            flexGrow={1}
            justifyContent="center"
          >
            <Box width={{ xs: '100%', md: '50%' }} mr={{ md: 1 }}>
              {closeButton || (
                <ApiariButton
                  variant="text"
                  color="secondary"
                  onClick={handleClose}
                >
                  Nevermind
                </ApiariButton>
              )}
            </Box>
            <Box
              width={{ xs: '100%', md: '50%' }}
              ml={{ md: 1 }}
              mb={{ xs: 2, md: 0 }}
            >
              {confirmationButton || (
                <ApiariButton onClick={handleConfirm}>Confirm</ApiariButton>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ConfirmationModal

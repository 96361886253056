import React from 'react'
import { ReactComponent as CornerIcon } from '../../assets/modal-corner.svg'
import Box from '@material-ui/core/Box'

const ProfileWrapper = ({ children, color = 'secondary' }) => {
  return (
    <Box borderRadius={8} border={1} borderColor="greyBlue.100" bgcolor="white">
      <Box color={`${color}.main`} position="absolute">
        <CornerIcon />
      </Box>
      <Box p={{ xs: 2, md: 4 }}>{children}</Box>
    </Box>
  )
}

export default ProfileWrapper

import React from 'react'
import Box from '@material-ui/core/Box'
import NavLink from '../../components/nav-link/nav-link'
import allRoutes from '../../routes'

const HeaderRoutes = ({
  display,
  isAuthenticated,
  isApiariBusiness,
  role,
  hasFavorites,
  isActiveUser,
}) => {
  let routeSet = 'public'
  if (isAuthenticated) {
    routeSet = role
  } else if (isApiariBusiness) {
    routeSet = 'business'
  }

  const routes = allRoutes[routeSet]
  return routes.map(route => {
    if (!route.title) return null
    if (route.title === 'My Team' && !hasFavorites) return null
    if (route.title === 'Become a Provider' && isActiveUser) return null
    return (
      <Box
        {...(route.path
          ? {
              component: NavLink,
              to: `${route.path}${route.qs || ''}`,
              ...(route.reloadOnClick && {
                state: { reloadId: +new Date() },
              }),
            }
          : {
              href: route.href,
              component: 'a',
            })}
        px={1.5}
        ml={1}
        py={1.5}
        display={display}
        key={`${route.path || route.href}${route.qs}`}
      >
        {route.title}
      </Box>
    )
  })
}

export default HeaderRoutes

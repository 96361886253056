import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  steps: {
    width: 75, // was 90 but wrapping
    textIndent: -9999,
    height: 7,
    background: theme.palette.stepper.inactive,

    [theme.breakpoints.down('xs')]: {
      width: 50,
    },

    '&.active': {
      background: theme.palette.stepper.active,
    },

    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
}))

const Stepper = props => {
  const { totalSteps, activeStep } = props

  const classes = useStyles()

  const STEPS = [...Array(totalSteps).keys()]

  return (
    <Box maxWidth={{ xs: 310, sm: 460 }} m="auto">
      <Grid container justify="space-between" alignItems="center">
        {STEPS.map((el, index) => {
          return (
            <Grid
              key={el}
              className={`${classes.steps} ${
                activeStep >= index ? 'active' : ''
              }`}
            >
              <></>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default Stepper

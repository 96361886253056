import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ApiariInput from '../../../components/inputs/input'
import { ProviderFormField } from '../become-a-provider-styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

//Config
import { ABOUT_SECTION_LABELS } from '../become-a-provider-config'

const useStyles = makeStyles(theme => ({
  smallTextarea: {
    '& > div.MuiInputBase-root textarea': {
      minHeight: '135px',
      maxHeight: '135px',
    },
  },
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '1.2',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
  tipsWraper: {
    '& > div > div': {
      position: 'relative',
      fontStyle: 'normal',
      top: '0',
      right: 'auto',
      maxWidth: '100%',
      paddingBottom: '10px',
    },
    '& .MuiBox-root': {
      textAlign: 'left',
    },
    '& .MuiMobileStepper-root': {
      marginTop: '10px',
    },
  },
}))

const AboutYourself = props => {
  const classes = useStyles()
  const { primaryService, isSubmitting, errors, touched } = props

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <React.Fragment key={primaryService}>
          <Grid item xs={12}>
            <Box
              px={1}
              fontSize={{ xs: 16, md: 20 }}
              fontFamily="RobotoBlack"
              color="text.darkGrey"
              mb={3.5}
              mt={5}
            >
              {ABOUT_SECTION_LABELS[primaryService].heading}
              <span className={classes.subHead}>
                {ABOUT_SECTION_LABELS[primaryService].subHeading}{' '}
              </span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box px={1}>
              <ProviderFormField
                component={ApiariInput}
                disabled={isSubmitting}
                multiline
                name={`about.${primaryService}`}
                variant="standard"
                placeholder={ABOUT_SECTION_LABELS[primaryService].placeholder}
                error={
                  errors[`about${primaryService}`] &&
                  touched[`about${primaryService}`]
                }
                helperText={
                  !!errors[`about${primaryService}`] &&
                  touched[`about${primaryService}`] &&
                  errors[`about${primaryService}`]
                }
              />
            </Box>
          </Grid>
        </React.Fragment>
      </Grid>
    </Box>
  )
}

export default React.memo(AboutYourself)

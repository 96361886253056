import React from 'react'

const BLANK_CHILD = {
  gender: null,
  birthday: null,
  name: '',
}

export const INIT_CHILDRENS_STATE = {
  index: 0,
  childrens: [BLANK_CHILD],
}

function reducerFn(state, action) {
  switch (action.type) {
    case 'NEXT': {
      const nextIndex = state.index + 1
      return {
        ...state,
        index: nextIndex,
      }
    }
    case 'PREVIOUS': {
      if (state.index === 0) return state
      return { ...state, index: state.index - 1 }
    }
    case 'ADD_CHILD': {
      return {
        ...state,
        childrens: [...state.childrens, BLANK_CHILD],
        index: state.childrens.length,
      }
    }
    case 'CHANGE_NAME': {
      return {
        ...state,
        childrens: state.childrens.map((child, i) => {
          if (i !== state.index) return child
          return {
            ...child,
            name: action.payload,
          }
        }),
      }
    }
    case 'CHANGE_BIRTHDAY': {
      return {
        ...state,
        childrens: state.childrens.map((child, i) => {
          if (i !== state.index) return child
          return {
            ...child,
            birthday: action.payload,
          }
        }),
      }
    }
    case 'CHANGE_GENDER': {
      return {
        ...state,
        childrens: state.childrens.map((child, i) => {
          if (i !== state.index) return child
          return {
            ...child,
            gender: action.payload,
          }
        }),
      }
    }
    default:
      throw new Error('Not supported action')
  }
}

const TITLES = {
  0: 'First',
  1: 'Second',
  2: 'Third',
  3: 'Forth',
  4: 'Fifth',
}

const getIndexTitle = i => {
  return TITLES[i] || `${i + 1}th`
}

export default function useChildrenManagement(
  initChildrens = INIT_CHILDRENS_STATE,
) {
  const [{ index, childrens }, dispatch] = React.useReducer(
    reducerFn,
    initChildrens,
  )
  const child = childrens[index]
  return {
    dispatch,
    child,
    childrens,
    allChildrensValid: childrens.every(c => c.birthday),
    singeChildrenValid: childrens.some(c => c.birthday),
    showPrevious: index > 0,
    showNext: index + 1 < childrens.length,
    indexTitle: getIndexTitle(index),
  }
}

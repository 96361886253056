import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import closeLogo from '../../../assets/images/so-close.png'

const useStyles = makeStyles(theme => ({
  pageHeading: {
    color: theme.palette.text.darkGrey,
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    lineHeight: '2.5',
  },

  pageContainer: {
    textAlign: 'center',

    '& img': {
      maxWidth: '408px',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '300px',
        maxWidth: '100%',
      },
    },
  },

  pageSubHeading: {
    fontWeight: '300',
    lineHeight: '1.3',
    marginBottom: '10px',
  },
}))

const SoClose = () => {
  const classes = useStyles()

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12} justify="center">
          <Box px={1} mb={3.5}>
            <img src={closeLogo} alt="So Colse" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={16}
            fontFamily="RobotoBlack"
            className={classes.pageHeading}
            mb={1}
          >
            Did you know?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={20}
            fontFamily="Roboto"
            className={classes.pageSubHeading}
          >
            We automatically ask for tips from your clients each week - and you
            keep 100% of it!
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(SoClose)

import React from 'react'
import * as PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import BookingDetailsPage from './booking-details-page'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  close: {
    position: 'relative',
    top: -12,
    left: '100%',
    textAlign: 'right',
    cursor: 'pointer',
    zIndex: 1,
    color: theme.palette.primary.main,
  },
}))

const BookingDetailsModal = props => {
  const { children, instanceId, disabled } = props

  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => !disabled && setIsModalOpen(true),
        style: {
          cursor: disabled ? 'default' : 'pointer',
          opacity: disabled ? 0.5 : 1,
        },
      })}
      {isModalOpen && (
        <Dialog
          open
          onClose={() => setIsModalOpen(false)}
          maxWidth="md"
          fullScreen={fullScreen}
          fullWidth
        >
          <DialogContent>
            <CloseIcon
              className={classes.close}
              onClick={() => setIsModalOpen(false)}
            />
            <BookingDetailsPage
              instanceId={instanceId}
              onCancel={() => setIsModalOpen(false)}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

BookingDetailsModal.propTypes = {
  instanceId: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default BookingDetailsModal

import { useState } from 'react'
import * as Yup from 'yup'
import get from 'lodash.get'
import { SmallAmountInput } from '../account-page'
import ApiariButton from '../../../components/inputs/button'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import useUpdateUser from '../../../hooks/use-update-user'
import { serviceNameToHuman } from '../../chat-bot/models/profile'
import ConfirmationModal from '../../../components/confirmation-modal'
import { withStyles, Box, Grid, Link, makeStyles } from '@material-ui/core'

const SERVICES_TO_DISPLAY = ['childcarePlus', 'night_nurse', 'childcare']
const DECIMAL_NUMBER_REGEXP = /^[-+]?[0-9]+\.[0-9]+$/
const INTEGER_NUMBER_REGEXP = /^[1-9]\d*$/

const validationScheme = Yup.object({
  prices: Yup.lazy(value => {
    return Yup.object(
      Object.fromEntries(
        Object.keys(value).map(service => [
          service,
          Yup.string()
            .label(`${serviceNameToHuman(service)} hourly price`)
            .nullable()
            .test(
              'Valid price',
              'Rate value must be a decimal number and be greater than 0',
              value =>
                !value
                  ? true
                  : (DECIMAL_NUMBER_REGEXP.test(value) ||
                      INTEGER_NUMBER_REGEXP.test(value)) &&
                    parseFloat(value),
            ),
        ]),
      ),
    )
  }),
})

const InfoWrapper = ({ title, children, titleWidth }) => (
  <Grid
    item
    container
    component={Box}
    flexDirection={{ xs: 'column', sm: 'row' }}
  >
    <Grid
      component={Box}
      item
      xs="auto"
      mb={{ xs: 1, sm: 0 }}
      mr={{ xs: 0, sm: 2 }}
      width={titleWidth}
    >
      <Box fontFamily="camptonBold" lineHeight="32px">
        {title}
      </Box>
    </Grid>
    <Box component={Grid} item xs lineHeight="32px">
      {children}
    </Box>
  </Grid>
)

const ConfirmHourlyRateButton = withStyles({
  root: {
    width: '100%',
    '& .MuiButton-startIcon': {
      marginTop: '-4px',
      marginRight: '6px',
    },
  },
})(ApiariButton)

const useStyles = makeStyles(theme => ({
  errorHelper: {
    color: 'red',
  },
}))

function getInitialState({ prices }) {
  return {
    prices,
  }
}

const HourlyRateInfo = props => {
  const classes = useStyles()
  const { user, onEdit, onSave, editMode } = props
  const meta = useUpdateUser(
    user,
    getInitialState,
    onSave,
    undefined,
    validationScheme,
  )
  const { state, errors } = meta
  const [
    isConfirmRateChanageModalOpen,
    setIsConfirmRateChanageModalOpen,
  ] = useState(false)

  return (
    <>
      {isConfirmRateChanageModalOpen && (
        <ConfirmationModal
          title="Are you sure you want to save this change?"
          message="The new rate(s) will apply to all bookings made after this change. Any bookings already on your calendar will continue to have your previous hourly rate."
          closeButton={
            <ConfirmHourlyRateButton
              variant="text"
              onClick={() => {
                setIsConfirmRateChanageModalOpen(false)
              }}
            >
              Cancel
            </ConfirmHourlyRateButton>
          }
          handleConfirm={() => {
            setIsConfirmRateChanageModalOpen(false)
            meta.handleSave()
          }}
        />
      )}
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle icon={<BulletIcon />} text="Hourly Rate" />
        </Grid>
        <Grid item xs="auto">
          {editMode ? (
            <>
              <Link
                color="primary"
                underline="none"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={() => {
                  meta.handleReset()
                  onSave()
                }}
              >
                Cancel
              </Link>
              <ApiariButton
                onClick={() => setIsConfirmRateChanageModalOpen(true)}
                disabled={!meta.hasChanges || errors}
                busy={meta.submitting}
              >
                Save
              </ApiariButton>
            </>
          ) : (
            <ApiariButton onClick={onEdit}>Edit</ApiariButton>
          )}
        </Grid>
      </Grid>

      <Box pl={3.6} mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md>
            <Grid container direction="column" spacing={3}>
              {Object.entries(state?.prices)
                .filter(([service]) => SERVICES_TO_DISPLAY.includes(service))
                .map(([service, price]) => (
                  <InfoWrapper
                    title={serviceNameToHuman(service)}
                    titleWidth={180}
                    key={service}
                  >
                    {editMode ? (
                      <>
                        <SmallAmountInput
                          autoFocus
                          error={errors && get(errors, `prices.${service}`)}
                          value={price || ''}
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                          onChange={event =>
                            meta.handleChange(event, (baseState, value) => ({
                              ...baseState,
                              prices: {
                                ...baseState.prices,
                                [service]: value || null,
                              },
                            }))
                          }
                        />
                        <Box>
                          <span className={classes.errorHelper}>
                            {get(errors, `prices.${service}`)}
                          </span>
                        </Box>
                      </>
                    ) : price ? (
                      `$ ${price}`
                    ) : (
                      ''
                    )}
                  </InfoWrapper>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default HourlyRateInfo

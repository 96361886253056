import React from 'react'
import { navigate, Link } from '@reach/router'
import { Formik, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import FormHelperText from '@material-ui/core/FormHelperText'
import ApiariButton from '../../components/inputs/button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Stepper from '../../components/stepper/stepper'
import ProviderHeader from './components/provider-header'
// Forms
import WelcomeForm from './components/welcome-form'
import AccountCreationForm from './components/account-creation-form'
import AddressForm from './components/address-form'
import AboutYourselfForm from './components/about-yourself-form'
import QualificationsForm from './components/qualifications-form'
import BackgroundCheckForm from './components/background-check-form'
import Breezing from './components/breezing'
import Congrats from './components/congrats'
import HourlyRateForm from './components/hourly-rate-form'
import IdentificationForm from './components/identification-form'
import KnowYouBetterForm from './components/know-you-better-form'
import PayForm from './components/pay-form'
import PreferencesForm from './components/preferences-form'
import ReferencesForm from './components/references-form'
import SoClose from './components/so-close'
import TermsOfUse from './components/terms-of-use'
import UploadsForm from './components/uploads-form'
import MoneyImg from '../../assets/images/money.png'
// Config
import {
  VALIDATION_SCHEMA,
  EMPTY_STEPS,
  prepareProviderData,
  setInitialValues,
  STEPS,
} from './become-a-provider-config'
import { INTERESTS } from '../../utils/config'
// Contexts
import storeContext from '../../stores'

const STEP_1_GROUP = [STEPS.EXPERIENCE]

const STEP_2_GROUP = [STEPS.ADDRESS]

const STEP_3_GROUP = [STEPS.BREEZING, STEPS.ABOUT_YOURSELF, STEPS.FUN_FACTS]

const STEP_4_GROUP = [STEPS.PRICES, STEPS.PREFERENCES]

const STEP_5_GROUP = [
  STEPS.UPLOADS,
  STEPS.SO_CLOSE,

  STEPS.REFERENCES,
  STEPS.IDENTIFICATION,
]

const STEP_6_GROUP = [
  STEPS.ZELLE,
  //STEPS.TERMS,
  STEPS.BACKGROUND_CHECK,
  STEPS.CONGRATS,
]

const STRIPE_LOAD = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const useStyles = makeStyles(theme => ({
  buttonYellow: {
    color: theme.palette.text.grey,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '200px',
    lineHeight: '45px',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      padding: '0 20px',
    },
    '&:hover': {
      color: theme.palette.background.default,
    },
  },

  providerWrapper: {
    fontFamily: 'Roboto',
    '& p.Mui-error': {
      background: theme.palette.alert.dangerBg,
      color: theme.palette.alert.dangerText,
      borderRadius: '6px',
      lineHeight: '1',
      padding: '12px 16px',
      marginLeft: '0',
      fontSize: '14px',
      fontFamily: 'Roboto',
      marginTop: '15px',
      '&.MuiFormHelperText-root': {
        marginTop: '15px',
        marginBottom: '0',
      },
    },
    '& p.MuiFormHelperText-root': {
      background: theme.palette.alert.dangerBg,
      color: theme.palette.alert.dangerText,
      borderRadius: '6px',
      lineHeight: '1',
      padding: '12px 16px',
      marginLeft: '0',
      fontSize: '14px',
      fontFamily: 'Roboto',
      marginBottom: '25px',
      marginTop: '0',
    },
  },
  payDay: {
    maxWidth: '40px',
    height: 'auto',
    marginLeft: '15px',
    verticalAlign: 'text-top',
  },
  stepperWrapper: {
    position: 'relative',
    padding: '0 10px',
    top: '-62px',
    [theme.breakpoints.down('md')]: {
      top: '0',
    },
  },
  apiError: {
    marginBottom: '0',
    marginTop: '20px',
    width: '100%',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'underline !important',
  },
  buttonBack: {
    color: theme.palette.text.grey,
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'none',
    lineHeight: '45px',
    padding: '0',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },

  buttonSkip: {
    marginLeft: 'auto',
    display: 'block',
    color: theme.palette.text.lightGrey,
    backgroundColor: 'transparent',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'none',
    lineHeight: '45px',
    padding: '0',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'space-around',

    '& > div:last-child': {
      marginLeft: 'auto',
    },
  },
}))

function definePrimaryService(services) {
  if (!services) return null

  if (services.includes('childcare')) {
    return 'childcare'
  } else if (services.includes('night_nurse')) {
    return 'nightNurse'
  } else if (services.includes('childcarePlus')) {
    return 'childcarePlus'
  } else if (services.includes('housekeeping')) {
    return 'housekeeping'
  }
}

const renderStepContent = (activeStep, props, id, stripeRef, login) => {
  switch (activeStep) {
    case STEPS.ACCOUNT_CREATION:
      return <AccountCreationForm {...props} id={id} gotoLogin={login} />
    case STEPS.WELCOME:
      return <WelcomeForm {...props} />
    case STEPS.EXPERIENCE:
      return <QualificationsForm {...props} />
    case STEPS.ADDRESS:
      return <AddressForm {...props} />
    case STEPS.ABOUT_YOURSELF:
      return <AboutYourselfForm {...props} />
    case STEPS.FUN_FACTS:
      return <KnowYouBetterForm {...props} />
    case STEPS.BREEZING:
      return <Breezing {...props} />
    case STEPS.PRICES:
      return <HourlyRateForm {...props} />
    case STEPS.PREFERENCES:
      return <PreferencesForm {...props} />
    case STEPS.UPLOADS:
      return <UploadsForm {...props} />
    case STEPS.SO_CLOSE:
      return <SoClose {...props} />
    case STEPS.REFERENCES:
      return <ReferencesForm {...props} />
    case STEPS.IDENTIFICATION:
      return <IdentificationForm {...props} />
    case STEPS.ZELLE:
      return <PayForm {...props} />
    case STEPS.TERMS:
      return <TermsOfUse {...props} />
    case STEPS.BACKGROUND_CHECK:
      return (
        <Elements stripe={STRIPE_LOAD}>
          <BackgroundCheckForm ref={stripeRef} {...props} />
        </Elements>
      )
    case STEPS.CONGRATS:
      return <Congrats {...props} />
    default:
      return <AccountCreationForm {...props} />
  }
}

const BecomeAProviderPage = () => {
  const classes = useStyles()
  const {
    auth: { user: self, logOutAction, successAuth, createProviderAction },
    ui: { setAfterLoginRoute },
  } = React.useContext(storeContext)
  const [activeStep, setActiveStep] = React.useState(STEPS.ACCOUNT_CREATION)
  const formRef = React.useRef(null)
  const stripeRef = React.useRef(null)
  const primaryService = definePrimaryService(self?.services)

  React.useEffect(() => {
    if (self?.id && self?.role !== 'provider') {
      logOutAction()
    }
  }, [logOutAction, self])

  const evaluateActiveStep = React.useCallback(() => {
    if (
      (!self.isActive &&
        (self.becomingProviderComplete || self.acceptedProviderTerms)) ||
      self.stripeToken
    ) {
      return STEPS.CONGRATS
    }
    // Disabled
    // if (self.zelleEmail || self.zellePhoneNumber) {
    //   return STEPS.TERMS
    // }
    if (self.identificationPhoto) {
      return STEPS.ZELLE
    }
    if (self.references && self.references[0].phoneNumber) {
      return STEPS.IDENTIFICATION
    }
    if (self.profilePic) {
      return STEPS.SO_CLOSE
    }
    if (self.childcareAgeRange && self.childcareAgeRange.min !== '') {
      return STEPS.UPLOADS
    }
    if (self.prices && Object.keys(self.prices).length) {
      return STEPS.PREFERENCES
    }
    if (self.funFacts) {
      return STEPS.BREEZING
    }
    if (self.quickIntro) {
      return STEPS.FUN_FACTS
    }
    if (self.address?.zipcode) {
      return STEPS.ABOUT_YOURSELF
    }
    if (self.services?.length) {
      return STEPS.ADDRESS
    }
    if (self.id) {
      return STEPS.WELCOME
    }

    return STEPS.ACCOUNT_CREATION
  }, [self])

  React.useEffect(() => {
    if (self?.id && self?.role === 'provider') {
      const newActiveStep = evaluateActiveStep()
      setActiveStep(newActiveStep)
    }
  }, [self, evaluateActiveStep])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const onSuccess = user => {
    if (activeStep === STEPS.ACCOUNT_CREATION && !self?.id) {
      setAfterLoginRoute('/become-a-provider')
      successAuth(user)
    }
    setActiveStep(activeStep + 1)
    formRef.current.setSubmitting(false)
  }

  const onError = error => {
    formRef.current.setErrors(
      typeof error == 'string' ? { error: error } : error,
    )
    formRef.current.setSubmitting(false)
  }

  const submitDetails = (values, { setSubmitting }) => {
    const body = prepareProviderData(values, self?.id, activeStep)
    if (!self?.id) {
      createProviderAction(body, onSuccess, onError)
    } else {
      self.updateProviderAction(body, onSuccess, onError)
    }
  }

  const gotoSite = () => {
    navigate('/account')
  }

  const handleSubmit = (values, actions) => {
    if (values.authorizedToWorkInUS === false || values.experience === 0) {
      return
    }

    if (EMPTY_STEPS.includes(activeStep)) {
      actions.setSubmitting(false)
      setActiveStep(activeStep + 1)
      return
    }

    if (activeStep === STEPS.ABOUT_YOURSELF) {
      const errors = {},
        touched = {}

      if (Object.keys(errors).length) {
        actions.setTouched(touched)
        actions.setSubmitting(false)
        setTimeout(() => {
          actions.setErrors(errors)
        }, 0)
        return
      }
    }

    if (activeStep === STEPS.PRICES) {
      const selectedInterestKeys = INTERESTS.filter(
        item => values.interests.indexOf(item.value) > -1,
      ).map(item => item.key)
      const errors = {},
        touched = {}
      selectedInterestKeys.forEach(key => {
        if (key !== 'housekeeping' && typeof values.prices[key] !== 'number') {
          errors[key] = 'Required'
          touched[key] = true
        }
      })
      if (Object.keys(errors).length) {
        actions.setTouched(touched)
        actions.setSubmitting(false)
        setTimeout(() => {
          actions.setErrors(errors)
        }, 0)
        return
      }
    }

    if (activeStep === STEPS.BACKGROUND_CHECK) {
      stripeRef.current.chargeCard()
      return
    }

    actions.setTouched({})
    setTimeout(() => {
      actions.setErrors({})
    }, 0)
    submitDetails(values, actions)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const gotoLogin = () => {
    setAfterLoginRoute('/become-a-provider')
    navigate('/login')
  }

  const getStepTitle = () => {
    switch (activeStep) {
      case STEPS.WELCOME:
        return <div>Welcome! We're excited to meet you</div>
      case STEPS.ACCOUNT_CREATION:
        return (
          <div>
            Let's set up your
            <br /> provider account!
          </div>
        )
      case STEPS.EXPERIENCE:
        return (
          <div>
            Some simple
            <br /> stuff first.
          </div>
        )
      case STEPS.ADDRESS:
        return (
          <div>
            Let's start with some
            <br /> general info.
          </div>
        )
      case STEPS.ABOUT_YOURSELF:
        return <div>Tell parents about yourself!</div>
      case STEPS.FUN_FACTS:
        return <div>Let's get to know you better</div>
      case STEPS.BREEZING:
        return (
          <div>
            You are breezing
            <br /> through this!
          </div>
        )
      case STEPS.PRICES:
        return <div>What's Your Hourly Rate?</div>
      case STEPS.PREFERENCES:
        return (
          <div>
            Let's find jobs based on
            <br /> your preferences.
          </div>
        )
      case STEPS.UPLOADS:
        return <div>Say "Cheese!"</div>
      case STEPS.SO_CLOSE:
        return <div>You are so close!</div>
      case STEPS.REFERENCES:
        return (
          <div>
            Congrats! Your profile
            <br /> is looking great.
          </div>
        )
      case STEPS.IDENTIFICATION:
        return <div>Identification</div>
      case STEPS.ZELLE:
        return (
          <div>
            Get ready for payday!{' '}
            <span>
              <img src={MoneyImg} alt="money" className={classes.payDay} />
            </span>
          </div>
        )
      case STEPS.TERMS:
        return <div>Terms of Use for Providers</div>
      case STEPS.BACKGROUND_CHECK:
        return <div>Hooray! Last Step!</div>
      case STEPS.CONGRATS:
        return <div>Congrats! You Did It!</div>
      default:
        return <div>Let's set up your provider account!</div>
    }
  }

  const getActiveStepForStepper = () => {
    console.log('get', activeStep)

    if (STEP_1_GROUP.includes(activeStep)) {
      return 1
    } else if (STEP_2_GROUP.includes(activeStep)) {
      return 2
    } else if (STEP_3_GROUP.includes(activeStep)) {
      return 3
    } else if (STEP_4_GROUP.includes(activeStep)) {
      return 4
    } else if (STEP_5_GROUP.includes(activeStep)) {
      return 5
    } else if (STEP_6_GROUP.includes(activeStep)) {
      return 6
    }

    console.log('unknown step')
  }

  return (
    <Box bgcolor="white" p={0} className={classes.providerWrapper}>
      <ProviderHeader />
      <Box maxWidth={1044} m="0 auto 100px" className={classes.stepperWrapper}>
        {activeStep !== 0 && (
          <Box maxWidth={600} m="0 auto">
            <Stepper
              totalSteps={6}
              activeStep={getActiveStepForStepper() - 1}
            />
          </Box>
        )}

        <Box
          align="center"
          fontFamily="CormorantinfantMedium"
          fontSize={24}
          mb={1}
          mt={{ xs: 2, sm: 3, md: 8 }}
        >
          {activeStep !== STEPS.WELCOME &&
            activeStep !== STEPS.ACCOUNT_CREATION &&
            activeStep !== STEPS.CONGRATS && (
              <>
                Step {getActiveStepForStepper()}{' '}
                <span style={{ fontFamily: 'CormorantinfantLightItalic' }}>
                  of
                </span>{' '}
                6
              </>
            )}
          {activeStep === STEPS.CONGRATS && <>Application Complete</>}
        </Box>
        <Box
          align="center"
          fontFamily="Roboto"
          fontWeight={700}
          color="text.darkGrey"
          style={{
            lineHeight: 1.1,
          }}
          fontSize={{ xs: 30, md: 52 }}
          mb={{ xs: 5, sm: 7, md: 10 }}
          px={{ xs: 0, sm: 2, md: 5 }}
          py={0}
        >
          {getStepTitle()}
        </Box>
        <Formik
          initialValues={setInitialValues(self || {})}
          validationSchema={VALIDATION_SCHEMA[activeStep]}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting, values, errors, ...rest }) => (
            <Form noValidate>
              {renderStepContent(
                activeStep,
                {
                  isSubmitting,
                  values,
                  errors,
                  submitDetails,
                  primaryService,
                  ...rest,
                },
                self?.id,
                stripeRef,
                gotoLogin,
              )}
              <Box
                maxWidth={activeStep !== STEPS.TERMS ? 600 : '100%'}
                mt={{ xs: 4, sm: 4, md: 3 }}
                m="0 auto"
              >
                <Grid container>
                  {!!errors.error && (
                    <FormHelperText className={classes.apiError}>
                      {errors.error === 'Account already exists' ? (
                        <span>
                          An account with this email already exists, click{' '}
                          <Link className={classes.link} to="/login">
                            here
                          </Link>{' '}
                          to login
                        </span>
                      ) : (
                        errors.error
                      )}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid container className={classes.btnContainer}>
                  {activeStep !== STEPS.ACCOUNT_CREATION &&
                    activeStep !== STEPS.WELCOME &&
                    activeStep !== STEPS.CONGRATS && (
                      <Grid item>
                        <Box px={1}>
                          <ApiariButton
                            busy={isSubmitting}
                            onClick={handleBack}
                            className={classes.buttonBack}
                            startIcon={
                              <ArrowBackIosIcon
                                style={{ fontSize: 16, marginRight: -4 }}
                              />
                            }
                          >
                            Back
                          </ApiariButton>
                        </Box>
                      </Grid>
                    )}
                  {[STEPS.ABOUT_YOURSELF, STEPS.FUN_FACTS].includes(
                    activeStep,
                  ) && (
                    <Box item flex="1">
                      <Box px={1}>
                        <ApiariButton
                          type="submit"
                          className={classes.buttonSkip}
                        >
                          Skip
                        </ApiariButton>
                      </Box>
                    </Box>
                  )}
                  {activeStep !== STEPS.TERMS && activeStep !== STEPS.CONGRATS && (
                    <Grid item>
                      <Box px={1}>
                        <ApiariButton
                          busy={
                            isSubmitting ||
                            values.authorizedToWorkInUS === false ||
                            values.experience === 0
                          }
                          fullWidth
                          type="submit"
                          className={classes.buttonYellow}
                        >
                          {activeStep === STEPS.BACKGROUND_CHECK
                            ? 'Subscribe'
                            : 'Next'}
                        </ApiariButton>
                      </Box>
                    </Grid>
                  )}
                  {activeStep === STEPS.TERMS && (
                    <Grid item sm={8}>
                      <Box px={2}>
                        <ApiariButton
                          busy={isSubmitting}
                          fullWidth
                          type="submit"
                          className={classes.buttonYellow}
                        >
                          I AGREE AND ACCEPT
                        </ApiariButton>
                      </Box>
                    </Grid>
                  )}

                  {activeStep === STEPS.CONGRATS && (
                    <Grid item sm={12}>
                      <Grid container justify="center">
                        <Grid item>
                          <Box px={2}>
                            <ApiariButton
                              busy={isSubmitting}
                              type="button"
                              className={classes.buttonYellow}
                              onClick={gotoSite}
                            >
                              Review My Profile
                            </ApiariButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

export default React.memo(BecomeAProviderPage)

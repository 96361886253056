export const TIMES = {
  Morning: [
    { time: '8:00 AM', active: false },
    { time: '8:30 AM', active: false },
    { time: '9:00 AM', active: false },
    { time: '9:30 AM', active: false },
    { time: '10:00 AM', active: true },
    { time: '10:30 AM', active: true },
    { time: '11:00 AM', active: true },
    { time: '11:30 AM', active: true },
  ],
  Afternoon: [
    { time: '12:00 PM', active: true },
    { time: '12:30 PM', active: true },
    { time: '1:00 PM', active: true },
    { time: '1:30 PM', active: true },
    { time: '2:00 PM', active: true },
    { time: '2:30 PM', active: true },
    { time: '3:00 PM', active: true },
    { time: '3:30 PM', active: true },
    { time: '4:00 PM', active: true },
    { time: '4:30 PM', active: true },
    { time: '5:00 PM', active: true },
    { time: '5:30 PM', active: true },
  ],
  Evening: [
    { time: '6:00 PM', active: true },
    { time: '6:30 PM', active: true },
    { time: '7:00 PM', active: true },
    { time: '7:30 PM', active: true },
    { time: '8:00 PM', active: true },
    { time: '8:30 PM', active: true },
    { time: '9:00 PM', active: true },
    { time: '9:30 PM', active: false },
    { time: '10:00 PM', active: false },
    { time: '10:30 PM', active: false },
    { time: '11:00 PM', active: false },
  ],
}

export const DATE_FORMATS = {
  DATE: 'yyyy-MM-dd',
  DATE_HUMAN: 'LLLL d, yyyy',
  TIME: 'h:mm',
  TIME_HUMAN: 'h:mm aaa',
  TIME_24_CLOCK: 'HH:mm',
  FULL: 'yyyy-MM-dd HH:mm',
}

export const PETS_OPTIONS = [
  { cond: { numCats: 0, numDogs: 0 }, title: 'None', value: 'None' },
  { cond: { numCats: 0, numDogs: 1 }, title: 'Dogs', value: 'Dogs' },
  { cond: { numCats: 1, numDogs: 0 }, title: 'Cats', value: 'Cats' },
  {
    cond: { numCats: 1, numDogs: 1 },
    title: 'Cats and Dogs',
    value: 'Cats and Dogs',
  },
]

export const SMOKING_OPTIONS = [
  { value: true, title: 'Yes' },
  { value: false, title: 'No' },
]

export const GENDER_OPTIONS = [
  {
    value: 'female',
    title: 'Female',
  },
  {
    value: 'male',
    title: 'Male',
  },
]

export const INTERESTS = [
  {
    value: 'childcare',
    title: 'Babysitting / Childcare',
    key: 'childcare',
  },
  {
    value: 'childcarePlus',
    title: 'Enrichment / Tutoring',
    key: 'childcarePlus',
  },
  {
    value: 'night_nurse',
    title: 'Night Nanny',
    key: 'night_nurse',
  },
  {
    value: 'housekeeping',
    title: 'Light Housekeeping',
    key: 'housekeeping',
  },
]

export const EXPERIENCE_DURATION = (min, max, unit) => {
  const options = [
    {
      value: 0,
      title: `No Experience`,
    },
  ]
  for (let i = min; i <= max; i++) {
    options.push({
      value: i,
      title: `${i} ${i < 2 ? unit : unit + 's'}`,
    })
  }
  options.push({
    value: max + 1,
    title: `${max}+ ${unit}s`,
  })
  return options
}

export const KIDS_RANGE = start => {
  const options = [],
    yearStart = start > 1 ? start : 1,
    months = [
      {
        value: 0,
        title: 'Newborn',
      },
      {
        value: 0.083,
        title: '1-3 Months',
      },
      {
        value: 0.33,
        title: '4-6 Months',
      },
      {
        value: 0.583,
        title: '7-9 Months',
      },
      {
        value: 0.83,
        title: '10-12 Months',
      },
    ]
  if (start < 1) {
    for (let i = months.findIndex(item => item.value === start); i <= 4; i++) {
      options.push(months[i])
    }
  }
  for (let i = yearStart; i <= 18; i++) {
    options.push({
      value: i,
      title: `${i} ${i < 2 ? 'Year' : 'Years'}`,
    })
  }
  return options
}

export const IMAGE_FORMATS = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
]

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ApiariButton from '../../../components/inputs/button'
import { SmallInput } from '../account-page'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import useUpdateUser from '../../../hooks/use-update-user'
import Link from '@material-ui/core/Link'

const InfoWrapper = ({ title, children, titleWidth }) => (
  <Grid
    item
    container
    component={Box}
    flexDirection={{ xs: 'column', sm: 'row' }}
  >
    <Grid
      component={Box}
      item
      xs="auto"
      mb={{ xs: 1, sm: 0 }}
      mr={{ xs: 0, sm: 2 }}
      width={titleWidth}
    >
      <Box fontFamily="camptonBold">{title}</Box>
    </Grid>
    <Box component={Grid} item xs>
      {children}
    </Box>
  </Grid>
)

function getInitialState({ funFacts = '', about = {} }) {
  return {
    funFacts,
    about,
  }
}

const ProviderProfileInfo = props => {
  const { user, onEdit, onSave, editMode } = props
  const meta = useUpdateUser(user, getInitialState, onSave)
  const serviceNameMap = user.getServiceNamesHumanMap()

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle icon={<BulletIcon />} text="Profile Info" />
        </Grid>
        <Grid item xs="auto">
          {editMode ? (
            <>
              <Link
                color="primary"
                underline="none"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={onSave}
              >
                Cancel
              </Link>
              <ApiariButton
                onClick={() => meta.handleSave()}
                disabled={!meta.hasChanges}
                busy={meta.submitting}
              >
                Save
              </ApiariButton>
            </>
          ) : (
            <ApiariButton onClick={onEdit}>Edit</ApiariButton>
          )}
        </Grid>
      </Grid>

      <Box pl={3.6} mt={2}>
        <Grid container spacing={3}>
          <InfoWrapper title="Fun Facts" titleWidth={180}>
            {editMode ? (
              <SmallInput
                autoFocus
                value={meta.state.funFacts}
                onChange={e =>
                  meta.handleChange(e, (b, funFacts) => ({
                    ...b,
                    funFacts,
                  }))
                }
              />
            ) : (
              meta.state.funFacts
            )}
          </InfoWrapper>
          {user.services.map(serviceName => (
            <InfoWrapper
              title={`${serviceNameMap[serviceName]} Experience`}
              titleWidth={180}
              key={serviceName}
            >
              {editMode ? (
                <SmallInput
                  autoFocus
                  multiline
                  value={
                    meta.state.about[
                      serviceName === 'night_nurse' ? 'nightNurse' : serviceName
                    ]
                  }
                  onChange={e =>
                    meta.handleChange(e, (b, val) => ({
                      ...b,
                      about: {
                        ...b.about,
                        [serviceName === 'night_nurse'
                          ? 'nightNurse'
                          : serviceName]: val,
                      },
                    }))
                  }
                />
              ) : (
                meta.state.about[serviceName]
              )}
            </InfoWrapper>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default ProviderProfileInfo

import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import ViewDetailsIcon from '@material-ui/icons/KeyboardArrowRight'
import BookingDetailsModal from '../../../booking-details/booking-details-modal'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import format from 'date-fns/format'
import { capitalize, createDateFromString } from '../../../../utils/utils'

const useStyles = makeStyles(theme => ({
  title: {
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const ChatEditCard = props => {
  const classes = useStyles()
  const {
    instanceId,
    startDate,
    startTime,
    serviceName,
    eventType,
    disabled,
    title,
  } = props

  const date = createDateFromString(startDate, startTime)
  let serviceNameString
  if (eventType === 'interview') serviceNameString = '📱Interview'
  else if (eventType === 'private') serviceNameString = title
  //'🕵️‍‍‍Personal'
  else if (serviceName === 'night_nurse') serviceNameString = '👶Night Nurse'
  else if (serviceName === 'cleaning') serviceNameString = '🧹Cleaning'
  else if (serviceName === 'childcare') serviceNameString = '👩‍👧‍👦Childcare'
  else if (serviceName === 'childcarePlus') serviceNameString = '🎨Enrichment'
  else if (serviceName === 'virtual') serviceNameString = '👨‍💻Virtual'
  else if (serviceName === 'cooking') serviceNameString = '🍳Cooking'
  else if (serviceName === 'housekeeping') serviceNameString = '🦸‍JAT'
  else serviceNameString = capitalize(serviceName)
  return (
    <BookingDetailsModal instanceId={instanceId} disabled={disabled}>
      <Box
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flex={1}>
          <Box width="43%" className={classes.title}>
            {serviceNameString}
          </Box>
          <Box width="27%">&nbsp;&nbsp;{format(date, 'MMM d')}</Box>
          <Box width="30%">&nbsp;&nbsp;{format(date, 'h:mma')}</Box>
        </Box>
        <IconButton size="small">
          <ViewDetailsIcon color="action" />
        </IconButton>
      </Box>
    </BookingDetailsModal>
  )
}

ChatEditCard.propTypes = {
  instanceId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}

export default ChatEditCard

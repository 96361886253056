import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Hexagon from '../../assets/hexagon.svg'

const useStyles = makeStyles(theme => ({
  line: {
    lineHeight: '20px',
    verticalAlign: 'center',
    clear: 'left',
    marginBottom: '20px',
  },
  bullet: {
    display: 'inline-block',
    position: 'relative',
    width: '40px',
    height: '35px',
    marginLeft: '10px',
    marginRight: '10px',
    float: 'left',

    '& img': {
      maxWidth: '100%',
    },
    '& span': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
  },
  text: {
    display: 'block',
    lineHeight: '35px',
    margin: 0,
    padding: 0,
    color: theme.palette.text.grey,
  },
}))

const HexagonBullet = props => {
  const { content, text } = props

  const classes = useStyles()

  return (
    <div className={classes.line}>
      <span className={classes.bullet}>
        <img src={Hexagon} alt={content} />
        <span>{content}</span>
      </span>
      <span className={classes.text}>{text}</span>
    </div>
  )
}

export default HexagonBullet

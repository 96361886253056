import Action from './action'

export default class Message {
  constructor(message) {
    if (message.output) {
      this.output = new Action(message.output)
    }
    this.input = message.input

    this.action = 'message'
    this.appointment = message.appointment || {}
    this.provider = message.provider || {}
    this.user = message.user || {}
    this.sessionId = message.sessionId
    this.undoId = message.undoId
  }

  get isFromBot() {
    return Boolean(this.output)
  }

  get isFromSlackHuman() {
    return Boolean(this.isFromBot && this.output.image)
  }

  get isFromUser() {
    return !this.isFromBot
  }

  get userAskedForHelp() {
    return this.isFromBot && this.output.needsHuman
  }

  get isAuthenticated() {
    return this.user && this.user.token
  }

  get isStartMessage() {
    return this.input && this.input.start
  }

  get messages() {
    if (this.isFromUser) {
      return [this.input]
    }
    return this.output.displayAsArray ? this.output.message : [this.output]
  }

  get text() {
    return this.isFromBot
      ? this.output.message
      : this.input.rawText || this.input.message
  }

  get isTyping() {
    return this.isFromBot && this.output.typingIndicator
  }

  get displayMessageAsAction() {
    return (
      this.isFromBot &&
      (this.output.displaySummaryCard || this.output.displayAsPagination)
    )
  }

  get displayBottomDrawer() {
    return (
      this.isFromBot &&
      (this.output.displayStripePayment ||
        this.output.displayLoginComponent ||
        this.output.displayRegisterComponent)
    )
  }

  get image() {
    return this.isFromBot ? this.output.image : ''
  }

  get buttons() {
    return this.isFromBot ? this.output.buttons : []
  }

  authenticateUser(user) {
    this.user = { ...this.user, ...user }
  }
}

import React from 'react'
import set from 'lodash.set'

export default function useUpdateUser(
  user,
  initFn,
  onSave,
  formatFn,
  validationScheme = null,
) {
  const initState = initFn(user)
  const [state, setState] = React.useState(initState)
  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState(null)

  React.useEffect(() => {
    validate()
  }, [state])

  const validate = () => {
    if (validationScheme) {
      return validationScheme
        .validate(state, { abortEarly: false })
        .then(() => setErrors(null))
        .catch(errors => {
          const resultErrors = {}
          errors.inner.forEach(item =>
            set(resultErrors, item.path, item.message),
          )

          setErrors(resultErrors)
        })
    }
  }

  const handleReset = () => {
    setState(initState)
  }

  const handleChange = (event, changeFn, autoSubmit) => {
    event && event.persist()
    setState(b => changeFn(b, event?.target?.value))
    autoSubmit && handleSave()
  }

  const handleSave = data => {
    setSubmitting(true)
    user
      .updateUser(formatFn ? formatFn(data || state) : data || state)
      .catch(() => setState(initFn(user)))
      .finally(() => {
        onSave()
        setSubmitting(false)
      })
  }

  return {
    state,
    errors,
    submitting,
    hasChanges: JSON.stringify(state) !== JSON.stringify(initFn(user)),
    handleChange,
    handleSave,
    handleReset,
  }
}

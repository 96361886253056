import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripeCardElement from './components/stripe-card-element'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { ReactComponent as ApplyIcon } from '../../assets/ic-apply.svg'
import { ReactComponent as CheckIcon } from '../../assets/ic-checks.svg'
import { ReactComponent as ConfirmationIcon } from '../../assets/ic-confirmations.svg'
import { ReactComponent as PaymentIcon } from '../../assets/ic-payments.svg'

const useStyles = makeStyles(theme => ({
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
  infoSec: {
    background: '#EBF9F8',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '6rem !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      background: '#fff',
    },
  },
  cardSec: {
    marginTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0 !important',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: '700',
    marginBottom: '1rem',
    fontFamily: 'Roboto',
  },
  textWidth: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullHeight: {
    marginTop: '0px',
    height: '100%',
  },
}))

const STRIPE_LOAD = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const StripeIntegrationForm = () => {
  const classes = useStyles()

  const IconTypography = ({ icon, text }) => {
    return (
      <Box display="flex" alignItems="flex-start" mb={1}>
        {icon}
        <Typography style={{ marginLeft: '0.5rem' }}>{text}</Typography>
      </Box>
    )
  }

  return (
    <Box
      className={classes.fullHeight}
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={4}
    >
      <Grid
        className={classes.fullHeight}
        container
        spacing={2}
        justify="center"
      >
        <Grid className={classes.infoSec} item xs={12} md={6}>
          <Box className={classes.textWidth}>
            <Typography variant="h5" className={classes.title}>
              Join Apiari's Premium Jobs platform At{' '}
              <span style={{ color: '#36B2A7' }}>$29.99</span>/Month
            </Typography>
            <IconTypography
              icon={<ApplyIcon />}
              text="Stop applying. Let jobs come to you"
            />
            <IconTypography icon={<PaymentIcon />} text="Payments guaranteed" />
            <IconTypography
              icon={<ConfirmationIcon />}
              text="Auto confirmations and reminders"
            />
            <IconTypography
              icon={<CheckIcon />}
              text="Free background and reference checks"
            />
          </Box>
        </Grid>
        <Grid className={classes.cardSec} item xs={12} md={6}>
          <Elements stripe={STRIPE_LOAD}>
            <StripeCardElement />
          </Elements>
        </Grid>
      </Grid>
    </Box>
  )
}

export default StripeIntegrationForm

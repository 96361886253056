import React from 'react'

import { InlineShareButtons } from 'sharethis-reactjs'

const ShareButtons = ({
  blurb,
  profilePic,
  profileUrl,
  humanServiceName,
  gender,
}) => {
  return (
    <InlineShareButtons
      config={{
        alignment: 'center',
        color: 'white',
        enabled: true,
        font_size: 16,
        labels: 'cta',
        language: 'en',
        networks: ['email', 'sms', 'messenger', 'facebook'],
        padding: 12,
        radius: 4,
        size: 40,

        url: profileUrl, // (defaults to current url)
        image: profilePic, // (defaults to og:image or twitter:image)
        description: blurb, // (defaults to og:description or twitter:description)
        subject: `Check out this ${humanServiceName} provider`, // (only for email sharing)

        message: `Hi! I found this great ${humanServiceName} provider at Apiari. Check out ${
          gender === 'male' ? 'his' : 'her'
        } profile here: ${profileUrl}`, // (only for email sharing)
      }}
    />
  )
}

export default ShareButtons

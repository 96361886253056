import React, { useReducer, useRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import Message from './models/message'
import ChatBotActions from './actions/chat-bot-actions'
import {
  addMessageAction,
  backToMainMenuAction,
  replaceTypingWithMessageAction,
  undoAction,
} from './messages/messages-actions'
import MessagesList from './messages/messages-list'
import messagesReducer from './messages/messages-reducer'
import { sleep } from '../../utils/utils'
import { BackgroundSetContext } from './contexts/background-context'
import InitializingBot from './initializing-bot'
import storeContext from '../../stores'
import useQueryString from '../../hooks/use-query-string'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles({
  paper: {
    alignItems: 'center',
  },
})

const ChatBot = ({ search }) => {
  const store = React.useContext(storeContext)
  const setBackgroundImages = React.useContext(BackgroundSetContext)
  const [messages, dispatch] = useReducer(messagesReducer, [])
  const qs = useQueryString(search)
  const slackMode = useRef(false)
  const [showDrawer, setShowDrawer] = React.useState(false)
  const drawerRef = React.useRef(null)
  const classes = useStyles({ showDrawer, drawerRef })
  const sessionId = React.useRef(qs.sessionId || null)

  const processMessage = async (message, messageData, messageItem, isLast) => {
    let msg =
      message.isFromBot && message.output.displayAsArray
        ? new Message({ ...messageData, output: messageItem })
        : message

    if (isLast && msg.displayBottomDrawer) {
      setShowDrawer(true)
    }

    if (messageItem.type === 'carousel') {
      msg.output.data = messageItem.message
    }
    if (!isLast && msg.output.displayChatInput) msg.output.type = null // hide input for array of messages

    if (messageItem.pause) await sleep(messageItem.pause)
    if (!messageItem.delay) return dispatch(addMessageAction(msg))

    const typingPlaceholder = {
      output: { typingIndicator: true, userPhotoUrl: msg.image },
    }
    dispatch(addMessageAction(new Message(typingPlaceholder)))
    await sleep(messageItem.delay)
    dispatch(replaceTypingWithMessageAction(msg))
  }

  const onMessage = async ({ data }) => {
    let messageData = JSON.parse(data)

    // noinspection JSValidateTypes
    messageData.background && setBackgroundImages(messageData.background)

    if (messageData.message === 'Internal server error') {
      return dispatch(
        addMessageAction(
          new Message({
            output: {
              message:
                'Oops! Something went wrong. Please try refreshing the page or email us at hello@theapiari.com.',
            },
          }),
        ),
      )
    }

    const message = new Message({ ...messageData })

    if (message.sessionId && !sessionId.current) {
      sessionId.current = message.sessionId
    }

    if (!store.auth.isAuthenticated && message.isAuthenticated) {
      store.auth.successAuth(message.user)
    }

    if (
      !slackMode.current &&
      (message.userAskedForHelp || message.isFromSlackHuman)
    ) {
      slackMode.current = true
    } else if (message?.input?.type === 'mainMenu') {
      slackMode.current = false
    }

    // eslint-disable-next-line no-unused-vars
    for (const [i, messageItem] of message.messages.entries()) {
      const isLast = message.messages.length - 1 === i
      if (messageItem.type === 'text' && !messageItem.message) continue
      await processMessage(message, messageData, messageItem, isLast)
    }
  }

  React.useEffect(() => {
    store.ws.addEventListener('message', onMessage)
    const { host } = window.location
    const providerId = qs.providerId
    const startIntent = qs.startIntent
    const slots = qs.slots
    const sig = qs.sig
    const data = qs.data
    const admin = !!qs.admin
    let origin = 'web'

    if (host.indexOf('providers.theapiari') !== -1) origin = 'provider'
    else if (host.indexOf('apiari.net') !== -1) origin = 'saas'

    handleSendMessage({
      input: {
        origin,
        start: true,
        admin,
        startIntent,
        sig,
        slots: slots ? JSON.parse(slots) : {},
        data: data ? JSON.parse(data) : {},
      },
      provider: {
        providerId,
      },
    })

    return () => store.ws.removeEventListener('message', onMessage)
  }, []) // eslint-disable-line

  const handleSendMessage = data => {
    const payload = new Message({ ...data, sessionId: sessionId.current })

    const token = localStorage.getItem('apiari_token')
    if (token) {
      payload.authenticateUser({ token })
    }

    if (showDrawer) {
      setShowDrawer(false)
    }

    store.ws.send(JSON.stringify(payload))

    if (slackMode.current || payload.isStartMessage) return // all messages in slackMode are handled by websocket

    // in botMode we need to handle local messages (mock)
    dispatch(addMessageAction(payload))
  }

  if (!messages.length) return <InitializingBot />

  console.log('messages = ', messages)
  console.log(
    'ids = ',
    messages.map(m => m.undoId),
  )

  return (
    <>
      <Box height="100%">
        <MessagesList
          messages={messages}
          onSend={handleSendMessage}
          isSlackMode={slackMode.current}
          onUndo={payload => {
            dispatch(undoAction(payload))
          }}
          showDrawer={showDrawer}
          onBack={() => dispatch(backToMainMenuAction())}
        />
      </Box>
      {showDrawer && (
        <Drawer
          variant="permanent"
          open
          anchor="bottom"
          classes={{ paper: classes.paper }}
        >
          <Box ref={drawerRef}>
            <ChatBotActions
              action={messages[messages.length - 1].output}
              onSend={handleSendMessage}
            />
          </Box>
        </Drawer>
      )}
    </>
  )
}

export default ChatBot

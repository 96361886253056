export default (state, { type, payload }) => {
  switch (type) {
    case 'REPLACE_TYPING_WITH_MESSAGE':
      return state.map(i => (i.isTyping ? payload : i))
    case 'ADD_MESSAGE_ACTION':
      return [...state, payload]
    case 'UNDO': {
      const match = [...state].reverse().find(el => {
        if (el.undoId && el.undoId !== payload) return true
        return el.isFromBot && el.undoId === null
      })
      if (!match) return []
      const newState = [...state]
      newState.length = state.indexOf(match)
      return newState
    }
    case 'BACK_TO_MAIN_MENU': {
      return []
    }
    default:
      return state
  }
}

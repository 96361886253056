import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Radio from '@material-ui/core/Radio'
import { ErrorMessage, getIn } from 'formik'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import {
  ProviderSelect,
  ProviderRadio,
  ProviderFormField,
} from '../become-a-provider-styles'
import ApiariSelect from '../../../components/inputs/select/select'
import ApiariInput from '../../../components/inputs/input'
import Chip from '@material-ui/core/Chip'
import ClearIcon from '@material-ui/icons/Clear'

import { KIDS_RANGE } from '../../../utils/config'
import LANGUAGES from '../../../utils/languages'

const useStyles = makeStyles(theme => ({
  formControlLanguage: {
    '& .MuiSelect-select': {
      color: 'transparent',
    },
  },
  smallTextarea: {
    '& > div.MuiInputBase-root textarea': {
      minHeight: '20px',
      maxHeight: '20px',
    },
  },
  chipContainer: {
    '& .MuiChip-root': {
      minWidth: '0',
      justifyContent: 'space-between',
      padding: '0 10px 0 24px',
      marginBottom: '10px',
      marginRight: '10px',
      background: theme.palette.stepper.inactive,
      '& .MuiChip-label': {
        padding: '0px',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: '600',
        marginRight: '10px',
      },
      '& .MuiChip-deleteIcon': {
        color: theme.palette.stepper.active,

        '&:hover': {
          color: theme.palette.text.grey,
        },
      },
    },
    // '& .MuiChip-root + .MuiChip-root': {
    //   marginLeft: '10px',
    // },
  },
  selectLabel: {
    color: 'black',
    marginBottom: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '1',
  },
  rangeTo: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '56px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1',
      marginBottom: '20px',
    },
  },
}))

const PreferencesForm = props => {
  const { isSubmitting, values, errors, touched, setFieldValue } = props

  // workaround for "Material-UI: A component is changing the uncontrolled value state of RadioGroup to be controlled." error
  values.specialNeeds =
    values.specialNeeds === undefined ? '' : values.specialNeeds

  const classes = useStyles()
  const [isSelectOpen, setSelectOpen] = React.useState(false)

  const handleSelectOpen = () => {
    setSelectOpen(true)
  }

  const handleSelectClose = () => {
    setSelectOpen(false)
  }

  const toggleOtherLanguages = val => {
    if (!val) {
      setFieldValue('knowsOtherLanguage', false, false)
      setFieldValue('otherLanguages', [], false)
    } else {
      setFieldValue('knowsOtherLanguage', true, false)
    }
  }

  const removeLanguage = lang => {
    setFieldValue(
      'otherLanguages',
      values.otherLanguages.filter(item => item !== lang),
      false,
    )
  }

  const getSelectedLanguages = () => {
    const selected = LANGUAGES.filter(lang =>
      values.otherLanguages?.includes(lang.value),
    )
    return [...new Map(selected.map(item => [item.value, item])).values()]
  }

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            What is the age range of kids you’d like to care for?
          </Box>
        </Grid>
        <Grid container>
          <Grid item sm={5} xs={12}>
            <Box px={1}>
              <ProviderSelect className={classes.formControl}>
                <ApiariSelect
                  labelId="childcareAgeRange"
                  id="childcareAgeRange_1"
                  name="childcareAgeRange.min"
                  value={values.childcareAgeRange.min}
                  options={KIDS_RANGE(0)}
                  onChange={e =>
                    setFieldValue(
                      'childcareAgeRange.min',
                      e.target.value,
                      false,
                    )
                  }
                  error={
                    getIn(errors, 'childcareAgeRange.min') &&
                    getIn(touched, 'childcareAgeRange.min')
                  }
                />
                {!!getIn(errors, 'childcareAgeRange.min') &&
                  getIn(touched, 'childcareAgeRange.min') && (
                    <ErrorMessage
                      component={FormHelperText}
                      name="childcareAgeRange.min"
                    />
                  )}
              </ProviderSelect>
            </Box>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Box className={classes.rangeTo}>to</Box>
          </Grid>
          <Grid item sm={5} xs={12}>
            <Box px={1}>
              <ProviderSelect className={classes.formControl}>
                <ApiariSelect
                  labelId="childcareAgeRange1"
                  id="childcareAgeRange_2"
                  name="childcareAgeRange.max"
                  value={values.childcareAgeRange.max}
                  options={KIDS_RANGE(values.childcareAgeRange.min || 0)}
                  onChange={e =>
                    setFieldValue(
                      'childcareAgeRange.max',
                      e.target.value,
                      false,
                    )
                  }
                  error={
                    getIn(errors, 'childcareAgeRange.max') &&
                    getIn(touched, 'childcareAgeRange.max')
                  }
                />
                {!!getIn(errors, 'childcareAgeRange.max') &&
                  getIn(touched, 'childcareAgeRange.max') && (
                    <ErrorMessage
                      component={FormHelperText}
                      name="childcareAgeRange.max"
                    />
                  )}
              </ProviderSelect>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Do you have any experience caring for kids with special needs? If so
            please tell us more in 1-2 sentences.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} mb={3}>
            <ProviderRadio component="fieldset" className={classes.customRadio}>
              <RadioGroup
                aria-label="specialNeeds"
                name="specialNeeds"
                value={values.specialNeeds}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      onChange={() =>
                        setFieldValue('specialNeeds', true, false)
                      }
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      onChange={() =>
                        setFieldValue('specialNeeds', false, false)
                      }
                    />
                  }
                  label="No"
                />
              </RadioGroup>
              {!!errors.specialNeeds && touched.specialNeeds && (
                <FormHelperText>{errors.specialNeeds}</FormHelperText>
              )}
            </ProviderRadio>
          </Box>
        </Grid>
        {values.specialNeeds && (
          <Grid item xs={12}>
            <Box px={1}>
              <ProviderFormField
                className={classes.smallTextarea}
                component={ApiariInput}
                disabled={isSubmitting}
                multiline
                name="specialNeedsExperience"
                variant="standard"
              />
            </Box>
          </Grid>
        )}
        {/*        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Do you have experience caring for twins, multiples and siblings?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderRadio component="fieldset" className={classes.customRadio}>
              <RadioGroup
                aria-label="multipleSiblingsExperience"
                name="multipleSiblingsExperience"
                value={values.multipleSiblingsExperience}
              >
                <FormControlLabel
                  value="twins"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('multipleSiblingsExperience', 'twins', false)
                      }}
                    />
                  }
                  label="Twins"
                />
                <FormControlLabel
                  value="multiples"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('multipleSiblingsExperience', 'multiples', false)
                      }}
                    />
                  }
                  label="Multiples"
                />
                <FormControlLabel
                  value="siblings"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('multipleSiblingsExperience', 'siblings', false)
                      }}
                    />
                  }
                  label="Siblings"
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('multipleSiblingsExperience', '', false)
                      }}
                    />
                  }
                  label="None of the above"
                />
              </RadioGroup>
            </ProviderRadio>
          </Box>
        </Grid>*/}

        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Are you willing to work in home with dogs and cats?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderRadio component="fieldset" className={classes.customRadio}>
              <RadioGroup
                aria-label="catsAndDogs"
                name="catsAndDogs"
                value={values.catsAndDogs}
              >
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('catsAndDogs', 'all', false)
                      }}
                    />
                  }
                  label="I am a-ok with both!"
                />
                <FormControlLabel
                  value="noDogs"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('catsAndDogs', 'noDogs', false)
                      }}
                    />
                  }
                  label="No dogs for me"
                />
                <FormControlLabel
                  value="noCats"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('catsAndDogs', 'noCats', false)
                      }}
                    />
                  }
                  label="No cats for me"
                />
                <FormControlLabel
                  value="neither"
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('catsAndDogs', 'neither', false)
                      }}
                    />
                  }
                  label="No dogs or cats"
                />
              </RadioGroup>
              {!!errors.catsAndDogs && touched.catsAndDogs && (
                <FormHelperText>{errors.catsAndDogs}</FormHelperText>
              )}
            </ProviderRadio>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Do you speak another language besides English?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} mb={2}>
            <ProviderRadio component="fieldset" className={classes.customRadio}>
              <RadioGroup
                aria-label="knowsOtherLanguage"
                name="knowsOtherLanguage"
                value={values.knowsOtherLanguage}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio onChange={() => toggleOtherLanguages(true)} />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio onChange={() => toggleOtherLanguages(false)} />
                  }
                  label="No"
                />
              </RadioGroup>
              {!!errors.knowsOtherLanguage && touched.knowsOtherLanguage && (
                <FormHelperText>{errors.knowsOtherLanguage}</FormHelperText>
              )}
            </ProviderRadio>
          </Box>
        </Grid>
        {values.knowsOtherLanguage && (
          <Grid container>
            <Grid item sm={8} xs={12}>
              <Box px={1} className={classes.formControlLanguage}>
                <ProviderSelect className={classes.formControl}>
                  <ApiariSelect
                    labelId="otherLanguages"
                    id="otherLanguages"
                    multiple
                    name="otherLanguages"
                    value={values.otherLanguages}
                    options={LANGUAGES}
                    onChange={e => {
                      setFieldValue('otherLanguages', e.target.value, false)
                      setSelectOpen(false)
                    }}
                    open={isSelectOpen}
                    onClose={handleSelectClose}
                    onOpen={handleSelectOpen}
                  />
                  {!!errors.otherLanguages && touched.otherLanguages && (
                    <FormHelperText>{errors.otherLanguages}</FormHelperText>
                  )}
                </ProviderSelect>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={1} className={classes.chipContainer}>
                {getSelectedLanguages().map(lang => (
                  <Chip
                    key={lang.value}
                    label={lang.title}
                    onDelete={() => removeLanguage(lang.value)}
                    deleteIcon={<ClearIcon />}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default React.memo(PreferencesForm)

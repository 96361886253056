import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { ReactComponent as BulletIcon } from '../../../../../assets/bullet.svg'
import { ReactComponent as CheckedIcon } from '../../../../../assets/checked.svg'

const ProfileQualifications = ({ qualifications = [] }) => {
  if (qualifications.length === 0) {
    return <></>
  }
  return (
    <>
      <Grid item container alignItems="center">
        <Box color="secondary.main" display="flex" alignItems="center">
          <BulletIcon width={22} />
        </Box>
        <Box ml={1} fontFamily="camptonBold" fontSize={18}>
          Qualifications
        </Box>
      </Grid>
      {qualifications.map(({ title, subTitle, subTitleLink, icon }) => (
        <Grid item container alignItems="baseline" key={title}>
          <Box color="primary.main">
            {icon ? (
              <Box width={22} fontSize="1.3rem" position="relative" top={7}>
                {icon}
              </Box>
            ) : (
              <CheckedIcon width={22} />
            )}
          </Box>
          <Grid item>
            <Box ml={1}>
              <Grid item>
                <Box fontFamily="camptonBold">{title}</Box>
              </Grid>
              {subTitle && (
                <Grid item>
                  {subTitleLink ? (
                    <Box color="primary.main">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={subTitleLink}
                      >
                        {subTitle}
                      </a>
                    </Box>
                  ) : (
                    subTitle
                  )}
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

ProfileQualifications.propTypes = {
  qualifications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
    }),
  ),
}

export default ProfileQualifications

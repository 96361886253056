import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Box from '@material-ui/core/Box'
import ForgotPasswordForm from './forgot-password-form'
import { forgotPasswordApi } from '../../api/auth-api'

const ForgotPasswordPage = () => {
  const [successState, setSuccessState] = React.useState(false)

  const forgotSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid e-mail address')
      .required('Email is required'),
  })

  return (
    <Box maxWidth={{ md: 600 }} m="auto" bgcolor="white">
      <Box py={{ xs: 6, sm: 8 }} px={{ xs: 4, sm: 16 }}>
        <Box align="center" fontFamily="camptonBold" fontSize={18} mb={6}>
          {successState ? 'Check your email!' : 'Forgot Password'}
        </Box>
        {!successState ? (
          <Formik
            validationSchema={forgotSchema}
            initialValues={{ email: '' }}
            component={ForgotPasswordForm}
            onSubmit={({ email }, { setErrors }) =>
              forgotPasswordApi(email)
                .then(() => setSuccessState(true))
                .catch(() =>
                  setErrors({ forgotPassword: 'Something went wrong' }),
                )
            }
          />
        ) : (
          <Box align="center">
            We've sent instructions to update your password to your email
            address.
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ForgotPasswordPage

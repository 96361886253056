import { useFormikContext, Field } from 'formik'
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import ApiariInput from '../../../components/inputs/input'
import { ProviderRadio } from '../../become-a-provider/become-a-provider-styles'

const useStyles = makeStyles(theme => ({
  radioInput: {
    '&.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
  },
}))

export const OtherReasonFeild = withStyles(theme => ({
  root: {
    '& > div input, & > div textarea': {
      background: theme.palette.background.light,
      border: '1px solid',
      borderColor: theme.palette.border.light,
      borderRadius: '6px',
      height: '54px',
      padding: '0 20px',
      fontWeight: '600',
    },
    '& > div textarea': {
      minHeight: '3em',
      padding: '16px',
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: '300',
      lineHeight: '20px',
    },
    '& > div input:focus, & > div textarea:focus': {
      background: 'white',
      border: '1px solid #8fc8ce',
      borderColor: theme.palette.border.active,
    },
    '& > div.Mui-error input, & > div.Mui-error textarea': {
      background: 'white',
      border: '1px solid',
      borderColor: theme.palette.border.error,
    },
  },
}))(Field)

const UnsubscribeForm = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext()
  const { radioInput } = useStyles()

  return (
    <ProviderRadio component="fieldset">
      <RadioGroup aria-label="specialNeeds" name="reason" value={values.reason}>
        <FormControlLabel
          value="I found the clients I need on Apiari"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue(
                  'reason',
                  'I found the clients I need on Apiari',
                  false,
                )
              }}
            />
          }
          label="I found the clients I need on Apiari"
        />
        <FormControlLabel
          value="I found a job elsewhere"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue('reason', 'I found a job elsewhere', false)
              }}
            />
          }
          label="I found a job elsewhere"
        />
        <FormControlLabel
          value="I no longer work in childcare"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue('reason', 'I no longer work in childcare', false)
              }}
            />
          }
          label="I no longer work in childcare"
        />
        <FormControlLabel
          value="It took too long to find work on Apiari"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue(
                  'reason',
                  'It took too long to find work on Apiari',
                  false,
                )
              }}
            />
          }
          label="It took too long to find work on Apiari"
        />
        <FormControlLabel
          value="I don't want my subscription to auto-renew"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue(
                  'reason',
                  "I don't want my subscription to auto-renew",
                  false,
                )
              }}
            />
          }
          label="I don't want my subscription to auto-renew"
        />
        <FormControlLabel
          value="other"
          className={radioInput}
          control={
            <Radio
              onChange={() => {
                setFieldValue('reason', 'other', false)
              }}
            />
          }
          label="Other:"
        />
      </RadioGroup>
      <OtherReasonFeild
        component={ApiariInput}
        disabled={values.reason !== 'other'}
        name="otherReason"
        placeholder="Please, tell us more..."
        variant="standard"
        inputProps={{
          maxlength: 200,
        }}
        multiline
      />
      {!!errors.specialNeeds && touched.specialNeeds && (
        <FormHelperText>{errors.specialNeeds}</FormHelperText>
      )}
    </ProviderRadio>
  )
}

export default UnsubscribeForm

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Picker from 'react-mobile-picker'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { getHours, getMinutes } from 'date-fns'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f8fafd',
    boxShadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '& .picker-item': {
      cursor: 'pointer',
      userSelect: 'none',
    },
    '& .picker-item-selected': {},

    '& .picker-highlight': {
      border: '1px solid #FFC43B',
      borderRadius: '10px',
      background: 'white',
      zIndex: -1,

      '&::before, &::after': {
        content: 'normal',
      },
    },
  },
}))

const PICKER_TYPES = {
  width: 310,
  options: {
    hours: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    minutes: [
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50',
      '55',
    ],
    ampm: ['AM', 'PM'],
  },
  defaultValue: {
    hours: '2',
    minutes: '30',
    ampm: 'AM',
  },
}

function getTimeValue({ hours, minutes, ampm }) {
  const timeValue = new Date()

  timeValue.setHours(
    (parseInt(hours) === 12 ? 0 : parseInt(hours)) + (ampm === 'PM' ? 12 : 0),
    parseInt(minutes),
  )

  return timeValue
}

function getDefaultValue(time) {
  if (!time) {
    return PICKER_TYPES.defaultValue
  }

  return {
    hours: String(getHours(time) % 12 || 12),
    minutes: String(Math.floor(getMinutes(time) / 5) * 5).padStart(2, '0'),
    ampm: getHours(time) >= 12 ? 'PM' : 'AM',
  }
}

function getOptionGroups() {
  return PICKER_TYPES.options
}

const ApiariTimePicker = ({ onChange, initiaTimelValue }) => {
  const classes = useStyles()
  const [valueGroups, setValueGroups] = useState(
    getDefaultValue(initiaTimelValue),
  )

  useEffect(() => {
    const timeValue = getTimeValue(valueGroups)
    onChange && onChange(timeValue)
  }, [])

  // Update the value in response to user picking event
  const handleChange = (sectionName, sectionValue) => {
    const value = {
      ...valueGroups,
      [sectionName]: sectionValue,
    }
    setValueGroups(value)

    const timeValue = getTimeValue(value)
    onChange && onChange(timeValue)
  }

  return (
    <Box className={classes.container} width={PICKER_TYPES.width} p={2}>
      <Picker
        wheel="normal"
        optionGroups={getOptionGroups()}
        valueGroups={valueGroups}
        onChange={handleChange}
      />
    </Box>
  )
}

ApiariTimePicker.propTypes = {
  onChange: PropTypes.func,
  initiaTimelValue: PropTypes.instanceOf(Date),
}

export default ApiariTimePicker

import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { ReactComponent as CalendarIcon } from '../../../../../assets/calendar.svg'
import { ReactComponent as ConversationIcon } from '../../../../../assets/conversation.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FavoriteButton from './favorite-button'
import storeContext from '../../../../../stores'
import { useObserver } from 'mobx-react-lite'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    justifyContent: 'flex-start',
  },
}))

const ProfileActions = ({ alias, id, onInterview }) => {
  const classes = useStyles()
  const store = React.useContext(storeContext)
  return useObserver(() => (
    <Box
      width={{ md: 220 }}
      display="flex"
      flexDirection="column"
      minWidth={230}
    >
      <a
        href={`/calendar/${alias || id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button color="primary" className={classes.button}>
          <Box
            component="span"
            color="secondary.main"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon width={22} />
          </Box>
          <Box component="span" ml={1}>
            VIEW CALENDAR
          </Box>
        </Button>
      </a>
      <Button
        color="primary"
        className={[classes.button, 'only-apiari-classic']}
        onClick={onInterview}
      >
        <Box
          component="span"
          color="secondary.main"
          display="flex"
          alignItems="center"
        >
          <ConversationIcon width={22} />
        </Box>
        <Box component="span" ml={1}>
          INTERVIEW
        </Box>
      </Button>
      {store.auth.isAuthenticated && (
        <FavoriteButton providerId={id} buttonClass={classes.button} />
      )}
    </Box>
  ))
}

ProfileActions.propTypes = {
  id: PropTypes.number.isRequired,
  onRequestBooking: PropTypes.func,
  onInterview: PropTypes.func,
}

export default ProfileActions

import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { ReactComponent as BulletIcon } from '../../../../../assets/bullet.svg'
import { ReactComponent as CheckedIcon } from '../../../../../assets/checked.svg'

const ProfileCertificates = ({ certificates = [] }) => {
  if (certificates.length === 0) {
    return <></>
  }
  return (
    <>
      <Grid item container alignItems="center">
        <Box color="secondary.main" display="flex" alignItems="center">
          <BulletIcon width={22} />
        </Box>
        <Box ml={1} fontFamily="camptonBold" fontSize={18}>
          Certifications
        </Box>
      </Grid>
      {certificates.map(({ name, date }) => (
        <Grid item container alignItems="baseline" key={name}>
          <Box color="primary.main">
            <CheckedIcon width={22} />
          </Box>
          <Grid item>
            <Box ml={1} maxWidth={180}>
              <Grid item>
                <Box fontFamily="camptonBold">{name}</Box>
              </Grid>
              {date && <Grid item>{date}</Grid>}
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

ProfileCertificates.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
}

export default ProfileCertificates

import React from 'react'
import { Field, Form } from 'formik'
import { Link } from '@reach/router'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ApiariInput from '../../components/inputs/input'
import ApiariButton from '../../components/inputs/button'
import Typography from '@material-ui/core/Typography'

const LoginForm = props => {
  const { isSubmitting, errors } = props

  return (
    <Grid component={Form} container direction="column" noValidate spacing={2}>
      <Typography color="error" variant="caption" align="center">
        {errors.login}
      </Typography>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="email"
          type="email"
          label="Email"
        />
      </Grid>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="password"
          type="password"
          label="Password"
        />
      </Grid>
      <Grid item justify="flex-end" container>
        <Box component={Link} color="inherit" to="/forgot-password">
          Forgot Password?
        </Box>
      </Grid>
      <Grid item>
        <ApiariButton busy={isSubmitting} fullWidth type="submit">
          Login
        </ApiariButton>
      </Grid>
    </Grid>
  )
}

export default LoginForm

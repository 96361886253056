import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Shimmer from '../../../../../components/shimmer/shimmer'
import Hidden from '@material-ui/core/Hidden'

const ProviderDetailsLayout = props => {
  return (
    <Grid container direction="column" style={{ padding: 8 }}>
      <Grid item container spacing={2}>
        <Grid item xs={12} md>
          <Box display={{ xs: 'flex', md: 'block' }} className="dmytro">
            <Box
              display="flex"
              flex={{ xs: 1, md: 0 }}
              alignItems={{ xs: 'center', md: 'flex-start' }}
              mb={3}
            >
              <Box mr={{ xs: 1, md: 4 }}>
                <Box width={{ xs: 110, md: 150 }} height={{ xs: 110, md: 150 }}>
                  {props.avatar}
                </Box>
              </Box>
              <Box
                flexGrow={1}
                fontSize={{ xs: 20, md: 30 }}
                fontFamily="camptonBold"
              >
                {props.nameAndRate}
                <Box className="only-apiari-classic" display="flex">
                  <Grid container direction="row" alignItems="center">
                    <Box fontSize={{ xs: 8 }}>{props.rating}</Box>
                    <Box sx={{ ml: 2 }} fontSize={{ xs: 13 }}>
                      &nbsp;{props.ratingVal}
                    </Box>
                  </Grid>
                </Box>
                <Box
                  sx={{ ml: 2 }}
                  fontFamily="campton"
                  fontSize={{ xs: 14 }}
                  style={{ paddingLeft: 4 }}
                  className="only-apiari-classic"
                >
                  {' '}
                  {parseFloat(props.reliabilityScoreVal).toFixed(0)}% Bookings
                  Accepted
                </Box>
                <Box
                  sx={{ ml: 2 }}
                  mb={1}
                  fontFamily="campton"
                  fontSize={{ xs: 14 }}
                  style={{ paddingLeft: 4 }}
                  className="only-apiari-classic"
                >
                  {' '}
                  {props.reviewCount} Reviews
                </Box>
                <Box className="only-apiari-classic">{props.classicCta}</Box>
                <Box className="only-apiari-business">{props.businessCta}</Box>
              </Box>
            </Box>
            <Grid item style={{ padding: 8 }}>
              <Grid container direction="column" spacing={2}>
                <Hidden smDown>
                  <Grid item container direction="column">
                    {props.about}
                  </Grid>
                  <Grid item container direction="column">
                    {props.reviews}
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item container direction="column">
                    {props.actions}
                    <Hidden smDown>{props.shareButtons}</Hidden>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md="auto">
          <Box
            width={{ xs: '100%', md: 250 }}
            ml={{ md: 2 }}
            style={{ padding: 12 }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item container direction="column" spacing={1}>
                <Hidden mdUp>
                  {props.about}
                  {props.reviews}
                </Hidden>
                <Hidden smDown>{props.actions}</Hidden>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                {props.qualifications}
              </Grid>
              <Grid item container direction="column" spacing={1}>
                {props.certificates}
              </Grid>
              <Grid>{props.shareButtons}</Grid>
              {/* <Hidden smDown>{<div>TEXT TEXT TEXT</div>}</Hidden> */}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

ProviderDetailsLayout.defaultProps = {
  avatar: (
    <Box position="relative" height="100%">
      <Shimmer h="100%" r="50%" />
    </Box>
  ),
  nameAndRate: (
    <Box width={{ xs: '100%', md: 280 }}>
      <Shimmer />
    </Box>
  ),
  rating: (
    <Box mt={1}>
      <Shimmer w={24 * 5} />
    </Box>
  ),
  about: <Shimmer h={200} />,
  reviews: <Shimmer h={150} />,
  actions: <Shimmer h={36 * 3} />,
  shareButtons: <Shimmer h={36 * 3} />,
  cta: (
    <Box mt={2}>
      <Shimmer h={36} w={180} r={20} />
    </Box>
  ),
  qualifications: <Shimmer h={200} />,
  certificates: <Shimmer h={200} />,
}

export default ProviderDetailsLayout

export const addMessageAction = payload => ({
  type: 'ADD_MESSAGE_ACTION',
  payload,
})

export const undoAction = undoId => ({
  type: 'UNDO',
  payload: undoId,
})

export const backToMainMenuAction = undoId => ({
  type: 'BACK_TO_MAIN_MENU',
  payload: undoId,
})

export const replaceTypingWithMessageAction = payload => ({
  type: 'REPLACE_TYPING_WITH_MESSAGE',
  payload,
})

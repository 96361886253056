import React from 'react'
import * as PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg'
import Box from '@material-ui/core/Box'
import { ReactComponent as CornerIcon } from '../../../../assets/modal-corner.svg'
import UserDetails, { userDetailsPropTypes } from './details'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'
import storeContext from '../../../../stores'

const useStyles = makeStyles(theme => ({
  close: {
    position: 'sticky',
    top: 10,
    left: '100%',
    textAlign: 'right',
    cursor: 'pointer',
    zIndex: 1,
    color: theme.palette.primary.main,
  },
}))

const DetailsModal = props => {
  const { children, detailsProps, onInterview } = props
  const store = React.useContext(storeContext)

  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    store.users.getUser(detailsProps.id)
  }, [detailsProps.id, store])

  const user = store.users.entries.get(detailsProps.id)

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => setIsModalOpen(true),
      })}
      {isModalOpen && (
        <Dialog
          PaperProps={{
            style: {
              marginTop: '96px',
              height: 'calc(calc(100vh - 65px) * 0.9)',
              maxWidth: '700px',
            },
          }}
          open
          onClose={() => setIsModalOpen(false)}
          maxWidth="md"
          fullScreen={fullScreen}
          fullWidth
        >
          <DialogContent>
            <CloseIcon
              className={classes.close}
              onClick={() => setIsModalOpen(false)}
            />
            <Box position="relative" className="apiari-classic">
              <Box
                position="absolute"
                top={-44}
                left={-25}
                color="secondary.main"
              >
                <CornerIcon />
              </Box>
              <UserDetails
                user={user}
                service={detailsProps.service}
                onInterview={() => {
                  onInterview && onInterview()
                  setIsModalOpen(false)
                }}
                onRequestBooking={() => {
                  detailsProps.onRequestBooking()
                  setIsModalOpen(false)
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

DetailsModal.propTypes = {
  detailsProps: PropTypes.shape(userDetailsPropTypes).isRequired,
  children: PropTypes.element.isRequired,
  onInterview: PropTypes.func,
}

export default DetailsModal

import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import Cropper from 'react-easy-crop'
import ApiariButton from '../inputs/button'
import { getCroppedImg } from './image-utils'
import { makeStyles } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      minWidth: '420px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      marginBottom: '32px',

      '& h2': {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '300px',
        fontSize: '18px',
        lineHeight: '1em',
        fontFamily: theme.typography.camptonBold,

        '& svg': {
          height: '13px',
          width: '13px',
          color: theme.palette.text.lightGrey,
          cursor: 'pointer',
        },
      },
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      borderRadius: '10px',
    },
    '& .MuiDialogActions-root': {
      padding: 0,

      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
}))

const useCircleButtonStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    color: theme.palette.text.grey,
    border: `2px solid ${theme.palette.text.grey}`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.background.light,
    },

    '& svg': {
      fontSize: '16px',
    },
  },
}))

const minZoom = 1
const maxZoom = 3
const zoomStep = 0.1

const AvatarCropper = ({ title, handleClose, file, onSave }) => {
  const theme = useTheme()
  const classes = useStyles()
  const circleButtonClasses = useCircleButtonStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [fileUrl, setFileUrl] = useState('')
  const [zoom, setZoom] = useState(1.5)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  useEffect(() => {
    if (file) {
      setFileUrl(URL.createObjectURL(file))
    }
  }, [file])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    const file = await getCroppedImg(fileUrl, croppedAreaPixels)
    onSave(file)
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="xs"
      fullScreen={fullScreen}
      classes={classes}
    >
      <Box
        p={{ xs: 2, sm: 4, md: 4 }}
        display={{ xs: 'flex', sm: 'block' }}
        flexDirection="column"
        alignItems="center"
      >
        <DialogTitle>
          <span>{title}</span>
          <CloseIcon onClick={handleClose} />
        </DialogTitle>
        <Box mb={3} component={DialogContent}>
          <Box overflow="hidden" position="relative" width={360} height={360}>
            <div className="crop-container">
              <Cropper
                image={fileUrl}
                objectFit="auto-cover"
                cropShape="round"
                showGrid={false}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </Box>
        </Box>
        <DialogActions>
          <Box display="flex">
            <Box display="flex" alignItems="center" width={208}>
              <Box mr={1.5}>
                <span className={circleButtonClasses.root}>
                  <RemoveIcon
                    onClick={() => zoom > minZoom && setZoom(zoom - zoomStep)}
                  />
                </span>
              </Box>
              <Slider
                value={zoom}
                min={minZoom}
                max={maxZoom}
                step={0.1}
                onChange={(event, value) => setZoom(value)}
                aria-labelledby="continuous-slider"
              />
              <Box ml={1.5}>
                <span className={circleButtonClasses.root}>
                  <AddIcon
                    onClick={() => zoom < maxZoom && setZoom(zoom + zoomStep)}
                  />
                </span>
              </Box>
            </Box>
            <Box width={120} ml={4}>
              <ApiariButton onClick={handleSave}>Save</ApiariButton>
            </Box>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

AvatarCropper.propTypes = {
  title: PropTypes.node,
  file: PropTypes.instanceOf(File),
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default AvatarCropper

import { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.secondary.brandGreenLight,
    padding: '20px 40px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
      padding: '20px',
    },

    '& .text-container': {
      marginRight: '8px',
      flexGrow: 1,

      [theme.breakpoints.down('xs')]: {
        marginRight: '20px',
        marginBottom: '16px',
      },
    },

    '& .buttons-container': {
      marginRight: '44px',
    },

    '& .close-button-container': {
      position: 'absolute',
      right: '40px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.text.lightGrey,
      cursor: 'pointer',

      [theme.breakpoints.down('xs')]: {
        top: '20px',
        right: '20px',
        transform: 'none',
      },
    },
  },
  title: {
    fontSize: '15px',
    fontWeight: 700,
    color: theme.palette.text.grey,
  },
  text: {
    color: theme.palette.text.primary,
  },
}))

const Banner = ({ title, text, button, isDismissible = true }) => {
  const classes = useStyles()
  const [isDismissed, setIsDismissed] = useState(false)

  if (isDismissed) return false

  return (
    <div className={['apiari-banner', classes.container].join(' ')}>
      <div className="text-container">
        <div>
          <span className={classes.title}>{title}</span>
        </div>
        <div>
          <span className={classes.text}>{text}</span>
        </div>
      </div>
      <div className="buttons-container">{button}</div>
      {isDismissible && (
        <div className="close-button-container">
          <CloseIcon onClick={() => setIsDismissed(true)} />
        </div>
      )}
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  dismissible: PropTypes.bool,
}

export default Banner

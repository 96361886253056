import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

const options = [
  { value: 'Sunday', text: 'Sun' },
  { value: 'Monday', text: 'Mon' },
  { value: 'Tuesday', text: 'Tue' },
  { value: 'Wednesday', text: 'Wed' },
  { value: 'Thursday', text: 'Thu' },
  { value: 'Friday', text: 'Fri' },
  { value: 'Saturday', text: 'Sat' },
]

const useStyles = makeStyles(theme => ({
  root: {},
  chipContainer: {
    display: 'flex',
    margin: '0 -2px',
  },
  chip: {
    margin: '0 2px',
    color: '#595A5A',
    border: 'none',
    outline: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    fontWeight: 700,

    '&.MuiChip-clickableColorPrimary:not(.MuiChip-outlined)': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& .MuiChip-label': {
      textOverflow: 'ellipsis',
      padding: '8px',
      lineHeight: '1.2em',
      fontSize: '12px',
    },
  },
}))

const WeekDaySelect = ({ label, onChange, defaultValue, isRequired }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState({})

  useEffect(function() {
    setSelected(
      (defaultValue || []).reduce(
        (acc, item) => ({ ...acc, [item]: true }),
        {},
      ),
    )
  }, [])

  useEffect(
    function() {
      onChange(Object.keys(selected))
    },
    [selected],
  )

  function onSelect(value) {
    if (selected[value]) {
      // if isRequired - prevent unselecting of the last one
      if (isRequired && Object.keys(selected).length <= 1) {
        return
      }

      delete selected[value]
      setSelected({ ...selected })
    } else {
      setSelected({ ...selected, [value]: true })
    }
  }

  return (
    <div className={classes.root}>
      {label && <label className="MuiInputLabel-root">{label}</label>}
      <div className={classes.chipContainer}>
        {options.map(({ text, value }) => (
          <Chip
            key={value}
            className={classes.chip}
            variant={selected[value] ? 'default' : 'outlined'}
            color="primary"
            label={text}
            clickable
            onClick={() => onSelect(value)}
          />
        ))}
      </div>
    </div>
  )
}

WeekDaySelect.propTypes = {
  label: PropTypes.node,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
}

export default WeekDaySelect

import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import getOverrides from './overrides'

const FONTS = ['"Roboto"', '"Helvetica"', 'sans-serif']

const theme = createMuiTheme({
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: ['Gotham', ...FONTS].join(','),
    button: {
      fontFamily: ['CamptonBold', ...FONTS].join(','),
    },
    campton: ['Campton', ...FONTS].join(','),
    camptonBold: ['CamptonBold', ...FONTS].join(','),
    roboto: ['Roboto', ...FONTS].join(','),
    robotoBold: ['RobotoBold'].join(','),
    robotoBlack: ['RobotoBlack'].join(','),
    cormorantinfantMedium: ['CormorantinfantMedium'].join(','),
    cormorantinfantLightItalic: ['CormorantinfantLightItalic'].join(','),
  },
  palette: {
    primary: {
      main: '#49A0AB', // Brand Blue
    },
    secondary: {
      main: '#FFC43B', // Brand Yellow (Honey) rgb(255, 196, 59)
      brandRed: '#FF7874', // Brand Red rgb(255, 120, 116)
      brandGreenLight: '#EBF9F8', // Brand Green Light rgb(235, 249, 248)
    },
    error: {
      main: red.A400,
      mainDark: '#ff7773',
    },
    background: {
      default: '#fff',
      light: '#f6f6f6',
      lightPink: '#fffbf3',
      xlightBlue: '#f9feff',
      lightBlue: '#8fc8ce',
    },
    greyBlue: {
      100: '#F1F3F7',
    },
    text: {
      primary: '#333333',
      grey: '#595A5A',
      darkGrey: '#30394a',
      lightGrey: '#a8a8a8',
      bGrey: '#b1b1b1',
      brandDarkGray: '#5C6A72',
      dGrey: '#dedede',
      black: '#000',
      lightBlue: '#8fc8ce',
    },
    border: {
      inactive: '#dedede',
      active: '#8fc8ce',
      light: '#f6f6f6',
      error: '#ff7773',
      lightGrey: '#c9c9c9',
    },
    stepper: {
      inactive: '#e3f5f7',
      active: '#8ec8cf',
    },
    alert: {
      dangerBg: '#fff6f6',
      dangerText: '#ff7773',
    },
  },
})

export default { ...theme, overrides: getOverrides(theme) }

import { Field } from 'formik'
import { withStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import ApiariButton from '../../components/inputs/button'

export const GhostBtn = withStyles(theme => ({
  root: {
    color: theme.palette.text.darkGrey,
    border: '1px solid',
    borderColor: theme.palette.border.inactive,
    backgroundColor: 'transparent',
    fontSize: '12px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '160px',
    lineHeight: '45px',
    boxShadow: 'none',
    padding: '0',
    borderRadius: '30px',
    '& .MuiButton-startIcon': {
      color: theme.palette.secondary.main,
      '& svg': {
        fontSize: '24px',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.default,
      boxShadow: 'none',
      '& .MuiButton-startIcon': {
        color: theme.palette.background.default,
      },
    },
  },
}))(ApiariButton)

export const BlankBtn = withStyles(theme => ({
  root: {
    color: theme.palette.error.mainDark,
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '12px',
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '0',
    lineHeight: '1',
    boxShadow: 'none',
    padding: '0',
    borderRadius: '0px',

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.error.main,
      boxShadow: 'none',
    },
  },
}))(ApiariButton)

export const ProviderFormField = withStyles(theme => ({
  root: {
    marginBottom: '25px',

    '& legend': {
      display: 'none',
    },
    '& label.MuiFormLabel-root': {
      position: 'static',
      transform: 'none',
      color: 'black',
      marginLeft: '0',
      fontSize: '14px',
      fontFamily: 'Roboto',
      '& strong': {
        fontWeight: 'normal',
        color: theme.palette.text.lightGrey,
      },
    },
    '& > div input, & > div textarea': {
      background: theme.palette.background.light,
      border: '1px solid',
      borderColor: theme.palette.border.light,
      borderRadius: '6px',
      height: '54px',
      padding: '0 20px',
      fontWeight: '600',

      '&.MuiInputBase-inputAdornedEnd': {
        paddingRight: '40px',
      },
      '&.MuiInputBase-inputAdornedStart': {
        paddingLeft: '40px',
      },
    },
    '& > div textarea': {
      minHeight: '160px',
      maxHeight: '160px',
      padding: '15px 20px',
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: '300',
      lineHeight: '20px',
    },
    '& > div input:focus, & > div textarea:focus': {
      background: 'white',
      border: '1px solid #8fc8ce',
      borderColor: theme.palette.border.active,
    },
    '& > div.Mui-error input, & > div.Mui-error textarea': {
      background: 'white',
      border: '1px solid',
      borderColor: theme.palette.border.error,
    },
  },
}))(Field)

export const ProviderDatePicker = withStyles(theme => ({
  root: {
    marginBottom: '25px',

    '& legend': {
      display: 'none',
    },
    '& label.MuiFormLabel-root': {
      position: 'static',
      transform: 'none',
      color: 'black',
      marginLeft: '0',
      fontSize: '14px',
      fontFamily: 'Roboto',

      '& strong': {
        fontWeight: 'normal',
        color: theme.palette.text.lightGrey,
      },
    },
    '& > div input': {
      background: theme.palette.background.light,
      border: '1px solid',
      borderColor: theme.palette.border.light,
      borderRadius: '6px',
      height: '54px',
      padding: '0 20px',
      fontWeight: '600',
    },
    '& > div.Mui-error input, & > div.Mui-error textarea': {
      background: 'white',
      border: '1px solid',
      borderColor: theme.palette.border.error,
    },
  },
}))(KeyboardDatePicker)

export const ProviderCheckbox = withStyles(theme => ({
  root: {
    marginLeft: '0',
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: '25px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },

    '& >span:first-child': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: '0',
      top: '0',
      padding: '0 24px',
      borderRadius: '6px',
      border: '1px solid',
      borderColor: theme.palette.border.inactive,
      color: theme.palette.border.inactive,
      transition: 'color 1s cubic-bezier(.4,.0,.23,1)',

      '&.Mui-checked': {
        borderColor: theme.palette.border.active,
        color: theme.palette.border.active,
      },

      '&:hover': {
        background: 'none',
      },

      '& >span': {
        justifyContent: 'flex-end',

        '& svg': {
          animation: 'myfirst 5s 2',
          animationDirection: 'right',
        },
      },
    },
    '& >span:last-child': {
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: '600',
      lineHeight: '56px',
      padding: '0 24px',
    },
  },
}))(FormControlLabel)

export const ProviderSelect = withStyles(theme => ({
  root: {
    width: '100%',

    '& .MuiSelect-selectMenu': {
      width: '100%',
      lineHeight: '35px',
      padding: '10px 44px 10px 24px',
      background: theme.palette.background.light,
      border: '1px solid',
      borderColor: theme.palette.border.light,
      borderRadius: '6px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: '600',
      marginBottom: '25px',
    },
    '& .MuiSelect-icon': {
      top: '14px',
      right: '15px',
      fontSize: '30px',
    },
  },
}))(FormControl)

export const ProviderRadio = withStyles(theme => ({
  root: {
    '& .MuiFormControlLabel-root': {
      marginBottom: '15px',
      marginLeft: '0px',

      '&  > span:first-child': {
        padding: '0',
        marginRight: '10px',
        color: '#ddd',

        '&.Mui-checked': {
          color: theme.palette.border.active,
        },
      },
      '&  > span:last-child': {
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontWeight: '600',
      },
    },
  },
}))(FormControl)

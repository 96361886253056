import { useState } from 'react'
import * as Yup from 'yup'
import get from 'lodash.get'
import { Box, Grid, Link, makeStyles } from '@material-ui/core'
import ApiariButton from '../../../components/inputs/button'
import { GENDER_OPTIONS } from '../../../utils/config'
import { SmallInput, SmallSelect } from '../account-page'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import useUpdateUser from '../../../hooks/use-update-user'
import ChildSelector from '../../chat-bot/components/actions/child-selector'
import { getStateCodeByZipcode } from '../../../utils/zipcodes'

const validationScheme = Yup.object({
  address: Yup.object({
    zipcode: Yup.string()
      .required()
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code')
      .test('State', 'We are not able to identify your zip code', value =>
        Boolean(getStateCodeByZipcode(value)),
      ),
  }),
})

const InfoWrapper = ({ title, children, height }) => (
  <Grid
    item
    container
    component={Box}
    flexDirection={{ xs: 'column', md: 'row' }}
  >
    <Grid component={Box} item xs="auto" width={200} mb={{ xs: 1, md: 0 }}>
      <Box fontFamily="camptonBold">{title}</Box>
    </Grid>
    <Box component={Grid} item xs height={height}>
      {children}
    </Box>
  </Grid>
)

function getInitialState(user) {
  return {
    gender: user.gender || '',
    phoneNumber: user.phoneNumber || '',
    entranceInstructions: user.entranceInstructions || '',
    address: {
      streetAddress: user.address?.streetAddress || '',
      zipcode: user.address?.zipcode || '',
      unit: user.address?.unit || '',
      state: user.address?.state || '',
    },
    specialNeeds: user.specialNeeds,
  }
}

const useStyles = makeStyles(theme => ({
  errorHelper: {
    display: 'block',
    marginBottom: '16px',
    color: 'red',
  },
}))

const BasicInfo = ({ user, onEdit, onSave, editMode }) => {
  const classes = useStyles()
  const meta = useUpdateUser(
    user,
    getInitialState,
    onSave,
    undefined,
    validationScheme,
  )
  const [editingChildren, setEditingChildren] = useState(false)
  const { state, errors } = meta

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle icon={<BulletIcon />} text="Basic Info" />
        </Grid>
        <Grid item xs="auto">
          {editMode ? (
            <>
              <Link
                color="primary"
                underline="none"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={onSave}
              >
                Cancel
              </Link>
              <ApiariButton
                onClick={() => meta.handleSave()}
                busy={meta.submitting}
                disabled={!meta.hasChanges || errors}
              >
                Save
              </ApiariButton>
            </>
          ) : (
            <ApiariButton onClick={onEdit}>Edit</ApiariButton>
          )}
        </Grid>
      </Grid>
      <Box pl={3.6} mt={2}>
        <Grid container direction="column" spacing={3}>
          <InfoWrapper title="Gender">
            {editMode ? (
              <Box width={{ base: '100%', sm: '50%' }}>
                <SmallSelect
                  variant="outlined"
                  autoFocus
                  value={state.gender}
                  onChange={e =>
                    meta.handleChange(e, (b, gender) => ({ ...b, gender }))
                  }
                  label="Select gender"
                  options={GENDER_OPTIONS}
                />
              </Box>
            ) : (
              state.gender || 'Not Specified'
            )}
          </InfoWrapper>
          {user.children && !!user.children.length && (
            <InfoWrapper title="Children">
              {`${user.childrenDisplay}  `}
              <Link
                onClick={() => setEditingChildren(true)}
                style={{ cursor: 'pointer' }}
              >
                Edit
              </Link>
            </InfoWrapper>
          )}
          {user.role === 'customer' && (editMode || state.specialNeeds) ? (
            <InfoWrapper title="Special Needs">
              {editMode ? (
                <Box>
                  <SmallInput
                    value={state.specialNeeds}
                    onChange={e =>
                      meta.handleChange(e, (b, specialNeeds) => ({
                        ...b,
                        specialNeeds,
                      }))
                    }
                  />
                </Box>
              ) : (
                state.specialNeeds
              )}
            </InfoWrapper>
          ) : (
            <></>
          )}
          <InfoWrapper title="Mobile Number">
            {editMode ? (
              <Box width={{ base: '100%', sm: '50%' }}>
                <SmallInput
                  value={state.phoneNumber}
                  onChange={e =>
                    meta.handleChange(e, (b, phoneNumber) => ({
                      ...b,
                      phoneNumber,
                    }))
                  }
                />
              </Box>
            ) : (
              state.phoneNumber
            )}
          </InfoWrapper>
          <InfoWrapper title="Home Address">
            {editMode ? (
              <Box width={{ base: '100%', sm: '50%' }}>
                <SmallInput
                  value={state.address.streetAddress}
                  onChange={e =>
                    meta.handleChange(e, (b, streetAddress) => ({
                      ...b,
                      address: { ...b.address, streetAddress },
                    }))
                  }
                />
              </Box>
            ) : (
              state.address.streetAddress
            )}
          </InfoWrapper>
          <InfoWrapper title="Apartment No.">
            {editMode ? (
              <Box width={{ base: '100%', sm: '50%' }}>
                <SmallInput
                  value={state.address.unit}
                  onChange={e =>
                    meta.handleChange(e, (b, unit) => ({
                      ...b,
                      address: { ...b.address, unit },
                    }))
                  }
                />
              </Box>
            ) : (
              state.address.unit || '/'
            )}
          </InfoWrapper>
          <InfoWrapper title="Zipcode">
            {editMode ? (
              <Box display="inline-flex" flexWrap="wrap">
                <Box width={70} mr={1.5} mb={1}>
                  <SmallInput
                    value={state.address.zipcode}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                    error={errors && !!get(errors, `address.zipcode`)}
                    onChange={e =>
                      meta.handleChange(e, (b, zipcode) => ({
                        ...b,
                        address: {
                          ...b.address,
                          zipcode,
                          state: getStateCodeByZipcode(zipcode),
                        },
                      }))
                    }
                  />
                </Box>
                <Box width={45}>
                  <SmallInput
                    value={state.address.state}
                    disabled
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                  />
                </Box>
                <Box width="100%">
                  <span className={classes.errorHelper}>
                    {get(errors, `address.zipcode`)}
                  </span>
                </Box>
              </Box>
            ) : (
              `${state.address.zipcode} (${state.address.state || '/'})` ?? '/'
            )}
          </InfoWrapper>
          <InfoWrapper title="Access Info." height={{ xs: 'auto', sm: 20 }}>
            {editMode ? (
              <Box>
                <SmallInput
                  value={state.entranceInstructions}
                  onChange={e =>
                    meta.handleChange(e, (b, entranceInstructions) => ({
                      ...b,
                      entranceInstructions,
                    }))
                  }
                  multiline
                  rows={3}
                />
              </Box>
            ) : (
              state.entranceInstructions ?? 'No info'
            )}
          </InfoWrapper>
        </Grid>
      </Box>
      {editingChildren && (
        <ChildSelector
          initChildrens={{
            index: 0,
            childrens: user.children.map(c => ({
              birthday: +new Date(c.birthdayTimestamp),
              gender: c.gender || null,
              name: c.name || '',
            })),
          }}
          onSubmit={data => {
            meta.handleSave({
              ...state,
              children: data.message.map(c => ({
                birthdayTimestamp: +new Date(c.birthday),
                name: c.name,
                gender: c.gender,
              })),
            })
            setEditingChildren(false)
          }}
          onClose={() => setEditingChildren(false)}
        />
      )}
    </>
  )
}

export default BasicInfo

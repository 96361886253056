import React from 'react'
import storeContext from '../../stores'
import Grid from '@material-ui/core/Grid'
import BasicInfo from './components/basic-info'
import HourlyRateInfo from './components/hourly-rate-info'
import AdditionalInfo from './components/additional-info'
import ProviderProfileInfo from './components/provider-profile-info'
import HomeDetails from './components/home-details'
import { withStyles } from '@material-ui/core'
import ApiariInput from '../../components/inputs/input'
import ApiariSelect from '../../components/inputs/select/select'
import ProfileWrapper from '../../components/profile-wrapper/profile-wrapper'
import AccountPageHeader from './components/account-page-header'

export const SmallInput = withStyles({
  root: {
    '& input': {
      padding: '6px 10px',
      fontSize: '0.875rem',
    },
    '& textarea': {
      padding: 0,
      margin: -4,
      fontSize: '0.875rem',
    },
  },
})(ApiariInput)

export const SmallAmountInput = withStyles(theme => ({
  root: {
    width: '310px',

    '& input': {
      padding: '6px 10px',
      fontSize: '0.875rem',
      paddingLeft: '26px',
      marginLeft: '-10px',
    },
    '& textarea': {
      padding: 0,
      margin: -4,
      fontSize: '0.875rem',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,

      '& span': {
        transform: 'translateX(100%)',
        color: theme.palette.text.brandDarkGray,
      },
    },
  },
}))(ApiariInput)

export const SmallSelect = withStyles({
  root: {
    padding: '6px 10px',
    paddingRight: '0 !important',
    fontSize: '0.875rem',
  },
})(ApiariSelect)

const AccountPage = () => {
  const {
    auth: { user },
  } = React.useContext(storeContext)
  const [editing, setEditing] = React.useState('')

  return (
    <ProfileWrapper>
      <Grid container spacing={8} direction="column">
        <AccountPageHeader user={user} />

        <Grid item>
          <BasicInfo
            user={user}
            onEdit={() => setEditing('basic')}
            onSave={() => setEditing('')}
            editMode={editing === 'basic'}
          />
        </Grid>
        {user.role === 'provider' && (
          <Grid item>
            <ProviderProfileInfo
              user={user}
              onEdit={() => setEditing('providerProfile')}
              onSave={() => setEditing('')}
              editMode={editing === 'providerProfile'}
            />
          </Grid>
        )}
        <Grid item className="only-apiari-classic">
          {user.role === 'provider' ? (
            <HourlyRateInfo
              user={user}
              onEdit={() => setEditing('hourly')}
              onSave={() => setEditing('')}
              editMode={editing === 'hourly'}
            />
          ) : (
            <AdditionalInfo
              user={user}
              onEdit={() => setEditing('additional')}
              onSave={() => setEditing('')}
              editMode={editing === 'additional'}
            />
          )}
        </Grid>
        <Grid item>
          <HomeDetails
            user={user}
            onEdit={() => setEditing('home')}
            onSave={() => setEditing('')}
            editMode={editing === 'home'}
          />
        </Grid>
      </Grid>
    </ProfileWrapper>
  )
}

export default AccountPage

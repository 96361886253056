import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

const DetailsItem = ({ title, children }) => {
  return (
    <Grid item container>
      <Grid item xs="auto">
        <Box width={{ xs: 100, md: 200 }} fontFamily="camptonBold">
          {title}
        </Box>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  )
}

export default DetailsItem

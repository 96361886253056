import { groupBy } from '../../../utils/utils'

export default class Action {
  constructor(action) {
    this.buttons = action.buttons
    this.message = action.message
    this.isMulti = action.multiselect
    this.isRequired = action.required
    this.image = action.userPhotoUrl
    this.type = action.type
    this.needsHuman = action.needsHuman
    this.data = action.data
    this.delay = action.delay
    this.pause = action.pause
    this.typingIndicator = action.typingIndicator
  }

  get displayChatInput() {
    return /text|password|textarea|address|number/i.test(this.type)
  }

  get displayChatSelect() {
    return /button/i.test(this.type)
  }

  get displayDatePicker() {
    return this.type === 'date'
  }

  get displayTimePicker() {
    return this.type === 'time'
  }

  get displayDateTimePicker() {
    return this.type === 'datetime'
  }

  get displayAsArray() {
    return this.type === 'array'
  }

  get displayAsPagination() {
    return this.type === 'carousel'
  }

  get displaySummaryCard() {
    return this.type === 'summary'
  }

  get displayLoginComponent() {
    return this.type === 'login'
  }

  get displayRegisterComponent() {
    return this.type === 'signup'
  }

  get displayStripePayment() {
    return this.type === 'creditCard'
  }

  get displayChildSelector() {
    return this.type === 'childSelector'
  }

  get timeSlots() {
    return this.data && groupBy(this.data.timeslots, 'window')
  }

  replaceTextWithAction(actionComponent) {
    this.message = actionComponent
  }
}

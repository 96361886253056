import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextSlider from '../../../components/text-slider/text-slider'

//Config
import { CLIFFS_NOTES } from '../become-a-provider-config'

const useStyles = makeStyles(theme => ({
  termsInner: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.07px',
    fontWeight: '300',
    padding: '40px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.border.inactive,
    marginBottom: '30px',
    height: '530px',
    overflow: 'auto',

    '& h3': {
      margin: '0 0 15px',
    },
  },
  termsInnerBottom: {
    padding: '0 40px',

    '& a:any-link': {
      textDecoration: 'underline',
    },
  },
  termsSlider: {
    '& > div': {
      position: 'relative',
      marginTop: '15px',
      marginBottom: '15px',
      right: '0',
      top: '0',
      textAlign: 'left',
      '& > div': {
        padding: '0',
        left: '36px',
      },
      '& > div:nth-child(2)': {
        fontSize: '14px',
        lineHeight: '-0.15px',
        marginBottom: '16px',
      },
      '& > div:nth-child(3)': {
        fontSize: '16px',
        lineHeight: '-0.15px',
        marginBottom: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
    },
  },
}))

const TermsOfUse = props => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item lg={4} md={12}>
        <Box className={classes.termsSlider}>
          <TextSlider steps={CLIFFS_NOTES} />
        </Box>
      </Grid>
      <Grid item lg={8} md={12}>
        <Box className={classes.termsSection}>
          <Box className={classes.termsInner}>
            <h3>Terms of service.</h3>
            <p>Last updated: July 10, 2020</p>
            <p>
              These Terms of Use (these&nbsp;<strong>Terms</strong>) govern your
              use of services of Apiari LLC (<strong>Apiari</strong>,&nbsp;
              <strong>we</strong>&nbsp;or&nbsp;<strong>us</strong>) available
              through our website located at&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://theapiari.com/"
              >
                https://theapiari.com
              </a>
              &nbsp;(such website and related content, the&nbsp;
              <strong>Website</strong>). The Website and the Company provide an
              online platform for customers to choose, schedule and manage
              Services&nbsp;<strong>(the Services)</strong>&nbsp;provided by
              third party care providers (the&nbsp;
              <strong>Care Providers</strong>) under independent contractor
              agreement with the Company or its affiliates.
            </p>
            <p>
              <strong>1. SERVICES</strong>
              <br />
              Apiari enables you to book vetted Care Providers based on your
              preferences and needs.&nbsp; We use information you provide on our
              Website to match you to one or more Care Providers, and to provide
              the Care Providers you book with relevant information for each
              session. We may communicate relevant information about you to your
              Care Providers via text, email or phone call. Please review
              our&nbsp;
              <a
                href="https://theapiari.com/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Statement
              </a>
              &nbsp;on our Website, which is incorporated into these Terms by
              reference, for more details.
            </p>
            <p>
              <strong>2. SCHEDULING; CANCELLATION</strong>
              <br />
              2.1&nbsp; After you enter your preferences, we will share with you
              profiles of the Care Providers that meet your requirements. You
              can request to book the Care Providers via text, email or our
              Website, and will be notified once a Care Provider accepts your
              request.&nbsp;
              <br />
              <br />
              2.2 &nbsp; You can reschedule, reduce, or cancel any Services with
              at least 24 hours’ notice at no charge. If you reschedule, reduce
              or cancel a service within 24 hours’ notice, we will charge
              the&nbsp;lower&nbsp;of (a) $50 or (b) actual fees for the Service
              had it not been canceled, reduced or rescheduled.&nbsp;
            </p>
            <p>
              <strong>3. PAYMENT TERMS</strong>
              <br />
              <strong>
                This Section 3 applies only to clients who make payments through
                Apiari.&nbsp;
              </strong>
            </p>
            <p>
              3.1 &nbsp; There is no fee for registering with Apiari. Amounts
              due for Services in any given week will be pre-authorized to your
              credit card prior to the start of the week, and will be charged to
              your credit card at the end of the week. For recurring services
              booked more than a week in advance, you will only be charged for
              the week’s service at the end of each week. Any amounts to be
              pre-authorized or charged for the Services will be notified to you
              via email on each Saturday. It is your responsibility to promptly
              notify us of any discrepancies between actual amounts due and
              amounts shown in the emails. Any reductions in hours booked are
              subject to our late cancellation and rescheduling policy set out
              in Section 2.2 above. If you are paying by cash, check or wire
              transfer, amounts due for Services in any given week will be due
              at the start of the week.&nbsp;
            </p>
            <p>
              3.2&nbsp; You authorize us to hold your credit card information on
              file, and to charge you for any late cancellations, extended
              Services and recurring Services according to these Terms.&nbsp;
            </p>
            <p>
              3.3&nbsp; Your payments will be processed by one or more
              third-party payment processors (the&nbsp;
              <strong>Payment Processors</strong>), which will link Apiari to
              your credit card account. Our current Payment Processors are
              Stripe and&nbsp; Square, and your payments are processed by Stripe
              (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://stripe.com/us/legal"
              >
                https://stripe.com/us/legal
              </a>
              ) and Square (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://squareup.com/legal/ua"
              >
                https://squareup.com/legal/ua
              </a>
              ) in accordance with their respective Terms of Service and Privacy
              Policy. We do not control, and are not liable for, the security or
              performance of the Payment Processors.&nbsp; In connection with
              your use of the Services, Apiari will obtain certain transaction
              details, which it will use solely in accordance with the Privacy
              Policy. Any payments by cash, check or wire transfer will be made
              to the account(s) stated in our invoices.&nbsp;
            </p>
            <p>
              3.4 You are responsible for notifying Apiari of any need to
              extend, shorten or cancel your scheduled session in advance of the
              session (subject to our late cancellation policy set out in
              Section 2.2 above). If your extension is confirmed by us, we will
              charge you at the same rate as your ongoing session. If you fail
              to notify us or if we are unable to extend your session, and your
              Care Provider is unable to leave your home or such other
              designated premises at the scheduled time due to your actions or
              inactions, you will be charged a fee of $45 for each 15 minutes of
              delay. You hereby authorize the Payment Processor to charge your
              credit card on file for any extension of scheduled Services
              (whether requested or otherwise) pursuant to this Section
              3.4.&nbsp;&nbsp;
            </p>
            <p>
              3.5 Gift certificates and gift cards for Services may not be
              returned, refunded, or redeemed for cash.To redeem a gift card,
              recipients need to enter “Gift card” under Apiari’s promo code
              section upon signing on with Apiari. Gift cards and gift
              certificates issued by Apiari do not expire.&nbsp;&nbsp;
            </p>
            <p>
              <strong>4. CONDUCT</strong>
              <br />
              You acknowledge and agree that you will, and will cause your
              family to, conduct yourself (and themselves) in an orderly manner
              and in full compliance with applicable laws, and will treat the
              Care Providers with due respect, and that you will not, and will
              cause your family members not to: (a) physically harm, threaten to
              harm, or intentionally or recklessly cause harm to our Care
              Providers or create a condition that endangers the health and
              safety of our Care Providers; (ii) make or attempt any unwanted
              sexual advance or statement to any Care Provider; (iii) threaten,
              harass, stalk, intimidate, or engage in any other aggressive or
              bullying behavior against any Care Provider; (iv) use any vulgar
              or obscene language or otherwise engage in behavior that is likely
              to make others feel uncomfortable, embarrassed or offended; or (v)
              engage in any behavior that discriminates against any Care
              Provider because of the individual’s race, color, sex, age,
              national origin, handicap, sexual orientation or religion. Any
              breach of the terms of this Section 4 entitles Apiari to terminate
              your Account and access and use of the Services without
              penalty.&nbsp;
            </p>
            <p>
              <strong>5. DIRECT PLACEMENT FEE</strong>
              <br />
              <strong>
                This section applies only with respect to Care Providers first
                introduced or recommended to you by Apiari.
              </strong>
            </p>
            <p>
              We have invested significant resources in recruiting and vetting
              Care Providers. In the event that, within a year after Services
              are provided to you by a Care Provider, you hire, employ or
              otherwise engage the services of such Care Provider, you will
              notify us and pay a placement fee equal to $4,500 per Care
              Provider. You acknowledge that this fee is a reasonable
              representation of damages suffered by us.
            </p>
            <p>
              <strong>6. LICENSES AND RESTRICTIONS</strong>
              <br />
              6.1 You acknowledge and agree that (a) Care Providers are not
              permitted to solicit work directly from you , (b) you will not
              directly contact any Care Provider in respect of Services, unless
              such Care Provider was known to you other than through the Apiari,
              and (c) if you intend to directly engage any Care Provider, you
              will first contact Apiari. You acknowledge and agree that Apiari
              provides a valuable service in introducing Care Providers to you
              and, should you decide to directly engage any Care Providers first
              known to you via Apiari, you will be charged a commission mutually
              agreed between you and Apiari.
            </p>
            <p>
              6.2 &nbsp; By accessing or using our website, you agree that you
              will not:
            </p>
            <p>
              (a)&nbsp; &nbsp; license, sell, rent, lease, transfer, assign,
              reproduce, distribute, host or otherwise commercially exploit the
              Website, or Apiari’s trademark, logo or any other intellectual
              property (collectively, the&nbsp;<strong>Apiari IP</strong>);
            </p>
            <p>
              (b)&nbsp; &nbsp; modify, translate, adapt, merge, make derivative
              works of, disassemble, decompile, reverse compile or reverse
              engineer any part of the Apiari IP except to the extent such
              restrictions are expressly prohibited by applicable law;
            </p>
            <p>
              (c) &nbsp; use any manual or automated software, devices or other
              processes (including but not limited to spiders, robots, scrapers,
              crawlers, avatars, data mining tools or the like) to “scrape” or
              download data from any web pages contained in the Website (except
              that we grant the operators of public search engines revocable
              permission to use spiders to copy materials from the Website for
              the sole purpose of and solely to the extent necessary for
              creating publicly available searchable indices of the materials,
              but not caches or archives of such materials);
            </p>
            <p>
              (d) &nbsp; without the Apiari’s express consent, copy, reproduce,
              distribute, republish, download, display, post or transmit in any
              form or by any means; or
            </p>
            <p>
              (e) &nbsp; use the Apiari IP for any illegal or unlawful purpose.
            </p>
            <p>
              Any unauthorized use of the Apiari IP terminates the licenses
              granted by Apiari pursuant to the Terms.
            </p>
            <p>
              <strong>7. NO WARRANTY</strong>
              <br />
              7.1 You expressly acknowledge and agree that your use of the
              Website and Services is at your sole risk. Apiari hereby disclaims
              all warranties and conditions with respect to the Services, the
              Website, the Services and the Care Providers. No information or
              advice given by Apiari, any of its directors, officers, employees,
              representatives or agents (collectively, the Relevant Persons) or
              any Care Providers shall create a warranty. To the fullest extent
              permitted by applicable law, none of the Relevant Persons shall be
              liable to you for any costs, expenses or losses incurred by you,
              except to the extent that such costs, expenses or losses are
              determined by a court of competent jurisdiction (from which no
              further appeal may be taken) to be attributable to such Relevant
              Persons’ fraud, wilful misconduct, or gross negligence.
            </p>
            <p>
              7.2 &nbsp; You acknowledge and agree that if any part of a Care
              Provider’s services are unsatisfactory, your sole recourse is
              against the Care Provider, and not Apiari.
            </p>
            <p>
              7.3 &nbsp; You acknowledge that none of our Care Providers are
              permitted to provide medical advice as part of the Services.
            </p>
            <p>
              <strong>8. LIMITATION OF LIABILITY; INDEMNIFICATION</strong>
              <br />
              8.1 &nbsp; To the fullest extent permitted by applicable law, in
              no event shall Apiari be liable for any incidental, special,
              punitive, indirect of consequential damages related to your use or
              inability to use any of the Website or Services, any information
              available on our Website, or from any communications or other
              interactions with the Care Providers, regardless of the theory of
              liability. In no event shall Apiari’s total liability to you for
              all damages (other than as may be required by applicable law in
              cases involving personal injury) exceed the amounts paid by you to
              Apiari in the twelve (12) month period immediately preceding the
              applicable event giving rise to such damage.
            </p>
            <p>
              8.2 &nbsp; Care Providers are solely responsible for their
              provision of Services.
            </p>
            <p>
              8.3 &nbsp; You agree to indemnify, defend, release, and hold
              harmless the Relevant Persons from all damages, losses and
              expenses arising directly or indirectly from (a) any negligent
              acts, omissions or willful misconduct by you, (b) any breach of
              these Terms by you, and/or (c) your violation of any law or of any
              rights of any third party.
            </p>
            <p>
              8.4 &nbsp; Apiari reserves the right, at its own cost, to assume
              the exclusive defense and control of any matter otherwise subject
              to indemnification by you, in which event you will fully cooperate
              with Apiari in asserting any available defenses.&nbsp;&nbsp;
            </p>
            <p>
              8.5 &nbsp; You hereby release the Relevant Parties and their
              successors from claims to the extent permissible by applicable
              law, of all demands, any and all losses, damages, rights, and
              actions of any kind, including personal injuries, death, and
              property damage, that is related to or arises from your use of the
              Website or Services, including but not limited to, any
              interactions with or conduct of the Care Providers.
            </p>
            <p>
              8.6 &nbsp; You hereby agree that if these Terms are not
              specifically enforced, Company will be irreparably damaged, and
              therefore you agree that Company shall be entitled, without bond,
              other security, proof of damages, to appropriate equitable
              remedies with respect to any of these Terms, in addition to any
              other available remedies.
            </p>
            <p>
              <strong>9. ARBITRATION; APPLICABLE LAW</strong>
              <br />
              9.1 You agree and acknowledge that, by accepting these Terms, you
              and the Company are each waiving the right to trial by jury or to
              participate in a class action. You and the Company agree that each
              may bring claims against the other only in your or its individual
              capacity, and not as a plaintiff or class member in any purported
              class or representative proceeding. Any arbitration will take
              place on an individual basis; class arbitrations and class actions
              are not permitted.&nbsp;
            </p>
            <p>
              9.2 &nbsp; The laws of the State of New York, excluding its
              conflicts of laws rules that would result in the laws of a State
              other than New York, govern this license and your use of the
              Website and Services.&nbsp;
            </p>
            <p>
              9.3 &nbsp; Except for a claim by Company against you, any dispute,
              claim, difference or controversy arising out of, relating to or
              having any connection with these Terms, including any dispute as
              to its existence, validity, interpretation, performance, breach or
              termination or the consequences of its nullity and any dispute
              relating to any non-contractual obligations arising out of or in
              connection with it (a&nbsp;<strong>Dispute</strong>), shall be
              referred to and finally resolved by arbitration under the
              Commercial Arbitration Rules and the Supplementary Procedures for
              Consumer Related Disputes (the&nbsp;<strong>Rules</strong>) of the
              American Arbitration Association (the&nbsp;<strong>AAA</strong>).
            </p>
            <p>
              <strong>10. MISCELLANEOUS</strong>
              <br />
              10.1 &nbsp; Changes to Terms. We may modify these Terms of Service
              from time to time. When changes are made, we will make the revised
              version available on this webpage, and will indicate at the top of
              this page the date that revisions were last made. Your use of the
              Website or the Services after such posting will constitute
              acceptance by you of such changes&nbsp;&nbsp;
            </p>
            <p>
              10.2 &nbsp; Termination. The Company may cancel, suspend or block
              your use of the Website or Services without notice if there has
              been a violation of these Terms. Your right to use the Website
              will end once your registration is terminated.&nbsp; The Company
              is not responsible or liable for any records or information that
              is made unavailable to you as a result of your termination of
              registration. The Company shall not be liable to you or any other
              party for any termination of your access to the Website.&nbsp;
              Sections 5, 6, 7 and 8 shall survive the termination of these
              Terms or your registration.
            </p>
          </Box>
          <Box className={classes.termsInnerBottom}>
            See our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://theapiari.com/privacy"
            >
              <strong>Privacy Policy</strong>
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(TermsOfUse)

import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Rating from '@material-ui/lab/Rating'

const ProviderCalendarBox = ({ provider }) => (
  <Box borderRadius={8} border={1} borderColor="greyBlue.100" p={2}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs="auto">
        <Box height={65} width={65}>
          <Avatar src={provider.profilePic} />
        </Box>
      </Grid>
      <Grid item xs>
        <Box fontFamily="camptonBold" fontSize="1rem">
          {provider.lastInitials}
        </Box>
        <Rating
          disabled
          value={Number(provider.rating)}
          precision={0.5}
          name="rating"
        />
      </Grid>
    </Grid>
  </Box>
)

export default ProviderCalendarBox

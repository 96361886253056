import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import HandIcon from '../../../assets/images/user2x.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import ApiariSelect from '../../../components/inputs/select/select'
import {
  ProviderCheckbox,
  ProviderSelect,
  ProviderRadio,
} from '../become-a-provider-styles'

//config
import { EXPERIENCE_DURATION, INTERESTS } from '../../../utils/config'
import { setAboutKeysOrder } from '../become-a-provider-config'

const QualificationsForm = props => {
  const {
    values: { interests = [], authorizedToWorkInUS, experience, firstName },
    setFieldValue,
    errors,
    touched,
  } = props

  const handleInterestsChange = ({ value, key }) => {
    let newInterests = []
    if (interests.includes(value)) {
      newInterests = interests.filter(interest => interest !== value)
      setFieldValue('interests', newInterests, false)
    } else {
      newInterests = [...interests, value]
      setFieldValue('interests', newInterests, false)
    }
    setFieldValue('about', setAboutKeysOrder(newInterests), false)
  }

  const getSelectedInterests = () => {
    const selectedInterests = INTERESTS.filter(item =>
      interests.includes(item.value),
    )
    const labels = selectedInterests.map(item => item.title)
    if (labels.length === 1) {
      return ` in ${labels[0]}`
    }
    if (labels.length > 1) {
      return ` in ${labels.slice(0, -1).join(', ')} and ${
        labels[labels.length - 1]
      }`
    }
    return ''
  }

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 20, md: 28 }}
            fontFamily="Roboto"
            color="text.darkGrey"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            mb={3.5}
          >
            Hi {firstName}!
            <img
              src={HandIcon}
              alt="undo"
              width={30}
              height={30}
              style={{ marginLeft: '12px' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            What are you interested in?
          </Box>
        </Grid>
        {INTERESTS.map(interest => (
          <Grid key={interest.value} item sm={6} xs={12}>
            <Box px={1}>
              <ProviderCheckbox
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={interests.includes(interest.value)}
                    onChange={() => handleInterestsChange(interest)}
                    name="interests"
                    color="primary"
                  />
                }
                label={interest.title}
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box px={1}>
            {!!errors.interests && touched.interests && (
              <FormHelperText>{errors.interests}</FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            How many years of experience have you had{getSelectedInterests()}?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <Grid item sm={4}>
              <ProviderSelect>
                <ApiariSelect
                  labelId="experience"
                  id="experience"
                  name="experience"
                  value={experience}
                  options={EXPERIENCE_DURATION(1, 15, 'Year')}
                  onChange={e =>
                    setFieldValue('experience', e.target.value, false)
                  }
                />
                {!!errors.experience && touched.experience && (
                  <FormHelperText>{errors.experience}</FormHelperText>
                )}
              </ProviderSelect>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Are you legally authorized to work in the US?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} mb={{ xs: 2, md: 4 }}>
            <ProviderRadio component="fieldset">
              <RadioGroup
                aria-label="authorizedToWorkInUS"
                name="authorizedToWorkInUS"
                value={authorizedToWorkInUS}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      onChange={() =>
                        setFieldValue('authorizedToWorkInUS', true, false)
                      }
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      onChange={() =>
                        setFieldValue('authorizedToWorkInUS', false, false)
                      }
                    />
                  }
                  label="No"
                />
              </RadioGroup>
              {!!errors.authorizedToWorkInUS &&
                touched.authorizedToWorkInUS && (
                  <FormHelperText>{errors.authorizedToWorkInUS}</FormHelperText>
                )}
            </ProviderRadio>
            {(authorizedToWorkInUS === false || experience === 0) && (
              <FormHelperText>
                Unfortunately, we are unable to invite you to become an Apiari
                member at this time. To be recommended to Apiari's clients, you
                must meet the following minimum criteria:
                <br />
                <ul>
                  <li>
                    Have at least 1 year of experience babysitting, teaching or
                    tutoring.
                  </li>
                  <li>Be authorized to work in the U.S.</li>
                </ul>
              </FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(QualificationsForm)

import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { navigate } from '@reach/router'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SetPasswordForm from './set-password-form'
import { setPasswordApi } from '../../api/auth-api'

const SetPasswordPage = props => {
  const [successMessage, setSuccessMessage] = React.useState('')

  const setPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .min(7, 'New password needs to have at least 7 characters'),
  })

  return (
    <Box maxWidth={{ md: 600 }} m="auto" bgcolor="white">
      <Box py={{ xs: 6, sm: 8 }} px={{ xs: 4, sm: 16 }}>
        <Box align="center" fontFamily="camptonBold" fontSize={18} mb={6}>
          Choose new password
        </Box>
        <Typography
          component="div"
          color="textPrimary"
          variant="caption"
          align="center"
        >
          {successMessage}
        </Typography>
        <Formik
          validationSchema={setPasswordSchema}
          initialValues={{ password: '', resetCode: props.resetCode }}
          component={SetPasswordForm}
          onSubmit={(data, { setErrors }) =>
            setPasswordApi(data)
              .then(() => {
                setSuccessMessage('New password is set')
                setTimeout(() => {
                  navigate('/login')
                }, 1000)
              })
              .catch(() => setErrors({ setPassword: 'Something went wrong' }))
          }
        />
      </Box>
    </Box>
  )
}

export default SetPasswordPage

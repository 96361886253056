import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../../components/inputs/button'
import ApiariInput from '../../../../components/inputs/input'
import { getFormValues } from '../../../../utils/utils'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const ChatRegister = ({ onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()

    onSubmit(getFormValues(e))
  }

  return (
    <Box textAlign="center" width={350} p={1}>
      <Box mb={2} fontFamily="camptonBold" fontSize={22}>
        Create Your Account
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={3}>
          <Grid item container spacing={2}>
            <Grid item xs>
              <ApiariInput
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                placeholder="Enter first name"
                required
                autoFocus
              />
            </Grid>
            <Grid item xs>
              <ApiariInput
                label="Last Name"
                name="lastName"
                required
                autoComplete="lastName"
                placeholder="Enter last name"
              />
            </Grid>
          </Grid>
          <Grid item>
            <ApiariInput
              label="Email Address"
              name="email"
              required
              autoComplete="email"
              placeholder="Enter email address"
            />
          </Grid>
          <Grid item>
            <ApiariInput
              label="Password"
              name="password"
              autoComplete="current-password"
              required
              placeholder="Enter password"
              type="password"
            />
          </Grid>
          <Grid item>
            <ApiariInput
              label="Phone Number"
              name="phoneNumber"
              autoComplete="phone"
              required
              placeholder="Enter phone number"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              name="acceptedTos"
              control={<Checkbox color="primary" required />}
              label={
                <span>
                  I agree to Apiari's{' '}
                  <a
                    href="https://theapiari.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service (TOS)
                  </a>
                </span>
              }
            />
          </Grid>
        </Grid>
        <Box mt={4} mx="auto" width={200}>
          <ApiariButton fullWidth type="submit">
            Create account
          </ApiariButton>
        </Box>
      </form>
    </Box>
  )
}

ChatRegister.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ChatRegister

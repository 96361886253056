import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputAdornment-positionEnd': {
      right: '6px',
    },
    '& input:focus': {
      outline: `1px solid ${theme.palette.border.active}`,
    },
  },
}))

const InlineDatePicker = ({
  name,
  label,
  onChange,
  initialValue,
  includePast,
  shouldDisableDate,
}) => {
  const [selectedDate, handleDateChange] = useState(initialValue || new Date())
  const classes = useStyles()

  useEffect(() => {
    handleDateChange(initialValue || new Date())
  }, [initialValue])

  return (
    <KeyboardDatePicker
      classes={classes}
      label={label}
      value={selectedDate}
      onChange={handleDateChange}
      variant="inline"
      openTo="date"
      shouldDisableDate={shouldDisableDate}
      onAccept={onChange}
      autoOk
      disablePast={!includePast}
      disableToolbar
      format="MM/dd/yyyy"
      keyboardIcon={CalendarIcon && <CalendarIcon />}
      inputProps={{
        id: name,
      }}
      InputLabelProps={{
        htmlFor: name,
      }}
    />
  )
}

InlineDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.instanceOf(Date),
}

export default InlineDatePicker

import React from 'react'
import * as PropTypes from 'prop-types'
import ApiariButton from '../../../../components/inputs/button'
import ChatEditCard from './chat-edit-card'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import storeContext from '../../../../stores'
import { useObserver } from 'mobx-react-lite'

const PER_PAGE = 8

const useStyles = makeStyles(theme => ({
  paginationWrapper: {
    borderRadius: 20,
    overflow: 'hidden',
    display: 'inline-block',
    background: theme.palette.greyBlue[100],
    padding: theme.spacing(2),
  },
}))

const ChatAppointmentPagination = ({ items }) => {
  const classes = useStyles()
  const store = React.useContext(storeContext)
  const [visibleConfirmed, setVisibleConfirmed] = React.useState(PER_PAGE)

  React.useEffect(() => {
    const customerIds = new Set(items.map(item => item.data.customerId))
    const providerIds = new Set(items.map(item => item.data.providerId))
    const missingUsersIds = store.users.getMissingUsersIds([
      ...customerIds,
      ...providerIds,
    ])
    Promise.all(missingUsersIds.map(store.users.getUser)).then(() => {
      items.map(i => i.data.instanceId).forEach(store.events.getEventInstance)
    })
  }, [items, store.events, store.users])

  const [[pending, confirmed, personal]] = React.useState(() =>
    items.reduce(
      (agg, el) => {
        let index = 0
        if (el.data.eventType === 'private') index = 2
        else if (!el.data.isPendingConfirmation) index = 1
        agg[index].push(el)
        return agg
      },
      [[], [], []],
    ),
  )

  const showMore = () => {
    setVisibleConfirmed(visibleConfirmed + PER_PAGE)
  }

  const showSeeMore = confirmed.length > visibleConfirmed

  return useObserver(() => (
    <Box
      className={classes.paginationWrapper}
      width="100%"
      maxWidth={{ sm: 400 }}
      minWidth={300}
    >
      {!!pending.length && (
        <>
          <Box fontWeight={700} fontFamily="camptonBold" fontSize={{ xs: 16 }}>
            Pending Sessions
          </Box>
          {pending.map(({ data, type }, index) => (
            <Box key={index} maxHeight={48}>
              <ChatEditCard
                {...data}
                disabled={!store.events.entries.has(data.instanceId)}
              />
              <Divider />
            </Box>
          ))}
        </>
      )}
      {!!confirmed.length && (
        <>
          <Box
            fontWeight={700}
            fontFamily="camptonBold"
            fontSize={{ xs: 16 }}
            mt={2}
          >
            Confirmed Sessions
          </Box>
          {confirmed.slice(0, visibleConfirmed).map(({ data, type }, index) => (
            <Box key={index} maxHeight={48}>
              <ChatEditCard
                {...data}
                disabled={!store.events.entries.has(data.instanceId)}
              />
              {(showSeeMore || index < confirmed.length - 1) && <Divider />}
            </Box>
          ))}
        </>
      )}
      {!!personal.length && (
        <>
          <Box
            fontWeight={700}
            fontFamily="camptonBold"
            fontSize={{ xs: 16 }}
            mt={2}
          >
            My Events
          </Box>
          {personal.slice(0, visibleConfirmed).map(({ data, type }, index) => (
            <Box key={index} maxHeight={48}>
              <ChatEditCard
                {...data}
                disabled={!store.events.entries.has(data.instanceId)}
              />
              {index < personal.length - 1 && <Divider />}
            </Box>
          ))}
        </>
      )}
      {showSeeMore && (
        <Box p={1.5} pb={0}>
          <ApiariButton variant="text" onClick={showMore} fullWidth>
            See more
          </ApiariButton>
        </Box>
      )}
    </Box>
  ))
}

ChatAppointmentPagination.propTypes = {
  items: PropTypes.array,
}

export default ChatAppointmentPagination

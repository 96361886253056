import {
  campton,
  camptonBold,
  gotham,
  robotoBlack,
  roboto,
  robotoBold,
  cormorantinfantLightItalic,
  cormorantinfantMedium,
} from '../fonts/fonts'

const getOverrides = theme => ({
  MuiCssBaseline: {
    '@global': {
      '@font-face': [
        gotham,
        campton,
        camptonBold,
        roboto,
        robotoBold,
        robotoBlack,
        cormorantinfantLightItalic,
        cormorantinfantMedium,
      ],
      body: {
        '& .debugger > *': {
          outline: '1px solid grey',
        },
      },
    },
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      backgroundColor: 'transparent',
      margin: theme.spacing(-2),
      marginBottom: theme.spacing(1),
    },
  },
  MuiTable: {
    root: {
      borderCollapse: 'separate',
    },
  },
  MuiTableHead: {
    root: {
      textTransform: 'uppercase',
      '& th': {
        '&:first-child': {
          paddingLeft: 0,
        },
        fontFamily: theme.typography.camptonBold,
        color: theme.palette.common.black,
        fontSize: '1rem',
        borderBottom: 'none',
      },
    },
  },
  MuiTableBody: {
    root: {
      '& tr:first-child': {
        borderTop: `1px solid rgba(224, 224, 224, 1)`,
        '& td': {
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      '& td:first-child': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      },
      '& tr:first-child td:first-child': {
        borderRadius: '1em 0 0 0',
      },
      '& tr:last-child td:first-child': {
        borderRadius: '0 0 0 1em',
      },
      '& td:last-child': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
      },
      '& tr:last-child td:last-child': {
        borderRadius: '0 0 1em 0',
      },
      '& tr:first-child td:last-child': {
        borderRadius: '0 1em 0 0',
      },
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      minHeight: 335,
    },
  },
  MuiPickersCalendarHeader: {
    dayLabel: {
      color: theme.palette.common.black,
    },
    transitionContainer: {
      textTransform: 'uppercase',
      '& p': {
        fontFamily: theme.typography.camptonBold,
      },
    },
    iconButton: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiPickersDay: {
    day: {
      borderRadius: 0,
      background: theme.palette.common.white,
      margin: theme.spacing(0.15),
      '& p': {
        fontSize: '0.75rem',
      },
    },
    daySelected: {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  MuiDialog: {
    paperScrollPaper: {
      maxHeight: '90vh',
    },
  },
  MuiAvatar: {
    root: {
      width: '100%',
      height: '100%',
    },
  },
  MuiInputAdornment: {
    positionStart: {
      position: 'absolute',
      left: theme.spacing(1.5),
    },
    positionEnd: {
      position: 'absolute',
      right: theme.spacing(1.5),
    },
  },
  MuiSelect: {
    select: {
      borderRadius: theme.spacing(1.25),
      '&:focus': {
        borderRadius: theme.spacing(1.25),
      },
    },
  },
  MuiListItem: {
    root: {
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.greyBlue['100'],
      },
    },
  },
  MuiInputBase: {
    input: {
      background: '#f8fafd',
      borderRadius: theme.spacing(1.25),
      padding: theme.spacing(1.25),
    },
    inputAdornedStart: {
      paddingLeft: theme.spacing(5),
    },
    inputAdornedEnd: {
      paddingRight: theme.spacing(5),
    },
    inputMultiline: {
      padding: theme.spacing(1.25),
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 42,
    },
  },
  MuiOutlinedInput: {
    root: {
      '&:hover $notchedOutline': {
        borderColor: 'transparent',
      },
    },
    adornedEnd: {
      paddingRight: 0,
    },
    inputAdornedEnd: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    notchedOutline: {
      borderRadius: theme.spacing(1),
      borderColor: 'transparent',
      borderWidth: `1px !important`,
    },
    multiline: {
      background: '#f8fafd',
    },
    inputMultiline: {
      borderRadius: 0,
      background: 'transparent',
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 12,
      color: theme.palette.text.grey,
    },
  },
  MuiInputLabel: {
    root: {
      '&:not($outlined)': {
        marginLeft: theme.spacing(1.25),
      },
    },
    formControl: {
      '&:not($shrink):not($outlined)': {
        top: theme.spacing(0.5),
      },
    },
  },
  MuiFormHelperText: {
    root: {
      marginLeft: theme.spacing(1.25),
    },
  },
  MuiButton: {
    root: {
      whiteSpace: 'nowrap',
      borderRadius: theme.spacing(2.5),
    },
    text: {
      padding: '6px 18px',
    },
    contained: {
      paddingTop: 8, //should be 6 but font camptonBold is not aligned
      paddingBottom: 5, //should be 6 but font camptonBold is not aligned
      boxShadow: 'none',
      '&:active': {
        boxShadow: 'none',
      },
    },
    outlined: {
      paddingTop: 8, //should be 6 but font camptonBold is not aligned
    },
    outlinedPrimary: {
      background: theme.palette.common.white,
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
        backgroundColor: theme.palette.secondary.brandGreenLight,
      },
    },
    outlinedSecondary: {
      background: theme.palette.common.white,
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      border: '1px solid rgba(0, 0, 0, 0.04)',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.1)',
    },
    arrow: {
      color: theme.palette.common.white,
    },
    tooltipPlacementTop: {
      '& > $arrow': {
        filter: 'drop-shadow(1px 4px 2px rgba(0, 0, 0, 0.14))',
      },
    },
    tooltipPlacementBottom: {
      '& > $arrow': {
        filter: 'drop-shadow(0 0 0 rgba(0, 0, 0, 0.44));',
      },
    },
    tooltipPlacementLeft: {
      '& > $arrow': {
        filter: 'drop-shadow(4px 0 2px rgba(0, 0, 0, 0.14));',
      },
    },
    tooltipPlacementRight: {
      '& > $arrow': {
        filter: 'drop-shadow(0 0 0 rgba(0, 0, 0, 0.44));',
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: theme.spacing(2.5),
      height: 36,
    },
    clickableColorPrimary: {
      '&:not($outlined), &:active': {
        background: theme.palette.text.grey,
      },
    },
    clickable: {},
    outlinedPrimary: {
      borderColor: theme.palette.text.grey,
      color: theme.palette.text.grey,
      '$clickable&:hover': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.common.black,
        color: theme.palette.common.black,
      },
    },
  },
})

export default getOverrides

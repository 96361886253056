import format from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import { DATE_FORMATS } from './config'
import addMonths from 'date-fns/addMonths'

export const getFormValues = (e, options = {}) => {
  const { removeBlank } = options

  return Array.from(e.target.elements)
    .map(i => i.name)
    .reduce((agg, el) => {
      if (!el) return agg
      const domEl = e.target.elements.namedItem(el)
      const value = domEl[domEl.type === 'checkbox' ? 'checked' : 'value']
      if (removeBlank && !value) return agg
      agg[el] = value
      return agg
    }, {})
}

export const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const nullify = obj =>
  Object.keys(obj).reduce((agg, el) => {
    agg[el] = null
    return agg
  }, {})

export const isObjectEmpty = o => Object.values(o).every(x => !x)

export const groupBy = (array, field) =>
  array.reduce((agg, el) => {
    if (!agg[el[field]]) {
      agg[el[field]] = [el]
    } else {
      agg[el[field]].push(el)
    }
    return agg
  }, {})

export const hideCharacters = string => string.replace(/./g, '•')

export const createDateFromString = (d, t) =>
  new Date(`${d.split('-').join('/')} ${t}`)

export const capitalize = s => s && `${s.charAt(0).toUpperCase()}${s.substr(1)}`

export const eventsQuery = {
  fromDate: format(subMonths(new Date(), 1), DATE_FORMATS.DATE),
  toDate: format(addMonths(new Date(), 12), DATE_FORMATS.DATE),
}

export const dateToTimestamp = date => {
  return +new Date(date)
}

export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const unsanitizePhoneNumber = phone => {
  return phone.replace(/\s/g, '').replace('+1', '')
}

export function serviceNameToHuman(service) {
  if (service === 'deepCleaning') return 'Deep cleaning'
  else if (service === 'childcarePlus') return 'Enrichment'
  else if (service === 'night_nurse') return 'Night nurse'
  return service.charAt(0).toUpperCase() + service.slice(1)
}

export function serviceNameToOccupation(service) {
  if (service === 'deepCleaning') return 'deep cleaner'
  else if (service === 'childcare') return 'sitter or nanny'
  else if (service === 'childcarePlus') return 'tutor'
  else if (service === 'night_nurse') return 'night nanny'
  return service.charAt(0).toUpperCase() + service.slice(1)
}

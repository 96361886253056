import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Warning from '../../../assets/warning.svg'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { ProviderCheckbox } from '../become-a-provider-styles'

const useStyles = makeStyles(theme => ({
  buttonYellow: {
    color: theme.palette.text.grey,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '200px',
    lineHeight: '45px',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      padding: '0 20px',
    },
    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
  benefits: {
    display: 'block',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px',
  },
  cardWrapper: {
    marginTop: '20px',
    marginBottom: '20px',
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '16px 20px',
    fontWeight: '600',
  },
  termsLabel: {
    display: 'block',
    marginBottom: '5px',
    '& .MuiCheckbox-root': {
      padding: '2px',
    },
    '& a:any-link': {
      textDecoration: 'underline',
    },
  },
}))

const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
  hidePostalCode: true,
}

const BackgroundCheckForm = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  let checkBox1 = false

  const [checkbox1, setCheckbox1] = useState(false)
  const [checkbox2, setCheckbox2] = useState(false)
  const { setErrors, submitDetails, setSubmitting, values } = props

  React.useImperativeHandle(ref, () => ({
    async chargeCard() {
      setErrors({})
      if (!stripe && !elements) {
        return
      }

      if (!checkbox1 || !checkbox2) {
        setErrors({ error: 'You must check both terms' })
        setSubmitting(false)
      } else if (stripe && elements) {
        const cardElement = elements.getElement(CardElement)
        if (cardElement) {
          setSubmitting(true)
          const { token, error } = await stripe.createToken(cardElement)
          if (token) {
            submitDetails(
              { ...values, stripeToken: token.id },
              { setSubmitting },
            )
          } else if (error) {
            setErrors({ error: error.message })
            setSubmitting(false)
          }
        }
      }
    },
  }))

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={1}
          >
            Join Apiari’s premium jobs platform at $29.99 a month.
            <span className={classes.benefits}>
              ✅ Stop applying. Let jobs come to you. <br />
              ✅ Late cancellation protection. <br />
              ✅ Booking support.
              <br />
            </span>
            <span className={classes.subHead}>
              Your $29.99 monthly subscription includes a one-time background
              check and reference checks. Both are required for successful
              matching on our platform. If you fail to complete them within 30
              days, we will pause your subscription. Cancel your subscription at
              any time by emailing us{' '}
              <a href="mailto:hello@theapiari.com">
                <u>here</u>
              </a>
              . No partial refunds.
            </span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} xl={12} lg={12} md={12} sm={12}>
            <CardElement
              className={classes.cardWrapper}
              options={STRIPE_CARD_ELEMENT_OPTIONS}
              onChange={() => setErrors({})}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box px={1}>
            <label className={classes.termsLabel}>
              <Checkbox
                checked={checkbox1}
                onChange={() => setCheckbox1(!checkbox1)}
                name="checkbox1"
                color="primary"
              />
              I agree that my subscription will automatically renew, and
              authorize charges for the above subscription fee.
            </label>
            <label className={classes.termsLabel}>
              <Checkbox
                checked={checkbox2}
                onChange={() => setCheckbox2(!checkbox2)}
                name="checkbox2"
                color="primary"
              />
              I have read and agree to the{' '}
              <a href="https://theapiari.com/providerterms">Terms of Use</a> and{' '}
              <a href="https://theapiari.com/privacy">Privacy Policy</a>.
            </label>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})

export default BackgroundCheckForm

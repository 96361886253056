import Box from '@material-ui/core/Box'
import * as PropTypes from 'prop-types'
import React from 'react'
import ChatInputForm from '../components/actions/chat-input'

const ChatSlackActions = ({ onSend }) => {
  return (
    <Box mb={10}>
      <ChatInputForm onSubmit={input => onSend({ input })} type="text" />
    </Box>
  )
}

ChatSlackActions.propTypes = {
  onSend: PropTypes.func.isRequired,
}

export default ChatSlackActions

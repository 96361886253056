import React from 'react'
import * as PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Message from '../../models/message'
import MessageText from './message-text'
import amyImage from '../../../../assets/images/amy.png'
import Box from '@material-ui/core/Box'

const BotMessage = ({ message }) => {
  const { text, image, isTyping, output } = message
  if (!text && !isTyping) return null
  return (
    <Grid container>
      <Grid item xs="auto">
        <Box width={44} height={44} mr={1.25} mb={1}>
          <Avatar src={image || amyImage} />
        </Box>
      </Grid>
      <Grid item xs>
        <MessageText
          text={text}
          isFromBot
          isTyping={isTyping}
          isPagination={output && output.displayAsPagination}
        />
      </Grid>
    </Grid>
  )
}

BotMessage.propTypes = {
  message: PropTypes.instanceOf(Message),
}

export default BotMessage

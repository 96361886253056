import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import apiariLogo from '../../../assets/provider-logo.svg'

const useStyles = makeStyles(theme => ({
  logo: {
    width: 225,
  },
  gridItem: {
    justifyContent: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  [theme.breakpoints.down('md')]: {
    top: '0',
  },
}))

const ProviderHeader = () => {
  const classes = useStyles()

  return (
    <Box px={5} py={5}>
      <Grid container alignItems="center" className={classes.gridItem}>
        <Grid item xs="auto">
          <a href="https://theapiari.com/become-a-provider">
            <img src={apiariLogo} alt="logo" className={classes.logo} />
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProviderHeader

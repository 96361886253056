import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    minHeight: '100vh',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    '& > div': {
      position: 'absolute',
    },
  },
  confetti0: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '35%',
    opacity: '0.5167149149',
    transform: 'rotate(169.0572689347deg)',
    WebkitAnimation: '$drop-0 4.1573557054s 0.0529963215s infinite',
    animation: '$drop-0 4.1573557054s 0.0529963215s infinite',
  },
  confetti1: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '60%',
    opacity: '0.6563857836',
    transform: 'rotate(17.5227501447deg)',
    WebkitAnimation: '$drop-1 4.3210825279s 0.0460217397s infinite',
    animation: '$drop-1 4.3210825279s 0.0460217397s infinite',
  },
  confetti2: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '38%',
    opacity: '0.9697543838',
    transform: 'rotate(148.4136271628deg)',
    WebkitAnimation: '$drop-2 4.4696350591s 0.4031022147s infinite',
    animation: '$drop-2 4.4696350591s 0.4031022147s infinite',
  },
  confetti3: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '65%',
    opacity: '0.9077315544',
    transform: 'rotate(75.2674728013deg)',
    WebkitAnimation: '$drop-3 4.5004420122s 0.7816357005s infinite',
    animation: '$drop-3 4.5004420122s 0.7816357005s infinite',
  },
  confetti4: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '23%',
    opacity: '1.1950756552',
    transform: 'rotate(8.1053269765deg)',
    WebkitAnimation: '$drop-4 4.4661222414s 0.2798506269s infinite',
    animation: '$drop-4 4.4661222414s 0.2798506269s infinite',
  },
  confetti5: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '72%',
    opacity: '1.0817032605',
    transform: 'rotate(95.6573873408deg)',
    WebkitAnimation: '$drop-5 4.9977323038s 0.0820442058s infinite',
    animation: '$drop-5 4.9977323038s 0.0820442058s infinite',
  },
  confetti6: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '72%',
    opacity: '1.1250607006',
    transform: 'rotate(263.0309858352deg)',
    WebkitAnimation: '$drop-6 4.0127403849s 0.9205891285s infinite',
    animation: '$drop-6 4.0127403849s 0.9205891285s infinite',
  },
  confetti7: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '13%',
    opacity: '0.780719185',
    transform: 'rotate(153.9515939411deg)',
    WebkitAnimation: '$drop-7 4.4827533146s 0.2507187532s infinite',
    animation: '$drop-7 4.4827533146s 0.2507187532s infinite',
  },
  confetti8: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '8%',
    opacity: '0.8886635453',
    transform: 'rotate(246.0068599576deg)',
    WebkitAnimation: '$drop-8 4.7750121411s 0.6003920963s infinite',
    animation: '$drop-8 4.7750121411s 0.6003920963s infinite',
  },
  confetti9: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '21%',
    opacity: '0.9441642782',
    transform: 'rotate(105.538378303deg)',
    WebkitAnimation: '$drop-9 4.9428432937s 0.0178993637s infinite',
    animation: '$drop-9 4.9428432937s 0.0178993637s infinite',
  },
  confetti10: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '58%',
    opacity: '1.2746880427',
    transform: 'rotate(254.7304275802deg)',
    WebkitAnimation: '$drop-10 4.2288717376s 0.2453341118s infinite',
    animation: '$drop-10 4.2288717376s 0.2453341118s infinite',
  },
  confetti11: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '47%',
    opacity: '1.1715837394',
    transform: 'rotate(277.1968227016deg)',
    WebkitAnimation: '$drop-11 4.8091932097s 0.8327643303s infinite',
    animation: '$drop-11 4.8091932097s 0.8327643303s infinite',
  },
  confetti12: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '67%',
    opacity: '1.1707405829',
    transform: 'rotate(34.4734787606deg)',
    WebkitAnimation: '$drop-12 4.0636627243s 0.8503618024s infinite',
    animation: '$drop-12 4.0636627243s 0.8503618024s infinite',
  },
  confetti13: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '9%',
    opacity: '1.1925973226',
    transform: 'rotate(100.7380702909deg)',
    WebkitAnimation: '$drop-13 4.5460203277s 0.9865712155s infinite',
    animation: '$drop-13 4.5460203277s 0.9865712155s infinite',
  },
  confetti14: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '62%',
    opacity: '1.1865750393',
    transform: 'rotate(293.0693413659deg)',
    WebkitAnimation: '$drop-14 4.0420299123s 0.5742632494s infinite',
    animation: '$drop-14 4.0420299123s 0.5742632494s infinite',
  },
  confetti15: {
    width: '5px',
    height: '2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '75%',
    opacity: '0.9609562913',
    transform: 'rotate(46.7944894885deg)',
    WebkitAnimation: '$drop-15 4.548932297s 0.7836784596s infinite',
    animation: '$drop-15 4.548932297s 0.7836784596s infinite',
  },
  confetti16: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '46%',
    opacity: '0.7429072074',
    transform: 'rotate(79.8762630232deg)',
    WebkitAnimation: '$drop-16 4.0698793042s 0.246900688s infinite',
    animation: '$drop-16 4.0698793042s 0.246900688s infinite',
  },
  confetti17: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '84%',
    opacity: '1.035831636',
    transform: 'rotate(252.2062506425deg)',
    WebkitAnimation: '$drop-17 4.2544205763s 0.011035912s infinite',
    animation: '$drop-17 4.2544205763s 0.011035912s infinite',
  },
  confetti18: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '47%',
    opacity: '0.9430100146',
    transform: 'rotate(216.2882544898deg)',
    WebkitAnimation: '$drop-18 4.084311484s 0.8822217498s infinite',
    animation: '$drop-18 4.084311484s 0.8822217498s infinite',
  },
  confetti19: {
    width: '5px',
    height: '2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '7%',
    opacity: '0.6814981381',
    transform: 'rotate(194.1897906786deg)',
    WebkitAnimation: '$drop-19 4.3274199924s 0.9774069953s infinite',
    animation: '$drop-19 4.3274199924s 0.9774069953s infinite',
  },
  confetti20: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '50%',
    opacity: '0.660200441',
    transform: 'rotate(152.4725096373deg)',
    WebkitAnimation: '$drop-20 4.323963057s 0.164999137s infinite',
    animation: '$drop-20 4.323963057s 0.164999137s infinite',
  },
  confetti21: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '45%',
    opacity: '1.264976348',
    transform: 'rotate(215.2159878099deg)',
    WebkitAnimation: '$drop-21 4.2507121688s 0.0514250789s infinite',
    animation: '$drop-21 4.2507121688s 0.0514250789s infinite',
  },
  confetti22: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '51%',
    opacity: '1.4876037607',
    transform: 'rotate(41.0479920138deg)',
    WebkitAnimation: '$drop-22 4.2700830273s 0.5480772054s infinite',
    animation: '$drop-22 4.2700830273s 0.5480772054s infinite',
  },
  confetti23: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '32%',
    opacity: '0.8585451565',
    transform: 'rotate(215.2676349019deg)',
    WebkitAnimation: '$drop-23 4.0596812729s 0.4362529488s infinite',
    animation: '$drop-23 4.0596812729s 0.4362529488s infinite',
  },
  confetti24: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '83%',
    opacity: '0.9521055746',
    transform: 'rotate(105.8222996652deg)',
    WebkitAnimation: '$drop-24 4.9975873s 0.6313612561s infinite',
    animation: '$drop-24 4.9975873s 0.6313612561s infinite',
  },
  confetti25: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '38%',
    opacity: '0.888679673',
    transform: 'rotate(206.0683273396deg)',
    WebkitAnimation: '$drop-25 4.4012420884s 0.0580887771s infinite',
    animation: '$drop-25 4.4012420884s 0.0580887771s infinite',
  },
  confetti26: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '83%',
    opacity: '1.1891865477',
    transform: 'rotate(307.4795005572deg)',
    WebkitAnimation: '$drop-26 4.3866878786s 0.4531064982s infinite',
    animation: '$drop-26 4.3866878786s 0.4531064982s infinite',
  },
  confetti27: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '31%',
    opacity: '1.1902364447',
    transform: 'rotate(158.4530456969deg)',
    WebkitAnimation: '$drop-27 4.8983000693s 0.2221341941s infinite',
    animation: '$drop-27 4.8983000693s 0.2221341941s infinite',
  },
  confetti28: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '23%',
    opacity: '0.5336843924',
    transform: 'rotate(28.010150444deg)',
    WebkitAnimation: '$drop-28 4.6414984163s 0.4505389652s infinite',
    animation: '$drop-28 4.6414984163s 0.4505389652s infinite',
  },
  confetti29: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '28%',
    opacity: '1.0163250872',
    transform: 'rotate(343.6318425635deg)',
    WebkitAnimation: '$drop-29 4.5198904227s 0.6178108376s infinite',
    animation: '$drop-29 4.5198904227s 0.6178108376s infinite',
  },
  confetti30: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '58%',
    opacity: '1.4561769454',
    transform: 'rotate(303.1021262923deg)',
    WebkitAnimation: '$drop-30 4.9702484436s 0.7932778471s infinite',
    animation: '$drop-30 4.9702484436s 0.7932778471s infinite',
  },
  confetti31: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '34%',
    opacity: '1.2071418699',
    transform: 'rotate(50.9659842459deg)',
    WebkitAnimation: '$drop-31 4.0276308759s 0.8859288403s infinite',
    animation: '$drop-31 4.0276308759s 0.8859288403s infinite',
  },
  confetti32: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '99%',
    opacity: '1.2008107542',
    transform: 'rotate(186.3302797514deg)',
    WebkitAnimation: '$drop-32 4.2315999328s 0.4618979441s infinite',
    animation: '$drop-32 4.2315999328s 0.4618979441s infinite',
  },
  confetti33: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '14%',
    opacity: '1.1852543399',
    transform: 'rotate(28.3164105507deg)',
    WebkitAnimation: '$drop-33 4.234623972s 0.2002494463s infinite',
    animation: '$drop-33 4.234623972s 0.2002494463s infinite',
  },
  confetti34: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '54%',
    opacity: '1.4954977933',
    transform: 'rotate(113.0631967596deg)',
    WebkitAnimation: '$drop-34 4.1662157197s 0.2180092694s infinite',
    animation: '$drop-34 4.1662157197s 0.2180092694s infinite',
  },
  confetti35: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '57%',
    opacity: '1.1583409085',
    transform: 'rotate(269.5841159246deg)',
    WebkitAnimation: '$drop-35 4.2421238622s 0.1423190414s infinite',
    animation: '$drop-35 4.2421238622s 0.1423190414s infinite',
  },
  confetti36: {
    width: '5px',
    height: '2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '30%',
    opacity: '0.8284899676',
    transform: 'rotate(34.2285057381deg)',
    WebkitAnimation: '$drop-36 4.7953525222s 0.7781701186s infinite',
    animation: '$drop-36 4.7953525222s 0.7781701186s infinite',
  },
  confetti37: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '33%',
    opacity: '1.0306004187',
    transform: 'rotate(137.2452769848deg)',
    WebkitAnimation: '$drop-37 4.9917159209s 0.8303279024s infinite',
    animation: '$drop-37 4.9917159209s 0.8303279024s infinite',
  },
  confetti38: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '90%',
    opacity: '1.0345252833',
    transform: 'rotate(242.7893745031deg)',
    WebkitAnimation: '$drop-38 4.0356914016s 0.2834767015s infinite',
    animation: '$drop-38 4.0356914016s 0.2834767015s infinite',
  },
  confetti39: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '50%',
    opacity: '0.799752108',
    transform: 'rotate(40.2647155043deg)',
    WebkitAnimation: '$drop-39 4.4236083312s 0.233618652s infinite',
    animation: '$drop-39 4.4236083312s 0.233618652s infinite',
  },
  confetti40: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '44%',
    opacity: '0.5372465261',
    transform: 'rotate(204.6129160643deg)',
    WebkitAnimation: '$drop-40 4.7193024308s 0.8909637694s infinite',
    animation: '$drop-40 4.7193024308s 0.8909637694s infinite',
  },
  confetti41: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '72%',
    opacity: '0.7249985344',
    transform: 'rotate(278.4341944783deg)',
    WebkitAnimation: '$drop-41 4.3210959054s 0.7624589601s infinite',
    animation: '$drop-41 4.3210959054s 0.7624589601s infinite',
  },
  confetti42: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '76%',
    opacity: '1.2634781594',
    transform: 'rotate(104.7773795525deg)',
    WebkitAnimation: '$drop-42 4.4221731303s 0.913177706s infinite',
    animation: '$drop-42 4.4221731303s 0.913177706s infinite',
  },
  confetti43: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '1%',
    opacity: '1.1787037964',
    transform: 'rotate(312.352673467deg)',
    WebkitAnimation: '$drop-43 4.8897586541s 0.903502678s infinite',
    animation: '$drop-43 4.8897586541s 0.903502678s infinite',
  },
  confetti44: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '77%',
    opacity: '0.6179060384',
    transform: 'rotate(258.2048084822deg)',
    WebkitAnimation: '$drop-44 4.749349843s 0.3657246036s infinite',
    animation: '$drop-44 4.749349843s 0.3657246036s infinite',
  },
  confetti45: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '40%',
    opacity: '0.7060373718',
    transform: 'rotate(190.8471139456deg)',
    WebkitAnimation: '$drop-45 4.2813379742s 0.1908795831s infinite',
    animation: '$drop-45 4.2813379742s 0.1908795831s infinite',
  },
  confetti46: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '80%',
    opacity: '0.8768643138',
    transform: 'rotate(276.5001994989deg)',
    WebkitAnimation: '$drop-46 4.6464014061s 0.826739275s infinite',
    animation: '$drop-46 4.6464014061s 0.826739275s infinite',
  },
  confetti47: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '5%',
    opacity: '0.766484106',
    transform: 'rotate(102.5820428908deg)',
    WebkitAnimation: '$drop-47 4.7320708892s 0.2796876077s infinite',
    animation: '$drop-47 4.7320708892s 0.2796876077s infinite',
  },
  confetti48: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '36%',
    opacity: '1.3361524756',
    transform: 'rotate(326.5750123455deg)',
    WebkitAnimation: '$drop-48 4.6596712576s 0.8043648662s infinite',
    animation: '$drop-48 4.6596712576s 0.8043648662s infinite',
  },
  confetti49: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '75%',
    opacity: '1.1298179296',
    transform: 'rotate(212.0190747242deg)',
    WebkitAnimation: '$drop-49 4.5645576303s 0.7106338384s infinite',
    animation: '$drop-49 4.5645576303s 0.7106338384s infinite',
  },
  confetti50: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '21%',
    opacity: '0.6306633076',
    transform: 'rotate(154.9066721333deg)',
    WebkitAnimation: '$drop-50 4.666155803s 0.9285323811s infinite',
    animation: '$drop-50 4.666155803s 0.9285323811s infinite',
  },
  confetti51: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '14%',
    opacity: '1.2198355868',
    transform: 'rotate(301.0682371326deg)',
    WebkitAnimation: '$drop-51 4.0648338193s 0.3319076982s infinite',
    animation: '$drop-51 4.0648338193s 0.3319076982s infinite',
  },
  confetti52: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '60%',
    opacity: '0.6432674382',
    transform: 'rotate(6.5275705759deg)',
    WebkitAnimation: '$drop-52 4.4112671955s 0.7535619349s infinite',
    animation: '$drop-52 4.4112671955s 0.7535619349s infinite',
  },
  confetti53: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '1%',
    opacity: '1.3794957513',
    transform: 'rotate(136.045458362deg)',
    WebkitAnimation: '$drop-53 4.3892580132s 0.9140258929s infinite',
    animation: '$drop-53 4.3892580132s 0.9140258929s infinite',
  },
  confetti54: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '87%',
    opacity: '0.8687634475',
    transform: 'rotate(357.0972589592deg)',
    WebkitAnimation: '$drop-54 4.0336057744s 0.3541638478s infinite',
    animation: '$drop-54 4.0336057744s 0.3541638478s infinite',
  },
  confetti55: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '30%',
    opacity: '1.4211625329',
    transform: 'rotate(357.0981702149deg)',
    WebkitAnimation: '$drop-55 4.7439404318s 0.2123915505s infinite',
    animation: '$drop-55 4.7439404318s 0.2123915505s infinite',
  },
  confetti56: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '87%',
    opacity: '1.0946320414',
    transform: 'rotate(11.7155035371deg)',
    WebkitAnimation: '$drop-56 4.0435141229s 0.8756012783s infinite',
    animation: '$drop-56 4.0435141229s 0.8756012783s infinite',
  },
  confetti57: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '46%',
    opacity: '1.1644336579',
    transform: 'rotate(96.327962646deg)',
    WebkitAnimation: '$drop-57 4.1064151476s 0.0824205029s infinite',
    animation: '$drop-57 4.1064151476s 0.0824205029s infinite',
  },
  confetti58: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '17%',
    opacity: '0.7228092727',
    transform: 'rotate(313.6620998744deg)',
    WebkitAnimation: '$drop-58 4.2806239882s 0.8570490199s infinite',
    animation: '$drop-58 4.2806239882s 0.8570490199s infinite',
  },
  confetti59: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '51%',
    opacity: '1.1457433088',
    transform: 'rotate(278.8247253454deg)',
    WebkitAnimation: '$drop-59 4.0230548861s 0.5943590829s infinite',
    animation: '$drop-59 4.0230548861s 0.5943590829s infinite',
  },
  confetti60: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '10%',
    opacity: '0.9372733868',
    transform: 'rotate(21.5066569221deg)',
    WebkitAnimation: '$drop-60 4.9020847708s 0.4554641979s infinite',
    animation: '$drop-60 4.9020847708s 0.4554641979s infinite',
  },
  confetti61: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '90%',
    opacity: '0.9356570648',
    transform: 'rotate(296.6944658038deg)',
    WebkitAnimation: '$drop-61 4.2856448835s 0.7959818021s infinite',
    animation: '$drop-61 4.2856448835s 0.7959818021s infinite',
  },
  confetti62: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '49%',
    opacity: '0.9815555134',
    transform: 'rotate(61.4045548659deg)',
    WebkitAnimation: '$drop-62 4.5955815057s 0.5814618054s infinite',
    animation: '$drop-62 4.5955815057s 0.5814618054s infinite',
  },
  confetti63: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '88%',
    opacity: '1.2638752658',
    transform: 'rotate(120.6030027128deg)',
    WebkitAnimation: '$drop-63 4.8086091696s 0.197163422s infinite',
    animation: '$drop-63 4.8086091696s 0.197163422s infinite',
  },
  confetti64: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '75%',
    opacity: '0.8477510874',
    transform: 'rotate(345.9977940046deg)',
    WebkitAnimation: '$drop-64 4.3111539933s 0.0907706835s infinite',
    animation: '$drop-64 4.3111539933s 0.0907706835s infinite',
  },
  confetti65: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '15%',
    opacity: '0.7637728652',
    transform: 'rotate(272.7356119017deg)',
    WebkitAnimation: '$drop-65 4.7144035346s 0.2891392992s infinite',
    animation: '$drop-65 4.7144035346s 0.2891392992s infinite',
  },
  confetti66: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '61%',
    opacity: '0.5524105434',
    transform: 'rotate(224.201201334deg)',
    WebkitAnimation: '$drop-66 4.9391321402s 0.8495808497s infinite',
    animation: '$drop-66 4.9391321402s 0.8495808497s infinite',
  },
  confetti67: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '88%',
    opacity: '1.3158841338',
    transform: 'rotate(159.0675180007deg)',
    WebkitAnimation: '$drop-67 4.8980075438s 0.2610508009s infinite',
    animation: '$drop-67 4.8980075438s 0.2610508009s infinite',
  },
  confetti68: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '64%',
    opacity: '0.9623039632',
    transform: 'rotate(335.1396360073deg)',
    WebkitAnimation: '$drop-68 4.153300136s 0.9370001735s infinite',
    animation: '$drop-68 4.153300136s 0.9370001735s infinite',
  },
  confetti69: {
    width: '5px',
    height: '2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '41%',
    opacity: '1.4203704739',
    transform: 'rotate(235.032907136deg)',
    WebkitAnimation: '$drop-69 4.1240089326s 0.9538466095s infinite',
    animation: '$drop-69 4.1240089326s 0.9538466095s infinite',
  },
  confetti70: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '80%',
    opacity: '1.3048644015',
    transform: 'rotate(109.6163569386deg)',
    WebkitAnimation: '$drop-70 4.6888935806s 0.0860937739s infinite',
    animation: '$drop-70 4.6888935806s 0.0860937739s infinite',
  },
  confetti71: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '66%',
    opacity: '0.6510521961',
    transform: 'rotate(127.8558852354deg)',
    WebkitAnimation: '$drop-71 4.0321291035s 0.3767829177s infinite',
    animation: '$drop-71 4.0321291035s 0.3767829177s infinite',
  },
  confetti72: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '26%',
    opacity: '0.6719259779',
    transform: 'rotate(24.6671116214deg)',
    WebkitAnimation: '$drop-72 4.210874323s 0.7231778639s infinite',
    animation: '$drop-72 4.210874323s 0.7231778639s infinite',
  },
  confetti73: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '29%',
    opacity: '1.4016268172',
    transform: 'rotate(220.0456549971deg)',
    WebkitAnimation: '$drop-73 4.4664710824s 0.8620662948s infinite',
    animation: '$drop-73 4.4664710824s 0.8620662948s infinite',
  },
  confetti74: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '10%',
    opacity: '0.8945866804',
    transform: 'rotate(210.2279148032deg)',
    WebkitAnimation: '$drop-74 4.9811888986s 0.7754168104s infinite',
    animation: '$drop-74 4.9811888986s 0.7754168104s infinite',
  },
  confetti75: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '27%',
    opacity: '0.5264010988',
    transform: 'rotate(259.5998074144deg)',
    WebkitAnimation: '$drop-75 4.5644963006s 0.9760472324s infinite',
    animation: '$drop-75 4.5644963006s 0.9760472324s infinite',
  },
  confetti76: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '85%',
    opacity: '1.1138185829',
    transform: 'rotate(121.2434092509deg)',
    WebkitAnimation: '$drop-76 4.7693504169s 0.4505568601s infinite',
    animation: '$drop-76 4.7693504169s 0.4505568601s infinite',
  },
  confetti77: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '74%',
    opacity: '1.2851128962',
    transform: 'rotate(65.5578464187deg)',
    WebkitAnimation: '$drop-77 4.7659590405s 0.346875393s infinite',
    animation: '$drop-77 4.7659590405s 0.346875393s infinite',
  },
  confetti78: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '2%',
    opacity: '0.836828543',
    transform: 'rotate(209.0723186858deg)',
    WebkitAnimation: '$drop-78 4.8525797244s 0.0841318795s infinite',
    animation: '$drop-78 4.8525797244s 0.0841318795s infinite',
  },
  confetti79: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '40%',
    opacity: '1.4631484896',
    transform: 'rotate(292.3776330556deg)',
    WebkitAnimation: '$drop-79 4.8800202423s 0.8684658934s infinite',
    animation: '$drop-79 4.8800202423s 0.8684658934s infinite',
  },
  confetti80: {
    width: '5px',
    height: '2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '46%',
    opacity: '0.658600763',
    transform: 'rotate(46.5723476316deg)',
    WebkitAnimation: '$drop-80 4.288388163s 0.0472649805s infinite',
    animation: '$drop-80 4.288388163s 0.0472649805s infinite',
  },
  confetti81: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '89%',
    opacity: '0.8540172547',
    transform: 'rotate(105.2053130932deg)',
    WebkitAnimation: '$drop-81 4.7285413346s 0.5021346141s infinite',
    animation: '$drop-81 4.7285413346s 0.5021346141s infinite',
  },
  confetti82: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '10%',
    opacity: '0.6676550401',
    transform: 'rotate(211.0900861768deg)',
    WebkitAnimation: '$drop-82 4.0875581105s 0.7662576755s infinite',
    animation: '$drop-82 4.0875581105s 0.7662576755s infinite',
  },
  confetti83: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '50%',
    opacity: '0.580019502',
    transform: 'rotate(14.6265955592deg)',
    WebkitAnimation: '$drop-83 4.3929590557s 0.0720983132s infinite',
    animation: '$drop-83 4.3929590557s 0.0720983132s infinite',
  },
  confetti84: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '69%',
    opacity: '1.4888414092',
    transform: 'rotate(292.844886717deg)',
    WebkitAnimation: '$drop-84 4.2203155856s 0.800827759s infinite',
    animation: '$drop-84 4.2203155856s 0.800827759s infinite',
  },
  confetti85: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '13%',
    opacity: '0.5417680673',
    transform: 'rotate(250.212066956deg)',
    WebkitAnimation: '$drop-85 4.0052593621s 0.855872562s infinite',
    animation: '$drop-85 4.0052593621s 0.855872562s infinite',
  },
  confetti86: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '54%',
    opacity: '0.920366886',
    transform: 'rotate(37.6095601298deg)',
    WebkitAnimation: '$drop-86 4.7748630901s 0.4444053979s infinite',
    animation: '$drop-86 4.7748630901s 0.4444053979s infinite',
  },
  confetti87: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '97%',
    opacity: '0.5739586974',
    transform: 'rotate(230.8908961602deg)',
    WebkitAnimation: '$drop-87 4.9100456676s 0.7100177971s infinite',
    animation: '$drop-87 4.9100456676s 0.7100177971s infinite',
  },
  confetti88: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '10%',
    opacity: '0.52546145',
    transform: 'rotate(313.0089257049deg)',
    WebkitAnimation: '$drop-88 4.4979257867s 0.35873234s infinite',
    animation: '$drop-88 4.4979257867s 0.35873234s infinite',
  },
  confetti89: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '78%',
    opacity: '1.3163237599',
    transform: 'rotate(338.0864672746deg)',
    WebkitAnimation: '$drop-89 4.8006788401s 0.2894146994s infinite',
    animation: '$drop-89 4.8006788401s 0.2894146994s infinite',
  },
  confetti90: {
    width: '5px',
    height: '2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '60%',
    opacity: '1.4444792646',
    transform: 'rotate(12.5596799806deg)',
    WebkitAnimation: '$drop-90 4.6612965243s 0.9921143485s infinite',
    animation: '$drop-90 4.6612965243s 0.9921143485s infinite',
  },
  confetti91: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '6%',
    opacity: '0.7120847691',
    transform: 'rotate(2.1490693238deg)',
    WebkitAnimation: '$drop-91 4.6990402365s 0.8446637892s infinite',
    animation: '$drop-91 4.6990402365s 0.8446637892s infinite',
  },
  confetti92: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '62%',
    opacity: '0.9392934501',
    transform: 'rotate(199.3988935556deg)',
    WebkitAnimation: '$drop-92 4.4613770897s 0.4660860954s infinite',
    animation: '$drop-92 4.4613770897s 0.4660860954s infinite',
  },
  confetti93: {
    width: '5px',
    height: '2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '97%',
    opacity: '1.4103996146',
    transform: 'rotate(171.1947515168deg)',
    WebkitAnimation: '$drop-93 4.7744003937s 0.3793461167s infinite',
    animation: '$drop-93 4.7744003937s 0.3793461167s infinite',
  },
  confetti94: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '47%',
    opacity: '0.8716013186',
    transform: 'rotate(327.5468053176deg)',
    WebkitAnimation: '$drop-94 4.0656373086s 0.8532478969s infinite',
    animation: '$drop-94 4.0656373086s 0.8532478969s infinite',
  },
  confetti95: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '81%',
    opacity: '1.1958217375',
    transform: 'rotate(228.7868043039deg)',
    WebkitAnimation: '$drop-95 4.7876845418s 0.4508391733s infinite',
    animation: '$drop-95 4.7876845418s 0.4508391733s infinite',
  },
  confetti96: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '22%',
    opacity: '0.8172145475',
    transform: 'rotate(141.8640101944deg)',
    WebkitAnimation: '$drop-96 4.3526261372s 0.4305005449s infinite',
    animation: '$drop-96 4.3526261372s 0.4305005449s infinite',
  },
  confetti97: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '49%',
    opacity: '0.9613575227',
    transform: 'rotate(123.5160278172deg)',
    WebkitAnimation: '$drop-97 4.855963943s 0.1948994086s infinite',
    animation: '$drop-97 4.855963943s 0.1948994086s infinite',
  },
  confetti98: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '46%',
    opacity: '0.8462022793',
    transform: 'rotate(206.8111646019deg)',
    WebkitAnimation: '$drop-98 4.3431472315s 0.7249385702s infinite',
    animation: '$drop-98 4.3431472315s 0.7249385702s infinite',
  },
  confetti99: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '20%',
    opacity: '1.035445981',
    transform: 'rotate(203.3050648559deg)',
    WebkitAnimation: '$drop-99 4.0502349238s 0.1061810885s infinite',
    animation: '$drop-99 4.0502349238s 0.1061810885s infinite',
  },
  confetti100: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '14%',
    opacity: '1.3269182319',
    transform: 'rotate(32.4760364388deg)',
    WebkitAnimation: '$drop-100 4.4966667572s 0.2546203328s infinite',
    animation: '$drop-100 4.4966667572s 0.2546203328s infinite',
  },
  confetti101: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '100%',
    opacity: '0.5069879818',
    transform: 'rotate(100.1806803496deg)',
    WebkitAnimation: '$drop-101 4.6493117664s 0.5592877186s infinite',
    animation: '$drop-101 4.6493117664s 0.5592877186s infinite',
  },
  confetti102: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '9%',
    opacity: '1.1778939598',
    transform: 'rotate(278.7732287527deg)',
    WebkitAnimation: '$drop-102 4.8034314691s 0.255447065s infinite',
    animation: '$drop-102 4.8034314691s 0.255447065s infinite',
  },
  confetti103: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '5%',
    opacity: '0.5701848176',
    transform: 'rotate(261.0636361746deg)',
    WebkitAnimation: '$drop-103 4.8110927827s 0.162692949s infinite',
    animation: '$drop-103 4.8110927827s 0.162692949s infinite',
  },
  confetti104: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '69%',
    opacity: '1.1062546581',
    transform: 'rotate(216.5077216152deg)',
    WebkitAnimation: '$drop-104 4.4899246095s 0.4778263116s infinite',
    animation: '$drop-104 4.4899246095s 0.4778263116s infinite',
  },
  confetti105: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '2%',
    opacity: '0.7927687329',
    transform: 'rotate(64.6072119228deg)',
    WebkitAnimation: '$drop-105 4.2439957082s 0.951988563s infinite',
    animation: '$drop-105 4.2439957082s 0.951988563s infinite',
  },
  confetti106: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '60%',
    opacity: '0.5906472927',
    transform: 'rotate(29.3189103787deg)',
    WebkitAnimation: '$drop-106 4.0096932657s 0.9458925596s infinite',
    animation: '$drop-106 4.0096932657s 0.9458925596s infinite',
  },
  confetti107: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '85%',
    opacity: '0.8373680498',
    transform: 'rotate(79.0410721028deg)',
    WebkitAnimation: '$drop-107 4.2910877638s 0.778704189s infinite',
    animation: '$drop-107 4.2910877638s 0.778704189s infinite',
  },
  confetti108: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '35%',
    opacity: '1.0259124734',
    transform: 'rotate(17.2770027209deg)',
    WebkitAnimation: '$drop-108 4.025974339s 0.2322571509s infinite',
    animation: '$drop-108 4.025974339s 0.2322571509s infinite',
  },
  confetti109: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '71%',
    opacity: '1.0017604364',
    transform: 'rotate(303.8790517143deg)',
    WebkitAnimation: '$drop-109 4.5707227935s 0.9829287164s infinite',
    animation: '$drop-109 4.5707227935s 0.9829287164s infinite',
  },
  confetti110: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '83%',
    opacity: '0.896631371',
    transform: 'rotate(119.9621032557deg)',
    WebkitAnimation: '$drop-110 4.6506579622s 0.169849139s infinite',
    animation: '$drop-110 4.6506579622s 0.169849139s infinite',
  },
  confetti111: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '27%',
    opacity: '1.3508549765',
    transform: 'rotate(63.6445786953deg)',
    WebkitAnimation: '$drop-111 4.3710764248s 0.3649604539s infinite',
    animation: '$drop-111 4.3710764248s 0.3649604539s infinite',
  },
  confetti112: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '58%',
    opacity: '1.3934674874',
    transform: 'rotate(206.4153104661deg)',
    WebkitAnimation: '$drop-112 4.6067311676s 0.7612833732s infinite',
    animation: '$drop-112 4.6067311676s 0.7612833732s infinite',
  },
  confetti113: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '95%',
    opacity: '0.8213579406',
    transform: 'rotate(41.5667831127deg)',
    WebkitAnimation: '$drop-113 4.9476376461s 0.5836189364s infinite',
    animation: '$drop-113 4.9476376461s 0.5836189364s infinite',
  },
  confetti114: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '24%',
    opacity: '1.2871638865',
    transform: 'rotate(76.7885735452deg)',
    WebkitAnimation: '$drop-114 4.2589144396s 0.1855391691s infinite',
    animation: '$drop-114 4.2589144396s 0.1855391691s infinite',
  },
  confetti115: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '88%',
    opacity: '0.6776838273',
    transform: 'rotate(0.473960255deg)',
    WebkitAnimation: '$drop-115 4.0411471313s 0.4296648983s infinite',
    animation: '$drop-115 4.0411471313s 0.4296648983s infinite',
  },
  confetti116: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '10%',
    opacity: '1.1287256916',
    transform: 'rotate(347.5966851004deg)',
    WebkitAnimation: '$drop-116 4.0160352382s 0.3013922861s infinite',
    animation: '$drop-116 4.0160352382s 0.3013922861s infinite',
  },
  confetti117: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '64%',
    opacity: '0.5675674296',
    transform: 'rotate(54.4790872019deg)',
    WebkitAnimation: '$drop-117 4.1990090632s 0.552896452s infinite',
    animation: '$drop-117 4.1990090632s 0.552896452s infinite',
  },
  confetti118: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '82%',
    opacity: '0.8946242545',
    transform: 'rotate(4.0018595714deg)',
    WebkitAnimation: '$drop-118 4.1466899227s 0.6924167378s infinite',
    animation: '$drop-118 4.1466899227s 0.6924167378s infinite',
  },
  confetti119: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '22%',
    opacity: '0.7955939817',
    transform: 'rotate(287.2818010004deg)',
    WebkitAnimation: '$drop-119 4.8548344808s 0.9064599269s infinite',
    animation: '$drop-119 4.8548344808s 0.9064599269s infinite',
  },
  confetti120: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '18%',
    opacity: '1.0291128575',
    transform: 'rotate(184.9220053211deg)',
    WebkitAnimation: '$drop-120 4.5086135302s 0.7026832934s infinite',
    animation: '$drop-120 4.5086135302s 0.7026832934s infinite',
  },
  confetti121: {
    width: '5px',
    height: '2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '39%',
    opacity: '1.1253642313',
    transform: 'rotate(191.4902167125deg)',
    WebkitAnimation: '$drop-121 4.1538144422s 0.0440873671s infinite',
    animation: '$drop-121 4.1538144422s 0.0440873671s infinite',
  },
  confetti122: {
    width: '5px',
    height: '2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '81%',
    opacity: '0.6233570547',
    transform: 'rotate(245.3362627657deg)',
    WebkitAnimation: '$drop-122 4.1036078536s 0.0988754843s infinite',
    animation: '$drop-122 4.1036078536s 0.0988754843s infinite',
  },
  confetti123: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '57%',
    opacity: '0.6978689987',
    transform: 'rotate(340.3620953871deg)',
    WebkitAnimation: '$drop-123 4.3516534095s 0.3057569146s infinite',
    animation: '$drop-123 4.3516534095s 0.3057569146s infinite',
  },
  confetti124: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '94%',
    opacity: '1.43458604',
    transform: 'rotate(277.4934561568deg)',
    WebkitAnimation: '$drop-124 4.5472538052s 0.4388126401s infinite',
    animation: '$drop-124 4.5472538052s 0.4388126401s infinite',
  },
  confetti125: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '33%',
    opacity: '1.190837965',
    transform: 'rotate(68.8639322493deg)',
    WebkitAnimation: '$drop-125 4.0749706715s 0.9013409342s infinite',
    animation: '$drop-125 4.0749706715s 0.9013409342s infinite',
  },
  confetti126: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '53%',
    opacity: '1.0858279726',
    transform: 'rotate(299.3317081099deg)',
    WebkitAnimation: '$drop-126 4.3459428668s 0.8283337766s infinite',
    animation: '$drop-126 4.3459428668s 0.8283337766s infinite',
  },
  confetti127: {
    width: '5px',
    height: '2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '53%',
    opacity: '0.8624751282',
    transform: 'rotate(63.0163948553deg)',
    WebkitAnimation: '$drop-127 4.8242824837s 0.1373526886s infinite',
    animation: '$drop-127 4.8242824837s 0.1373526886s infinite',
  },
  confetti128: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '86%',
    opacity: '0.7884184415',
    transform: 'rotate(272.7826498596deg)',
    WebkitAnimation: '$drop-128 4.8163448986s 0.9156424978s infinite',
    animation: '$drop-128 4.8163448986s 0.9156424978s infinite',
  },
  confetti129: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '17%',
    opacity: '0.8376081235',
    transform: 'rotate(215.5209450695deg)',
    WebkitAnimation: '$drop-129 4.8611893744s 0.8262296425s infinite',
    animation: '$drop-129 4.8611893744s 0.8262296425s infinite',
  },
  confetti130: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '55%',
    opacity: '1.1243147202',
    transform: 'rotate(44.6310361651deg)',
    WebkitAnimation: '$drop-130 4.5781166645s 0.2635819685s infinite',
    animation: '$drop-130 4.5781166645s 0.2635819685s infinite',
  },
  confetti131: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '61%',
    opacity: '1.4643420779',
    transform: 'rotate(15.3970646278deg)',
    WebkitAnimation: '$drop-131 4.5437055189s 0.3779766415s infinite',
    animation: '$drop-131 4.5437055189s 0.3779766415s infinite',
  },
  confetti132: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '82%',
    opacity: '1.1849342696',
    transform: 'rotate(78.5398177663deg)',
    WebkitAnimation: '$drop-132 4.059365224s 0.2739086742s infinite',
    animation: '$drop-132 4.059365224s 0.2739086742s infinite',
  },
  confetti133: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '36%',
    opacity: '0.8235658059',
    transform: 'rotate(288.3417193923deg)',
    WebkitAnimation: '$drop-133 4.9949480749s 0.0851508558s infinite',
    animation: '$drop-133 4.9949480749s 0.0851508558s infinite',
  },
  confetti134: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '9%',
    opacity: '0.6293076437',
    transform: 'rotate(57.0660911953deg)',
    WebkitAnimation: '$drop-134 4.4153957832s 0.8503744134s infinite',
    animation: '$drop-134 4.4153957832s 0.8503744134s infinite',
  },
  confetti135: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '72%',
    opacity: '1.4129487441',
    transform: 'rotate(216.859349799deg)',
    WebkitAnimation: '$drop-135 4.7847975173s 0.0566152766s infinite',
    animation: '$drop-135 4.7847975173s 0.0566152766s infinite',
  },
  confetti136: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '26%',
    opacity: '1.4547915865',
    transform: 'rotate(359.7868087156deg)',
    WebkitAnimation: '$drop-136 4.8238807737s 0.296954081s infinite',
    animation: '$drop-136 4.8238807737s 0.296954081s infinite',
  },
  confetti137: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '54%',
    opacity: '1.4988434652',
    transform: 'rotate(152.2343752432deg)',
    WebkitAnimation: '$drop-137 4.0872667458s 0.2831984993s infinite',
    animation: '$drop-137 4.0872667458s 0.2831984993s infinite',
  },
  confetti138: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '15%',
    opacity: '0.7575315716',
    transform: 'rotate(255.8480398289deg)',
    WebkitAnimation: '$drop-138 4.0109285073s 0.4682426159s infinite',
    animation: '$drop-138 4.0109285073s 0.4682426159s infinite',
  },
  confetti139: {
    width: '1px',
    height: '0.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '73%',
    opacity: '0.9111391198',
    transform: 'rotate(39.1287845492deg)',
    WebkitAnimation: '$drop-139 4.0890481939s 0.8218811401s infinite',
    animation: '$drop-139 4.0890481939s 0.8218811401s infinite',
  },
  confetti140: {
    width: '5px',
    height: '2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '28%',
    opacity: '1.32524885',
    transform: 'rotate(319.8919267971deg)',
    WebkitAnimation: '$drop-140 4.680583193s 0.6475756059s infinite',
    animation: '$drop-140 4.680583193s 0.6475756059s infinite',
  },
  confetti141: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '5%',
    opacity: '0.9864966536',
    transform: 'rotate(69.0832912756deg)',
    WebkitAnimation: '$drop-141 4.676147208s 0.6789113516s infinite',
    animation: '$drop-141 4.676147208s 0.6789113516s infinite',
  },
  confetti142: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '3%',
    opacity: '0.7128009662',
    transform: 'rotate(139.0830610903deg)',
    WebkitAnimation: '$drop-142 4.3452560962s 0.3517927381s infinite',
    animation: '$drop-142 4.3452560962s 0.3517927381s infinite',
  },
  confetti143: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '37%',
    opacity: '1.2624083222',
    transform: 'rotate(125.6894545619deg)',
    WebkitAnimation: '$drop-143 4.5799123115s 0.2546972689s infinite',
    animation: '$drop-143 4.5799123115s 0.2546972689s infinite',
  },
  confetti144: {
    width: '2px',
    height: '0.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '39%',
    opacity: '1.4487022989',
    transform: 'rotate(111.647407672deg)',
    WebkitAnimation: '$drop-144 4.9598657228s 0.7155990665s infinite',
    animation: '$drop-144 4.9598657228s 0.7155990665s infinite',
  },
  confetti145: {
    width: '7px',
    height: '2.8px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '67%',
    opacity: '0.7443784168',
    transform: 'rotate(171.6524601314deg)',
    WebkitAnimation: '$drop-145 4.0230008669s 0.4845234174s infinite',
    animation: '$drop-145 4.0230008669s 0.4845234174s infinite',
  },
  confetti146: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '45%',
    opacity: '1.1491821091',
    transform: 'rotate(136.6478259574deg)',
    WebkitAnimation: '$drop-146 4.3229956132s 0.9690209383s infinite',
    animation: '$drop-146 4.3229956132s 0.9690209383s infinite',
  },
  confetti147: {
    width: '6px',
    height: '2.4px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '67%',
    opacity: '0.7974761608',
    transform: 'rotate(41.1270884768deg)',
    WebkitAnimation: '$drop-147 4.7431273382s 0.7530384528s infinite',
    animation: '$drop-147 4.7431273382s 0.7530384528s infinite',
  },
  confetti148: {
    width: '8px',
    height: '3.2px',
    backgroundColor: '#d13447',
    top: '-10%',
    left: '75%',
    opacity: '0.8489025031',
    transform: 'rotate(214.2119742906deg)',
    WebkitAnimation: '$drop-148 4.6638646615s 0.8280029159s infinite',
    animation: '$drop-148 4.6638646615s 0.8280029159s infinite',
  },
  confetti149: {
    width: '4px',
    height: '1.6px',
    backgroundColor: '#ffbf00',
    top: '-10%',
    left: '12%',
    opacity: '1.2873700081',
    transform: 'rotate(81.5378884457deg)',
    WebkitAnimation: '$drop-149 4.3945093847s 0.1706896381s infinite',
    animation: '$drop-149 4.3945093847s 0.1706896381s infinite',
  },
  confetti150: {
    width: '3px',
    height: '1.2px',
    backgroundColor: '#263672',
    top: '-10%',
    left: '100%',
    opacity: '0.9171633069',
    transform: 'rotate(269.6204673205deg)',
    WebkitAnimation: '$drop-150 4.9387881929s 0.9829028493s infinite',
    animation: '$drop,-150 4.9387881929s 0.9829028493s infinite',
  },

  '@-webkit-keyframes drop-0': {
    '100%': {
      top: '110%',
      left: '50%',
    },
  },

  '@keyframes drop-0': {
    '100%': {
      top: '110%',
      left: '50%',
    },
  },

  '@-webkit-keyframes drop-1': {
    '100%': {
      top: '110%',
      left: '70%',
    },
  },

  '@keyframes drop-1': {
    '100%': {
      top: '110%',
      left: '70%',
    },
  },

  '@-webkit-keyframes drop-2': {
    '100%': {
      top: '110%',
      left: '51%',
    },
  },

  '@keyframes drop-2': {
    '100%': {
      top: '110%',
      left: '51%',
    },
  },

  '@-webkit-keyframes drop-3': {
    '100%': {
      top: '110%',
      left: '77%',
    },
  },

  '@keyframes drop-3': {
    '100%': {
      top: '110%',
      left: '77%',
    },
  },

  '@-webkit-keyframes drop-4': {
    '100%': {
      top: '110%',
      left: '26%',
    },
  },

  '@keyframes drop-4': {
    '100%': {
      top: '110%',
      left: '26%',
    },
  },

  '@-webkit-keyframes drop-5': {
    '100%': {
      top: '110%',
      left: '85%',
    },
  },

  '@keyframes drop-5': {
    '100%': {
      top: '110%',
      left: '85%',
    },
  },

  '@-webkit-keyframes drop-6': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@keyframes drop-6': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@-webkit-keyframes drop-7': {
    '100%': {
      top: '110%',
      left: '18%',
    },
  },

  '@keyframes drop-7': {
    '100%': {
      top: '110%',
      left: '18%',
    },
  },

  '@-webkit-keyframes drop-8': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-8': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@-webkit-keyframes drop-9': {
    '100%': {
      top: '110%',
      left: '28%',
    },
  },

  '@keyframes drop-9': {
    '100%': {
      top: '110%',
      left: '28%',
    },
  },
  '@-webkit-keyframes drop-10': {
    '100%': {
      top: '110%',
      left: '59%',
    },
  },

  '@keyframes drop-10': {
    '100%': {
      top: '110%',
      left: '59%',
    },
  },

  '@-webkit-keyframes drop-11': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },

  '@keyframes drop-11': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },

  '@-webkit-keyframes drop-12': {
    '100%': {
      top: '110%',
      left: '72%',
    },
  },

  '@keyframes drop-12': {
    '100%': {
      top: '110%',
      left: '72%',
    },
  },

  '@-webkit-keyframes drop-13': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },

  '@keyframes drop-13': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },
  '@-webkit-keyframes drop-14': {
    '100%': {
      top: '110%',
      left: '63%',
    },
  },

  '@keyframes drop-14': {
    '100%': {
      top: '110%',
      left: '63%',
    },
  },

  '@-webkit-keyframes drop-15': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@keyframes drop-15': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@-webkit-keyframes drop-16': {
    '100%': {
      top: '110%',
      left: '58%',
    },
  },

  '@keyframes drop-16': {
    '100%': {
      top: '110%',
      left: '58%',
    },
  },

  '@-webkit-keyframes drop-17': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },

  '@keyframes drop-17': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },

  '@-webkit-keyframes drop-18': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },

  '@keyframes drop-18': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },

  '@-webkit-keyframes drop-19': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@keyframes drop-19': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@-webkit-keyframes drop-20': {
    '100%': {
      top: '110%',
      left: '59%',
    },
  },

  '@keyframes drop-20': {
    '100%': {
      top: '110%',
      left: '59%',
    },
  },

  '@-webkit-keyframes drop-21': {
    '100%': {
      top: '110%',
      left: '57%',
    },
  },

  '@keyframes drop-21': {
    '100%': {
      top: '110%',
      left: '57%',
    },
  },

  '@-webkit-keyframes drop-22': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@keyframes drop-22': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },
  '@-webkit-keyframes drop-23': {
    '100%': {
      top: '110%',
      left: '44%',
    },
  },

  '@keyframes drop-23': {
    '100%': {
      top: '110%',
      left: '44%',
    },
  },

  '@-webkit-keyframes drop-24': {
    '100%': {
      top: '110%',
      left: '93%',
    },
  },

  '@keyframes drop-24': {
    '100%': {
      top: '110%',
      left: '93%',
    },
  },

  '@-webkit-keyframes drop-25': {
    '100%': {
      top: '110%',
      left: '40%',
    },
  },

  '@keyframes drop-25': {
    '100%': {
      top: '110%',
      left: '40%',
    },
  },

  '@-webkit-keyframes drop-26': {
    '100%': {
      top: '110%',
      left: '98%',
    },
  },

  '@keyframes drop-26': {
    '100%': {
      top: '110%',
      left: '98%',
    },
  },
  '@-webkit-keyframes drop-27': {
    '100%': {
      top: '110%',
      left: '46%',
    },
  },

  '@keyframes drop-27': {
    '100%': {
      top: '110%',
      left: '46%',
    },
  },

  '@-webkit-keyframes drop-28': {
    '100%': {
      top: '110%',
      left: '30%',
    },
  },

  '@keyframes drop-28': {
    '100%': {
      top: '110%',
      left: '30%',
    },
  },
  '@-webkit-keyframes drop-29': {
    '100%': {
      top: '110%',
      left: '39%',
    },
  },

  '@keyframes drop-29': {
    '100%': {
      top: '110%',
      left: '39%',
    },
  },

  '@-webkit-keyframes drop-30': {
    '100%': {
      top: '110%',
      left: '63%',
    },
  },

  '@keyframes drop-30': {
    '100%': {
      top: '110%',
      left: '63%',
    },
  },

  '@-webkit-keyframes drop-31': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@keyframes drop-31': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@-webkit-keyframes drop-32': {
    '100%': {
      top: '110%',
      left: '100%',
    },
  },

  '@keyframes drop-32': {
    '100%': {
      top: '110%',
      left: '100%',
    },
  },

  '@-webkit-keyframes drop-33': {
    '100%': {
      top: '110%',
      left: '28%',
    },
  },

  '@keyframes drop-33': {
    '100%': {
      top: '110%',
      left: '28%',
    },
  },

  '@-webkit-keyframes drop-34': {
    '100%': {
      top: '110%',
      left: '62%',
    },
  },

  '@keyframes drop-34': {
    '100%': {
      top: '110%',
      left: '62%',
    },
  },

  '@-webkit-keyframes drop-35': {
    '100%': {
      top: '110%',
      left: '70%',
    },
  },

  '@keyframes drop-35': {
    '100%': {
      top: '110%',
      left: '70%',
    },
  },

  '@-webkit-keyframes drop-36': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },

  '@keyframes drop-36': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },
  '@-webkit-keyframes drop-37': {
    '100%': {
      top: '110%',
      left: '42%',
    },
  },

  '@keyframes drop-37': {
    '100%': {
      top: '110%',
      left: '42%',
    },
  },

  '@-webkit-keyframes drop-38': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@keyframes drop-38': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@-webkit-keyframes drop-39': {
    '100%': {
      top: '110%',
      left: '55%',
    },
  },

  '@keyframes drop-39': {
    '100%': {
      top: '110%',
      left: '55%',
    },
  },

  '@-webkit-keyframes drop-40': {
    '100%': {
      top: '110%',
      left: '58%',
    },
  },

  '@keyframes drop-40': {
    '100%': {
      top: '110%',
      left: '58%',
    },
  },
  '@-webkit-keyframes drop-41': {
    '100%': {
      top: '110%',
      left: '84%',
    },
  },

  '@keyframes drop-41': {
    '100%': {
      top: '110%',
      left: '84%',
    },
  },

  '@-webkit-keyframes drop-42': {
    '100%': {
      top: '110%',
      left: '87%',
    },
  },

  '@keyframes drop-42': {
    '100%': {
      top: '110%',
      left: '87%',
    },
  },
  '@-webkit-keyframes drop-43': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-43': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },
  '@-webkit-keyframes drop-44': {
    '100%': {
      top: '110%',
      left: '89%',
    },
  },

  '@keyframes drop-44': {
    '100%': {
      top: '110%',
      left: '89%',
    },
  },

  '@-webkit-keyframes drop-45': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },

  '@keyframes drop-45': {
    '100%': {
      top: '110%',
      left: '49%',
    },
  },
  '@-webkit-keyframes drop-46': {
    '100%': {
      top: '110%',
      left: '81%',
    },
  },

  '@keyframes drop-46': {
    '100%': {
      top: '110%',
      left: '81%',
    },
  },

  '@-webkit-keyframes drop-47': {
    '100%': {
      top: '110%',
      left: '12%',
    },
  },

  '@keyframes drop-47': {
    '100%': {
      top: '110%',
      left: '12%',
    },
  },
  '@-webkit-keyframes drop-48': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@keyframes drop-48': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@-webkit-keyframes drop-49': {
    '100%': {
      top: '110%',
      left: '86%',
    },
  },

  '@keyframes drop-49': {
    '100%': {
      top: '110%',
      left: '86%',
    },
  },
  '@-webkit-keyframes drop-50': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },

  '@keyframes drop-50': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },

  '@-webkit-keyframes drop-51': {
    '100%': {
      top: '110%',
      left: '22%',
    },
  },

  '@keyframes drop-51': {
    '100%': {
      top: '110%',
      left: '22%',
    },
  },

  '@-webkit-keyframes drop-52': {
    '100%': {
      top: '110%',
      left: '68%',
    },
  },

  '@keyframes drop-52': {
    '100%': {
      top: '110%',
      left: '68%',
    },
  },

  '@-webkit-keyframes drop-53': {
    '100%': {
      top: '110%',
      left: '2%',
    },
  },

  '@keyframes drop-53': {
    '100%': {
      top: '110%',
      left: '2%',
    },
  },
  '@-webkit-keyframes drop-54': {
    '100%': {
      top: '110%',
      left: '96%',
    },
  },

  '@keyframes drop-54': {
    '100%': {
      top: '110%',
      left: '96%',
    },
  },
  '@-webkit-keyframes drop-55': {
    '100%': {
      top: '110%',
      left: '39%',
    },
  },

  '@keyframes drop-55': {
    '100%': {
      top: '110%',
      left: '39%',
    },
  },
  '@-webkit-keyframes drop-56': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },

  '@keyframes drop-56': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },
  '@-webkit-keyframes drop-57': {
    '100%': {
      top: '110%',
      left: '53%',
    },
  },

  '@keyframes drop-57': {
    '100%': {
      top: '110%',
      left: '53%',
    },
  },

  '@-webkit-keyframes drop-58': {
    '100%': {
      top: '110%',
      left: '22%',
    },
  },

  '@keyframes drop-58': {
    '100%': {
      top: '110%',
      left: '22%',
    },
  },
  '@-webkit-keyframes drop-59': {
    '100%': {
      top: '110%',
      left: '66%',
    },
  },

  '@keyframes drop-59': {
    '100%': {
      top: '110%',
      left: '66%',
    },
  },

  '@-webkit-keyframes drop-60': {
    '100%': {
      top: '110%',
      left: '12%',
    },
  },

  '@keyframes drop-60': {
    '100%': {
      top: '110%',
      left: '12%',
    },
  },
  '@-webkit-keyframes drop-61': {
    '100%': {
      top: '110%',
      left: '95%',
    },
  },

  '@keyframes drop-61': {
    '100%': {
      top: '110%',
      left: '95%',
    },
  },

  '@-webkit-keyframes drop-62': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@keyframes drop-62': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@-webkit-keyframes drop-63': {
    '100%': {
      top: '110%',
      left: '94%',
    },
  },

  '@keyframes drop-63': {
    '100%': {
      top: '110%',
      left: '94%',
    },
  },

  '@-webkit-keyframes drop-64': {
    '100%': {
      top: '110%',
      left: '88%',
    },
  },

  '@keyframes drop-64': {
    '100%': {
      top: '110%',
      left: '88%',
    },
  },

  '@-webkit-keyframes drop-65': {
    '100%': {
      top: '110%',
      left: '21%',
    },
  },

  '@keyframes drop-65': {
    '100%': {
      top: '110%',
      left: '21%',
    },
  },
  '@-webkit-keyframes drop-66': {
    '100%': {
      top: '110%',
      left: '66%',
    },
  },

  '@keyframes drop-66': {
    '100%': {
      top: '110%',
      left: '66%',
    },
  },

  '@-webkit-keyframes drop-67': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },

  '@keyframes drop-67': {
    '100%': {
      top: '110%',
      left: '91%',
    },
  },
  '@-webkit-keyframes drop-68': {
    '100%': {
      top: '110%',
      left: '69%',
    },
  },

  '@keyframes drop-68': {
    '100%': {
      top: '110%',
      left: '69%',
    },
  },
  '@-webkit-keyframes drop-69': {
    '100%': {
      top: '110%',
      left: '51%',
    },
  },

  '@keyframes drop-69': {
    '100%': {
      top: '110%',
      left: '51%',
    },
  },

  '@-webkit-keyframes drop-70': {
    '100%': {
      top: '110%',
      left: '81%',
    },
  },

  '@keyframes drop-70': {
    '100%': {
      top: '110%',
      left: '81%',
    },
  },

  '@-webkit-keyframes drop-71': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@keyframes drop-71': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@-webkit-keyframes drop-72': {
    '100%': {
      top: '110%',
      left: '35%',
    },
  },

  '@keyframes drop-72': {
    '100%': {
      top: '110%',
      left: '35%',
    },
  },

  '@-webkit-keyframes drop-73': {
    '100%': {
      top: '110%',
      left: '30%',
    },
  },

  '@keyframes drop-73': {
    '100%': {
      top: '110%',
      left: '30%',
    },
  },

  '@-webkit-keyframes drop-74': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@keyframes drop-74': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@-webkit-keyframes drop-75': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },

  '@keyframes drop-75': {
    '100%': {
      top: '110%',
      left: '31%',
    },
  },

  '@-webkit-keyframes drop-76': {
    '100%': {
      top: '110%',
      left: '92%',
    },
  },

  '@keyframes drop-76': {
    '100%': {
      top: '110%',
      left: '92%',
    },
  },

  '@-webkit-keyframes drop-77': {
    '100%': {
      top: '110%',
      left: '84%',
    },
  },

  '@keyframes drop-77': {
    '100%': {
      top: '110%',
      left: '84%',
    },
  },

  '@-webkit-keyframes drop-78': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@keyframes drop-78': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@-webkit-keyframes drop-79': {
    '100%': {
      top: '110%',
      left: '52%',
    },
  },

  '@keyframes drop-79': {
    '100%': {
      top: '110%',
      left: '52%',
    },
  },

  '@-webkit-keyframes drop-80': {
    '100%': {
      top: '110%',
      left: '54%',
    },
  },

  '@keyframes drop-80': {
    '100%': {
      top: '110%',
      left: '54%',
    },
  },

  '@-webkit-keyframes drop-81': {
    '100%': {
      top: '110%',
      left: '97%',
    },
  },

  '@keyframes drop-81': {
    '100%': {
      top: '110%',
      left: '97%',
    },
  },
  '@-webkit-keyframes drop-82': {
    '100%': {
      top: '110%',
      left: '16%',
    },
  },

  '@keyframes drop-82': {
    '100%': {
      top: '110%',
      left: '16%',
    },
  },

  '@-webkit-keyframes drop-83': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@keyframes drop-83': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@-webkit-keyframes drop-84': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@keyframes drop-84': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@-webkit-keyframes drop-85': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-85': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@-webkit-keyframes drop-86': {
    '100%': {
      top: '110%',
      left: '67%',
    },
  },

  '@keyframes drop-86': {
    '100%': {
      top: '110%',
      left: '67%',
    },
  },

  '@-webkit-keyframes drop-87': {
    '100%': {
      top: '110%',
      left: '99%',
    },
  },

  '@keyframes drop-87': {
    '100%': {
      top: '110%',
      left: '99%',
    },
  },

  '@-webkit-keyframes drop-88': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@keyframes drop-88': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@-webkit-keyframes drop-89': {
    '100%': {
      top: '110%',
      left: '88%',
    },
  },

  '@keyframes drop-89': {
    '100%': {
      top: '110%',
      left: '88%',
    },
  },

  '@-webkit-keyframes drop-90': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@keyframes drop-90': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@-webkit-keyframes drop-91': {
    '100%': {
      top: '110%',
      left: '7%',
    },
  },

  '@keyframes drop-91': {
    '100%': {
      top: '110%',
      left: '7%',
    },
  },

  '@-webkit-keyframes drop-92': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@keyframes drop-92': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@-webkit-keyframes drop-93': {
    '100%': {
      top: '110%',
      left: '107%',
    },
  },

  '@keyframes drop-93': {
    '100%': {
      top: '110%',
      left: '107%',
    },
  },

  '@-webkit-keyframes drop-94': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@keyframes drop-94': {
    '100%': {
      top: '110%',
      left: '48%',
    },
  },

  '@-webkit-keyframes drop-95': {
    '100%': {
      top: '110%',
      left: '95%',
    },
  },

  '@keyframes drop-95': {
    '100%': {
      top: '110%',
      left: '95%',
    },
  },

  '@-webkit-keyframes drop-96': {
    '100%': {
      top: '110%',
      left: '35%',
    },
  },

  '@keyframes drop-96': {
    '100%': {
      top: '110%',
      left: '35%',
    },
  },

  '@-webkit-keyframes drop-97': {
    '100%': {
      top: '110%',
      left: '56%',
    },
  },

  '@keyframes drop-97': {
    '100%': {
      top: '110%',
      left: '56%',
    },
  },

  '@-webkit-keyframes drop-98': {
    '100%': {
      top: '110%',
      left: '53%',
    },
  },

  '@keyframes drop-98': {
    '100%': {
      top: '110%',
      left: '53%',
    },
  },

  '@-webkit-keyframes drop-99': {
    '100%': {
      top: '110%',
      left: '26%',
    },
  },

  '@keyframes drop-99': {
    '100%': {
      top: '110%',
      left: '26%',
    },
  },

  '@-webkit-keyframes drop-100': {
    '100%': {
      top: '110%',
      left: '16%',
    },
  },

  '@keyframes drop-100': {
    '100%': {
      top: '110%',
      left: '16%',
    },
  },

  '@-webkit-keyframes drop-101': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@keyframes drop-101': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@-webkit-keyframes drop-102': {
    '100%': {
      top: '110%',
      left: '19%',
    },
  },

  '@keyframes drop-102': {
    '100%': {
      top: '110%',
      left: '19%',
    },
  },

  '@-webkit-keyframes drop-103': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-103': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@-webkit-keyframes drop-104': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@keyframes drop-104': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@-webkit-keyframes drop-105': {
    '100%': {
      top: '110%',
      left: '8%',
    },
  },

  '@keyframes drop-105': {
    '100%': {
      top: '110%',
      left: '8%',
    },
  },

  '@-webkit-keyframes drop-106': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@keyframes drop-106': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@-webkit-keyframes drop-107': {
    '100%': {
      top: '110%',
      left: '96%',
    },
  },

  '@keyframes drop-107': {
    '100%': {
      top: '110%',
      left: '96%',
    },
  },

  '@-webkit-keyframes drop-108': {
    '100%': {
      top: '110%',
      left: '43%',
    },
  },

  '@keyframes drop-108': {
    '100%': {
      top: '110%',
      left: '43%',
    },
  },

  '@-webkit-keyframes drop-109': {
    '100%': {
      top: '110%',
      left: '75%',
    },
  },

  '@keyframes drop-109': {
    '100%': {
      top: '110%',
      left: '75%',
    },
  },

  '@-webkit-keyframes drop-110': {
    '100%': {
      top: '110%',
      left: '89%',
    },
  },

  '@keyframes drop-110': {
    '100%': {
      top: '110%',
      left: '89%',
    },
  },

  '@-webkit-keyframes drop-111': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@keyframes drop-111': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@-webkit-keyframes drop-112': {
    '100%': {
      top: '110%',
      left: '64%',
    },
  },

  '@keyframes drop-112': {
    '100%': {
      top: '110%',
      left: '64%',
    },
  },

  '@-webkit-keyframes drop-113': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@keyframes drop-113': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@-webkit-keyframes drop-114': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@keyframes drop-114': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@-webkit-keyframes drop-115': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@keyframes drop-115': {
    '100%': {
      top: '110%',
      left: '103%',
    },
  },

  '@-webkit-keyframes drop-116': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@keyframes drop-116': {
    '100%': {
      top: '110%',
      left: '20%',
    },
  },

  '@-webkit-keyframes drop-117': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@keyframes drop-117': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@-webkit-keyframes drop-118': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@keyframes drop-118': {
    '100%': {
      top: '110%',
      left: '83%',
    },
  },

  '@-webkit-keyframes drop-119': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@keyframes drop-119': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@-webkit-keyframes drop-120': {
    '100%': {
      top: '110%',
      left: '23%',
    },
  },

  '@keyframes drop-120': {
    '100%': {
      top: '110%',
      left: '23%',
    },
  },

  '@-webkit-keyframes drop-121': {
    '100%': {
      top: '110%',
      left: '46%',
    },
  },

  '@keyframes drop-121': {
    '100%': {
      top: '110%',
      left: '46%',
    },
  },

  '@-webkit-keyframes drop-122': {
    '100%': {
      top: '110%',
      left: '87%',
    },
  },

  '@keyframes drop-122': {
    '100%': {
      top: '110%',
      left: '87%',
    },
  },

  '@-webkit-keyframes drop-123': {
    '100%': {
      top: '110%',
      left: '72%',
    },
  },

  '@keyframes drop-123': {
    '100%': {
      top: '110%',
      left: '72%',
    },
  },

  '@-webkit-keyframes drop-124': {
    '100%': {
      top: '110%',
      left: '106%',
    },
  },

  '@keyframes drop-124': {
    '100%': {
      top: '110%',
      left: '106%',
    },
  },

  '@-webkit-keyframes drop-125': {
    '100%': {
      top: '110%',
      left: '36%',
    },
  },

  '@keyframes drop-125': {
    '100%': {
      top: '110%',
      left: '36%',
    },
  },

  '@-webkit-keyframes drop-126': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@keyframes drop-126': {
    '100%': {
      top: '110%',
      left: '65%',
    },
  },

  '@-webkit-keyframes drop-127': {
    '100%': {
      top: '110%',
      left: '68%',
    },
  },

  '@keyframes drop-127': {
    '100%': {
      top: '110%',
      left: '68%',
    },
  },

  '@-webkit-keyframes drop-128': {
    '100%': {
      top: '110%',
      left: '93%',
    },
  },

  '@keyframes drop-128': {
    '100%': {
      top: '110%',
      left: '93%',
    },
  },

  '@-webkit-keyframes drop-129': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },

  '@keyframes drop-129': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },

  '@-webkit-keyframes drop-130': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@keyframes drop-130': {
    '100%': {
      top: '110%',
      left: '61%',
    },
  },

  '@-webkit-keyframes drop-131': {
    '100%': {
      top: '110%',
      left: '64%',
    },
  },

  '@keyframes drop-131': {
    '100%': {
      top: '110%',
      left: '64%',
    },
  },

  '@-webkit-keyframes drop-132': {
    '100%': {
      top: '110%',
      left: '86%',
    },
  },

  '@keyframes drop-132': {
    '100%': {
      top: '110%',
      left: '86%',
    },
  },

  '@-webkit-keyframes drop-133': {
    '100%': {
      top: '110%',
      left: '40%',
    },
  },

  '@keyframes drop-133': {
    '100%': {
      top: '110%',
      left: '40%',
    },
  },

  '@-webkit-keyframes drop-134': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-134': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@-webkit-keyframes drop-135': {
    '100%': {
      top: '110%',
      left: '75%',
    },
  },

  '@keyframes drop-135': {
    '100%': {
      top: '110%',
      left: '75%',
    },
  },

  '@-webkit-keyframes drop-136': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@keyframes drop-136': {
    '100%': {
      top: '110%',
      left: '32%',
    },
  },

  '@-webkit-keyframes drop-137': {
    '100%': {
      top: '110%',
      left: '62%',
    },
  },

  '@keyframes drop-137': {
    '100%': {
      top: '110%',
      left: '62%',
    },
  },

  '@-webkit-keyframes drop-138': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },

  '@keyframes drop-138': {
    '100%': {
      top: '110%',
      left: '24%',
    },
  },

  '@-webkit-keyframes drop-139': {
    '100%': {
      top: '110%',
      left: '82%',
    },
  },

  '@keyframes drop-139': {
    '100%': {
      top: '110%',
      left: '82%',
    },
  },

  '@-webkit-keyframes drop-140': {
    '100%': {
      top: '110%',
      left: '33%',
    },
  },

  '@keyframes drop-140': {
    '100%': {
      top: '110%',
      left: '33%',
    },
  },

  '@-webkit-keyframes drop-141': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@keyframes drop-141': {
    '100%': {
      top: '110%',
      left: '17%',
    },
  },

  '@-webkit-keyframes drop-142': {
    '100%': {
      top: '110%',
      left: '11%',
    },
  },

  '@keyframes drop-142': {
    '100%': {
      top: '110%',
      left: '11%',
    },
  },

  '@-webkit-keyframes drop-143': {
    '100%': {
      top: '110%',
      left: '43%',
    },
  },

  '@keyframes drop-143': {
    '100%': {
      top: '110%',
      left: '43%',
    },
  },

  '@-webkit-keyframes drop-144': {
    '100%': {
      top: '110%',
      left: '47%',
    },
  },

  '@keyframes drop-144': {
    '100%': {
      top: '110%',
      left: '47%',
    },
  },

  '@-webkit-keyframes drop-145': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@keyframes drop-145': {
    '100%': {
      top: '110%',
      left: '74%',
    },
  },

  '@-webkit-keyframes drop-146': {
    '100%': {
      top: '110%',
      left: '47%',
    },
  },

  '@keyframes drop-146': {
    '100%': {
      top: '110%',
      left: '47%',
    },
  },

  '@-webkit-keyframes drop-147': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@keyframes drop-147': {
    '100%': {
      top: '110%',
      left: '80%',
    },
  },

  '@-webkit-keyframes drop-148': {
    '100%': {
      top: '110%',
      left: '82%',
    },
  },

  '@keyframes drop-148': {
    '100%': {
      top: '110%',
      left: '82%',
    },
  },

  '@-webkit-keyframes drop-149': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@keyframes drop-149': {
    '100%': {
      top: '110%',
      left: '14%',
    },
  },

  '@-webkit-keyframes drop-150': {
    '100%': {
      top: '110%',
      left: '108%',
    },
  },

  '@keyframes drop-150': {
    '100%': {
      top: '110%',
      left: '108%',
    },
  },
}))

const Confetti = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.confetti149}></div>
      <div className={classes.confetti148}></div>
      <div className={classes.confetti147}></div>
      <div className={classes.confetti146}></div>
      <div className={classes.confetti145}></div>
      <div className={classes.confetti144}></div>
      <div className={classes.confetti143}></div>
      <div className={classes.confetti142}></div>
      <div className={classes.confetti141}></div>
      <div className={classes.confetti140}></div>
      <div className={classes.confetti139}></div>
      <div className={classes.confetti138}></div>
      <div className={classes.confetti137}></div>
      <div className={classes.confetti136}></div>
      <div className={classes.confetti135}></div>
      <div className={classes.confetti134}></div>
      <div className={classes.confetti133}></div>
      <div className={classes.confetti132}></div>
      <div className={classes.confetti131}></div>
      <div className={classes.confetti130}></div>
      <div className={classes.confetti129}></div>
      <div className={classes.confetti128}></div>
      <div className={classes.confetti127}></div>
      <div className={classes.confetti126}></div>
      <div className={classes.confetti125}></div>
      <div className={classes.confetti124}></div>
      <div className={classes.confetti123}></div>
      <div className={classes.confetti122}></div>
      <div className={classes.confetti121}></div>
      <div className={classes.confetti120}></div>
      <div className={classes.confetti119}></div>
      <div className={classes.confetti118}></div>
      <div className={classes.confetti117}></div>
      <div className={classes.confetti116}></div>
      <div className={classes.confetti115}></div>
      <div className={classes.confetti114}></div>
      <div className={classes.confetti113}></div>
      <div className={classes.confetti112}></div>
      <div className={classes.confetti111}></div>
      <div className={classes.confetti110}></div>
      <div className={classes.confetti109}></div>
      <div className={classes.confetti108}></div>
      <div className={classes.confetti107}></div>
      <div className={classes.confetti106}></div>
      <div className={classes.confetti105}></div>
      <div className={classes.confetti104}></div>
      <div className={classes.confetti103}></div>
      <div className={classes.confetti102}></div>
      <div className={classes.confetti101}></div>
      <div className={classes.confetti100}></div>
      <div className={classes.confetti99}></div>
      <div className={classes.confetti98}></div>
      <div className={classes.confetti97}></div>
      <div className={classes.confetti96}></div>
      <div className={classes.confetti95}></div>
      <div className={classes.confetti94}></div>
      <div className={classes.confetti93}></div>
      <div className={classes.confetti92}></div>
      <div className={classes.confetti91}></div>
      <div className={classes.confetti90}></div>
      <div className={classes.confetti89}></div>
      <div className={classes.confetti88}></div>
      <div className={classes.confetti87}></div>
      <div className={classes.confetti86}></div>
      <div className={classes.confetti85}></div>
      <div className={classes.confetti84}></div>
      <div className={classes.confetti83}></div>
      <div className={classes.confetti82}></div>
      <div className={classes.confetti81}></div>
      <div className={classes.confetti80}></div>
      <div className={classes.confetti79}></div>
      <div className={classes.confetti78}></div>
      <div className={classes.confetti77}></div>
      <div className={classes.confetti76}></div>
      <div className={classes.confetti75}></div>
      <div className={classes.confetti74}></div>
      <div className={classes.confetti73}></div>
      <div className={classes.confetti72}></div>
      <div className={classes.confetti71}></div>
      <div className={classes.confetti70}></div>
      <div className={classes.confetti69}></div>
      <div className={classes.confetti68}></div>
      <div className={classes.confetti67}></div>
      <div className={classes.confetti66}></div>
      <div className={classes.confetti65}></div>
      <div className={classes.confetti64}></div>
      <div className={classes.confetti63}></div>
      <div className={classes.confetti62}></div>
      <div className={classes.confetti61}></div>
      <div className={classes.confetti60}></div>
      <div className={classes.confetti59}></div>
      <div className={classes.confetti58}></div>
      <div className={classes.confetti57}></div>
      <div className={classes.confetti56}></div>
      <div className={classes.confetti55}></div>
      <div className={classes.confetti54}></div>
      <div className={classes.confetti53}></div>
      <div className={classes.confetti52}></div>
      <div className={classes.confetti51}></div>
      <div className={classes.confetti50}></div>
      <div className={classes.confetti49}></div>
      <div className={classes.confetti48}></div>
      <div className={classes.confetti47}></div>
      <div className={classes.confetti46}></div>
      <div className={classes.confetti45}></div>
      <div className={classes.confetti44}></div>
      <div className={classes.confetti43}></div>
      <div className={classes.confetti42}></div>
      <div className={classes.confetti41}></div>
      <div className={classes.confetti40}></div>
      <div className={classes.confetti39}></div>
      <div className={classes.confetti38}></div>
      <div className={classes.confetti37}></div>
      <div className={classes.confetti36}></div>
      <div className={classes.confetti35}></div>
      <div className={classes.confetti34}></div>
      <div className={classes.confetti33}></div>
      <div className={classes.confetti32}></div>
      <div className={classes.confetti31}></div>
      <div className={classes.confetti30}></div>
      <div className={classes.confetti29}></div>
      <div className={classes.confetti28}></div>
      <div className={classes.confetti27}></div>
      <div className={classes.confetti26}></div>
      <div className={classes.confetti25}></div>
      <div className={classes.confetti24}></div>
      <div className={classes.confetti23}></div>
      <div className={classes.confetti22}></div>
      <div className={classes.confetti21}></div>
      <div className={classes.confetti20}></div>
      <div className={classes.confetti19}></div>
      <div className={classes.confetti18}></div>
      <div className={classes.confetti17}></div>
      <div className={classes.confetti16}></div>
      <div className={classes.confetti15}></div>
      <div className={classes.confetti14}></div>
      <div className={classes.confetti13}></div>
      <div className={classes.confetti12}></div>
      <div className={classes.confetti11}></div>
      <div className={classes.confetti10}></div>
      <div className={classes.confetti9}></div>
      <div className={classes.confetti8}></div>
      <div className={classes.confetti7}></div>
      <div className={classes.confetti6}></div>
      <div className={classes.confetti5}></div>
      <div className={classes.confetti4}></div>
      <div className={classes.confetti3}></div>
      <div className={classes.confetti2}></div>
      <div className={classes.confetti1}></div>
      <div className={classes.confetti0}></div>
    </div>
  )
}

export default Confetti

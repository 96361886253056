import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ApiariButton from '../../../components/inputs/button'
import { SmallInput } from '../account-page'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import useUpdateUser from '../../../hooks/use-update-user'
import Link from '@material-ui/core/Link'

const InfoWrapper = ({ title, children, titleWidth }) => (
  <Grid
    item
    container
    component={Box}
    flexDirection={{ xs: 'column', sm: 'row' }}
  >
    <Grid
      component={Box}
      item
      xs="auto"
      mb={{ xs: 1, sm: 0 }}
      mr={{ xs: 0, sm: 2 }}
      width={titleWidth}
    >
      <Box fontFamily="camptonBold">{title}</Box>
    </Grid>
    <Box component={Grid} item xs height={20}>
      {children}
    </Box>
  </Grid>
)

function getInitialState({ partner, emergencyContact: emergency }) {
  return {
    partner: {
      firstName: partner?.firstName || '',
      phoneNumber: partner?.phoneNumber || '',
      email: partner?.email || '',
    },
    emergencyContact: {
      name: emergency?.name || '',
      relationship: emergency?.relationship || '',
      phoneNumber: emergency?.phoneNumber || '',
    },
  }
}

const AdditionalInfo = props => {
  const { user, onEdit, onSave, editMode } = props
  const meta = useUpdateUser(user, getInitialState, onSave)

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle
            icon={<BulletIcon />}
            text="Additional Contact Information"
          />
        </Grid>
        <Grid item xs="auto">
          {editMode ? (
            <>
              <Link
                color="primary"
                underline="none"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={onSave}
              >
                Cancel
              </Link>
              <ApiariButton
                onClick={() => meta.handleSave()}
                disabled={!meta.hasChanges}
                busy={meta.submitting}
              >
                Save
              </ApiariButton>
            </>
          ) : (
            <ApiariButton onClick={onEdit}>Edit</ApiariButton>
          )}
        </Grid>
      </Grid>

      <Box pl={3.6} mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md>
            <Grid container direction="column" spacing={3}>
              <InfoWrapper title="Partner's Name" titleWidth={180}>
                {editMode ? (
                  <SmallInput
                    autoFocus
                    value={meta.state.partner.firstName}
                    onChange={e =>
                      meta.handleChange(e, (b, firstName) => ({
                        ...b,
                        partner: {
                          ...b.partner,
                          firstName,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.partner.firstName
                )}
              </InfoWrapper>

              <InfoWrapper title="Partner's Mobile Number" titleWidth={180}>
                {editMode ? (
                  <SmallInput
                    value={meta.state.partner.phoneNumber}
                    onChange={e =>
                      meta.handleChange(e, (b, phoneNumber) => ({
                        ...b,
                        partner: {
                          ...b.partner,
                          phoneNumber,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.partner.phoneNumber
                )}
              </InfoWrapper>

              <InfoWrapper title="Partner's Email" titleWidth={180}>
                {editMode ? (
                  <SmallInput
                    value={meta.state.partner.email}
                    onChange={e =>
                      meta.handleChange(e, (b, email) => ({
                        ...b,
                        partner: {
                          ...b.partner,
                          email,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.partner.email
                )}
              </InfoWrapper>
            </Grid>
          </Grid>
          <Grid item xs={12} md>
            <Grid container direction="column" spacing={3}>
              <InfoWrapper title="Emergency Contact Name" titleWidth={250}>
                {editMode ? (
                  <SmallInput
                    value={meta.state.emergencyContact.name}
                    onChange={e =>
                      meta.handleChange(e, (b, name) => ({
                        ...b,
                        emergencyContact: {
                          ...b.emergencyContact,
                          name,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.emergencyContact.name
                )}
              </InfoWrapper>

              <InfoWrapper
                title="Emergency Contact Mobile Number"
                titleWidth={250}
              >
                {editMode ? (
                  <SmallInput
                    value={meta.state.emergencyContact.phoneNumber}
                    onChange={e =>
                      meta.handleChange(e, (b, phoneNumber) => ({
                        ...b,
                        emergencyContact: {
                          ...b.emergencyContact,
                          phoneNumber,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.emergencyContact.phoneNumber
                )}
              </InfoWrapper>

              <InfoWrapper title="Relationship" titleWidth={250}>
                {editMode ? (
                  <SmallInput
                    value={meta.state.emergencyContact.relationship}
                    onChange={e =>
                      meta.handleChange(e, (b, relationship) => ({
                        ...b,
                        emergencyContact: {
                          ...b.emergencyContact,
                          relationship,
                        },
                      }))
                    }
                  />
                ) : (
                  meta.state.emergencyContact.relationship
                )}
              </InfoWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AdditionalInfo

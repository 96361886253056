import React from 'react'
import Box from '@material-ui/core/Box'

const Service = ({ service }) => {
  return (
    <Box bgcolor="greyBlue.100" display="inline-block" p={1} borderRadius={12}>
      {service}
    </Box>
  )
}

export default Service

import React from 'react'
import { Link } from '@reach/router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Box from '@material-ui/core/Box'
import RegisterForm from './register-form'
import storeContext from '../../stores'

const RegisterPage = () => {
  const store = React.useContext(storeContext)

  const registerSchema = Yup.object().shape({
    firstName: Yup.string().required('Name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Please enter valid e-mail address')
      .required('Your email is required'),
    password: Yup.string().required('Password is required'),
    phoneNumber: Yup.string().required('Your phone number is required'),
  })

  return (
    <Box maxWidth={{ md: 600 }} m="auto" bgcolor="white">
      <Box py={{ xs: 6, sm: 8 }} px={{ xs: 4, sm: 16 }}>
        <Box align="center" fontFamily="camptonBold" fontSize={18} mb={6}>
          Create Your Account
        </Box>
        <Formik
          validationSchema={registerSchema}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneNumber: '',
          }}
          component={RegisterForm}
          onSubmit={(values, { setErrors }) =>
            store.auth.registerAction(values, setErrors)
          }
        />
        <Box mt={4} align="center">
          Already have an account yet?
          <Box color="primary.main" display="inline-block" ml={1}>
            <Link to="/login">Login here!</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RegisterPage

import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typing from './typing'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(({ palette }) => ({
  messageContainer: {
    width: props => props.isPagination && '100%',
    '& a': {
      color: 'rgb(73, 160, 171) !important',
    },
    wordBreak: 'break-word',
    background: ({ isFromBot, isPagination }) => {
      if (isPagination) return 'white'
      return isFromBot ? palette.greyBlue[100] : palette.secondary.main
    },
    color: ({ isFromBot }) => (isFromBot ? 'inherit' : palette.text.grey),
  },
}))

const MessageText = props => {
  const { text, isTyping, isPagination } = props
  const classes = useStyles(props)
  return (
    <Box
      py="0.8em"
      px="1em"
      {...(isPagination && { px: 0, py: 0 })}
      borderRadius={20}
      display="inline-flex"
      whiteSpace="pre-line"
      className={classes.messageContainer}
    >
      {isTyping ? (
        <Typing />
      ) : typeof text === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        text
      )}
    </Box>
  )
}

MessageText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isFromBot: PropTypes.bool,
  isPagination: PropTypes.bool,
  isTyping: PropTypes.bool,
}

export default MessageText

import { flow, getRoot, types, applySnapshot, getParent } from 'mobx-state-tree'
import { values } from 'mobx'
import isToday from 'date-fns/isToday'
import {
  getEventApi,
  getEventsApi,
  updateEventApi,
  updateEventInstanceApi,
} from '../../api/events-api'
import { createDateFromString, eventsQuery } from '../../utils/utils'
// import getDay from 'date-fns/getDay'
import isAfter from 'date-fns/isAfter'
import UserModel from './user-model'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import addHours from 'date-fns/addHours'

const EventsModel = types
  .model('EventsModel', {
    entries: types.map(
      types
        .model('EventModel', {
          id: types.maybeNull(types.string),
          instanceId: types.maybeNull(types.string),
          customer: types.maybeNull(
            types.reference(UserModel, {
              get(identifier, self) {
                const user = getRoot(self).users.entries.get(identifier)
                return user ? user : null
              },
              set(user) {
                return user.id
              },
            }),
          ),
          provider: types.reference(UserModel),
          startDate: types.maybeNull(types.string),
          startTime: types.maybeNull(types.string),
          endDate: types.maybeNull(types.string),
          endTime: types.maybeNull(types.string),
          paymentMethod: types.maybeNull(types.string),
          weekdays: types.maybeNull(types.array(types.string)),
          weekday: types.maybeNull(types.string),
          eventType: types.maybeNull(types.string),
          dialInInfo: types.maybeNull(types.string),
          title: types.maybeNull(types.string),
          notes: types.maybeNull(types.string),
          serviceName: types.maybeNull(types.string),
          isCancelled: types.maybeNull(types.boolean),
          isPendingConfirmation: types.maybeNull(types.boolean),
          createdTimestamp: types.maybeNull(types.number),
          exceptionDate: types.maybeNull(types.string),
        })
        .views(self => ({
          get start() {
            return createDateFromString(self.startDate, self.startTime)
          },
          get end() {
            return createDateFromString(self.endDate, self.endTime)
          },
          get isToday() {
            return isToday(self.start)
          },
          get wasInLast12Hours() {
            return (
              self.start < +new Date() &&
              Math.abs(differenceInHours(new Date(), self.start)) < 12
            )
          },
          get isIn24Hrs() {
            const diff = differenceInHours(new Date(), self.start)
            return Math.abs(diff) <= 24
          },
          get customerCanEdit() {
            // allow customer to edit if event is in the future or in the last 12 hours
            console.log('comparing', +new Date(), addHours(self.end, 12))
            return !isAfter(+new Date(), addHours(self.end, 12))
          },
          get isPastEvent() {
            return self.start < +new Date()
          },
          get eventDuration() {
            const diff = differenceInMinutes(self.end, self.start) / 30 / 2
            return `${diff} Hour${diff !== 1 ? 's' : ''}`
          },
          get status() {
            if (self.isCancelled) return 'Canceled'
            if (self.isPendingConfirmation) return 'Pending'
            return 'Booked'
          },
          get isUserEvent() {
            const { user } = getRoot(self).auth
            const role = user && user.role
            if (!self[role]) return false
            return getRoot(self).auth.user.id === self[role].id
          },
          // get insideProviderWorkingHours() {
          //   const schedule = self.provider.regularSchedule
          //   if (!schedule || !schedule.length) return true
          //   const sDay = WEEK_DAYS[getDay(self.start)]
          //   const eDay = WEEK_DAYS[getDay(self.end)]
          //   console.log('schedule = ', schedule)
          //   console.log('sday = ', sDay)
          //   console.log('eday = ', eDay)
          //   const providerStartDayObj = schedule.find(s => s.weekday === sDay)
          //   const providerEndDayObj = schedule.find(s => s.weekday === eDay)
          //   if (!providerStartDayObj || !providerEndDayObj) return false
          //   // providerDayObj.startTime = '11:00:00'
          //   // providerDayObj.endTime = '15:20:50'
          //   const { startTime, endTime } = providerDayObj
          //   const start = createDateFromString(self.startDate, startTime)
          //   const end = createDateFromString(self.endDate, endTime)
          //   return isAfter(self.start, start) && isAfter(end, self.end)
          // },
          get isDisabled() {
            return !self.isUserEvent /*|| !self.insideProviderWorkingHours*/
          },
          get calendarShape() {
            const user = getRoot(self).auth.user
            const otherRole =
              user && user.role === 'provider' ? 'customer' : 'provider'
            let title = self.title
            let serviceString = 'Session'
            if (self.serviceName === 'night_nurse')
              serviceString = 'Night Nurse'
            else if (self.serviceName === 'childcarePlus')
              serviceString = 'Enrichment'
            else if (self.serviceName)
              serviceString =
                self.serviceName.charAt(0).toUpperCase() +
                self.serviceName.slice(1)
            if (!title) {
              title = `${serviceString} with ${self[otherRole].firstName}`
            }
            return {
              start: self.start,
              end: self.end,
              title,
              id: self.instanceId,
              full: self,
              ...(self.isDisabled
                ? {
                    classNames: ['disabled'],
                  }
                : {
                    url: `/my-bookings/${self.instanceId}`,
                  }),
            }
          },
        }))
        .actions(self => ({
          updateNote: flow(function* updateNote(notes) {
            yield updateEventApi({ id: self.id, notes })
            self.notes = notes
          }),
          updateDateTime: flow(function* updateDateTime(
            dateAndTime,
            isViewerCustomer,
          ) {
            yield updateEventInstanceApi(self.instanceId, dateAndTime)
            applySnapshot(self, {
              ...self,
              ...dateAndTime,
              isPendingConfirmation: isViewerCustomer,
            })
          }),
          cancelSession: flow(function* cancelSession() {
            yield updateEventInstanceApi(self.instanceId, {
              isCancelled: true,
            })
            getParent(self, 2).removeEvent(self)
          }),
        })),
    ),
  })
  .views(self => ({
    get hasTodayEvents() {
      return values(self.entries).some(e => e.isToday)
    },
    get loggedUserEvents() {
      return values(self.entries).filter(event => {
        const user = getRoot(self).auth.user
        if (!event[user.role]) return false
        return event[user.role].id === user.id
      })
    },
    getProviderEvents(providerId) {
      return values(self.entries).filter(
        event => event.provider.shortUserId === providerId,
      )
    },
  }))
  .actions(self => ({
    removeEvent({ instanceId }) {
      self.entries.delete(instanceId)
    },
    setEvent(event, hard) {
      if (self.entries.get(event.instanceId) && !hard) return
      self.entries.set(event.instanceId, {
        ...event,
        customer: event.customerId,
        provider: event.providerId,
      })
    },
    getEventInstance: flow(function* getEventInstance(instanceId) {
      const { data } = yield getEventApi(instanceId)
      self.setEvent(data, true)
    }),
    getAllCustomerEvents: flow(function* getAllCustomerEvents() {
      const { data } = yield getEventsApi({
        customerId: getRoot(self).auth.user.id,
        ...eventsQuery,
      })
      const providerIds = Array.from(new Set(data.map(i => i.providerId)))
      const all = yield Promise.all(providerIds.map(self.getAllProvidersEvents))
      data.forEach(self.setEvent)
      all.flat().map(self.setEvent)
    }),
    getAllProvidersEvents: flow(function* getAllProvidersEvents(providerId) {
      yield getRoot(self).users.getUser(providerId)
      const { data } = yield getEventsApi({ ...eventsQuery, providerId })
      const customerIds = Array.from(new Set(data.map(i => i.customerId)))
      yield Promise.all(
        customerIds.filter(c => c).map(getRoot(self).users.getUser),
      )
      data.forEach(self.setEvent)
      return data
    }),
    reset() {
      self.entries = {}
    },
  }))

export default EventsModel

import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import QuickActions from '../actions/quick-actions'
import Message from '../models/message'
import ChatBotActions from '../actions/chat-bot-actions'
import ChatSlackActions from '../actions/chat-slack-actions'
import BotMessage from '../components/messages/bot-message'
import UserMessage from '../components/messages/user-message'
import { useMediaQuery, useTheme } from '@material-ui/core'

const SIZES = {
  login: { true: 230, false: 200 },
  signup: { true: 440, false: 380 },
  creditCard: { true: 300, false: 280 },
}
let lastScrolled = null

const getMsgOffsetBottom = (msg, isSmall, showDrawer) => {
  const size = SIZES[msg?.output?.type]
  if (!size || !showDrawer) return 0
  return size[isSmall]
}

const MessagesList = props => {
  const { messages, isSlackMode, onSend, onUndo, onBack, showDrawer } = props
  /** @type {object} @property {HTMLElement} current */
  const containerElement = React.useRef(null)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (containerElement.current) {
      const botMessages = containerElement.current.querySelectorAll('#bot')
      if (botMessages.length) {
        if (botMessages[botMessages.length - 1] !== lastScrolled) {
          botMessages[botMessages.length - 1].scrollIntoView({
            behavior: 'smooth',
          })
          lastScrolled = botMessages[botMessages.length - 1]
        }
      }
    }
  }, [messages])

  const lastMessage = messages[messages.length - 1]
  const quickActionsOffset = getMsgOffsetBottom(
    lastMessage,
    matches,
    showDrawer,
  )

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      wrap="nowrap"
      style={{ height: '100%' }}
      ref={containerElement}
    >
      {messages.map((message, index) => {
        const isLastMessage = messages.length - 1 === index
        const previousMessage = messages[index - 1]

        const displayLastMessageAction =
          isLastMessage &&
          !message.displayBottomDrawer &&
          !message.displayMessageAsAction

        const offset = getMsgOffsetBottom(message, matches, showDrawer)
        const undoId = message?.undoId

        if (message.displayMessageAsAction) {
          message.output.replaceTextWithAction(
            <ChatBotActions action={message.output} onSend={onSend} />,
          )
        }

        return (
          <Grid
            item
            key={index}
            {...(!message.isStartMessage &&
              !message.isTyping &&
              !previousMessage?.isFromBot &&
              message.isFromBot && { id: 'bot' })}
          >
            <Box
              pr={{
                xs: message.isFromBot ? 2 : 0,
                sm: message.isFromBot ? 6 : 0,
              }}
              pl={{
                xs: message.isFromBot ? 0 : 2,
                sm: message.isFromBot ? 0 : 6,
              }}
              {...(offset && isLastMessage && { pb: `${offset}px` })}
            >
              {message.isFromBot ? (
                <BotMessage message={message} />
              ) : (
                <UserMessage message={message} />
              )}
            </Box>
            {displayLastMessageAction && !isSlackMode && (
              <Box ml={7.2} mt={2}>
                <ChatBotActions
                  action={message.output}
                  onSend={onSend}
                  onUndo={() => {
                    onSend({ input: { rawText: 'Undo', type: 'undo', undoId } })
                    onUndo(undoId)
                  }}
                />
              </Box>
            )}
          </Grid>
        )
      })}
      {isSlackMode && (
        <Box pl={7.2} mt={2} width="100%">
          <ChatSlackActions onSend={onSend} />
        </Box>
      )}
      <div style={{ height: '100%', flex: 'none' }} />
      <QuickActions
        message={lastMessage}
        onSend={onSend}
        onUndo={onUndo}
        onBack={onBack}
        style={{
          paddingBottom: quickActionsOffset ? +quickActionsOffset - 16 : 0,
        }}
      />
    </Grid>
  )
}

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.instanceOf(Message)),
  isSlackMode: PropTypes.bool,
  showDrawer: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
}

MessagesList.defaultProps = {
  messages: [],
}

export default MessagesList

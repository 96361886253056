import React from 'react'
import { Link } from '@reach/router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Box from '@material-ui/core/Box'
import LoginForm from './login-form'
import storeContext from '../../stores'

const LoginPage = () => {
  const store = React.useContext(storeContext)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid e-mail address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  return (
    <Box maxWidth={{ md: 600 }} m="auto" bgcolor="white">
      <Box py={{ xs: 6, sm: 8 }} px={{ xs: 4, sm: 16 }}>
        <Box align="center" fontFamily="camptonBold" fontSize={18} mb={6}>
          Login into your account
        </Box>
        <Formik
          validationSchema={loginSchema}
          initialValues={{ email: '', password: '' }}
          component={LoginForm}
          onSubmit={(values, { setErrors }) =>
            store.auth.loginAction(values, setErrors)
          }
        />
        <Box mt={4} align="center">
          Don't have an account yet?
          <Box
            color="primary.main"
            ml={1}
            display="inline-block"
            className="only-apiari-classic"
          >
            <Link to="/chat-bot?startIntent=authenticate">Sign up here!</Link>
          </Box>
          <Box
            color="primary.main"
            ml={1}
            display="inline-block"
            className="only-apiari-business"
          >
            <a href="https://www.apiari.net/profile-builder">
              Create a profile today
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LoginPage

import React from 'react'
import TextField from '@material-ui/core/TextField'
import useFormProps from '../../hooks/use-form-props'

const ApiariInput = props => {
  const { margin = 'none', field, form, required, ...rest } = props
  const formProps = useFormProps({ field, form, required })
  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: true }}
      margin={margin}
      variant="outlined"
      size="small"
      {...formProps}
      {...rest}
    />
  )
}

export default ApiariInput

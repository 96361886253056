import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactComponent as HeartIcon } from '../../../../../assets/heart.svg'
import storeContext from '../../../../../stores'
import { observer } from 'mobx-react-lite'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(theme => ({
  icon: {
    '& path': {
      fill: ({ isInFavorites }) =>
        isInFavorites && theme.palette.secondary.main,
    },
  },
}))

const FavoriteButton = observer(({ buttonClass, providerId }) => {
  const store = React.useContext(storeContext)
  const isViewerProvider =
    store.auth.user && store.auth.user.role === 'provider'
  const isInFavorites = store.auth.isInFavorites(providerId)
  const classes = useStyles({ isInFavorites })
  const [inProcess, setInProcess] = React.useState(false)

  const handleToggle = async () => {
    if (isViewerProvider) return
    setInProcess(true)
    await store.auth.toggleFavoriteAction(providerId)
    setInProcess(false)
  }

  return (
    <Button
      color="primary"
      className={[buttonClass, 'only-apiari-classic']}
      onClick={handleToggle}
      disabled={inProcess}
    >
      <Box
        component="span"
        color="secondary.main"
        display="flex"
        alignItems="center"
      >
        <HeartIcon className={classes.icon} width={22} />
      </Box>
      <Box component="span" ml={1}>
        {isInFavorites ? 'REMOVE FROM' : 'ADD TO MY'} TEAM{' '}
      </Box>
    </Button>
  )
})
FavoriteButton.propTypes = {
  buttonClass: PropTypes.string,
  providerId: PropTypes.number.isRequired,
}

export default FavoriteButton

import React from 'react'
import { useField } from 'formik'
import InputMask from 'react-input-mask'

const MaskedInput = props => {
  const [, meta, helpers] = useField(props.name)
  const { disabled, mask, maskChar, children, sanitizeValue } = props
  const { value } = meta
  const { setValue } = helpers
  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={event =>
        setValue(
          sanitizeValue
            ? event.target.value.replace(/[^0-9]/g, '')
            : event.target.value,
          false,
        )
      }
      maskChar={maskChar}
      disabled={disabled}
    >
      {() => children}
    </InputMask>
  )
}

export default MaskedInput

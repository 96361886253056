import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import CongratsImg from '../../../assets/congrats.svg'
import Confetti from '../../../components/confetti'

const useStyles = makeStyles(theme => ({
  pageHeading: {
    color: theme.palette.text.darkGrey,
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    lineHeight: '2.5',
  },

  pageContainer: {
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '300px',
      },
    },
  },

  pageSubHeading: {
    fontWeight: '300',
    lineHeight: '1.3',
  },
}))

const Congrats = () => {
  const classes = useStyles()
  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={20}
            fontFamily="Roboto"
            className={classes.pageSubHeading}
            mb={5}
          >
            Look out for an email from our background check provider
            SpringVerify to complete your background check. In the meantime, we
            are contacting your references and glamming up your profile. We will
            email you once your background check and references are cleared.
          </Box>
        </Grid>
        <Grid item xs={12} justify="center">
          <Box px={1} mb={3.5}>
            <img src={CongratsImg} alt="congrats" />
          </Box>
        </Grid>
      </Grid>
      <Confetti />
    </Box>
  )
}

export default React.memo(Congrats)

import { useState } from 'react'
import { Link } from '@reach/router'
import { Box, Grid, Avatar, withStyles, makeStyles } from '@material-ui/core'
import {
  CameraAlt,
  HowToReg,
  Extension,
  NightsStay,
  ChildFriendly,
} from '@material-ui/icons'
import AvatarCropper from '../../../components/avatar-cropper'
import ApiariButton from '../../../components/inputs/button'
import ApiariUpload from '../../../components/inputs/upload'
import ConfirmationModal from '../../../components/confirmation-modal'

const useStyles = makeStyles(theme => ({
  subtext: {
    '& > .link': {
      color: theme.palette.primary.main,
    },
  },
}))

const serviceIconMap = {
  childcare: <ChildFriendly />,
  childcarePlus: <Extension />,
  night_nurse: <NightsStay />,
  default: <HowToReg />,
}

const AccountPageButton = withStyles({
  root: {
    width: '100%',
    '& .MuiButton-startIcon': {
      marginTop: '-4px',
      marginRight: '6px',
    },
  },
})(ApiariButton)

const AccountPageHeader = ({ user }) => {
  let subtext

  const classes = useStyles()
  const [isAvatarCropperOpen, setIsAvatarCropperOpen] = useState(false)
  const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false)
  const [profilePictureFile, setProfilePictureFile] = useState(null)

  const serviceNameMap = user.getServiceNamesHumanMap()
  if (user.role === 'provider') {
    subtext = (
      <>
        <Box mb={2}>View your public profile:</Box>
        {Object.keys(serviceNameMap).map((service, i) => (
          <Box
            key={service}
            color="primary.main"
            mb={{ xs: 2 }}
            mr={1.5}
            display="inline-block"
          >
            <Link
              to={`/user/${user.alias}${
                service === 'childcarePlus' ? '' : `/${service}`
              }`}
            >
              <AccountPageButton
                variant="outlined"
                startIcon={serviceIconMap[service] || serviceIconMap.default}
              >
                {serviceNameMap[service]}
              </AccountPageButton>
            </Link>
          </Box>
        ))}
      </>
    )
  } else {
    subtext = (
      <div className={classes.subtext}>
        <span>
          🎁 Refer a friend and get $25 when they book with your code
          <strong>&nbsp;{user.personalReferralCode}&nbsp;</strong>
        </span>
        <a
          className="link"
          href="https://theapiari.com/referral-program/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms apply.
        </a>
      </div>
    )
  }

  return (
    <Grid item container>
      {isUploadImageModalOpen && (
        <ConfirmationModal
          title="Change Profile Picture"
          handleClose={() => setIsUploadImageModalOpen(false)}
          confirmationButton={
            <ApiariUpload
              text="Upload Photo"
              isButtonMode
              uploadButton={
                <ApiariButton component="span">Upload photo</ApiariButton>
              }
              file={profilePictureFile}
              inputProps={{
                name: 'profilePic',
                accept: 'image/*',
                onChange: file => {
                  setProfilePictureFile(file)
                  setIsUploadImageModalOpen(false)
                  setIsAvatarCropperOpen(true)
                },
              }}
            />
          }
          closeButton={
            <AccountPageButton
              variant="outlined"
              onClick={() => setIsUploadImageModalOpen(false)}
            >
              Cancel
            </AccountPageButton>
          }
        />
      )}
      {isAvatarCropperOpen && (
        <AvatarCropper
          title="Edit Photo"
          file={profilePictureFile}
          handleClose={() => {
            setIsAvatarCropperOpen(false)
            setProfilePictureFile(null)
          }}
          onSave={file => {
            setIsAvatarCropperOpen(false)
            user.updateProviderAction(
              {
                profilePic: file,
              },
              () => setProfilePictureFile(null),
              () => setProfilePictureFile(null),
            )
          }}
        />
      )}
      <Grid item xs>
        <Box display="flex" mb={3} p={1} md="flex-start" xs="center">
          <Box mr={{ xs: 1, md: 4 }}>
            <Box width={{ xs: 110, md: 150 }} height={{ xs: 110, md: 150 }}>
              <Avatar src={user.profilePic} />
            </Box>
          </Box>
          <Box>
            <Box
              fontFamily="camptonBold"
              mb={1}
              fontSize={{ xs: '20px', mb: '30px' }}
            >
              <h1
                style={{
                  margin: 0,
                  lineHeight: '1.4em',
                  fontSize: 'inherit',
                }}
              >
                {user.fullName}
              </h1>
            </Box>

            <Box
              fontFamily="campton"
              fontSize={14}
              mb={2}
              maxWidth={{ xs: '170px', md: '100%' }}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <span>{user.email}</span>
            </Box>
            <Box fontFamily="campton" mt={1}>
              <ApiariButton
                startIcon={<CameraAlt />}
                onClick={() => setIsUploadImageModalOpen(true)}
              >
                Change photo
              </ApiariButton>
            </Box>
          </Box>
        </Box>

        <Box p={1} display={{ xs: 'flex', sm: 'block' }} flexDirection="column">
          {subtext}
        </Box>
      </Grid>
    </Grid>
  )
}

export default AccountPageHeader

import React from 'react'
import { Link } from '@reach/router'
import { useTheme } from '@material-ui/core'

const NavLink = ({ children, ...rest }) => {
  const theme = useTheme()
  return (
    <Link
      {...rest}
      getProps={({ isCurrent, href, location }) => {
        const withQs =
          href.split('?')[0] === location.pathname &&
          href.includes(location.search)
        return {
          style: {
            color:
              isCurrent || withQs ? theme.palette.secondary.main : 'inherit',
          },
        }
      }}
    >
      {children}
    </Link>
  )
}

export default NavLink

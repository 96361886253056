import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../components/inputs/button'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import useUpdateUser from '../../../hooks/use-update-user'
import { PETS_OPTIONS, SMOKING_OPTIONS } from '../../../utils/config'
import { SmallInput, SmallSelect } from '../account-page'
import Link from '@material-ui/core/Link'

const InfoWrapper = ({ title, children }) => (
  <Grid
    item
    container
    component={Box}
    flexDirection={{ xs: 'column', md: 'row' }}
  >
    <Grid component={Box} item xs="auto" width={200} mb={{ xs: 1, md: 0 }}>
      <Box fontFamily="camptonBold">{title}</Box>
    </Grid>
    <Box component={Grid} item xs height={20}>
      {children}
    </Box>
  </Grid>
)

function getInitialState(user) {
  return {
    pets: user.petsString,
    smokingHousehold: user.smokingHousehold,
    dietaryRestrictions: user.dietaryRestrictions,
  }
}

const formatForApi = state => ({
  smokingHousehold: state.smokingHousehold,
  dietaryRestrictions: state.dietaryRestrictions,
  ...PETS_OPTIONS.find(i => i.value === state.pets).cond,
})

const HomeDetails = ({ user, onEdit, editMode, onSave }) => {
  const meta = useUpdateUser(user, getInitialState, onSave, formatForApi)
  if (user.role === 'provider') return <></>
  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle icon={<BulletIcon />} text="Other Home Details" />
        </Grid>
        <Grid item xs="auto">
          {editMode ? (
            <>
              <Link
                color="primary"
                underline="none"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={onSave}
              >
                Cancel
              </Link>
              <ApiariButton
                onClick={() => meta.handleSave()}
                disabled={!meta.hasChanges}
                busy={meta.submitting}
              >
                Save
              </ApiariButton>
            </>
          ) : (
            <ApiariButton onClick={onEdit}>Edit</ApiariButton>
          )}
        </Grid>
      </Grid>
      <Box pl={3.6} mt={2} mb={2}>
        <Box component={Grid} container spacing={3}>
          <Grid item xs={12} md>
            <InfoWrapper title="Pets">
              {editMode ? (
                <SmallSelect
                  variant="outlined"
                  value={meta.state.pets}
                  onChange={e =>
                    meta.handleChange(e, (b, pets) => ({ ...b, pets }))
                  }
                  options={PETS_OPTIONS}
                />
              ) : (
                meta.state.pets
              )}
            </InfoWrapper>
          </Grid>
          <Grid item xs={12} md>
            <InfoWrapper title="Smoking">
              {editMode ? (
                <SmallSelect
                  variant="outlined"
                  value={meta.state.smokingHousehold}
                  onChange={e =>
                    meta.handleChange(e, (b, smokingHousehold) => ({
                      ...b,
                      smokingHousehold,
                    }))
                  }
                  options={SMOKING_OPTIONS}
                />
              ) : meta.state.smokingHousehold ? (
                'Yes'
              ) : (
                'No'
              )}
            </InfoWrapper>
          </Grid>
          <Grid item xs={12}>
            <InfoWrapper title="Dietary Restrictions?">
              {editMode ? (
                <Box>
                  <SmallInput
                    autoFocus
                    value={meta.state.dietaryRestrictions}
                    onChange={e =>
                      meta.handleChange(e, (b, dietaryRestrictions) => ({
                        ...b,
                        dietaryRestrictions,
                      }))
                    }
                    multiline
                    rows={2}
                  />
                </Box>
              ) : (
                meta.state.dietaryRestrictions ?? 'No restrictions'
              )}
            </InfoWrapper>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default HomeDetails

import fetchApi from './fetch'

export const becomeAProviderApi = data =>
  fetchApi.post('provider', data, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })
export const getMembershipStatus = ({ email }) =>
  fetchApi.get(`billing/subscriptionStatus/${email}`, {
    DOMAIN: process.env.REACT_APP_BILLING_URL,
  })
export const updateCreditCard = ({ shortUserId, cardToken }) =>
  fetchApi.post(
    `user/updateCreditCard/${shortUserId}`,
    { cardToken },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )
export const subscribeUserApi = ({ shortUserId, token }) =>
  fetchApi.patch(
    `user/subscribe/${shortUserId}`,
    { token },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

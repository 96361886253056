import { Backdrop, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const useStyles = makeStyles(theme => ({
  box: {
    position: 'relative',
    paddingRight: '15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    display: '-webkit-box',
    minHeight: '3em',
    lineClamp: 4,
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
  arrow: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
    color: theme.palette.text.brandDarkGray,

    '& > svg': {
      fontSize: '15px',
    },
  },
}))

const BlurbBox = ({ text }) => {
  const classes = useStyles()

  return (
    <Box my={1}>
      <Typography classes={{ root: classes.box }} variant="body2">
        <div className={classes.arrow}>
          <ArrowForwardIosIcon />
        </div>
        {text}
      </Typography>
    </Box>
  )
}
export default BlurbBox

import fetchApi from './fetch'

export const loginApi = data =>
  fetchApi.post('authenticate', data, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const forgotPasswordApi = email =>
  fetchApi.post(`user/${email}/resetPassword`, null, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const setPasswordApi = data =>
  fetchApi.patch('user', data, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const updateUserApi = (id, data) =>
  fetchApi.patch(`user/${id}`, data, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const registerUserApi = data =>
  fetchApi.post('user', data, {
    publicApi: true,
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const getS3UrlApi = file =>
  fetchApi.post(
    `user/123/photos/initiate-upload?contentType=${file.type}`,
    null,
    {
      publicApi: true,
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

export const uploadToS3Api = (file, url) =>
  fetchApi.upload(url, file, {
    method: 'PUT',
    headers: {
      'x-amz-security-token': process.env.REACT_APP_AMZ_SEC_TOKEN,
      'Content-Type': file.type,
    },
  })

export const getUserApi = id =>
  fetchApi.get(`user/${id}?fields=details,ratingDetails`, {
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const getUserFavoritesApi = id =>
  fetchApi.get(`user/favorites/${id}`, {
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const addToFavoritesApi = ({ userId, providerId }) =>
  fetchApi.post(`user/${userId}/favorites/${providerId}`, null, {
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const removeFromFavoritesApi = ({ userId, providerId }) =>
  fetchApi.delete(`user/${userId}/favorites/${providerId}`, {
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const requestAccountDelete = ({ userId }) =>
  fetchApi.delete(`user/${userId}/requestAccountDelete`, {
    DOMAIN: process.env.REACT_APP_API_URL,
  })

export const cancelUserMembership = ({ userId, reason }) =>
  fetchApi.post(
    `user/${userId}/unsubscribe`,
    { reason },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

export const likeProvider = ({ shortUserId, providerId }) =>
  fetchApi.post(
    `user/${shortUserId}/like`,
    { providerId },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

export const dislikeProvider = ({ shortUserId, providerId }) =>
  fetchApi.post(
    `user/${shortUserId}/dislike`,
    { providerId },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

export const descendProvider = ({ shortUserId, providerId }) =>
  fetchApi.post(
    `user/${shortUserId}/descend`,
    { providerId },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

export const ascendProvider = ({ shortUserId, providerId }) =>
  fetchApi.post(
    `user/${shortUserId}/ascend`,
    { providerId },
    {
      DOMAIN: process.env.REACT_APP_API_URL,
    },
  )

import React from 'react'
import { Formik, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ApiariInput from '../../../components/inputs/input'
import {
  GhostBtn,
  BlankBtn,
  ProviderFormField,
} from '../become-a-provider-styles'
import AddIcon from '@material-ui/icons/Add'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MaskedInput from '../../../components/inputs/masked-input'
// config
import {
  CERTIFICATE_INITIAL_VALUES,
  CERTIFICATE_VALIDATION_SCHEMA,
} from '../become-a-provider-config'

const useStyles = makeStyles(theme => ({
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '1.2',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
  certificateList: {
    '& li': {
      position: 'relative',
      borderWidth: '1px',
      borderStyle: 'none none dashed none',
      borderColor: theme.palette.border.inactive,
      padding: '18px 120px 15px 0',
      color: theme.palette.text.black,
      [theme.breakpoints.down('sm')]: {
        padding: '18px 80px 15px 0',
      },

      '&:last-child': {
        border: 'none',
      },
      '& .MuiListItemText-primary': {
        fontSize: '18px',
        lineHeight: '25px',
        fontFamily: 'RobotoBold',
      },
      '& .MuiListItemText-secondary': {
        fontSize: '14px',
        lineHeight: '25px',
        fontFamily: 'Roboto',
        color: theme.palette.text.black,
      },
      '& .MuiListItem-gutters': {
        fontSize: '20px',
        fontFamily: 'RobotoBlack',
        padding: '0px',

        '& .MuiListItemText-multiline': {
          margin: '0',
        },
      },
      '& .MuiListItemSecondaryAction-root': {
        position: 'absolute',
        right: '0px',
        top: '30px',
      },
    },
  },
}))

const KnowYouBetterForm = props => {
  const classes = useStyles()
  const { isSubmitting, values, setFieldValue, errors, setErrors } = props

  const handleCertFormSubmit = (value, { resetForm }) => {
    setFieldValue(
      'certificates',
      [...values.certificates, { name: value.name, date: value.date }],
      false,
    )
    resetForm()
    if (!errors.aboutYourself) {
      setTimeout(() => {
        setErrors({})
      }, 0)
    }
  }

  const removeCertificate = index => {
    setFieldValue(
      'certificates',
      values.certificates.filter((item, idx) => index !== idx),
      false,
    )
  }

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            Share something fun or interesting about yourself
            <span className={classes.subHead}>
              What are your favorite pastimes, personal goals, or some fun facts
              about you?
            </span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              multiline
              placeholder="I'm a theater major and love reading children's books in different cartoon voices!"
              name="aboutYourself"
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            What certifications, diplomas, awards or other qualifications can we
            proudly display for you?
            <span className={classes.subHead}>
              E.g. College degree, CPR, newborn care certification. Please
              include name of the institution.
            </span>
          </Box>
        </Grid>
        <Formik
          initialValues={CERTIFICATE_INITIAL_VALUES}
          validationSchema={CERTIFICATE_VALIDATION_SCHEMA}
          onSubmit={handleCertFormSubmit}
        >
          {({ setFieldValue, values }) => (
            <Grid component={Form} container noValidate spacing={2}>
              <Grid item sm={7} xs={12}>
                <Box px={1}>
                  <ProviderFormField
                    component={ApiariInput}
                    disabled={isSubmitting}
                    name="name"
                    label="Name of Certificate"
                    variant="standard"
                  />
                </Box>
              </Grid>
              <Grid item sm={5} xs={8}>
                <Box px={1}>
                  {/*<ProviderDatePicker
                    disableFuture
                    format="MM/dd/yyyy"
                    views={['year', 'month', 'date']}
                    label={
                      <span>
                        Date obtained <strong>(optional)</strong>
                      </span>
                    }
                    value={values.date}
                    onChange={(date, value) => setFieldValue('date', value, false)}
                    animateYearScrolling
                    name="date"
                  />*/}
                  <MaskedInput
                    disabled={isSubmitting}
                    name="date"
                    mask="99/99/9999"
                    maskChar="_"
                  >
                    <ProviderFormField
                      component={ApiariInput}
                      name="date"
                      placeholder="MM/DD/YYYY"
                      label={
                        <span>
                          Date obtained <strong>(optional)</strong>
                        </span>
                      }
                      variant="standard"
                    />
                  </MaskedInput>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box px={1}>
                  <GhostBtn
                    busy={isSubmitting}
                    type="submit"
                    startIcon={<AddIcon />}
                  >
                    Add A Certificate
                  </GhostBtn>
                </Box>
              </Grid>
            </Grid>
          )}
        </Formik>
        {!!values.certificates?.length && (
          <Grid item xs={12}>
            <Box px={1} mt={3}>
              <List className={classes.certificateList}>
                {values.certificates.map((cert, index) => (
                  <ListItem key={`${cert.name}${index}`}>
                    <ListItemText primary={cert.name} secondary={cert.date} />
                    <ListItemSecondaryAction>
                      <BlankBtn onClick={() => removeCertificate(index)}>
                        Remove
                      </BlankBtn>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default React.memo(KnowYouBetterForm)

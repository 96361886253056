import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../../components/inputs/button'
import ApiariInput from '../../../../components/inputs/input'
import { getFormValues } from '../../../../utils/utils'

const ChatLogin = ({ onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()

    onSubmit(getFormValues(e))
  }

  return (
    <Box textAlign="center" width={300} p={2}>
      <Box mb={1} fontFamily="camptonBold" fontSize={22}>
        Log into your account
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <ApiariInput
              label="Email"
              name="email"
              autoComplete="username"
              placeholder="Enter email"
              autoFocus
            />
          </Grid>
          <Grid item>
            <ApiariInput
              label="Password"
              name="password"
              autoComplete="current-password"
              placeholder="Enter password"
              type="password"
            />
          </Grid>
        </Grid>
        <Box mt={2} mx="auto">
          <ApiariButton fullWidth type="submit">
            Login
          </ApiariButton>
        </Box>
      </form>
    </Box>
  )
}

ChatLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ChatLogin

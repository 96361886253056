import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import ApiariButton from '../../../components/inputs/button'
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from '@stripe/react-stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import UpdateCard from './updateCard'

const STRIPE_LOAD = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

const PaymentInfo = ({ paymentInfo, fetchMembership }) => {
  const [editing, setEditing] = useState(false)
  const [submitUpdateCard, setSubmitUpdateCard] = useState(false)

  const InfoWrapper = ({ title, children, height = 20 }) => (
    <Grid
      item
      container
      component={Box}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Grid component={Box} item xs="auto" width={200} mb={{ xs: 1, md: 0 }}>
        <Box fontFamily="camptonBold">{title}</Box>
      </Grid>
      <Box component={Grid} item xs height={height}>
        {children}
      </Box>
    </Grid>
  )
  return (
    <Grid item>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs>
          <SubTitle icon={<BulletIcon />} text="Payment Info" />
        </Grid>
        {paymentInfo && (
          <Grid item xs="auto">
            {editing ? (
              <>
                <span
                  underline="none"
                  style={{
                    cursor: 'pointer',
                    marginRight: 10,
                    color: '#49A0AB',
                  }}
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </span>
                <ApiariButton
                  onClick={() => setSubmitUpdateCard(true)}
                  busy={submitUpdateCard}
                  // disabled={!meta.hasChanges}
                >
                  Save
                </ApiariButton>
              </>
            ) : (
              <ApiariButton onClick={() => setEditing(true)}>
                Update
              </ApiariButton>
            )}
          </Grid>
        )}
      </Grid>
      {editing ? (
        <Elements stripe={STRIPE_LOAD}>
          <UpdateCard
            submitUpdateCard={submitUpdateCard}
            setSubmitUpdateCard={setSubmitUpdateCard}
            fetchMembership={fetchMembership}
            setEditing={setEditing}
          />
        </Elements>
      ) : (
        <Box pl={3.6} mt={2}>
          <Grid container direction="column" spacing={3}>
            <InfoWrapper title="Payment Method">
              {paymentInfo
                ? paymentInfo?.paymentInfo
                  ? paymentInfo.paymentInfo
                  : 'Credit card on file'
                : 'None on file'}
            </InfoWrapper>
            {paymentInfo && (
              <>
                <InfoWrapper title="Ending In">
                  {paymentInfo?.last4}
                </InfoWrapper>
                <InfoWrapper title="Expires">
                  {paymentInfo?.exp_mon + '/' + paymentInfo?.exp_year}
                </InfoWrapper>
              </>
            )}
          </Grid>
        </Box>
      )}
    </Grid>
  )
}

export default PaymentInfo

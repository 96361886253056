import { colors, makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  root: {
    '& .fc-unthemed td': {
      borderColor: theme.palette.divider,
    },
    '& .fc-widget-header': {
      border: 'none',
    },
    '& .fc-axis': {
      ...theme.typography.body2,
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2,
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1,
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6,
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6,
    },
    '& .fc th': {
      borderColor: theme.palette.divider,
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: 900,
      color: theme.palette.common.black,
      padding: theme.spacing(1),
      border: 'none',
    },
    '& .fc-day-top': {
      ...theme.typography.body2,
    },
    '& .fc-highlight': {
      backgroundColor: colors.blueGrey[50],
    },
    '& .fc-event': {
      '&.disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 1,
      opacity: 0.9,
      '& .fc-time': {
        ...theme.typography.subtitle2,
        color: 'inherit',
      },
      '& .fc-title': {
        ...theme.typography.subtitle2,
        color: 'inherit',
      },
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1,
      background: theme.palette.greyBlue['100'],
      height: '30vh',
    },
  },
}))

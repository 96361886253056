import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  loadingWrapper: {
    display: 'flex',
    margin: 'auto',
    minWidth: 200,
    minHeight: 60,
    animation: 'pulse 1s ease-in-out infinite alternate both',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
})

const LoadingScreen = ({ logo, ...props }) => {
  const classes = useStyles(props)
  return (
    <div className={classes.loadingWrapper}>
      <img src={logo} alt="logo" />
    </div>
  )
}

export default LoadingScreen

import { useEffect } from 'react'
import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core'
import { ErrorMessage, getIn } from 'formik'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ApiariInput from '../../../components/inputs/input'
import ApiariSelect from '../../../components/inputs/select/select'
import {
  ProviderFormField,
  ProviderSelect,
  ProviderRadio,
} from '../become-a-provider-styles'
import { getStateCodeByZipcode } from '../../../utils/zipcodes'

const useStyles = makeStyles(theme => ({
  selectLabel: {
    color: 'black',
    marginBottom: '16px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '1',
  },
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '1.2',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
}))

const AddressForm = props => {
  const classes = useStyles()
  const { isSubmitting, values, errors, touched, setFieldValue } = props

  values.hasCar = values.hasCar === undefined ? '' : values.hasCar

  useEffect(() => {
    const zipcode = values.address.zipcode

    setFieldValue(
      'address.state',
      errors.address?.zipcode ? '' : getStateCodeByZipcode(zipcode),
    )
  }, [values.address.zipcode, errors.address?.zipcode])

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            What’s your home address?
            <span className={classes.subHead}>
              This help us to match you with jobs nearby!
            </span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              name="address.streetAddress"
              label="Street Address"
              variant="standard"
              error={
                getIn(errors, 'address.streetAddress') &&
                getIn(touched, 'address.streetAddress')
              }
            />
            {!!getIn(errors, 'address.streetAddress') &&
              getIn(touched, 'address.streetAddress') && (
                <ErrorMessage
                  component={FormHelperText}
                  name="address.streetAddress"
                />
              )}
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              name="address.city"
              label="City"
              variant="standard"
              error={
                getIn(errors, 'address.city') && getIn(touched, 'address.city')
              }
            />
            {!!getIn(errors, 'address.city') &&
              getIn(touched, 'address.city') && (
                <ErrorMessage component={FormHelperText} name="address.city" />
              )}
          </Box>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Box px={1}>
            <ProviderFormField
              component={ApiariInput}
              disabled={isSubmitting}
              name="address.zipcode"
              label="Zip"
              variant="standard"
              error={
                getIn(errors, 'address.zipcode') &&
                getIn(touched, 'address.zipcode')
              }
            />
            {!!getIn(errors, 'address.zipcode') &&
              getIn(touched, 'address.zipcode') && (
                <ErrorMessage
                  component={FormHelperText}
                  name="address.zipcode"
                />
              )}
          </Box>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Box px={1}>
            <ProviderFormField
              label="State"
              component={ApiariInput}
              disabled
              name="address.state"
              variant="standard"
              error={
                getIn(errors, 'address.state') &&
                getIn(touched, 'address.state')
              }
            />
            {!!getIn(errors, 'address.state') &&
              getIn(touched, 'address.state') && (
                <ErrorMessage component={FormHelperText} name="address.state" />
              )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            How far will you travel for work each way?
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box px={1}>
            <ProviderSelect className={classes.formControl}>
              <ApiariSelect
                labelId="desirableCommutingTime"
                id="desirableCommutingTime"
                name="desirableCommutingTime"
                value={values.desirableCommutingTime}
                options={[
                  { title: 'Less than 30 min', value: '30' },
                  { title: 'Less than 60 min', value: '60' },
                  { title: 'Less than 90 min', value: '90' },
                ]}
                onChange={e =>
                  setFieldValue('desirableCommutingTime', e.target.value, false)
                }
                error={
                  getIn(errors, 'desirableCommutingTime') &&
                  getIn(touched, 'desirableCommutingTime')
                }
              />
              {!!getIn(errors, 'desirableCommutingTime') &&
                getIn(touched, 'desirableCommutingTime') && (
                  <ErrorMessage
                    component={FormHelperText}
                    name="desirableCommutingTime"
                  />
                )}
            </ProviderSelect>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={5}
          >
            Do you have a car?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} mb={{ xs: 2, md: 4 }}>
            <ProviderRadio component="fieldset" className={classes.customRadio}>
              <RadioGroup
                aria-label="hasCar"
                name="hasCar"
                value={values.hasCar}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('hasCar', true, false)
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      onChange={() => {
                        setFieldValue('hasCar', false, false)
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
              {!!errors.hasCar && touched.hasCar && (
                <FormHelperText>{errors.hasCar}</FormHelperText>
              )}
            </ProviderRadio>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddressForm

import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import SubTitle from './booking-details-sub-title'
import Shimmer from '../../../components/shimmer/shimmer'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg'
import { ReactComponent as NoteIcon } from '../../../assets/note.svg'
import ApiariButton from '../../../components/inputs/button'
import ConfirmationModal from '../../../components/confirmation-modal'

const boxProps = {
  border: 1,
  borderRadius: 10,
  borderColor: 'grey.200',
  p: 2,
  pr: { md: '150px' },
  mb: 3,
  position: 'relative',
}

const EditButton = props => {
  return (
    <Box position="absolute" right={10} top={8}>
      <ApiariButton {...props}>edit</ApiariButton>
    </Box>
  )
}

EditButton.propTypes = { onClick: PropTypes.func }

function BookingDetailsLayout(props) {
  const [editing, setEditing] = React.useState('')
  const [editingEnabled, setEditingEnabled] = React.useState(true)
  const [confirmation, setConfirmation] = React.useState(false)
  const [cancelling, setCancelling] = React.useState(false)
  const isPrivateEvent = props.eventType === 'private'
  let title = 'Booking Details'
  let cancellationWarning = null
  if (props.isIn24Hrs && !isPrivateEvent) {
    if (props.isViewerCustomer)
      cancellationWarning =
        "As this appointment is less than 24 hours from now, you'll be charged a cancellation fee of $50 or the full price of the session, whichever is lower."
    else
      cancellationWarning =
        'Please note that cancellations within 24 hours will result in a deduction of 2 hours pay unless it is due to illness or inclement weather. Please text your client to let them know as well.'
  }
  if (props.eventType === 'private') title = props.title || 'Event'
  else if (props.eventType === 'interview') title = 'Interview Details'
  return (
    <Grid container direction="column" style={{ overflow: 'hidden' }}>
      <Grid item container justify="space-between" alignItems="center">
        <Grid item>
          <Box mb={3} fontSize={{ xs: 20, md: 25 }} fontFamily="camptonBold">
            {title}
          </Box>
        </Grid>
        {!!props.onCancel && (
          <Grid item>
            <Box mb={3}>
              <ApiariButton
                onClick={() => setConfirmation(true)}
                disabled={props.isLoading}
                busy={cancelling}
              >
                {isPrivateEvent ? 'remove personal event' : 'cancel session'}
              </ApiariButton>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        {props.avatar ? (
          <Grid item xs={12} sm="auto">
            <Box width={{ xs: 125, md: 165 }} height={{ xs: 125, md: 165 }}>
              {props.avatar}
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm>
          {!isPrivateEvent ? (
            <Box {...boxProps}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md="auto"
                  component={Box}
                  width={200}
                  mb={{ xs: 2, md: 0 }}
                >
                  <SubTitle icon={<BulletIcon />} text="Session Details" />
                </Grid>
                <Grid item xs={12} md container direction="column" spacing={1}>
                  {React.cloneElement(props.session, {
                    editing: editing === 'session',
                    setEditingEnabled,
                    onSave: () => {
                      setEditingEnabled(true)
                      setEditing('')
                    },
                  })}
                </Grid>
                {editing !== 'session' && props.session && (
                  <EditButton
                    disabled={
                      !editingEnabled ||
                      !props.canEditSession ||
                      props.isLoading ||
                      (!props.isViewerCustomer && !isPrivateEvent)
                    }
                    onClick={() => setEditing('session')}
                  />
                )}
              </Grid>
            </Box>
          ) : (
            <></>
          )}
          <Box {...boxProps}>
            <Grid container>
              <Grid
                item
                xs={12}
                md="auto"
                component={Box}
                width={200}
                mb={{ xs: 2, md: 0 }}
              >
                <SubTitle icon={<ClockIcon />} text="Date & Time" />
              </Grid>
              <Grid item xs={12} md container direction="column" spacing={1}>
                {React.cloneElement(props.dateTime, {
                  editing: editing === 'dateTime',
                  setEditingEnabled,
                  onSave: () => {
                    setEditingEnabled(true)
                    setEditing('')
                  },
                })}
              </Grid>
              {editing !== 'dateTime' && props.dateTime && (
                <EditButton
                  disabled={
                    !props.canEditSession ||
                    !editingEnabled ||
                    props.isLoading ||
                    (props.customerCanEdit && props.isViewerCustomer)
                  }
                  onClick={() => setEditing('dateTime')}
                />
              )}
            </Grid>
          </Box>
          <Box {...boxProps}>
            <Grid container>
              <Grid
                item
                xs={12}
                md="auto"
                component={Box}
                width={200}
                mb={{ xs: 2, md: 0 }}
              >
                <SubTitle icon={<NoteIcon />} text="Other Notes" />
              </Grid>
              <Grid item xs={12} md container direction="column" spacing={1}>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {React.cloneElement(props.notes, {
                    editing: editing === 'notes',
                    setEditingEnabled,
                    onSave: () => {
                      setEditingEnabled(true)
                      setEditing('')
                    },
                  })}
                </div>
              </Grid>
              {editing !== 'notes' && props.notes && (
                <EditButton
                  disabled={
                    !props.canEditSession ||
                    !editingEnabled ||
                    props.isLoading ||
                    (!props.isViewerCustomer && !isPrivateEvent)
                  }
                  onClick={() => setEditing('notes')}
                />
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {confirmation && (
        <ConfirmationModal
          title={
            isPrivateEvent ? 'Remove Personal Event' : 'Cancelling Session'
          }
          message={
            isPrivateEvent
              ? 'Are you sure you want to make yourself available for customers during this time?'
              : 'Are you sure you want to cancel this session?'
          }
          {...(cancellationWarning && {
            note: cancellationWarning,
          })}
          handleConfirm={() => {
            setConfirmation(false)
            setCancelling(true)
            props.onCancel().finally(() => setCancelling(false))
          }}
          handleClose={() => setConfirmation(false)}
        />
      )}
    </Grid>
  )
}

BookingDetailsLayout.propTypes = {
  avatar: PropTypes.element,
  session: PropTypes.element,
  dateTime: PropTypes.element,
  notes: PropTypes.element,
  isPastEvent: PropTypes.bool,
  isIn24Hrs: PropTypes.bool,
  canEditSession: PropTypes.bool,
  eventType: PropTypes.string,
}

BookingDetailsLayout.defaultProps = {
  avatar: <Shimmer r="50%" h="100%" />,
  session: <Shimmer h={160} />,
  dateTime: <Shimmer h={48} />,
  notes: <Shimmer h={100} />,
}

export default BookingDetailsLayout

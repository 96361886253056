import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BackgroundStateContext } from '../../contexts/background-context'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles({
  rootContainer: {
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'baseline',
    justifyContent: 'space-around',
    '& > div': {
      borderRadius: '50%',
      height: ({ photoSize }) => photoSize,
      width: ({ photoSize }) => photoSize,
      margin: ({ photoSize }) => photoSize / 2,
      backgroundSize: 'cover',
      opacity: 0.6,
    },
  },
})

const BackgroundImages = () => {
  const { data: { photoUrls: images = [], photoSize } = {} } = React.useContext(
    BackgroundStateContext,
  )
  const [no, setNo] = React.useState(0)
  /** @type {object} @property {HTMLElement} current */
  const containerRef = React.useRef(null)

  const classes = useStyles({ photoSize })

  React.useEffect(() => {
    if (!containerRef.current) return
    const handleResize = () => {
      const { height, width } = containerRef.current.getBoundingClientRect()
      const dim = 2 * photoSize // margin is size / 2 so need to revert it
      setNo(Math.ceil(height / dim) * Math.ceil(width / dim))
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [photoSize])

  return (
    <div className={classes.rootContainer}>
      <div className={classes.root} ref={containerRef}>
        {Boolean(images.length) && no
          ? Array(no)
              .fill(null)
              .map((x, i) => i)
              .map(i => (
                <div
                  key={i}
                  style={{
                    backgroundImage: `url("${encodeURI(
                      images[i % images.length],
                    )}")`,
                  }}
                />
              ))
          : null}
      </div>
    </div>
  )
}

export default BackgroundImages

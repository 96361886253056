import Typography from '@material-ui/core/Typography'
import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../../components/inputs/button'
import { ReactComponent as CheckedIcon } from '../../../../assets/checked.svg'

const ChatSelect = props => {
  const { options, onChange, isMulti, isRequired } = props
  const [selected, setSelected] = React.useState([])

  const onChipClick = value => {
    if (isMulti) {
      if (selected.includes(value)) {
        setSelected(s => s.filter(i => i !== value))
      } else {
        setSelected(s => [...s, value])
      }
    } else {
      onChange &&
        onChange({
          type: 'text',
          message: value,
          rawText: options.find(i => i.value === value).text,
        })
    }
  }

  const onMultiConfirm = () => {
    onChange &&
      onChange({
        type: 'text',
        message: selected.join(' ') || 'skip',
        rawText:
          options
            .filter(i => selected.includes(i.value))
            .map(i => i.text)
            .join(', ') || 'Skip',
      })
  }

  const isSkippable = !isRequired && !selected.length

  return (
    <>
      <Grid container spacing={1}>
        {options.map(option => (
          <Grid item key={option.text}>
            <Chip
              variant={selected.includes(option.value) ? 'default' : 'outlined'}
              color="primary"
              label={option.text}
              clickable
              onClick={() => onChipClick(option.value)}
              {...(selected.includes(option.value) && {
                style: {
                  borderLeft: '1px solid transparent',
                  borderRight: '1px solid transparent',
                },
              })}
            />
          </Grid>
        ))}
      </Grid>
      {isMulti && (
        <Box display="block" mt="1em">
          <ApiariButton
            onClick={onMultiConfirm}
            disabled={!selected.length && isRequired}
          >
            {!isSkippable && (
              <Box mr="0.6em">
                <CheckedIcon />
              </Box>
            )}
            <Typography variant="button">
              {isSkippable ? 'Skip' : 'Confirm'}
            </Typography>
          </ApiariButton>
        </Box>
      )}
    </>
  )
}

ChatSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
}

ChatSelect.defaultProps = {
  options: [],
}

export default ChatSelect

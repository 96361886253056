import { flow, getRoot, types } from 'mobx-state-tree'
import {
  addToFavoritesApi,
  getUserFavoritesApi,
  loginApi,
  registerUserApi,
  removeFromFavoritesApi,
} from '../../api/auth-api'
import UserModel from './user-model'
import { reaction } from 'mobx'

const AuthModel = types
  .model('AuthModel', {
    user: types.maybeNull(types.reference(UserModel)),
    favorites: types.array(types.number),
  })
  .views(self => ({
    get isAuthenticated() {
      return self.user !== null
    },
    isInFavorites(id) {
      return self.favorites.includes(+id)
    },
    get populatedFavorites() {
      return self.favorites.map(id => getRoot(self).users.entries.get(id))
    },
    get isViewerCustomer() {
      return self.user?.role === 'customer'
    },
    getToggleFavoriteApi(id) {
      if (self.isInFavorites(id)) return removeFromFavoritesApi
      return addToFavoritesApi
    },
  }))
  .actions(self => ({
    afterCreate() {
      reaction(
        () => self.isAuthenticated,
        isAuthenticated => {
          if (isAuthenticated) {
            if (self.user.role === 'provider') {
              getRoot(self).events.getAllProvidersEvents(self.user.id)
            } else {
              getRoot(self).events.getAllCustomerEvents()
            }
          } else {
            getRoot(self).events.reset()
            getRoot(self).ws.reconnect()
          }
        },
      )
    },
    successAuth: flow(function* auth(user) {
      const { data: favorites } = yield getUserFavoritesApi(user.shortUserId)
      self.favorites = favorites
      getRoot(self).users.setUser(user)
      self.user = user.shortUserId
      if (!localStorage.getItem('apiari_token')) {
        localStorage.setItem('apiari_token', user.token)
      }
    }),
    reAuthenticateAction: flow(function* reAuthenticate() {
      if (window.location.pathname === '/become-a-provider') {
        getRoot(self).ui.setAfterLoginRoute('/become-a-provider')
      }
      const urlParams = new URLSearchParams(window.location.search)
      let qsToken = urlParams.get('token')
      let token = localStorage.getItem('apiari_token')
      if (!token && qsToken) {
        localStorage.setItem('apiari_token', qsToken)
        token = qsToken
      }
      if (qsToken) {
        urlParams.delete('token')
        const qs = urlParams.toString()
        const { protocol, host, pathname: path } = window.location
        const cleanUrl = `${protocol}//${host}${path}${qs ? `?${qs}` : ''}`
        window.history.replaceState({ path: cleanUrl }, '', cleanUrl)
      }
      if (!token) return getRoot(self).ui.setReAuthenticateState(false)
      try {
        const { data: user } = yield loginApi({ token })
        if (
          window.location.pathname === '/become-a-provider' &&
          user?.isActive &&
          (user?.becomingProviderComplete || user?.acceptedProviderTerms)
        ) {
          getRoot(self).ui.setAfterLoginRoute('/account')
        }
        yield self.successAuth(user)
      } catch (err) {
        localStorage.removeItem('apiari_token')
      } finally {
        getRoot(self).ui.setReAuthenticateState(false)
      }
    }),
    loginAction: flow(function* login(credentials, setErrors) {
      try {
        getRoot(self).ui.setAuthenticateState(true)
        const { data: user } = yield loginApi(credentials)
        if (
          user?.isActive &&
          user?.userRole === 'provider' &&
          (user?.becomingProviderComplete || user?.acceptedProviderTerms)
        ) {
          getRoot(self).ui.setAfterLoginRoute('/account')
        }
        yield self.successAuth(user)
        getRoot(self).ui.setAuthenticateState(false)
      } catch (err) {
        console.log(err)
        setErrors({
          login: 'Invalid credentials used, please try again.',
        })
      }
    }),
    registerAction: flow(function* register(userData, setErrors) {
      try {
        const { data: user } = yield registerUserApi(userData)
        yield self.successAuth(user)
      } catch (err) {
        setErrors(err)
      }
    }),
    createProviderAction: flow(function*(providerData, onSuccess, onError) {
      try {
        const { data: user } = yield registerUserApi(providerData)
        onSuccess(user)
      } catch (err) {
        onError(err)
      }
    }),
    setFavorites(id) {
      if (self.isInFavorites(id)) {
        self.favorites.splice(self.favorites.indexOf(+id), 1)
      } else {
        self.favorites.push(+id)
      }
    },
    toggleFavoriteAction: flow(function* toggleFavorite(providerId) {
      const api = self.getToggleFavoriteApi(providerId)
      self.setFavorites(providerId)
      try {
        yield api({ userId: self.user.id, providerId })
      } catch (e) {
        self.setFavorites(providerId)
      }
    }),
    logOutAction() {
      localStorage.removeItem('apiari_token')
      self.user = null
      getRoot(self).users.reset()
    },
  }))

export default AuthModel

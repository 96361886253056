import React from 'react'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import { DATE_FORMATS } from '../../../../utils/config'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import DetailsItem from '../details-item'
import ApiariButton from '../../../../components/inputs/button'
import { InfoOutlined, WarningOutlined } from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

const hasChanged = (instance, newValues) => {
  try {
    return (
      format(newValues.date, DATE_FORMATS.DATE) !== instance.startDate ||
      format(newValues.startTime, 'HH:mm') !== instance.startTime ||
      format(newValues.endTime, 'HH:mm') !== instance.endTime
    )
  } catch (err) {
    // likely due to user typing in a time manually before they have finished
    console.log(err)
    return false
  }
}

const ManageDateTime = props => {
  const { instance, onChange, setEditingEnabled, onSave } = props

  const [busy, setBusy] = React.useState(false)
  const [date, setDate] = React.useState(new Date(instance.start))
  const [startTime, setStartTime] = React.useState(instance.start)
  const [endTime, setEndTime] = React.useState(instance.end)

  const didChanged = hasChanged(instance, { date, startTime, endTime })

  React.useEffect(() => {
    setEditingEnabled(!didChanged)
  }, [didChanged]) // eslint-disable-line

  // const validTimeRange = +new Date(endTime) + 1 > +new Date(startTime)

  return (
    <>
      <Box position="absolute" right={10} top={8}>
        <Link
          color="primary"
          underline="none"
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={onSave}
        >
          Cancel
        </Link>
        <ApiariButton
          busy={busy}
          disabled={!didChanged}
          onClick={() => {
            setBusy(true)
            onChange({
              startDate: format(date, DATE_FORMATS.DATE),
              endDate: format(date, DATE_FORMATS.DATE),
              startTime: format(startTime, 'HH:mm'), //api use 24hr
              endTime: format(endTime, 'HH:mm'),
            })
          }}
        >
          save
        </ApiariButton>
      </Box>
      <DetailsItem title="Date">
        <KeyboardDatePicker
          size="small"
          inputVariant="outlined"
          value={date}
          onChange={d => setDate(new Date(d))}
          KeyboardButtonProps={{ size: 'small' }}
          autoOk
          disableToolbar
        />
      </DetailsItem>
      <DetailsItem title="Start time">
        <KeyboardTimePicker
          size="small"
          inputVariant="outlined"
          value={startTime}
          onChange={d => setStartTime(new Date(d))}
          keyboardIcon={<AccessTimeIcon />}
          KeyboardButtonProps={{ size: 'small' }}
          minutesStep={15}
        />
      </DetailsItem>
      <DetailsItem title="End time">
        <KeyboardTimePicker
          size="small"
          inputVariant="outlined"
          value={endTime}
          onChange={d => setEndTime(new Date(d))}
          keyboardIcon={<AccessTimeIcon />}
          KeyboardButtonProps={{ size: 'small' }}
          minutesStep={15}
        />
      </DetailsItem>
    </>
  )
}

export default ManageDateTime

import React from 'react'
import Calendar from '../../components/calendar/calendar'
import storeContext from '../../stores'
import { observer } from 'mobx-react-lite'
import useQueryString from '../../hooks/use-query-string'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Link } from '@reach/router'
import ProviderCalendarBox from './provider-calendar-link'
import ApiariButton from '../../components/inputs/button'

const MyCalendarPage = observer(({ alias, navigate, ...rest }) => {
  const store = React.useContext(storeContext)
  const qs = useQueryString(rest.location.search)
  const specifiedUserId = alias || qs.id
  const provider = specifiedUserId
    ? store.users.entries.get(specifiedUserId)
    : store.auth.user
  const id = provider ? provider.shortUserId : null
  const onEventClick = to => navigate(to)

  const otherProviders =
    specifiedUserId &&
    provider &&
    store.auth.populatedFavorites.filter(
      i => i && i.alias !== alias && qs.id !== i.id,
    )

  const events = specifiedUserId
    ? store.events.getProviderEvents(id)
    : store.events.loggedUserEvents

  React.useEffect(() => {
    if (store.auth.user) {
      if (store.auth.isViewerCustomer) {
        store.events.getAllCustomerEvents(store.auth.user.id)
      } else {
        store.events.getAllProvidersEvents(store.auth.user.id)
      }
    }
  }, [store])

  React.useEffect(() => {
    specifiedUserId && store.users.getUser(specifiedUserId)
    specifiedUserId && store.events.getAllProvidersEvents(id)
  }, [id, specifiedUserId, store])

  return (
    <>
      {specifiedUserId && provider && (
        <Box fontFamily="camptonBold" fontSize="2rem" align="center">
          {provider.firstName}'s Calendar
        </Box>
      )}
      <Box
        display="flex"
        my={2}
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        color="white"
      >
        {specifiedUserId && provider && (
          <>
            <a
              className="only-apiari-business"
              href={`mailto:${provider.email}?subject=Booking%20Request`}
            >
              <ApiariButton>Contact Me</ApiariButton>
            </a>

            <ApiariButton
              component={Link}
              to={`/chat-bot?startIntent=bookService&providerId=${provider.shortUserId}`}
              className="only-apiari-classic"
            >
              Request booking
            </ApiariButton>
          </>
        )}
      </Box>
      <Calendar
        events={events.map(e => e.calendarShape)}
        {...(provider && { businessHours: provider.businessHours })}
        onEventClick={onEventClick}
        onUiChange={store.ui.setCalendarUiState}
        defaultDate={store.ui.calendar.date}
        defaultView={store.ui.defaultCalendarView}
      />
      {otherProviders && !!otherProviders.length && (
        <span className="only-apiari-classic">
          <Box fontFamily="camptonBold" fontSize="1.3rem" my={2}>
            Other providers from your team:
          </Box>
          <Grid container spacing={2}>
            {otherProviders.map(fav => {
              if (fav.id === qs.id || fav.alias === alias) return null
              return (
                <Grid
                  item
                  component={Link}
                  to={`?id=${fav.id}`}
                  key={fav.id}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <ProviderCalendarBox provider={fav} />
                </Grid>
              )
            })}
          </Grid>
        </span>
      )}
    </>
  )
})

export default MyCalendarPage

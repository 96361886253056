import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as BulletIcon } from '../../../../../assets/bullet.svg'
import Rating from '@material-ui/lab/Rating'

const ProfileReviews = ({ reviews }) => {
  if (!reviews || reviews.length === 0) {
    return <></>
  }

  return (
    <>
      <Grid item container>
        <Box color="secondary.main" display="flex" alignItems="center">
          <BulletIcon width={22} />
        </Box>
        <Box ml={1} fontFamily="camptonBold" fontSize={18}>
          Reviews
        </Box>
      </Grid>
      <Grid item>
        <Box ml={3.6} mt={1.5} p={2} bgcolor="greyBlue.100" borderRadius={5}>
          {reviews
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .map((review, i) => (
              <Box key={review.date} {...(i < reviews.length - 1 && { mb: 3 })}>
                <Typography variant="body2">
                  {`“${review.text}” `}{' '}
                  {
                    <Rating
                      style={{ top: 3 }}
                      size="small"
                      disabled
                      value={review.rating || 5}
                      default={5}
                      precision={0.5}
                    />
                  }{' '}
                  {` - ${review.from}, ${review.date}`}
                </Typography>
              </Box>
            ))}
        </Box>
      </Grid>
    </>
  )
}

ProfileReviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      rating: PropTypes.number,
      createdTimestamp: PropTypes.string,
    }),
  ),
}

export default ProfileReviews

import PropTypes from 'prop-types'
import { Grid, Hidden, ButtonGroup, Button, Box } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { format } from 'date-fns'

const viewOptions = {
  dayGridMonth: {
    label: 'Month',
    format: 'MMMM yyyy',
  },
  timeGridWeek: {
    label: 'Week',
    format: 'MMMM dd',
  },
  timeGridDay: {
    label: 'Day',
    format: 'MMMM dd',
  },
  listWeek: {
    format: 'MMMM dd',
  },
}

function Toolbar({
  date,
  view,
  onDatePrev,
  onDateNext,
  onViewChange,
  onDateToday,
  ...rest
}) {
  const isAgenda = view === 'listWeek'
  return (
    <div {...rest}>
      <Grid alignItems="center" container spacing={3}>
        <Grid item xs="auto" component={Box} minWidth={{ sm: 250 }}>
          <ButtonGroup color="secondary">
            <Button onClick={onDatePrev} {...(isAgenda && { size: 'small' })}>
              <ArrowBackIos
                {...(isAgenda && { style: { height: 16, width: 16 } })}
              />
            </Button>
            <Button onClick={onDateToday} {...(isAgenda && { size: 'small' })}>
              <Box color="black" style={{ lineHeight: 2 }}>
                Today
              </Box>
            </Button>
            <Button onClick={onDateNext} {...(isAgenda && { size: 'small' })}>
              <ArrowForwardIos
                {...(isAgenda && {
                  style: {
                    height: 16,
                    width: 16,
                    position: 'relative',
                    left: 4,
                  },
                })}
              />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs>
          <Box
            textAlign="center"
            fontFamily="camptonBold"
            fontSize={{ xs: '0.9rem', sm: '2rem' }}
            {...(view !== 'dayGridMonth' && { pl: '55px' })}
          >
            {(view === 'timeGridWeek' || view === 'listWeek') && 'Week of '}
            {viewOptions[view] && format(date, viewOptions[view].format)}
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item component={Box} minWidth={{ sm: 250 }} xs="auto">
            <ButtonGroup color="secondary">
              {Object.keys(viewOptions).map(viewOption => {
                if (!viewOptions[viewOption].label) return null
                return (
                  <Button
                    onClick={() => onViewChange(viewOption)}
                    key={viewOption}
                  >
                    <Box
                      color={
                        viewOption === view ? 'text.secondary' : 'text.primary'
                      }
                    >
                      {viewOptions[viewOption].label}
                    </Box>
                  </Button>
                )
              })}
            </ButtonGroup>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

Toolbar.propTypes = {
  date: PropTypes.any.isRequired,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired,
}

export default Toolbar

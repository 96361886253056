import { useState, useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ApiariButton from './button'

import { IMAGE_FORMATS } from '../../utils/config'

const useStyles = makeStyles(theme => ({
  buttonYellow: {
    color: theme.palette.alert.dangerBg,
    backgroundColor: theme.palette.alert.dangerText,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '140px',
    lineHeight: '45px',
    padding: '0',
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    marginLeft: '-70px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      padding: '0 20px',
      marginLeft: '-40px',
    },
    '&:hover': {
      color: theme.palette.background.default,
    },
  },

  uploadWrapper: ({ isButtonMode, containsPreview }) => ({
    display: 'block',
    ...(!isButtonMode
      ? {
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          marginBottom: '25px',
          position: 'relative',
          borderRadius: '6px',
          borderWidth: '2px',
          borderStyle: 'dashed',
          display: 'flex',
          borderColor: (() =>
            containsPreview ? 'transparent' : theme.palette.border.lightGrey)(),
        }
      : {}),

    '& > div': {
      height: '100%',
    },
  }),
  uploadBtn: {
    background: theme.palette.background.lightBlue,
    color: theme.palette.background.default,
    minWidth: '174px',
    lineHeight: '50px',
    fontFamily: 'RobotoBold',
    fontSize: '15px',
    borderRadius: '24px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      marginRight: '10px',
    },
  },
  imgDim: {
    borderRadius: '50%',
    maxHeight: '100%',
    maxWidth: '100%',
    height: '300px',
    width: '300px',
  },
  fileName: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    padding: '0 25px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '600',
    color: theme.palette.text.darkGrey,
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
    alignItems: 'center',

    '& .MuiButton-containedPrimary': {
      background: 'none',
      padding: '0',
      color: theme.palette.alert.dangerText,
      minWidth: '0',

      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
}))

const ApiariUpload = ({
  text,
  icon,
  blank,
  file,
  inputProps = {},
  isButtonMode,
  uploadButton,
  setImageClearer,
  showPreview = false,
  onRemove = () => {},
}) => {
  const [image, setImage] = useState({ preview: '', raw: '' })
  const classes = useStyles({
    isButtonMode,
    containsPreview: Boolean(image.preview),
  })
  const fileInputRef = useRef(null)

  const clearImageInput = () => {
    fileInputRef.current.value = null
    onRemove()
  }

  const handleClear = e => {
    clearImageInput()
    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    if (file && !image.raw) {
      if (typeof file === 'string' && showPreview) {
        setImage({
          preview: file,
          raw: {
            name: `${inputProps.name}${file.slice(file.lastIndexOf('.'))}`,
          },
        })
      } else if (typeof file === 'string') {
        setImage({
          preview: '',
          raw: {
            name: `${inputProps.name}${file.slice(file.lastIndexOf('.'))}`,
          },
        })
      } else if (IMAGE_FORMATS.includes(file.type) && showPreview) {
        setImage({
          preview: URL.createObjectURL(file),
          raw: file,
        })
      } else {
        setImage({
          preview: '',
          raw: file,
        })
      }
    } else if (!file || !image.raw) {
      setImage({ preview: '', raw: '' })
    }
  }, [file, image.raw, showPreview, inputProps.name])

  useEffect(() => {
    setImageClearer && setImageClearer(() => clearImageInput)
  }, [setImageClearer])

  const handleChange = e => {
    if (e.target.files.length) {
      if (IMAGE_FORMATS.includes(e.target.files[0].type) && showPreview) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        })
      } else {
        setImage({
          preview: '',
          raw: e.target.files[0],
        })
      }
      if (inputProps.onChange) {
        inputProps.onChange(e.target.files[0])
      }
    }
  }

  return (
    <>
      <label htmlFor={inputProps.name} className={classes.uploadWrapper}>
        {!!image.preview && (
          <div>
            <img src={image.preview} alt="dummy" className={classes.imgDim} />

            <ApiariButton
              type="button"
              className={classes.buttonYellow}
              onClick={handleClear}
            >
              Remove Photo
            </ApiariButton>
          </div>
        )}
        {!image.raw &&
          (uploadButton ? (
            uploadButton
          ) : (
            <>
              <span className={`${classes.uploadBtn} ${classes[blank]}`}>
                {!!icon && icon}
                {!!text && text}
              </span>{' '}
            </>
          ))}
        {image.raw && !image.preview && (
          <span className={classes.fileName}>
            {image.raw.name}{' '}
            <ApiariButton type="button" onClick={handleClear}>
              <CloseIcon />
            </ApiariButton>
          </span>
        )}
      </label>
      <input
        type="file"
        id={inputProps.name}
        style={{ display: 'none' }}
        onChange={handleChange}
        name={inputProps.name}
        accept={inputProps.accept || '*'}
        ref={fileInputRef}
      ></input>
    </>
  )
}

ApiariUpload.prototype = {
  showPreview: PropTypes.bool,
}

export default memo(ApiariUpload)

import { applySnapshot, flow, types } from 'mobx-state-tree'
import {
  updateUserApi,
  getS3UrlApi,
  uploadToS3Api,
  likeProvider,
  dislikeProvider,
  descendProvider,
  ascendProvider,
} from '../../api/auth-api'
import { PETS_OPTIONS } from '../../utils/config'
import format from 'date-fns/format'
import yearsDiff from 'date-fns/differenceInYears'
import { WEEK_DAYS, serviceNameToHuman } from '../../utils/utils'

const UserModel = types
  .model('UserModel', {
    id: types.identifier,
    firstName: types.string,
    lastName: types.string,
    email: types.maybeNull(types.string),
    alias: types.maybeNull(types.string),
    shortUserId: types.number,
    role: types.maybeNull(types.string),
    numCats: types.maybeNull(
      types.union(types.number, types.string, types.undefined),
    ),
    numDogs: types.maybeNull(
      types.union(types.number, types.string, types.undefined),
    ),
    children: types.array(types.frozen()),
    smokingHousehold: types.maybeNull(types.boolean),
    hasPublicProfile: types.maybeNull(types.boolean),
    hasCar: types.maybeNull(types.boolean),
    phoneNumber: types.maybeNull(types.string),
    profilePic: types.maybeNull(types.string),
    address: types.frozen(),
    prices: types.frozen(),
    about: types.frozen(),
    funFacts: types.maybeNull(types.string),
    reviews: types.array(types.frozen()),
    services: types.array(types.frozen()),
    certificates: types.array(types.frozen()),
    rating: types.maybeNull(types.string),
    ratingCount: types.maybeNull(types.number),
    experienceSince: types.maybeNull(types.number),
    covidCommitments: types.array(types.frozen()),
    lastBackgroundCheck: types.maybeNull(types.number),
    gender: types.maybeNull(types.string),
    personalReferralCode: types.maybeNull(types.string),
    entranceInstructions: types.maybeNull(types.string),
    specialNeeds: types.maybeNull(types.string),
    dietaryRestrictions: types.frozen(),
    partner: types.maybeNull(types.frozen()),
    emergencyContact: types.maybeNull(types.frozen()),
    numberOfReferences: types.maybeNull(
      types.union(types.number, types.string, types.undefined),
    ),
    regularSchedule: types.maybeNull(types.frozen()),
    tosHistory: types.array(types.frozen()),
    references: types.maybeNull(types.array(types.frozen())),
    hasSpecialNeedsExperience: types.maybeNull(types.boolean),
    desirableCommutingTime: types.maybeNull(types.string),
    allowsCats: types.maybeNull(types.boolean),
    allowsDogs: types.maybeNull(types.boolean),
    speaksLanguages: types.maybeNull(types.array(types.string)),
    resume: types.maybeNull(types.string),
    CPRCertificate: types.maybeNull(types.string),
    multipleSiblingsExperience: types.maybeNull(types.string),
    identificationPhoto: types.maybeNull(types.string),
    quickIntro: types.maybeNull(types.string),
    extraService: types.maybeNull(types.string),
    zelleEmail: types.maybeNull(types.string),
    zellePhoneNumber: types.maybeNull(types.string),
    childcareAgeRange: types.maybeNull(types.frozen()),
    stripeToken: types.maybeNull(types.string),
    acceptedProviderTerms: types.maybeNull(types.boolean),
    becomingProviderComplete: types.maybeNull(types.boolean),
    becomingProviderLastStep: types.maybeNull(types.string),
    isActive: types.maybeNull(types.number),
    reliabilityScore: types.maybeNull(
      types.union(types.number, types.string, types.undefined),
    ),
    likedProviders: types.array(types.number),
    descendedProviders: types.array(types.number),
  })
  .views(self => ({
    get businessHours() {
      return (self.regularSchedule || []).map(day => {
        const dayOfWeek = {
          ...day,
          daysOfWeek: [WEEK_DAYS.indexOf(day.weekday)],
        }
        if (day.allDay) {
          dayOfWeek.startTime = '00:00'
          dayOfWeek.endTime = '24:00'
        }
        return dayOfWeek
      })
    },
    get fullAddress() {
      if (!self.address) return ''
      return `${self.address.streetAddress || ''} ${self.address.unit ||
        ''} ${self.address.city || ''} ${self.address.state || ''} ${self
        .address.zipcode || ''}`
    },
    getServiceNamesHumanMap() {
      return (self.services || []).reduce((a, s) => {
        a[s] = serviceNameToHuman(s)
        return a
      }, {})
    },
    get petsString() {
      return PETS_OPTIONS.find(
        i =>
          !!i.cond.numCats === !!self.numCats &&
          !!i.cond.numDogs === !!self.numDogs,
      ).value
    },
    get petsDisplay() {
      const pets = []
      if (Number(self.numDogs)) {
        pets.push('Dogs')
      }
      if (Number(self.numCats)) {
        pets.push('Cats')
      }
      return pets.length ? pets.join(' / ') : 'No Pets'
    },
    get childrenDisplay() {
      const children = self.children.map(child => {
        const { birthdayTimestamp, gender, name } = child
        const genderString = gender
          ? gender === 'male'
            ? ' boy'
            : ' girl'
          : ''
        let age =
          (+new Date() - birthdayTimestamp) / 1000 / 60 / 60 / 24 / 365.25
        if (age > 1) age = `${Math.round(age)} year old`
        else if (age > 1 / 12) age = `${Math.round(age * 12)} month old`
        else if (age > 0) age = `${Math.round(age * 52)} week old`
        else age = `expecting`
        return `${age}${genderString}${name ? ` named ${name}` : ''}`
      })
      return children.join(', ')
    },
    get fullName() {
      let name = self.firstName.trim()
      if (self.lastName) name += ` ${self.lastName.trim()}`
      return name
    },
    get lastInitials() {
      let name = self.firstName
      if (self.lastName) {
        if (self.hasPublicProfile) name += ` ${self.lastName}`
        else name += ` ${self.lastName[0].toUpperCase()}.`
      }
      return name
    },
    get initials() {
      return self.fullName
        .split(' ')
        .map(i => i[0].toUpperCase())
        .join('')
    },
    get formattedReviews() {
      return self.reviews.map(review => ({
        from: review.clientUserName,
        text: review.review,
        date: format(review.createdTimestamp, 'MMMM dd, yyyy'),
        rating: review.rating ? Number(review.rating) : null,
        createdTimestamp: review.createdTimestamp,
      }))
    },
    get formattedCertificated() {
      return self.certificates.map(cert => ({
        name: cert.name,
        date:
          cert.timestamp && cert.timestamp > 0
            ? format(cert.timestamp, 'MMMM dd, yyyy')
            : null,
      }))
    },
    getPrice(service) {
      return self.prices ? self.prices[service] : ''
    },
    getBio(service) {
      if (service) {
        return self.about[service === 'night_nurse' ? 'nightNurse' : service] // sorry for the hack -Adam
      }
      return (
        self.about.childcare ||
        self.about.childcarePlus ||
        self.about.nightNurse ||
        self.about.cleaning
      )
    },
    getQualifications(service) {
      let subTitle = ''
      if (service === 'night_nurse') subTitle = 'Night Nurse'
      else if (service === 'childcarePlus') subTitle = 'Enrichment'
      else if (service)
        subTitle = service.charAt(0).toUpperCase() + service.slice(1)
      const qualificationList = []
      if (self.experienceSince && self.experienceSince > 0) {
        const years = yearsDiff(new Date(), new Date(self.experienceSince))
        qualificationList.push({
          title: `${years} Years Experience`,
          subTitle,
        })
      }
      if (self.lastBackgroundCheck && self.lastBackgroundCheck > 0) {
        qualificationList.push({
          title: 'Background Check',
          subTitle: new Date(self.lastBackgroundCheck).toLocaleDateString(
            'en-US',
          ),
        })
      }
      if (self.hasCar) {
        qualificationList.unshift({
          title: 'Has Car',
        })
      }
      if (
        self.covidCommitments &&
        self.covidCommitments.length > 0 &&
        self.covidCommitments[self.covidCommitments.length - 1].action ===
          'accepted'
      ) {
        qualificationList.unshift({
          title: 'COVID Commitment',
          subTitle: 'Read More',
          subTitleLink: 'https://theapiari.com/community_commitments/',
          icon: '🎖',
        })
      }
      return qualificationList
    },
    getLikedProviders() {
      return self.likedProviders || []
    },
    getDescendedProviders() {
      return self.descendedProviders || []
    },
  }))
  .actions(self => ({
    updateUser: flow(function* updateUser(payload) {
      yield updateUserApi(self.id, payload)
      const { address, ...rest } = payload
      applySnapshot(self, {
        ...self,
        address: { ...self.address, ...address },
        ...rest,
      })
    }),
    uploadAction: flow(function*(file) {
      try {
        const {
          data: { writeUrl, publicUrl },
        } = yield getS3UrlApi(file)
        yield uploadToS3Api(file, writeUrl)
        return { publicUrl }
      } catch (err) {
        return { error: err }
      }
    }),
    // chargeCardAction: flow(function*(stripeToken) {
    //   try {
    //     const {
    //       data: { chargeId },
    //     } = yield chargeCardApi(stripeToken)
    //     return { chargeId }
    //   } catch (err) {
    //     return { error: err }
    //   }
    // }),
    updateProviderAction: flow(function*(providerData, onSuccess, onError) {
      try {
        let profilePic = providerData.profilePic || null,
          resume = providerData.resume || null,
          CPRCertificate = providerData.CPRCertificate || null,
          identificationPhoto = providerData.identificationPhoto || null

        // if (providerData.stripeToken) {
        //   const { chargeId, error } = yield self.chargeCardAction(
        //     providerData.stripeToken,
        //   )
        //   if (error) {
        //     return onError(error)
        //   }
        //   delete providerData.stripeToken;
        //   providerData.chargeId = chargeId
        // }

        if (
          providerData.profilePic &&
          typeof providerData.profilePic !== 'string'
        ) {
          const { publicUrl, error } = yield self.uploadAction(
            providerData.profilePic,
          )
          if (error) {
            return onError(error)
          }
          profilePic = publicUrl
        }
        if (providerData.resume && typeof providerData.resume !== 'string') {
          const { publicUrl, error } = yield self.uploadAction(
            providerData.resume,
          )
          if (error) {
            return onError(error)
          }
          resume = publicUrl
        }
        if (
          providerData.CPRCertificate &&
          typeof providerData.CPRCertificate !== 'string'
        ) {
          const { publicUrl, error } = yield self.uploadAction(
            providerData.CPRCertificate,
          )
          if (error) {
            return onError(error)
          }
          CPRCertificate = publicUrl
        }
        if (
          providerData.identificationPhoto &&
          typeof providerData.identificationPhoto !== 'string'
        ) {
          const { publicUrl, error } = yield self.uploadAction(
            providerData.identificationPhoto,
          )
          if (error) {
            return onError(error)
          }
          identificationPhoto = publicUrl
        }
        const updateResponse = yield updateUserApi(self.id, {
          ...providerData,
          profilePic,
          resume,
          CPRCertificate,
          identificationPhoto,
        })

        let error = updateResponse.error || updateResponse.data.error
        if (error) {
          return onError(error)
        }

        const { address, ...rest } = providerData
        const newUser = {
          ...self,
          address: { ...self.address, ...address },
          ...rest,
          profilePic,
          resume,
          CPRCertificate,
          identificationPhoto,
        }
        applySnapshot(self, newUser)
        onSuccess(newUser)
      } catch (err) {
        console.log(err)
        onError(err)
      }
    }),

    likeProvider: flow(function*({ providerId }) {
      try {
        const { data } = yield likeProvider({
          shortUserId: self.shortUserId,
          providerId,
        })

        applySnapshot(self, {
          ...self,
          likedProviders: data,
        })
      } catch (err) {
        return { error: err }
      }
    }),
    dislikeProvider: flow(function*({ providerId }) {
      try {
        const { data } = yield dislikeProvider({
          shortUserId: self.shortUserId,
          providerId,
        })

        applySnapshot(self, {
          ...self,
          likedProviders: data,
        })
      } catch (err) {
        return { error: err }
      }
    }),

    descendProvider: flow(function*({ providerId }) {
      try {
        const { data } = yield descendProvider({
          shortUserId: self.shortUserId,
          providerId,
        })

        applySnapshot(self, {
          ...self,
          descendedProviders: data,
        })
      } catch (err) {
        return { error: err }
      }
    }),
    ascendProvider: flow(function*({ providerId }) {
      try {
        const { data } = yield ascendProvider({
          shortUserId: self.shortUserId,
          providerId,
        })

        applySnapshot(self, {
          ...self,
          descendedProviders: data,
        })
      } catch (err) {
        return { error: err }
      }
    }),
  }))

export default UserModel

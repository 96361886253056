import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import ApiariInput from '../../../components/inputs/input'
import InputAdornment from '@material-ui/core/InputAdornment'
import AttachMoney from '@material-ui/icons/AttachMoney'
import { ProviderFormField } from '../become-a-provider-styles'
import Warning from '../../../assets/warning.svg'
import TextSlider from '../../../components/text-slider/text-slider'

// config
import { INTERESTS } from '../../../utils/config'
import { RATE_TIPS } from '../become-a-provider-config'

const useStyles = makeStyles(theme => ({
  rateTipsWrapper: {
    '& > div > div': {
      position: 'relative',
      fontStyle: 'normal',
      top: '0',
      right: 'auto',
      maxWidth: '100%',
      paddingBottom: '10px',
    },
    '& .MuiMobileStepper-root': {
      marginTop: '10px',
    },
  },
  rateContainer: {
    '& a:link': {
      color: theme.palette.stepper.active,
      fontSize: '14px',
      fontFamily: 'RobotoBold',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  warningWrapper: {
    background: theme.palette.border.light,
    padding: '7px 9px',
    fontSize: '12px',
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '22px',
    marginTop: '30px',
    color: theme.palette.text.darkGrey,
    '& img': {
      marginRight: '10px',
    },
  },
  label: {
    position: 'static',
    transform: 'none',
    color: 'black',
    marginLeft: '0',
    fontSize: '14px',
    fontFamily: 'Roboto',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  value: {
    color: 'black',
    fontSize: '18px',
    fontFamily: 'Roboto',
    display: 'block',
    padding: '10px 0',
    position: 'relative',
    fontWeight: '500',
    marginTop: '16px',
    '& > span': {
      fontSize: '16px',
      fontWeight: '500',
      color: theme.palette.text.lightGrey,
      marginLeft: '5px',
    },
  },
  rateInput: {
    fontFamily: 'Roboto',
    position: 'relative',

    '& label.MuiFormLabel-root.MuiInputLabel-formControl': {
      position: 'static',
      transform: 'none',
      color: 'black',
      marginLeft: '0',
      fontSize: '14px',
      fontFamily: 'Roboto',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    '& > span': {
      position: 'absolute',
      top: '45px',
      right: '20px',
      fontSize: '16px',
      fontWeight: '500',
      color: theme.palette.text.lightGrey,
      [theme.breakpoints.down('xs')]: {
        right: 'auto',
        left: '110px',
      },
    },
  },
  ratesWrapper: {
    '& .MuiInputBase-root': {
      maxWidth: '134px',

      '& input': {
        color: theme.palette.text.lightGrey,
        fontSize: '18px',
        fontWeight: '500',
        [theme.breakpoints.down('xs')]: {
          paddingRight: '40px',
        },
      },
      '& input:focus + .MuiInputAdornment-root': {
        color: theme.palette.border.active,
      },

      '& .MuiInputAdornment-root': {
        color: theme.palette.border.inactive,
      },
    },
  },
  tableHeading: {
    fontSize: '16px',
    fontFamily: 'RobotoBlack',
    lineHeight: '1',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },

    '& > div': {
      borderStyle: 'none none solid',
      borderWidth: '1px',
      borderColor: theme.palette.border.inactive,
      paddingBottom: '16px',
    },
  },
  subHead: {
    display: 'block',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    marginTop: '5px',
  },
}))

const HourlyRateForm = props => {
  const classes = useStyles()
  const {
    isSubmitting,
    errors,
    touched,
    values: { prices, interests },
  } = props

  const selectedInterests = INTERESTS.filter(
    item => interests.indexOf(item.value) > -1,
  )

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container className={classes.rateContainer}>
        <Grid item xs={12}>
          <Box
            px={{ sm: 0, md: 5 }}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            style={{
              textAlign: 'center',
            }}
            mb={6}
          >
            Set a client rate that gives you the take-home <br />
            rate you want!
            <span className={classes.subHead}>
              3% of what clients pay goes to pay for credit card processing
              fees. This is the LOWEST fee available anywhere.
            </span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={560} m="0 auto" className={classes.ratesWrapper}>
            <Grid container>
              <Box maxWidth={480} m="0 auto">
                <Grid container>
                  <Grid item xs={6} className={classes.tableHeading}>
                    <Box px={1} mx={{ sm: 1, md: 3 }} mb={3}>
                      Your clients will pay:
                    </Box>
                  </Grid>
                  <Grid item xs={6} className={classes.tableHeading}>
                    <Box px={1} mx={{ sm: 1, md: 3 }} mb={3}>
                      You will receive:
                    </Box>
                  </Grid>
                  {selectedInterests?.map(
                    ({ title, value, key }) =>
                      key !== 'housekeeping' && (
                        <React.Fragment key={value}>
                          <Grid item xs={6}>
                            <Box
                              px={1}
                              mx={{ sm: 1, md: 3 }}
                              className={classes.rateInput}
                            >
                              <ProviderFormField
                                component={ApiariInput}
                                disabled={isSubmitting}
                                name={`prices.${key}`}
                                label={title}
                                variant="standard"
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoney />
                                    </InputAdornment>
                                  ),
                                }}
                                error={errors[key] && touched[key]}
                                helperText={
                                  !!errors[key] && touched[key] && 'Required'
                                }
                              />
                              <span>/hr</span>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box px={1} mx={{ sm: 1, md: 3 }}>
                              <div className={classes.label}>{title}</div>
                              <div className={classes.value}>
                                {prices[key] !== ''
                                  ? numeral(prices[key] * (97 / 100)).format(
                                      '$0.00',
                                    )
                                  : '$0.00'}{' '}
                                <span>/hr</span>
                              </div>
                            </Box>
                          </Grid>
                        </React.Fragment>
                      ),
                  )}
                </Grid>
              </Box>
              <Grid item xs={12} className={classes.rateTipsWrapper}>
                <Box>
                  <TextSlider steps={RATE_TIPS} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(HourlyRateForm)

import { Button, styled } from '@material-ui/core'
import React from 'react'
import { CircularProgress } from '@material-ui/core'

const ApiariButton = props => {
  const {
    children: text,
    variant = 'contained',
    color = 'primary',
    busy,
    ...rest
  } = props

  return (
    <Button
      variant={variant}
      color={color}
      {...rest}
      {...(busy && {
        disabled: true,
        endIcon: <CircularProgress size={14} color="inherit" />,
      })}
    >
      {text}
    </Button>
  )
}

export const WhiteApiariButton = styled(ApiariButton)(({ theme }) => ({
  paddingRight: '20px',
  paddingLeft: '20px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',

  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#fff',
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}))

export default ApiariButton

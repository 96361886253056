import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { navigate as redirectTo, Redirect, Router } from '@reach/router'
import routes from './index'
import storeContext from '../stores'

const NotFound = ({ navigate, fallbackRoute, setAfterLoginRoute }) => {
  React.useEffect(() => {
    if (setAfterLoginRoute) {
      setAfterLoginRoute(`${window.location.pathname}${window.location.search}`)
    }
  }, [setAfterLoginRoute])

  React.useEffect(() => {
    navigate(fallbackRoute)
  }, [navigate, fallbackRoute])

  return null
}

const Routes = observer(() => {
  const { auth, ui } = React.useContext(storeContext)
  const routesToRender = auth.isAuthenticated
    ? routes[auth.user.role]
    : routes.public
  const fallbackRoute = auth.isAuthenticated ? '/chat-bot' : '/login'
  const isMount = React.useRef(true)

  React.useEffect(() => {
    isMount.current = false
    if (auth.isAuthenticated && ui.afterLoginRoute) {
      redirectTo(ui.afterLoginRoute)
      ui.setAfterLoginRoute(null)
    }
  }, [auth.isAuthenticated, ui])

  return (
    <>
      <Router primary={false} component={Fragment}>
        {routesToRender.map(route => {
          const Component = route.component
          if (!Component) return null
          return <Component path={route.path} key={route.path} />
        })}
        <Redirect from="/" to="/chat-bot" noThrow />
        <NotFound
          default
          fallbackRoute={fallbackRoute}
          {...(isMount.current &&
            !auth.isAuthenticated && {
              setAfterLoginRoute: ui.setAfterLoginRoute,
            })}
        />
      </Router>
    </>
  )
})

export default Routes

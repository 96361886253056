import React from 'react'
import * as PropTypes from 'prop-types'
import ApiariButton from '../../../../components/inputs/button'
import ChatBookCard from './chat-book-card'
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'

const PER_PAGE = 3

const useStyles = makeStyles(theme => ({
  paginationWrapper: {
    '& > *': {
      background: theme.palette.greyBlue[100],
      '&:not(button)': {
        borderRadius: 20,
        padding: 15,
      },
      '& + *': {
        marginTop: 16,
      },
    },
  },
}))

const ChatPagination = ({ items, onChange, isLikable }) => {
  const classes = useStyles()
  const [visibleItems, setVisibleItems] = React.useState(PER_PAGE)
  const itemsLeft = items.length - visibleItems
  const [requestedBooking, setRequestedBooking] = React.useState(false)

  const showMore = () => setVisibleItems(visibleItems + PER_PAGE)

  return (
    <div className={classes.paginationWrapper}>
      {items.slice(0, visibleItems).map(({ data, type }, index) => (
        <ChatBookCard
          key={index}
          index={index}
          isExpanded={true}
          isCollapsible={false}
          requestedBooking={requestedBooking}
          setRequestedBooking={setRequestedBooking}
          {...data}
          onChange={onChange}
          isLikable={isLikable}
        />
      ))}
      {items.length > visibleItems && (
        <ApiariButton variant="text" onClick={showMore} fullWidth>
          View {itemsLeft > PER_PAGE ? PER_PAGE : itemsLeft} more
          <ArrowIcon
            height={12}
            style={{ transform: 'rotate(-90deg)', marginLeft: 10 }}
          />
        </ApiariButton>
      )}
    </div>
  )
}

ChatPagination.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isLikable: PropTypes.bool,
}

export default ChatPagination

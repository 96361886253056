import ChatBotPage from '../pages/chat-bot/chat-bot-page'
import LoginPage from '../pages/login/login-page'
// import MyBookingsPage from '../pages/my-bookings/my-bookings-page'
import BookingDetailsPage from '../pages/booking-details/booking-details-page'
import AccountPage from '../pages/account/account-page'
import ForgotPasswordPage from '../pages/forgot-password/forgot-password-page'
import SetPasswordPage from '../pages/set-password/set-password-page'
import RegisterPage from '../pages/register/register-page'
import MyCalendarPage from '../pages/my-calendar/my-calendar'
import UserDetailsPage from '../pages/user-details/user-details'
import MyBookingsPage from '../pages/my-bookings/my-bookings-page'
import BecomeAProviderPage from '../pages/become-a-provider/become-a-provider-page'
import MyMembershipPage from '../pages/my-membership/my-membership'
import SubscriptionForm from '../pages/subscription-form/subscription-form'

const routes = [
  {
    title: 'How It Works',
    href: 'https://theapiari.com/how-it-works/',
    authGuard: false,
  },
  {
    title: 'Why Apiari',
    href: 'https://theapiari.com/why-apiari/',
    authGuard: false,
  },
  {
    title: 'Services',
    href: 'https://theapiari.com/#services',
    authGuard: false,
  },
  {
    title: 'Become a Provider',
    component: BecomeAProviderPage,
    provider: true,
    path: '/become-a-provider',
  },
  {
    title: 'Log In',
    component: LoginPage,
    authGuard: false,
    apiariBusiness: true,
    path: '/login',
  },
  {
    component: ForgotPasswordPage,
    authGuard: false,
    path: '/forgot-password',
  },
  {
    component: SetPasswordPage,
    authGuard: false,
    path: '/set-password/:resetCode',
  },
  {
    component: RegisterPage,
    authGuard: false,
    path: '/signup',
  },
  {
    title: 'Find Provider',
    component: ChatBotPage,
    authGuard: true,
    customer: true,
    path: '/new-session',
    qs: '?startIntent=bookService',
    reloadOnClick: true,
  },
  {
    title: 'My Team',
    component: ChatBotPage,
    authGuard: true,
    customer: true,
    path: '/new-session',
    reloadOnClick: true,
    qs: '?startIntent=bookFromMyTeam',
  },
  {
    title: 'My Calendar',
    component: MyCalendarPage,
    customer: true,
    provider: true,
    authGuard: true,
    path: '/calendar',
  },
  {
    component: MyCalendarPage,
    path: '/calendar/:alias',
  },
  {
    // title: 'My Bookings',
    component: MyBookingsPage,
    authGuard: true,
    customer: true,
    provider: true,
    path: '/my-bookings',
  },
  {
    component: AccountPage,
    authGuard: true,
    customer: true,
    provider: true,
    path: '/account',
  },
  {
    component: UserDetailsPage,
    path: '/user/:id',
  },
  {
    component: UserDetailsPage,
    path: '/user/:id/:serviceName',
  },
  {
    component: BookingDetailsPage,
    authGuard: true,
    customer: true,
    provider: true,
    path: '/my-bookings/:instanceId',
  },
  {
    title: 'Get Started',
    component: ChatBotPage,
    authGuard: false,
    path: '/chat-bot',
    reloadOnClick: true,
  },
  {
    title: 'Main Menu',
    component: ChatBotPage,
    customer: true,
    provider: true,
    authGuard: true,
    path: '/chat-bot',
    reloadOnClick: true,
  },
  {
    path: '/apply',
    component: () => {
      window.location.href = '/become-a-provider'
      return null
    },
  },
  {
    component: MyMembershipPage,
    authGuard: true,
    provider: true,
    path: '/my-membership',
  },
  {
    component: SubscriptionForm,
    authGuard: true,
    provider: true,
    path: '/subscribe',
  },
]
const apiariBusiness = window.location.host.indexOf('apiari.net') !== -1

if (apiariBusiness) {
  routes.push({
    path: '/',
    component: () => {
      window.location.href = 'https://www.apiari.net'
      return null
    },
  })
}

export default routes.reduce(
  (agg, el) => {
    agg.all.push(el)
    if (el.authGuard === false) {
      agg['public'].push(el)
      if (el.apiariBusiness) agg['business'].push(el)
    } else {
      if (!el.authGuard) agg['public'].push(el)
      if (!el.authGuard || el.customer) agg['customer'].push(el)
      if (!el.authGuard || el.provider) agg['provider'].push(el)
      if (el.apiariBusiness) agg['business'].push(el)
    }
    return agg
  },
  { all: [], public: [], customer: [], provider: [], business: [] },
)

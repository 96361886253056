import React from 'react'
import Box from '@material-ui/core/Box'

const ListItem = ({ children, first, last, only }) => {
  return (
    <Box mb={{ xs: 4, sm: 4, md: 0 }}>
      <Box
        border={1}
        borderRadius={{ xs: '8px', md: 0 }}
        {...(first ? { // prettier-ignore
          borderRadius: { xs: '8px', md: '8px 8px 0 0' },
        } : {
          borderTop: [1, 1, 0],
        })} // prettier-ignore
        {...(last && {
          borderRadius: { xs: '8px', md: '0 0 8px 8px' },
        })}
        {...(only && {
          borderRadius: { xs: '8px', md: '8px 8px 8px 8px' },
        })}
        style={{ borderColor: 'rgba(224, 224, 224, 1)' }}
      >
        <Box display={{ md: 'flex' }}>{children}</Box>
      </Box>
    </Box>
  )
}

export default ListItem

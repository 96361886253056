// handleClose, title, message, handleConfirm, note
import React from 'react'
import { useState } from 'react'
import DeleteAccountConfirmationModal from './delete-account-confirmation-modal'
import Box from '@material-ui/core/Box'
import { requestAccountDelete } from '../../../api/auth-api'
import AccountChangeStatusModal from './account-change-status-modal'

const DeleteAccountSection = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [displayStatusModal, setDisplayStatusModal] = useState(false)
  const [successState, setSuccessState] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const onDeleteHandler = async () => {
    setIsInProgress(true)
    await requestAccountDelete({ userId: user.id })
      .then(() => setSuccessState(true))
      .catch(() => {
        setSuccessState(false)
        console.log('error deleteing account')
      })
    setIsInProgress(false)
  }

  return (
    <div>
      {isModalOpen && (
        <DeleteAccountConfirmationModal
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={async () => {
            await onDeleteHandler()
            setIsModalOpen(false)
            setDisplayStatusModal(true)
          }}
          title="Delete Account"
          message="Are you sure you want to delete your account permanently?"
          apiRequestInProgress={isInProgress}
        />
      )}
      {displayStatusModal && (
        <AccountChangeStatusModal
          handleClose={() => setDisplayStatusModal(false)}
          type={successState ? 'success' : 'error'}
          message={
            successState
              ? 'Got it. We have received your request and will delete your account and all of the information associated with it as soon as possible. Please note that it may take up to 30 days for this request to be completed.'
              : 'Something went wrong. Please try again later.'
          }
        />
      )}
      <Box fontFamily="camptonBold">
        <span
          onClick={() => setIsModalOpen(true)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          Delete Account Permanently
        </span>
      </Box>
    </div>
  )
}

export default DeleteAccountSection

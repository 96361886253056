import React from 'react'
import * as PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import Avatar from '@material-ui/core/Avatar'
import BookingDetailsLayout from './components/booking-details-layout'
import SessionDetails from './components/session/session-details'
import DateTimeDetails from './components/date-time/date-time-details'
import NotesDetails from './components/notes/notes-details'
import storeContext from '../../stores'

const BookingDetailsPage = observer(props => {
  const { instanceId, navigate, onCancel } = props
  const store = React.useContext(storeContext)

  const instanceData = store.events.entries.get(instanceId)

  if (!instanceData) return <BookingDetailsLayout isLoading />

  console.log('instanceData = ', instanceData)

  const handleCancelSession = () => {
    return instanceData.cancelSession().then(() => {
      if (onCancel) {
        onCancel()
      } else {
        navigate('/calendar')
      }
    })
  }
  const profilePic = store.auth.isViewerCustomer
    ? instanceData.provider && instanceData.provider.profilePic
    : instanceData.customer && instanceData.customer.profilePic
  const isPrivateEvent = instanceData.eventType === 'private'
  const isInterview = instanceData.eventType === 'interview'
  const isAppointment = instanceData.eventType === 'appointment'
  let canEditSession
  if (store.auth.isViewerCustomer) {
    canEditSession =
      instanceData.customer.id === store.auth.user.id &&
      instanceData.customerCanEdit
  } else {
    canEditSession =
      instanceData.provider.id === store.auth.user.id &&
      (isPrivateEvent ||
        (isAppointment && instanceData.wasInLast12Hours) ||
        (isAppointment && instanceData.isIn24Hrs) ||
        (isInterview && !instanceData.isPastEvent))
    console.log(
      'can edit?',
      instanceData.provider.id,
      store.auth.user.id,
      canEditSession,
      isInterview,
      instanceData.isIn24Hrs,
      instanceData.isPastEvent,
    )
  }
  return (
    <BookingDetailsLayout
      session={
        isPrivateEvent ? (
          <></>
        ) : (
          <SessionDetails
            instance={instanceData}
            isViewerCustomer={store.auth.isViewerCustomer}
          />
        )
      }
      isViewerCustomer={store.auth.isViewerCustomer}
      dateTime={
        <DateTimeDetails
          instance={instanceData}
          isViewerCustomer={store.auth.isViewerCustomer}
        />
      }
      notes={<NotesDetails instance={instanceData} />}
      avatar={profilePic ? <Avatar src={profilePic} /> : <></>}
      onCancel={
        !instanceData.isCancelled &&
        !instanceData.isPastEvent &&
        (!instanceData.isPendingConfirmation || store.auth.isViewerCustomer) && // providers can't cancel pending sessions this way
        handleCancelSession
      }
      isPastEvent={instanceData.isPastEvent}
      isIn24Hrs={instanceData.isIn24Hrs}
      canEditSession={canEditSession}
      eventType={instanceData.eventType}
      title={instanceData.title}
    />
  )
})

export const bookingDetailsPropTypes = {
  instanceId: PropTypes.string,
  isViewerCustomer: PropTypes.bool,
}

BookingDetailsPage.propTypes = bookingDetailsPropTypes

export default BookingDetailsPage

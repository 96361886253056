import { memo, useState } from 'react'
import {
  Grid,
  Box,
  FormHelperText,
  ListItemText,
  List,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AvatarCropper from '../../../components/avatar-cropper'
import ApiariUpload from '../../../components/inputs/upload'
import Upload from '../../../assets/cloud-upload.svg'
import Bulb from '../../../assets/blub-blue.svg'
import GroupPhoto from '../../../assets/images/group-photo.jpg'

const useStyles = makeStyles(theme => ({
  imageUpload: {
    height: '390px',
  },

  photoEx: {
    padding: '8px 10px 15px 18px',
    background: theme.palette.background.xlightBlue,
    maxWidth: '260px',
    borderRadius: '27px',
    top: '332px',
    position: 'absolute',
    right: 'calc(50% - 580px)',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      maxWidth: '100%',
      marginTop: '30px',
    },
    '& img': {
      marginBottom: '10px',
      maxWidth: '100%',
    },
  },

  subHead: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: theme.palette.text.lightGrey,
  },

  fileUpload: {
    height: '80px',
    position: 'relative',
    '& a:any-link': {
      color: theme.palette.border.active,
      textDecoration: 'underline',
      fontWeight: '500',
    },
    '& > label > span': {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      background: 'transparent',
      fontSize: '16px',
      color: theme.palette.text.lightGrey,
    },
  },
  sideHeading: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '600',
  },
  photoExListing: {
    '& .MuiTypography-root': {
      fontFamily: 'RobotoBold',
      fontSize: '14px',
      color: theme.palette.text.lightBlue,
      paddingLeft: '15px',
      position: 'relative',
      lineHeight: '24px',

      '&::after': {
        content: '""',
        height: '7px',
        width: '7px',
        display: 'inline-flex',
        position: 'absolute',
        left: '0',
        top: '8px',
        borderRadius: '50%',
        background: theme.palette.text.lightBlue,
      },
    },
  },
  photoUpload: {
    '& .MuiBox-root .MuiFormHelperText-root': {
      marginBottom: '0',
      marginTop: '20px',
    },
  },
}))

const UploadForm = ({ touched, errors, setFieldValue, values }) => {
  const classes = useStyles()
  const [isAvatarCropperOpen, setIsAvatarCropperOpen] = useState(false)
  const [profilePictureFile, setProfilePictureFile] = useState(null)
  const [imageClearer, setImageClearer] = useState(null)

  return (
    <Box maxWidth={600} m="0 auto" className={classes.photoUpload}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
          >
            Make a great first impression. Upload a friendly photo of yourself.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} className={classes.imageUpload}>
            <ApiariUpload
              showPreview
              text="Upload Photo"
              icon={<img src={Upload} alt="upload" />}
              file={values.profilePic}
              setImageClearer={setImageClearer}
              inputProps={{
                name: 'profilePic',
                accept: 'image/*',
                onChange: file => {
                  setProfilePictureFile(file)
                  setIsAvatarCropperOpen(true)
                },
              }}
              onRemove={() => {
                setFieldValue('profilePic', null, false)
              }}
            />
            {isAvatarCropperOpen && (
              <AvatarCropper
                title="Edit Photo"
                file={profilePictureFile}
                handleClose={() => {
                  setIsAvatarCropperOpen(false)
                  setProfilePictureFile(null)

                  imageClearer && imageClearer()
                }}
                onSave={file => {
                  setFieldValue('profilePic', file, false)
                  setIsAvatarCropperOpen(false)
                }}
              />
            )}
          </Box>
          <Box px={1}>
            {touched.profilePic && !!errors.profilePic && (
              <FormHelperText>{errors.profilePic}</FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={7.5}
          >
            Upload a resume <span className={classes.subHead}>(optional)</span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box px={1} className={classes.fileUpload}>
            <ApiariUpload
              text={
                <span>
                  Drag &amp; drop file or <a href={null}>browse</a>
                </span>
              }
              file={values.resume}
              inputProps={{
                name: 'resume',
                onChange: file => {
                  setFieldValue('resume', file, false)
                },
              }}
              onRemove={() => {
                setFieldValue('resume', null, false)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={{ xs: 16, md: 20 }}
            fontFamily="RobotoBlack"
            color="text.darkGrey"
            mb={3.5}
            mt={7.5}
          >
            Upload your CPR certificate{' '}
            <span className={classes.subHead}>(optional)</span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3.5} px={1} className={classes.fileUpload}>
            <ApiariUpload
              text={
                <span>
                  Drag &amp; drop file or <a href={null}>browse</a>
                </span>
              }
              file={values.CPRCertificate}
              inputProps={{
                name: 'CPRCertificate',
                onChange: file => {
                  setFieldValue('CPRCertificate', file, false)
                },
              }}
              onRemove={() => {
                setFieldValue('CPRCertificate', null, false)
              }}
            />
          </Box>
        </Grid>
        <div className={classes.photoEx}>
          <Grid item xs={12}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <img src={Bulb} alt="Bulb" />
                </Grid>
                <Grid item xs={12}>
                  <img src={GroupPhoto} alt="Bulb" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <div className={classes.sideHeading}>
                What makes a great photo?
              </div>
              <List className={classes.photoExListing}>
                <ListItemText primary="Natural light" />
                <ListItemText primary="In focus" />
                <ListItemText primary="No special effects" />
                <ListItemText primary="Smile!" />
              </List>
            </Box>
          </Grid>
        </div>
      </Grid>
    </Box>
  )
}

export default memo(UploadForm)

import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Add } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../../components/inputs/button'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { DatePicker } from '@material-ui/pickers'
import addYears from 'date-fns/addYears'
import format from 'date-fns/format'
import ApiariInput from '../../../../components/inputs/input'
import useChildrenManagement from '../../../../hooks/use-children-management'
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg'

const childrenRaw = child => {
  let raw = ''
  if (child.name) raw += `${child.name}, `
  if (child.gender === 'male') raw += 'Boy, '
  else if (child.gender === 'female') raw += 'Girl, '
  raw += `${format(child.birthday, 'MMM yyyy')}\n`
  return raw
}

const useStyles = makeStyles(theme => ({
  datepicker: {
    width: '100%',
    background: '#f8fafd',
    borderRadius: theme.spacing(1.25),
    '& input': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  navigation: {
    cursor: 'pointer',
  },
}))

const ChildSelector = ({ onSubmit, initChildrens, onClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    child,
    childrens,
    dispatch,
    showNext,
    showPrevious,
    allChildrensValid,
    singeChildrenValid,
  } = useChildrenManagement(initChildrens)

  return (
    <Dialog
      PaperProps={{
        style: {
          marginTop: '96px',
          ...(!initChildrens
            ? {
                height: 'calc(calc(100vh - 65px) * 0.9)',
              }
            : {
                paddingBottom: '20px',
              }),
          maxWidth: '600px',
        },
      }}
      {...(initChildrens && {
        onClose,
      })}
      open
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent>
        <Grid container alignItems="center">
          <Grid
            item
            component={Box}
            color="primary.main"
            visibility={showPrevious ? 'visible' : 'hidden'}
          >
            <ArrowBackIos
              fontSize="small"
              className={classes.navigation}
              onClick={() => dispatch({ type: 'PREVIOUS' })}
            />
          </Grid>
          <Grid item xs>
            <Box textAlign="center" fontSize="1rem" fontFamily="camptonBold">
              {initChildrens
                ? "Edit childrens' details"
                : 'Who is this session for?'}{' '}
              🧒👶
            </Box>
          </Grid>
          <Grid
            item
            component={Box}
            color="primary.main"
            visibility={showNext ? 'visible' : 'hidden'}
          >
            <ArrowForwardIos
              fontSize="small"
              className={classes.navigation}
              onClick={() => dispatch({ type: 'NEXT' })}
            />
          </Grid>
        </Grid>
        <Box maxWidth={300} mx="auto">
          <Box pt={4}>
            <Box fontSize="1rem" fontFamily="camptonBold">
              Birth / Due Date
            </Box>
            <Box position="relative" color="primary.main">
              <DatePicker
                openTo="year"
                views={['year', 'month']}
                maxDate={addYears(new Date(), 1)}
                inputVariant="outlined"
                value={child.birthday}
                placeholder="Date"
                onChange={d =>
                  dispatch({ type: 'CHANGE_BIRTHDAY', payload: new Date(d) })
                }
                className={classes.datepicker}
                autoOk
                disableToolbar
                keyboardIcon={<CalendarIcon />}
              />
              <Box
                position="absolute"
                top={10}
                right={16}
                style={{ pointerEvents: 'none' }}
              >
                {CalendarIcon && <CalendarIcon />}
              </Box>
            </Box>
          </Box>
          <Box pt={2}>
            <Box fontSize="1rem" fontFamily="camptonBold">
              Gender
            </Box>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={child.gender}
                onChange={e => {
                  dispatch({
                    type: 'CHANGE_GENDER',
                    payload: e.target.value || null,
                  })
                }}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" size="small" />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" size="small" />}
                  label="Male"
                />
                <FormControlLabel
                  value={null}
                  control={<Radio color="primary" size="small" />}
                  label="Unspecified"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box pt={2}>
            <Box fontSize="1rem" fontFamily="camptonBold">
              Name (Optional)
            </Box>
            <Box>
              <ApiariInput
                value={child.name}
                onChange={e =>
                  dispatch({ type: 'CHANGE_NAME', payload: e.target.value })
                }
                placeholder="name"
              />
            </Box>
          </Box>
          <Box pt={4}>
            <ApiariButton
              variant="text"
              color="primary"
              startIcon={<Add fontSize="small" />}
              disabled={!allChildrensValid}
              onClick={() => dispatch({ type: 'ADD_CHILD' })}
            >
              <Box position="relative" top={2}>
                Add another
              </Box>
            </ApiariButton>
          </Box>
          <Box pt={4}>
            <ApiariButton
              fullWidth
              disabled={!singeChildrenValid}
              onClick={() =>
                onSubmit({
                  type: 'children',
                  rawText: childrens.reduce((agg, child) => {
                    agg += childrenRaw(child)
                    return agg
                  }, ``),
                  message: childrens,
                })
              }
            >
              {initChildrens ? 'Save' : 'Done'}
            </ApiariButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ChildSelector

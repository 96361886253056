import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Down from '@material-ui/icons/ExpandMoreSharp'

const ApiariSelect = ({ options, label, ...props }) => {
  return (
    <Select
      {...props}
      IconComponent={Down}
      fullWidth
      {...(!!label && { displayEmpty: true })}
    >
      {label && <MenuItem value="">{label}</MenuItem>}
      {options.map(({ value, title, disabled }, index) => (
        <MenuItem value={value} key={value + index} disabled={disabled}>
          {title}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ApiariSelect

import * as PropTypes from 'prop-types'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { DialogContent, Dialog } from '@material-ui/core'

function WidgetLayout({ children }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      PaperProps={{
        style: {
          marginTop: '96px',
          height: 'calc(calc(100vh - 65px) * 0.9)',
          maxWidth: '600px',
        },
      }}
      open
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

WidgetLayout.propTypes = {
  children: PropTypes.element,
}

export default WidgetLayout

import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timelinePlugin from '@fullcalendar/timeline'
import { useTheme, useMediaQuery } from '@material-ui/core'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/list/main.css'
import Toolbar from '../../components/calendar/toolbar'
import DialogContent from '@material-ui/core/DialogContent'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import Dialog from '@material-ui/core/Dialog'
import useStyles from './calendar-styles'

function Calendar(props) {
  const {
    events,
    onEventClick,
    defaultView,
    defaultDate,
    businessHours,
    onUiChange,
  } = props

  const classes = useStyles()
  const calendarRef = React.useRef(null)
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const [view, setView] = React.useState(
    mobileDevice ? 'timeGridDay' : defaultView,
  )
  const [date, setDate] = React.useState(
    defaultDate ? new Date(defaultDate) : new Date(),
  )

  const [eventModal, setEventModal] = React.useState({
    open: false,
    event: null,
  })

  React.useEffect(() => {
    onUiChange({ date, view })
  }, [date, view, onUiChange])

  const handleEventClick = info => {
    console.log('info = ', info)
    console.log('info.event = ', info.event)
    info.jsEvent.preventDefault()
    if (!info.event.url) return false
    return onEventClick(info.event.url)
    // setEventModal({
    //   open: true,
    //   event: selected,
    // })
  }

  // const handleEventNew = () => {
  //   setEventModal({
  //     open: true,
  //     event: null,
  //   })
  // }

  // const handleEventDelete = event => {
  //   setEvents(currentEvents => currentEvents.filter(e => e.id !== event.id))
  //   setEventModal({
  //     open: false,
  //     event: null,
  //   })
  // }

  const handleModalClose = () => {
    setEventModal({
      open: false,
      event: null,
    })
  }

  // const handleEventAdd = event => {
  //   setEvents(currentEvents => [...currentEvents, event])
  //   setEventModal({
  //     open: false,
  //     event: null,
  //   })
  // }

  // const handleEventEdit = event => {
  //   setEvents(currentEvents =>
  //     currentEvents.map(e => (e.id === event.id ? event : e)),
  //   )
  //   setEventModal({
  //     open: false,
  //     event: null,
  //   })
  // }

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi()

    calendarApi.today()
    setDate(calendarApi.getDate())
  }

  const handleViewChange = newView => {
    const calendarApi = calendarRef.current.getApi()

    calendarApi.changeView(newView)
    setView(newView)
  }

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi()

    calendarApi.prev()
    setDate(calendarApi.getDate())
  }

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi()

    calendarApi.next()
    setDate(calendarApi.getDate())
  }

  React.useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    const newView = mobileDevice ? 'timeGridDay' : defaultView

    calendarApi.changeView(newView)
    setView(newView)
  }, [mobileDevice, defaultView])

  return (
    <div className={classes.root}>
      <Toolbar
        date={date}
        onDateNext={handleDateNext}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        // onEventAdd={handleEventNew}
        onViewChange={handleViewChange}
        view={view}
      />
      <br />
      <FullCalendar
        allDayMaintainDuration
        defaultDate={date}
        defaultView={view}
        eventClick={handleEventClick}
        events={events}
        header={false}
        {...(businessHours && { businessHours })}
        height={view !== 'timeGridDay' ? 1150 : 'auto'}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          timelinePlugin,
        ]}
        ref={calendarRef}
        rerenderDelay={10}
        weekends
      />
      {eventModal.open && (
        <Dialog
          open
          onClose={handleModalClose}
          maxWidth="md"
          fullScreen={false}
          fullWidth
        >
          <DialogContent>
            <CloseIcon className={classes.close} onClick={handleModalClose} />
            TODO
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default Calendar

import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as BulletIcon } from '../../../../../assets/bullet.svg'

const ProfileAbout = ({ bio, funFacts }) => {
  return (
    <>
      <Grid item container>
        <Box color="secondary.main" display="flex" alignItems="center">
          <BulletIcon width={22} />
        </Box>
        <Box ml={1} fontFamily="camptonBold" fontSize={18}>
          About
        </Box>
      </Grid>
      <Grid item>
        <Box ml={3.6} mt={1.5}>
          <Box fontSize={14} fontFamily="camptonBold">
            Experience
          </Box>
          <Box whiteSpace="pre-line">
            <Typography variant="body2">{bio}</Typography>
          </Box>
          <Box fontSize={14} fontFamily="camptonBold" mt={1}>
            Fun Facts
          </Box>
          <Box whiteSpace="pre-line">
            <Typography variant="body2">
              <span className="only-apiari-classic">“{funFacts}”</span>
              <span className="only-apiari-business">{funFacts}</span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

ProfileAbout.propTypes = {
  bio: PropTypes.string.isRequired,
  funFacts: PropTypes.string.isRequired,
}

export default ProfileAbout

import React from 'react'
import { Link } from '@reach/router'
import Box from '@material-ui/core/Box'
import ChevronRight from '@material-ui/core/SvgIcon/SvgIcon'

const ListItemAction = ({ instance }) => {
  return (
    <Box
      maxHeight={{ md: 20 }}
      display="flex"
      justifyContent={{ md: 'flex-end' }}
      alignItems="center"
      component={Link}
      to={`/my-bookings/${instance.instanceId}`}
      color="primary.main"
    >
      <Box fontWeight={700} fontFamily="camptonBold">
        DETAILS
      </Box>
      <ChevronRight />
    </Box>
  )
}

export default ListItemAction

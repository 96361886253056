import React from 'react'
import * as PropTypes from 'prop-types'
import Rating from '@material-ui/lab/Rating'
import Avatar from '@material-ui/core/Avatar'
import ProfileActions from './components/profile-actions'
import ProfileQualifications from './components/profile-qualifications'
import ProfileCertificates from './components/profile-certificates'
import ProfileReviews from './components/profile-reviews'
import ProfileAbout from './components/profile-about'
import ProviderNameRate from '../name-rate/name-rate'
import ProviderDetailsLayout from './components/details-layout'
import ApiariButton from '../../../../components/inputs/button'
import Box from '@material-ui/core/Box'
import storeContext from '../../../../stores'
import ShareButtons from './components/share-buttons'
import { serviceNameToHuman } from '../../models/profile'

const UserDetails = props => {
  const store = React.useContext(storeContext)
  const isViewerProvider =
    store.auth.user && store.auth.user.role === 'provider'
  const { user, onRequestBooking, service, onInterview } = props

  if (!user) return <ProviderDetailsLayout />

  return (
    <ProviderDetailsLayout
      avatar={<Avatar src={user.profilePic} />}
      nameAndRate={
        <ProviderNameRate
          name={user.lastInitials}
          rate={user.getPrice(service)}
        />
      }
      rating={
        <Rating
          disabled
          value={Number(user.rating)}
          precision={0.5}
          name="rating"
        />
      }
      ratingVal={Number(user.rating)}
      reliabilityScore={
        <Rating
          value={user.reliabilityScore}
          max={5}
          disabled
          precision={0.5}
          name="reliabilityScore"
        />
      }
      reliabilityScoreVal={Math.min(10, user.reliabilityScore) * 10}
      about={
        <ProfileAbout bio={user.getBio(service)} funFacts={user.funFacts} />
      }
      reviews={<ProfileReviews reviews={user.formattedReviews} />}
      reviewCount={user.formattedReviews.length + user.ratingCount}
      actions={
        <ProfileActions
          onInterview={isViewerProvider ? null : onInterview}
          id={user.id}
          alias={user.alias}
        />
      }
      classicCta={
        <>
          <ApiariButton
            onClick={isViewerProvider ? null : onRequestBooking}
            style={{ 'min-width': '100px ' }}
          >
            BOOK
          </ApiariButton>
        </>
      }
      businessCta={
        <a href={`mailto:${user.email}?subject=Booking%20Request`}>
          <ApiariButton>Contact Me</ApiariButton>
        </a>
      }
      certificates={
        <Box pl={2}>
          <ProfileCertificates certificates={user.formattedCertificated} />
        </Box>
      }
      qualifications={
        <Box pl={2}>
          <ProfileQualifications
            qualifications={user.getQualifications(service)}
          />
        </Box>
      }
      shareButtons={
        <ShareButtons
          blurb={user.getBio(service)}
          profilePic={user.profilePic}
          name={`${user.lastInitials} on Apiari`}
          profileUrl={`${process.env.REACT_APP_WEB_URL}/user/${
            user.alias
          }/${service || ''}`}
          gender={user.gender}
          humanServiceName={serviceNameToHuman(service).toLowerCase()}
        />
      }
    />
  )
}

export const userDetailsPropTypes = {
  user: PropTypes.object,
  service: PropTypes.string.isRequired,
  onRequestBooking: PropTypes.func.isRequired,
  onInterview: PropTypes.func,
}
UserDetails.propTypes = userDetailsPropTypes

export default UserDetails

import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import storeContext from '../../stores'
import PaymentInfo from './components/paymentInfo'
import MembershipInfo from './components/membershipInfo'
import { ReactComponent as EmailIcon } from '../../assets/ic-email.svg'
import { getMembershipStatus } from '../../api/provider-api'
import DeleteAccountSection from './components/delete-account'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const MyMembershipPage = () => {
  const [paymentInfo, setPaymentInfo] = React.useState(null)
  const [membershipInfo, setMembershipInfo] = React.useState(null)
  const STRIPE_LOAD = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

  const {
    auth: { user },
  } = React.useContext(storeContext)

  React.useEffect(() => {
    ;(async () => {
      fetchMembership()
    })()
  }, [user])
  const fetchMembership = async () => {
    const { data } = await getMembershipStatus({ email: user.email })
    if (data.success === true) {
      setPaymentInfo(data.paymentInfo)
      const renewDate = new Date(data.membershipInfo.autoRenew * 1000)
      setMembershipInfo({ ...data.membershipInfo, date: renewDate })
    }
  }
  return (
    <Box borderRadius={8} border={1} borderColor="greyBlue.100" p={4}>
      <Grid container spacing={8} direction="column">
        <Elements stripe={STRIPE_LOAD}>
          <PaymentInfo
            paymentInfo={paymentInfo}
            fetchMembership={fetchMembership}
          />
        </Elements>
        <MembershipInfo membershipInfo={membershipInfo} user={user} />
        <Grid
          item
          container
          component={Box}
          flexDirection={{ xs: 'row' }}
          style={{
            background: '#F8FAFD',
            margin: '20px',
            paddingLeft: '60px',
            paddingRight: '60px',
            textAlign: 'center',
            width: '-webkit-fill-available',
            alignItems: 'center',
          }}
        >
          <EmailIcon />
          <span style={{ marginRight: '5px', marginLeft: '10px' }}>
            Need help? Email us at
          </span>
          <a href="mailto:hello@theapiari.com">hello@theapiari.com</a>
        </Grid>
        <Grid item>
          <DeleteAccountSection user={user} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MyMembershipPage

import React from 'react'
import * as PropTypes from 'prop-types'
import ManageDateTime from './manage-date-time'
import { observer } from 'mobx-react-lite'
import DetailsItem from '../details-item'
import format from 'date-fns/format'
import { createDateFromString } from '../../../../utils/utils'
import { DATE_FORMATS } from '../../../../utils/config'
import Box from '@material-ui/core/Box'

const DateTimeDetails = observer(
  ({ instance, editing, onSave, setEditingEnabled, isViewerCustomer }) => {
    const handleChange = dateTime => {
      instance.updateDateTime(dateTime, isViewerCustomer).then(onSave)
    }

    if (editing)
      return (
        <ManageDateTime
          instance={instance}
          onChange={handleChange}
          setEditingEnabled={setEditingEnabled}
          onSave={onSave}
        />
      )

    const isPending = instance.isPendingConfirmation
    const startDateDisplay = format(
      createDateFromString(instance.startDate, instance.startTime),
      DATE_FORMATS.DATE_HUMAN,
    )
    const endDateDisplay = format(
      createDateFromString(instance.endDate, instance.endTime),
      DATE_FORMATS.DATE_HUMAN,
    )
    const dateDisplay =
      instance.startDate === instance.endDate
        ? `${startDateDisplay}`
        : `${startDateDisplay} to ${endDateDisplay}`
    return (
      <>
        <DetailsItem title="Date">
          <Box color={isPending && 'text.hint'} display="inline-flex" mr={1}>
            {dateDisplay}
          </Box>
          {isPending && (
            <Box
              px={1}
              py={0.2}
              fontSize={12}
              fontFamily="camptonBold"
              bgcolor="greyBlue.100"
              color="text.grey"
              display="inline-flex"
              borderRadius={3}
            >
              Pending confirmation
            </Box>
          )}
        </DetailsItem>
        <DetailsItem title="Time">
          <Box color={isPending && 'text.hint'}>
            {format(instance.start, `${DATE_FORMATS.TIME} aaa`)} -&nbsp;
            {format(instance.end, `${DATE_FORMATS.TIME} aaa`)}
          </Box>
        </DetailsItem>
      </>
    )
  },
)

DateTimeDetails.propTypes = {
  instance: PropTypes.object.isRequired,
  isViewerCustomer: PropTypes.bool,
}

export default DateTimeDetails

import { applySnapshot, getRoot, types } from 'mobx-state-tree'

const UiModel = types
  .model('UiModel', {
    authenticating: types.optional(types.boolean, false),
    reAuthenticating: types.optional(types.boolean, true),
    afterLoginRoute: types.maybeNull(types.string),
    calendar: types.frozen(),
  })
  .views(self => ({
    get defaultCalendarView() {
      if (self.calendar.view) return self.calendar.view
      return getRoot(self).events.hasTodayEvents
        ? 'timeGridDay'
        : 'timeGridWeek'
    },
  }))
  .actions(self => ({
    setAuthenticateState(active) {
      self.authenticating = active
    },
    setReAuthenticateState(active) {
      self.reAuthenticating = active
    },
    setAfterLoginRoute(route) {
      self.afterLoginRoute = route
    },
    setCalendarUiState(state) {
      if (!getRoot(self).events.entries.size) return
      self.calendar = state
    },
    authChange(auth) {
      applySnapshot(self, { ...self, ...auth })
    },
  }))

export default UiModel

import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ChatSelect from './chat-select'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(theme => ({
  box: {
    userSelect: 'none',
    opacity: ({ isDisabled }) => isDisabled && 0.5,
    borderColor: ({ isActive }) =>
      isActive ? theme.palette.secondary.main : '#D8DCE7',
    color: ({ isActive }) =>
      isActive ? theme.palette.common.white : theme.palette.text.grey,
    cursor: ({ isDisabled }) => !isDisabled && 'pointer',
    background: ({ isActive, isDisabled }) => {
      if (isActive) return theme.palette.secondary.main
      if (isDisabled) return 'transparent'
      return theme.palette.common.white
    },
    transition: 'all 0.2s',
  },
}))

const TimeItem = ({ onClick, option, ...rest }) => {
  const classes = useStyles(rest)
  return (
    <Box
      px={1}
      py={0.5}
      border={1}
      borderRadius={5}
      width={80}
      textAlign="center"
      className={classes.box}
      onClick={() => {
        if (rest.isDisabled) return
        onClick(option)
      }}
    >
      {option}
    </Box>
  )
}

TimeItem.propTypes = {
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
}

const ChatTimePicker = ({ onChange, times, buttons }) => {
  const [selected, setSelected] = React.useState('')

  const handleClick = option => {
    setSelected(option)
    onChange({ type: 'time', message: option })
  }

  return (
    <Grid container direction="column" spacing={2}>
      {Object.keys(times).map(key => (
        <Grid item container key={key}>
          <Box fontSize="0.8rem" color="text.grey">
            {key}
          </Box>
          <Grid container spacing={1}>
            {times[key].map(({ time, active: notDisabled }) => (
              <Grid item key={time}>
                <TimeItem
                  isActive={selected === time}
                  isDisabled={!notDisabled}
                  onClick={handleClick}
                  option={time}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      {buttons && (
        <Grid item>
          <ChatSelect options={buttons} onChange={onChange} />
        </Grid>
      )}
    </Grid>
  )
}

ChatTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  times: PropTypes.object.isRequired,
  buttons: PropTypes.array,
}

export default ChatTimePicker

import React from 'react'
import Box from '@material-ui/core/Box'
import Shimmer from '../../components/shimmer/shimmer'

const InitializingBot = () => {
  return (
    <Box display="flex" ml={4}>
      <Box mr={1.25}>
        <Shimmer w={44} h={44} r="50%" />
      </Box>
      <Shimmer h={30} r={20} w={100} />
    </Box>
  )
}

export default InitializingBot

import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ChatSelect from './chat-select'

const types = {
  text: 'text',
  passthrough: 'text',
  address: 'address',
  number: 'number',
  buttonText: 'text',
  password: 'password',
}

const ChatInputForm = props => {
  const { onSubmit, type, initValue, buttons, ...rest } = props
  const [value, setValue] = React.useState(initValue)

  const handleChange = e => setValue(e.target.value)

  const submitAnswer = e => {
    e.preventDefault()
    onSubmit && onSubmit({ type: types[type], message: value })
    setValue('')
  }

  const submitOnEnter = e => {
    if (e.key === 'Enter' && type !== 'textarea') {
      submitAnswer(e)
      e.persist()
      e.target.blur()
      // blurring it shortly so auto-scroll works properly
      setTimeout(() => {
        e.target.focus()
      }, 500)
    }
  }
  return (
    <form onSubmit={submitAnswer}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <TextField
            value={value}
            onChange={handleChange}
            onKeyPress={submitOnEnter}
            multiline={type !== 'password'}
            fullWidth
            label="Enter your response"
            variant="outlined"
            margin="dense"
            type={types[type]}
            {...rest}
          />
        </Grid>
        <Grid item xs="auto">
          <Button color="primary" type="submit" disabled={!value}>
            Send
          </Button>
        </Grid>
        {buttons && buttons.length && (
          <ChatSelect
            options={buttons}
            onChange={({ message }) =>
              onSubmit && onSubmit({ type: types[type], message: message })
            }
          />
        )}
      </Grid>
    </form>
  )
}

ChatInputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  type: PropTypes.oneOf([
    'text',
    'password',
    'buttonText',
    'textarea',
    'address',
    'number',
  ]),
}

export default ChatInputForm

import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles({
  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: '-1000px 0',
    },
    '100%': {
      backgroundPosition: '1000px 0',
    },
  },
  shimmer: {
    background:
      'linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)',
    backgroundSize: '1000px 100%',
    animation: '$shimmer 2s infinite linear',
    width: ({ w }) => w || '100%',
    height: ({ h }) => h || 30,
    borderRadius: ({ r }) => r || 4,
  },
})

const Shimmer = props => {
  const classes = useStyles(props)
  return <div className={classes.shimmer} />
}

export default Shimmer

import { useState } from 'react'
import { Formik } from 'formik'
import { isAfter } from 'date-fns'
import { Link } from '@reach/router'
import { Box, Grid, styled, withStyles } from '@material-ui/core'

import UnsubscribeForm from './unsubscribe-form'
import ApiariButton from '../../../components/inputs/button'
import AccountChangeStatusModal from './account-change-status-modal'
import ConfirmationModal from '../../../components/confirmation-modal'
import ApiariSpinner from '../../../components/apiari-spinner'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import { cancelUserMembership } from '../../../api/auth-api'

import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'

const UnsubscribeButton = styled(props => <span {...props}>Unsubscribe</span>)(
  ({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.text.brandDarkGray,
  }),
)

const ConfirmUnsubscribeButton = styled(ApiariButton)(({ theme }) => ({
  borderColor: 'currentColor',
  color: theme.palette.secondary.brandRed,
  background: 'transparent',

  '&:hover': {
    background: 'rgb(255 120 116 / 10%)',
    borderColor: 'currentColor',
  },
}))

const CancelButton = withStyles({
  root: {
    width: '100%',
    '& .MuiButton-startIcon': {
      marginTop: '-4px',
      marginRight: '6px',
    },
  },
})(ApiariButton)

const MembershipInfo = ({ membershipInfo, user }) => {
  const isBecomingProviderComplete = Boolean(user?.becomingProviderComplete)
  const isUserActive = Boolean(user?.isActive)
  const isSubscriptionActive = membershipInfo?.status === 'active'
  const isLegacyUser =
    user?.lastBackgroundCheck &&
    isAfter(new Date(user?.lastBackgroundCheck), new Date(2017, 0, 1)) &&
    !isSubscriptionActive

  const autoRenewsOn = membershipInfo
    ? membershipInfo.date.getMonth() +
      1 +
      '/' +
      membershipInfo.date.getDate() +
      '/' +
      membershipInfo.date.getFullYear()
    : 'xx/xx/xxxx'

  const [successState, setSuccessState] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)
  const [isStatusModal, setStatusModal] = useState(false)
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false)

  const handleSubmit = async ({ reason, otherReason }) => {
    const realReason = reason === 'other' ? 'Other: ' + otherReason : reason
    setIsInProgress(true)
    try {
      await cancelUserMembership({ userId: user.id, reason: realReason })
      setSuccessState(true)
    } catch (error) {
      setSuccessState(false)
      console.log('error deleteing account', error)
    }
    setIsInProgress(false)
    setIsUnsubscribeModalOpen(false)
    setStatusModal(true)
  }

  const InfoWrapper = ({ title, children, height = 20 }) => (
    <Grid
      item
      container
      component={Box}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Grid component={Box} item xs="auto" width={200} mb={{ xs: 1, md: 0 }}>
        <Box fontFamily="camptonBold">{title}</Box>
      </Grid>
      <Box component={Grid} item xs height={height}>
        {children}
      </Box>
    </Grid>
  )
  return (
    <Grid item>
      {isInProgress && <ApiariSpinner isGlobal size={64} />}
      {isUnsubscribeModalOpen && (
        <Formik
          initialValues={{
            reason: 'I found the clients I need on Apiari',
            otherReason: '',
          }}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {({ handleSubmit }) => (
            <ConfirmationModal
              title="Cancel my subscription"
              message={
                <>
                  <Box mb={3}>
                    We are sorry to see you go! Please let us know the reason
                    for choosing to unsubscribe:
                  </Box>
                  <Box px={1}>
                    <UnsubscribeForm />
                  </Box>
                </>
              }
              confirmationButton={
                <ConfirmUnsubscribeButton
                  variant="outlined"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Unsubscribe
                </ConfirmUnsubscribeButton>
              }
              closeButton={
                <CancelButton
                  variant="outlined"
                  onClick={() => setIsUnsubscribeModalOpen(false)}
                >
                  Cancel
                </CancelButton>
              }
            />
          )}
        </Formik>
      )}
      {isStatusModal && (
        <AccountChangeStatusModal
          handleClose={() => setStatusModal(false)}
          type="neutral"
          message={
            successState ? (
              <div>
                <p>
                  Got it. Your subscription will end on {autoRenewsOn}. It may
                  take us up to 2 business days to complete your request.
                </p>
                <p>
                  In the meantime, you will continue to have access to our
                  premium matching platform. We will continue to match you to
                  new job opportunities until then.
                </p>
              </div>
            ) : (
              'Something went wrong. Please try again later.'
            )
          }
        />
      )}
      <SubTitle
        icon={<BulletIcon />}
        text="Membership"
        fontSize={{ xs: 20, md: 26 }}
      />
      <Box pl={3.6} mt={2}>
        <Grid container direction="column" spacing={3}>
          <InfoWrapper title="Membership">
            {membershipInfo
              ? membershipInfo.name
              : 'Free (add and manage your own client bookings)'}
          </InfoWrapper>
          {membershipInfo ? (
            <>
              <InfoWrapper title="Auto-renews on">{autoRenewsOn}</InfoWrapper>
              <InfoWrapper title="Payment">
                {membershipInfo
                  ? '$' + membershipInfo.price + '/ ' + membershipInfo.interval
                  : '$29.99/ month'}
              </InfoWrapper>
              <Box p={{ xs: 1.5 }} fontFamily="camptonBold">
                <UnsubscribeButton
                  onClick={() => setIsUnsubscribeModalOpen(true)}
                />
              </Box>
            </>
          ) : (
            <InfoWrapper title="Want more bookings?">
              <span>
                {!isBecomingProviderComplete &&
                !isUserActive &&
                !isLegacyUser ? (
                  <Link
                    to={'/become-a-provider'}
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  >
                    Subscribe
                  </Link>
                ) : (
                  <Link
                    to={'/subscribe'}
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  >
                    Upgrade
                  </Link>
                )}
                &nbsp; to Premium Matching Platform to get matched with clients
                meeting your preferences!
              </span>
            </InfoWrapper>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

export default MembershipInfo

import React from 'react'
import { Field, Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import ApiariInput from '../../components/inputs/input'
import ApiariButton from '../../components/inputs/button'
import Typography from '@material-ui/core/Typography'

const RegisterForm = props => {
  const { isSubmitting, errors, touched } = props

  return (
    <Grid component={Form} container direction="column" noValidate spacing={2}>
      <Typography color="error" variant="caption" align="center">
        {errors.error}
      </Typography>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="firstName"
          label="First Name"
        />
      </Grid>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="lastName"
          label="Last Name"
        />
      </Grid>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="email"
          type="email"
          label="Email Address"
        />
      </Grid>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="password"
          type="password"
          label="Password"
        />
      </Grid>
      <Grid item>
        <Field
          component={ApiariInput}
          disabled={isSubmitting}
          name="phoneNumber"
          label="Phone Number"
          error={
            /phone/.test(errors.error) ||
            (touched.phoneNumber && !!errors.phoneNumber)
          }
        />
      </Grid>
      <Grid item>
        <ApiariButton busy={isSubmitting} fullWidth type="submit">
          Create Account
        </ApiariButton>
      </Grid>
    </Grid>
  )
}

export default RegisterForm

import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Avatar, styled } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import ApiariButton from '../../../../components/inputs/button'
import ProviderNameRate from '../name-rate/name-rate'

const ApiariRejectButton = styled(ApiariButton)(({ theme }) => ({
  border: 'transparent',
  color: theme.palette.secondary.brandRed,
  background: 'transparent',

  '&:hover': {
    background: 'rgb(255 120 116 / 10%)',
    border: 'transparent',
  },
}))

const ChatSummaryCard = props => {
  const {
    body,
    rating,
    onChange,
    firstName,
    profilePic,
    lastInitial,
    isRejectable,
    requestButtonText,
    priceInfo: { hourlyPrice } = {},
  } = props
  const [sent, setSent] = React.useState(false)

  const handleClick = message => {
    setSent(true)
    return !sent && onChange({ type: 'requestBooking', message })
  }

  return (
    <Box maxWidth={330}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Box width={70} height={70}>
            <Avatar src={profilePic} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box fontFamily="camptonBold" fontSize="1.125rem">
            <ProviderNameRate
              name={`${firstName} ${lastInitial}`}
              rate={hourlyPrice}
            />
          </Box>
          {rating && (
            <Box display="flex" alignItems="center">
              <Rating disabled value={Number(rating)} precision={0.5} />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box my={1}>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </Box>
      <Box mt={1} display="flex" flexDirection="column">
        <Box mt={1}>
          <ApiariButton
            fullWidth
            onClick={() => handleClick(requestButtonText || 'Send Request')}
          >
            {requestButtonText || 'SEND REQUEST'}
          </ApiariButton>
        </Box>
        <Box mt={1}>
          <ApiariButton
            fullWidth
            variant="outlined"
            onClick={() => handleClick('Edit Details')}
          >
            EDIT DETAILS
          </ApiariButton>
        </Box>
        {isRejectable && (
          <Box mt={1}>
            <ApiariRejectButton
              fullWidth
              variant="outlined"
              onClick={() => handleClick('Reject Booking')}
            >
              REJECT
            </ApiariRejectButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

ChatSummaryCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  profilePic: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastInitial: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  priceInfo: PropTypes.shape({
    hourlyPrice: PropTypes.string,
  }),
  requestButtonText: PropTypes.string,
}

export default ChatSummaryCard

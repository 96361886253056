import React from 'react'

export const BackgroundStateContext = React.createContext(null)
export const BackgroundSetContext = React.createContext(null)

function BackgroundProvider({ children }) {
  const [background, setBackground] = React.useState({
    data: { photoUrls: [], photoSize: 20 },
  })

  return (
    <BackgroundStateContext.Provider value={background}>
      <BackgroundSetContext.Provider value={setBackground}>
        {children}
      </BackgroundSetContext.Provider>
    </BackgroundStateContext.Provider>
  )
}

export default BackgroundProvider

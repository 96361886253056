import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import apiariLogo from '../../assets/images/apiari_logo.png'
import poweredByApiariLogo from '../../assets/images/powered_by_apiari.png'
import apiariBusinessLogo from '../../assets/images/apiariBusinessLogo.png'
import FacebookIcon from '../../assets/facebook'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

const apiariClassicLinks = [
  { label: 'Jobs', href: 'https://theapiari.com/apiari-careers/' },
  { label: 'Signup', href: '/signup' },
  {
    label: 'Terms of service',
    href: 'https://theapiari.com/terms-of-service/',
  },
  { label: 'Privacy', href: 'https://theapiari.com/privacy/' },
  { label: 'The Sweet Life', href: 'https://theapiari.com/the-sweet-life/' },
]

const apiariBusinessLinks = [
  {
    label: 'Terms of service',
    href: 'https://www.apiari.net/terms',
  },
  { label: 'Privacy', href: 'https://www.apiari.net/privacy-policy/' },
]

const Footer = ({ isApiariBusiness, isPublicPage }) => {
  const classes = useStyles()
  const links = isApiariBusiness ? apiariBusinessLinks : apiariClassicLinks
  // if (isApiariBusiness) {
  //   return (
  //     <></>
  //   );
  // }
  return (
    <Box
      component="footer"
      px={{ xs: 2, sm: 2, md: 3 }}
      py={3}
      fontFamily="camptonBold"
      color="text.grey"
      fontWeight={700}
      fontSize="12px"
    >
      <Grid
        container
        justify="space-between"
        className={classes.root}
        spacing={4}
      >
        <Box component={Grid} item sm="auto" textAlign="center">
          {isApiariBusiness ? (
            <a href="https://www.apiari.net/">
              <img
                src={isPublicPage ? poweredByApiariLogo : apiariBusinessLogo}
                alt="logo"
                width={130}
              />
            </a>
          ) : (
            <a href="https://theapiari.com/">
              <img src={apiariLogo} alt="logo" width={130} />
            </a>
          )}
          <Box color="#d8d8d8">Copyright {new Date().getFullYear()}</Box>
        </Box>
        <Grid item sm>
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            mt={{ xs: 0, md: 6 }}
          >
            {isApiariBusiness ? (
              <></>
            ) : (
              <>
                <Box>
                  <Link href={`mailto:hello@theapiari.com`} color="inherit">
                    hello@theapiari.com
                  </Link>
                </Box>
                <Box ml={{ xs: 1, md: 4 }}>1.212.381.9687</Box>
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            {links.map(link => (
              <Box mr={{ xs: 1, sm: 1, md: 4 }} key={link.href}>
                <a href={link.href} rel="noopener noreferrer">
                  {link.label}
                </a>
              </Box>
            ))}
          </Box>
        </Grid>
        {isApiariBusiness ? (
          <></>
        ) : (
          <Grid item sm="auto">
            <Grid container spacing={1} justify="center">
              <Grid item>
                <IconButton
                  component="a"
                  href="http://www.facebook.com/theapiari/"
                  target="_blank"
                >
                  <FacebookIcon
                    width="1em"
                    height="1em"
                    style={{ padding: 3 }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  component="a"
                  href="https://twitter.com/Apiari_NYC"
                  target="_blank"
                >
                  <TwitterIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  component="a"
                  href="http://instagram.com/apiari_nyc"
                  target="_blank"
                >
                  <InstagramIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Footer

import { useState, useContext } from 'react'
import * as PropTypes from 'prop-types'
import Rating from '@material-ui/lab/Rating'
import { Collapse, Grid, Avatar, Box } from '@material-ui/core'
import DetailsModal from '../../details/details-modal'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ApiariButton from '../../../../../components/inputs/button'
import storeContext from '../../../../../stores'
import HideButton from './components/hide-button'
import LikeButton from './components/like-button'
import BlurbBox from './components/blurb-box'

// noinspection JSCheckFunctionSignatures
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',

    '&': {
      padding: '15px',
    },

    '& .action-button-container': {
      top: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      transform: 'translateX(50%)',
    },

    '& .action-button': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '36px',
      width: '36px',
      marginBottom: '5px',
      borderRadius: '50%',
      background: 'white',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',

      '&:hover': {
        background: theme.palette.background.brandGreenLight,
      },
    },

    '& .like-button': {
      color: theme.palette.secondary.brandRed,
    },

    '& .hide-button': {
      color: theme.palette.text.lightGrey,
    },
  },
}))

const ChatBookCard = props => {
  const {
    id,
    instanceId,
    profilePic,
    firstName,
    lastInitial,
    percentMatch,
    priceInfo: { hourlyPrice } = {},
    rating,
    serviceName,
    blurb,
    onChange,
    isExpanded,
    setRequestedBooking,
    requestedBooking,
    isLikable,
  } = props

  const {
    auth: { user },
  } = useContext(storeContext)
  const classes = useStyles({ isExpanded })
  const [sent] = useState(false)
  const [isDescended, setIsDescended] = useState(
    isLikable
      ? () => (user?.getDescendedProviders() || []).includes(id)
      : false,
  )
  const [isLiked, setIsLiked] = useState(
    isLikable ? () => (user?.getLikedProviders() || []).includes(id) : false,
  )

  const handleLike = () => {
    setIsLiked(true)
    user.likeProvider({ providerId: id })
  }
  const handleDislike = () => {
    setIsLiked(false)
    user.dislikeProvider({ providerId: id })
  }

  const handleDescension = () => {
    setIsDescended(true)
    user.descendProvider({ providerId: id })
  }
  const handleAscension = () => {
    setIsDescended(false)
    user.ascendProvider({ providerId: id })
  }

  const handleBookingClick = () => {
    setRequestedBooking(true)
    return (
      !sent &&
      onChange({
        type: 'bookingRequest',
        message: instanceId,
        rawText: `Request to ${firstName} ${lastInitial}`,
      })
    )
  }

  const handleInterviewClick = () => {
    setRequestedBooking(true)
    return (
      !sent &&
      onChange({
        data: {
          provider: { id },
        },
        type: 'interview',
        message: 'interview',
        rawText: 'Interview',
      })
    )
  }

  return (
    <Box maxWidth={280} className={classes.root}>
      {isLikable && (
        <div className="action-button-container">
          {!isDescended && (
            <LikeButton
              handleLike={handleLike}
              handleDislike={handleDislike}
              isLiked={isLiked}
            />
          )}
          {!isLiked && (
            <HideButton
              handleDescension={handleDescension}
              handleAscension={handleAscension}
              isDescended={isDescended}
            />
          )}
        </div>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Box height={70} width={70}>
            <Avatar src={profilePic} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box fontFamily="camptonBold" fontSize="1rem" pr={2}>
            {`${firstName} ${lastInitial}.`}
          </Box>
          <Box color="text.grey" fontFamily="camptonBold" fontSize="0.8rem">
            {['childcare', 'childcarePlus', 'virtual', 'night_nurse'].includes(
              serviceName,
            ) &&
              percentMatch && <>{Math.round(percentMatch * 100)}% Match</>}
          </Box>
          <Box fontFamily="camptonBold" color="text.grey" fontSize="0.8rem">
            {hourlyPrice && <>${hourlyPrice}/hr</>}
          </Box>

          <Box display="flex" alignItems="center">
            <Rating disabled value={Number(rating)} precision={0.5} />
          </Box>
        </Grid>
      </Grid>

      <Collapse in={!isDescended}>
        <Box mt={1} display="flex" flexDirection="column">
          <DetailsModal
            detailsProps={{
              id,
              service: serviceName,
              onRequestBooking: handleBookingClick,
            }}
            onInterview={handleInterviewClick}
          >
            <div>
              <BlurbBox text={blurb} />
            </div>
          </DetailsModal>
          <Box mt={1}>
            <ApiariButton
              fullWidth
              variant="outlined"
              onClick={handleInterviewClick}
              disabled={requestedBooking}
            >
              INTERVIEW
            </ApiariButton>
          </Box>
          <Box mt={1}>
            <ApiariButton
              fullWidth
              onClick={handleBookingClick}
              disabled={requestedBooking}
            >
              BOOK
            </ApiariButton>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

ChatBookCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  profilePic: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  percentMatcb: PropTypes.string,
  lastInitial: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  serviceName: PropTypes.string.isRequired,
  priceInfo: PropTypes.shape({
    hourlyPrice: PropTypes.string,
  }),
  isExpanded: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isLikable: PropTypes.bool,
}

export default ChatBookCard

import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import ApiariButton from '../../../components/inputs/button'

const AccountChangeStatusModal = props => {
  const { handleClose, type, message } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const colorMap = {
    success: '#2e7d32',
    error: '#d32f2f',
    neutral: theme.palette.text.primary,
  }
  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullScreen={fullScreen}>
      <Box component={DialogContent} mb={4} color={colorMap[type]}>
        {message}
      </Box>
      <Box pb={1} pl={1} pr={1}>
        <DialogActions>
          <ApiariButton onClick={handleClose}>Close</ApiariButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AccountChangeStatusModal

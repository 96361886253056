import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InlineDatePicker from '../../../../../components/inputs/inline-date-picker'
import InlineTimePicker from '../../../../../components/inputs/inline-time-picker'
import ApiariButton from '../../../../../components/inputs/button'
import ButtonSwitch from './button-switch'
import WeekDaySelect from './week-day-select'
import {
  parse,
  format,
  addDays,
  addHours,
  isBefore,
  setDayOfYear,
  getDayOfYear,
} from 'date-fns'
import { DATE_FORMATS } from '../../../../../utils/config'
import WidgetLayout from '../../layouts/widget-layout'

const flexibleDateOption = [
  { value: false, text: 'Exact date' },
  { value: true, text: '+- 1 week' },
]

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiInputLabel-root, & .MuiFormControlLabel-label': {
      position: 'static',
      margin: '0 0 4px 0',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.palette.text.darkGrey,
      transform: 'none',
    },
    '& .MuiInputBase-root': {
      margin: 0,
    },
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      color: theme.palette.text.darkGrey,
      fontSize: '14px',
      fontWeight: 700,
    },
  },
}))

function getDefaultStartTime(date) {
  // Start time — +1 hour to current time (rounded to a bigger number)
  // Example, now is 5:15 PM — round to 7 PM
  const current = date || new Date()
  current.setSeconds(0, 0)
  const currentMinutes = current.getMinutes()

  if (currentMinutes > 1) {
    current.setMinutes(0)

    return addHours(current, 2)
  }

  return addHours(current, 1)
}

function getDefaultEndTime(startTime, serviceType) {
  const DEFAULT_SESSION_DURATION = 1

  let duration = DEFAULT_SESSION_DURATION
  switch (serviceType) {
    case 'childcare':
      duration = 4
      break
    case 'childcarePlus':
      duration = 1
      break
    case 'night_nurse':
      duration = 10
      break
  }

  return setDayOfYear(addHours(startTime, duration), getDayOfYear(startTime))
}

function getDefaultWeekday(date) {
  // By default Day of the week, that was chosen as a start date
  // will be selected in section “Repeat on” (March, 15 it’s Mon).
  return format(date, 'EEEE')
}

function createAmyTextResponse({
  startDate,
  endDate,
  startTime,
  endTime,
  isRecurring,
  weekdays,
}) {
  const formatedStartDate = format(startDate, DATE_FORMATS.DATE_HUMAN)
  const formatedStartTime = format(startTime, DATE_FORMATS.TIME_HUMAN)
  const formatedEndTime = format(endTime, DATE_FORMATS.TIME_HUMAN)
  if (isRecurring) {
    const formatedWeekdays = weekdays.map(day => day.substr(0, 3)).join(', ')
    const formatedEndDate = format(endDate, DATE_FORMATS.DATE_HUMAN)
    return `Every ${formatedWeekdays} from ${formatedStartTime} to ${formatedEndTime}, from ${formatedStartDate} to ${formatedEndDate}`
  } else {
    return `${formatedStartDate} from ${formatedStartTime} to ${formatedEndTime}`
  }
}

function transformInitialValue(initialValue) {
  return {
    initialStartDate: parse(
      initialValue.startDate,
      DATE_FORMATS.DATE,
      new Date(),
    ),
    initialStartTime: parse(
      initialValue.startTime,
      DATE_FORMATS.TIME_24_CLOCK,
      new Date(),
    ),
    initialEndTime: parse(
      initialValue.endTime,
      DATE_FORMATS.TIME_24_CLOCK,
      new Date(),
    ),
    initialRecurrence: Boolean(initialValue.recurring),
    ...(Boolean(initialValue.recurring)
      ? {
          initialEndDate: parse(
            initialValue.recurring.endDate,
            DATE_FORMATS.DATE,
            new Date(),
          ),
          initialWeekdays: initialValue.recurring.weekdays,
          // initialFlexibleDate: false,
        }
      : {}),
  }
}

const ChatAddSession = ({
  title,
  onSave,
  onCancel,
  serviceName,
  initialState,
  allowRecurring,
  allowFlexibleDate,
}) => {
  const classes = useStyles()
  const defaultStartDate = new Date()
  const defaultEndDate = addDays(new Date(), 7)
  const defaultStartTime = getDefaultStartTime()
  const defaultEndTime = getDefaultEndTime(defaultStartTime, serviceName)
  const isEditMode = !!initialState

  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndTime)

  const [isRecurring, setIsRecurring] = useState(false)
  const [weekdays, setWeekdays] = useState([getDefaultWeekday(startDate)])
  const [flexibleDate, setFlexibleDate] = useState(flexibleDateOption[0].value)

  const [
    isPostInitialStateRetrievalPhase,
    setIsPostInitialStateRetrievalPhase,
  ] = useState(false)

  const isOvernight =
    Math.trunc(startTime / (60 * 1000)) >= Math.trunc(endTime / (60 * 1000))

  function calculateDisabledDates(date) {
    return isBefore(date, startDate)
  }

  // As start date changes the end date adjusts with 7 days interval
  // setIsPostInitialStateRetrievalPhase flag points that current re-render was caused by state retrieval in edit mode
  useEffect(() => {
    if (isPostInitialStateRetrievalPhase) {
      return setIsPostInitialStateRetrievalPhase(false)
    }
    const newDefaultEndDate = addDays(startDate, 7)
    setEndDate(newDefaultEndDate)
  }, [startDate])

  // As start time changes the end time adjusts to +4 (or 1 or 10 depending on service)
  // setIsPostInitialStateRetrievalPhase flag points that current re-render was caused by state retrieval in edit mode
  useEffect(() => {
    if (isPostInitialStateRetrievalPhase) {
      return setIsPostInitialStateRetrievalPhase(false)
    }
    const newDefaultEndTime = getDefaultEndTime(startTime, serviceName)
    setEndTime(newDefaultEndTime)
  }, [startTime])

  useEffect(() => {
    if (!initialState) return

    const {
      initialStartDate,
      initialStartTime,
      initialEndTime,
      initialEndDate,
      initialRecurrence,
      initialWeekdays,
      // initialFlexibleDate
    } = transformInitialValue(initialState)

    setStartDate(initialStartDate)
    setStartTime(initialStartTime)
    setEndTime(initialEndTime)
    setIsRecurring(initialRecurrence)

    if (initialRecurrence) {
      setEndDate(initialEndDate)
      setWeekdays(initialWeekdays)
      // setFlexibleDate(initialFlexibleDate)
    }
    setIsPostInitialStateRetrievalPhase(true)
  }, [initialState])

  return (
    <WidgetLayout>
      <Box p={1} classes={classes}>
        <Box width={302}>
          <Grid
            container
            spacing={1}
            justify="space-between"
            direction="column"
            style={{ minHeight: '520px' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box mb={2.5} textAlign="center" fontFamily="camptonBold">
                  <h2
                    style={{
                      fontSize: '16px',
                      lineHeight: '1.2em',
                      padding: 0,
                      margin: 0,
                      marginBottom: 0,
                    }}
                  >
                    {title}
                  </h2>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mb={2.5}>
                  <InlineDatePicker
                    label="Starts"
                    name="start-date"
                    initialValue={startDate}
                    onChange={setStartDate}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2.5}>
                  <InlineTimePicker
                    name="start-time"
                    ampm={true}
                    variant="inline"
                    label="From"
                    initialValue={startTime}
                    onChange={setStartTime}
                    pickerPosition={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    anchorOriginPosition={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2.5}>
                  <InlineTimePicker
                    name="end-time"
                    ampm={true}
                    variant="inline"
                    label="To"
                    isOvernight={isOvernight}
                    initialValue={endTime}
                    onChange={setEndTime}
                    pickerPosition={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorOriginPosition={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  />
                </Box>
              </Grid>
              {allowRecurring && (
                <Grid item xs={12}>
                  <Box mb={2.5}>
                    <FormControlLabel
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: 0,
                      }}
                      control={
                        <Switch
                          onChange={() => setIsRecurring(!isRecurring)}
                          checked={isRecurring}
                          color="primary"
                        />
                      }
                      label="Recurring"
                      labelPlacement="start"
                    />
                  </Box>
                </Grid>
              )}
              {isRecurring && (
                <Grid item xs={12}>
                  <Box mb={2.5}>
                    <WeekDaySelect
                      label="Repeat on"
                      isRequired
                      defaultValue={weekdays}
                      onChange={setWeekdays}
                    />
                  </Box>
                </Grid>
              )}
              {isRecurring && (
                <Grid item xs={12}>
                  <Box mb={2.5}>
                    <InlineDatePicker
                      label="Ends"
                      name="end-date"
                      initialValue={endDate}
                      shouldDisableDate={calculateDisabledDates}
                      onChange={setEndDate}
                    />
                  </Box>
                </Grid>
              )}
              {isRecurring && allowFlexibleDate && (
                <Grid item xs={12}>
                  <Box mb={3}>
                    <ButtonSwitch
                      label="Start searching"
                      initialValue={flexibleDate}
                      options={flexibleDateOption}
                      onChange={setFlexibleDate}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                {!isEditMode && (
                  <ApiariButton
                    style={{ width: '100%' }}
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Back
                  </ApiariButton>
                )}
              </Grid>
              <Grid item xs={isEditMode ? 12 : 6}>
                <ApiariButton
                  style={{ width: '100%' }}
                  onClick={() => {
                    const data = {
                      startDate: format(startDate, DATE_FORMATS.DATE),
                      startTime: format(startTime, DATE_FORMATS.TIME_24_CLOCK),
                      endTime: format(endTime, DATE_FORMATS.TIME_24_CLOCK),
                      recurring: isRecurring
                        ? {
                            endDate: format(endDate, DATE_FORMATS.DATE),
                            weekdays,
                            flexibleDate: allowFlexibleDate
                              ? flexibleDate
                              : null,
                          }
                        : null,
                    }

                    const rawText = createAmyTextResponse({
                      isRecurring,
                      startDate,
                      endDate,
                      startTime,
                      endTime,
                      weekdays,
                    })
                    onSave({ type: 'datetime', message: data, rawText })
                  }}
                >
                  Done
                </ApiariButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </WidgetLayout>
  )
}

ChatAddSession.propTypes = {
  title: PropTypes.node,
  serviceName: PropTypes.string,
  allowRecurring: PropTypes.bool,
  allowFlexibleDate: PropTypes.bool,
}

export default ChatAddSession

import React from 'react'
import UserDetails from '../chat-bot/components/details/details'
import storeContext from '../../stores'
import { observer } from 'mobx-react-lite'
import ProfileWrapper from '../../components/profile-wrapper/profile-wrapper'
import useQueryString from '../../hooks/use-query-string'

const UserDetailsPage = observer(({ id, serviceName, location, navigate }) => {
  const store = React.useContext(storeContext)
  const qs = useQueryString(location.search)
  if (!serviceName) {
    serviceName = serviceName || qs.serviceName
  }
  React.useEffect(() => {
    store.users.getUser(id)
  }, [id, store])

  // hack to get around inconsistency between a recently stored providers store.auth.user.alias matching
  // an existing record's user.shortUserId
  const user =
    store.auth.user && id === store.auth.user.alias
      ? store.auth.user
      : store.users.entries.get(id)

  const onInterview = () => {
    let path = `/chat-bot?startIntent=scheduleInterview&providerId=${id}&slots=${encodeURIComponent(
      JSON.stringify({ serviceName }),
    )}`

    if (qs.interviewData) {
      path = `${path}&data=${qs.interviewData}`
    }

    navigate(path)
  }

  const onBooking = () => {
    const slots = {
      enrichmentActivities: [],
      niceToHaves: [],
      forceDateTimeSelection: true,
    }
    if (serviceName && !qs.promptForService) {
      slots.serviceName = serviceName
    }
    navigate(
      `/chat-bot?startIntent=bookService&providerId=${id}&slots=${encodeURIComponent(
        JSON.stringify(slots),
      )}`,
    )
  }

  return (
    <ProfileWrapper>
      <UserDetails
        user={user}
        service={serviceName || 'childcarePlus'}
        onInterview={onInterview}
        onRequestBooking={onBooking}
      />
    </ProfileWrapper>
  )
})
export default UserDetailsPage

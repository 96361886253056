import React from 'react'
import * as PropTypes from 'prop-types'
import DetailsItem from '../details-item'
import ManageSession from './manage-session'
import { observer } from 'mobx-react-lite'
import Service from '../../../../components/service/service'
import Typography from '@material-ui/core/Typography'

const SessionDetails = observer(props => {
  const {
    instance,
    editing,
    onSave,
    setEditingEnabled,
    isViewerCustomer,
  } = props

  if (editing)
    return (
      <ManageSession
        instance={instance}
        isViewerCustomer={isViewerCustomer}
        onSave={onSave}
        setEditingEnabled={setEditingEnabled}
      />
    )
  const phoneNumber = isViewerCustomer
    ? instance.provider.phoneNumber
    : instance.customer.phoneNumber
  const serviceNameMap = instance.provider.getServiceNamesHumanMap()
  const serviceNameHuman =
    serviceNameMap[instance.serviceName] || instance.serviceName
  return (
    <>
      {isViewerCustomer ? (
        <DetailsItem title="Provider">
          <Typography color="primary" variant="body2">
            <a
              href={`/user/${instance.provider.alias ||
                instance.provider.shortUserId}/${instance.serviceName}`}
              target="_blank"
              color="primary"
              rel="noopener noreferrer"
            >
              {instance.provider.firstName}
            </a>
          </Typography>
        </DetailsItem>
      ) : (
        <DetailsItem title="Customer">
          {instance.customer.firstName}
        </DetailsItem>
      )}
      {instance.serviceName ? (
        <DetailsItem title="Service">
          <Service service={serviceNameHuman} />
        </DetailsItem>
      ) : (
        <></>
      )}
      <DetailsItem title="Status">{instance.status.toUpperCase()}</DetailsItem>
      {['childcare', 'nightNurse', 'childcarePlus', 'virtual'].includes(
        instance.serviceName,
      ) ? (
        <>
          <DetailsItem title="Children">
            {instance.customer.childrenDisplay}
          </DetailsItem>

          {instance.customer.specialNeeds ? (
            <DetailsItem title="Special Needs">
              {instance.customer.specialNeeds}
            </DetailsItem>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <DetailsItem title="Address">{instance.customer.fullAddress}</DetailsItem>
      {instance.customer.entranceInstructions ? (
        <DetailsItem title="Instructions">
          {instance.customer.entranceInstructions}
        </DetailsItem>
      ) : (
        <></>
      )}
      <DetailsItem title="Pets">{instance.customer.petsDisplay}</DetailsItem>
      <DetailsItem title="Smoking">
        {instance.customer.smokingHousehold ? 'Yes' : 'No'}
      </DetailsItem>
      {instance.dialInInfo ? (
        <DetailsItem title="Dial In">
          <a href={`tel:${instance.dialInInfo}`}>{instance.dialInInfo}</a>
        </DetailsItem>
      ) : (
        <></>
      )}

      {instance.customer.phoneNumber ? (
        <DetailsItem title="Contact Number">
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </DetailsItem>
      ) : (
        <></>
      )}
      {instance.paymentMethod === 'direct' ? (
        <DetailsItem title="Payment Method">
          {isViewerCustomer
            ? `${instance.provider.firstName} will collect your payment`
            : `Collect payment directly from ${instance.customer.firstName}`}
        </DetailsItem>
      ) : (
        <></>
      )}
    </>
  )
})

SessionDetails.propTypes = {
  instance: PropTypes.object.isRequired,
}

export default SessionDetails

import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Box from '@material-ui/core/Box'
import ChatBot from './chat-bot'
import BackgroundImages from './components/background/images'
import BackgroundProvider from './contexts/background-context'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(1),
      borderTop: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`,
    },
    background: theme.palette.background.default,
    height: '90%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxWidth: '100%',
    },
    overflowY: 'overlay',
    fallbacks: {
      overflowY: 'auto',
    },
  },
}))

function ChatBotPage({ location }) {
  const classes = useStyles()
  return (
    <BackgroundProvider>
      <BackgroundImages />
      <Box className={classes.root}>
        <Container maxWidth="sm" className={classes.container}>
          <ChatBot
            search={location.search}
            key={`${location.href}${location.state?.reloadId || ''}`}
          />
        </Container>
      </Box>
    </BackgroundProvider>
  )
}

export default ChatBotPage

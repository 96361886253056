import { useState, useRef } from 'react'
import { makeStyles, Popover, Box } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { KeyboardTimePicker } from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ApiariTimePicker from '../apiari-time-picker'
import { ReactComponent as ClockIcon } from '../../assets/clock.svg'

const useStyles = makeStyles(theme => ({
  root: {
    '&.overnight .MuiInputBase-root': {
      '&::after': {
        content: '"+1"',
        position: 'absolute',
        fontSize: '10px',
        top: '50%',
        left: '85px',
        transform: 'translateY(-90%)',
        fontWeight: '900',
        color: theme.palette.text.grey,
      },
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputAdornment-positionEnd': {
      right: '6px',
    },
    '& input:focus': {
      outline: `1px solid ${theme.palette.border.active}`,
    },
  },
}))

const useStylesPopover = makeStyles(theme => ({
  paper: {
    background: 'none',
    boxShadow: 'none',
    overflow: 'initial',
  },
}))

const InlineTimePicker = ({
  ampm,
  name,
  label,
  onChange,
  initialValue,
  isOvernight,
  pickerPosition,
  anchorOriginPosition,
}) => {
  const inputNode = useRef(null)
  const value = initialValue || new Date()
  const classes = useStyles()
  const popoverClasses = useStylesPopover()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <KeyboardTimePicker
        open={false}
        onOpen={() => {
          setIsOpen(true)
        }}
        className={isOvernight && 'overnight'}
        classes={classes}
        ampm={ampm}
        variant="inline"
        label={label}
        value={value}
        onChange={onChange}
        inputProps={{
          id: name,
        }}
        InputLabelProps={{
          htmlFor: name,
        }}
        InputProps={{
          ref: inputNode,
        }}
        keyboardIcon={ClockIcon ? <ClockIcon /> : <AccessTimeIcon />}
      />

      <Popover
        open={isOpen}
        anchorEl={inputNode.current}
        onClose={() => setIsOpen(false)}
        classes={popoverClasses}
        anchorOrigin={anchorOriginPosition}
        transformOrigin={pickerPosition}
      >
        <Box mt={1}>
          <ApiariTimePicker
            initiaTimelValue={value}
            onChange={value => onChange(value)}
          />
        </Box>
      </Popover>
    </>
  )
}

InlineTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  ampm: PropTypes.bool,
  initialValue: PropTypes.instanceOf(Date),
  isOvernight: PropTypes.bool,
  anchorOriginPosition: PropTypes.object.isRequired,
  pickerPosition: PropTypes.object.isRequired,
}

export default InlineTimePicker

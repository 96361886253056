import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import undoUrl from '../../../assets/images/undo.png'
import homeUrl from '../../../assets/images/home.png'
import MoreVert from '@material-ui/icons/MoreVert'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: theme.spacing(2),
    alignItems: 'flex-end',
    height: 0,
  },
  fab: {
    minHeight: 56,
  },
}))
const useActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: 'pre',
  },
}))
const useTooltip = makeStyles(() => ({
  popper: {
    opacity: 0,
  },
}))

const labelProps = {
  position: 'absolute',
  background: 'white',
  right: 50,
  textTransform: 'none',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  paddingRight: 10,
  borderRadius: 6,
  paddingTop: '2px',
  justifyContent: 'flex-end',
}

const QuickActions = ({ onSend, onUndo, onBack, message, style }) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const actionClasses = useActionStyles()
  const tooltipClasses = useTooltip()

  const undoId = message?.undoId

  React.useEffect(() => {
    setOpen(false)
  }, [message])

  const actions = [
    {
      icon: (
        <>
          <Box
            boxShadow={2}
            style={{
              width: 60,
              ...labelProps,
            }}
          >
            Undo
          </Box>
          <img src={undoUrl} alt="undo" width={20} height={20} />
        </>
      ),
      name: 'Undo',
      disabled: !undoId,
      onClick: () => {
        onSend({ input: { rawText: 'Undo', type: 'undo', undoId } })
        onUndo(undoId)
        setOpen(false)
      },
    },
    {
      icon: (
        <>
          <Box
            boxShadow={2}
            style={{
              width: 156,
              ...labelProps,
            }}
          >
            Back to main menu
          </Box>
          <img src={homeUrl} alt="home" width={20} height={20} />
        </>
      ),
      name: 'Back to Main Menu',
      onClick: () => {
        onSend({ input: { rawText: 'Back to Main Menu', type: 'mainMenu' } })
        onBack()
        setOpen(false)
      },
    },
  ]

  return (
    <SpeedDial
      style={style}
      ariaLabel="quick menu"
      classes={classes}
      icon={open ? <Close /> : <MoreVert />}
      FabProps={{
        onClick: () => setOpen(!open),
      }}
      open={open}
    >
      {actions.map(action => {
        if (action.disabled) return null
        return (
          <SpeedDialAction
            title={action.name}
            tooltipPlacement="top"
            TooltipClasses={tooltipClasses}
            key={action.name}
            icon={action.icon}
            classes={{ staticTooltipLabel: actionClasses.staticTooltipLabel }}
            FabProps={{ onClick: action.onClick }}
          />
        )
      })}
    </SpeedDial>
  )
}

export default QuickActions

import * as Yup from 'yup'
import subYears from 'date-fns/subYears'
import differenceInYears from 'date-fns/differenceInYears'
import format from 'date-fns/format'
import { IMAGE_FORMATS } from '../../utils/config'
import { dateToTimestamp, unsanitizePhoneNumber } from '../../utils/utils'
import { getStateCodeByZipcode } from '../../utils/zipcodes'

export const STEPS = Object.freeze({
  ACCOUNT_CREATION: 0,

  WELCOME: 1,

  // Step 1
  EXPERIENCE: 2,

  // Step 2
  ADDRESS: 3,

  // Step 3
  BREEZING: 4,
  ABOUT_YOURSELF: 5,
  FUN_FACTS: 6,

  // Step 4
  PRICES: 7,
  PREFERENCES: 8,

  // Step 5
  UPLOADS: 9,
  SO_CLOSE: 10,
  REFERENCES: 11,
  IDENTIFICATION: 12,

  // Step 6
  ZELLE: 13,
  // TERMS: 14,
  BACKGROUND_CHECK: 14,
  CONGRATS: 15,
})

export const EMPTY_STEPS = [
  STEPS.WELCOME,
  STEPS.BREEZING,
  STEPS.SO_CLOSE,
  // STEPS.TERMS,
]

export const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  confirmPassword: '',
  interests: [],
  experience: '',
  authorizedToWorkInUS: '',
  address: {
    streetAddress: '',
    city: '',
    state: '',
    country: 'US',
    zipcode: '',
  },
  desirableCommutingTime: '60',
  hasCar: '',
  quickIntro: '',
  about: {},
  extraHelp: '',
  aboutYourself: '',
  certificates: [],
  prices: {},
  childcareAgeRange: { min: '', max: '' },
  specialNeeds: '',
  specialNeedsExperience: '',
  multipleSiblingsExperience: null,
  catsAndDogs: '',
  otherLanguages: [],
  knowsOtherLanguage: '',
  profilePic: null,
  resume: null,
  CPRCertificate: null,
  references: [
    {
      name: '',
      phoneNumber: '',
      email: '',
    },
    {
      name: '',
      phoneNumber: '',
      email: '',
    },
    {
      name: '',
      phoneNumber: '',
      email: '',
    },
  ],
  identification: '',
  zelleEmail: '',
  zellePhoneNumber: '',
}

export const CLIFFS_NOTES = [
  {
    label: "Cliff's Notes (not legally binding)",
    description:
      'You are an independent contractor with Apiari, not an employee. We do not withhold any taxes for you.',
  },
  {
    label: "Cliff's Notes (not legally binding)",
    description:
      'We will charge your clients the hourly rate you gave us, and keep 3% to pay for the services we provide. Your take-home rate is 97% of hourly rate. We make payments weekly via Zelle.',
  },
  {
    label: "Cliff's Notes (not legally binding)",
    description: 'You pay for your own meal and travel expenses.',
  },
  {
    label: "Cliff's Notes (not legally binding)",
    description:
      'Don’t "poach” our clients. If you do, you can be responsible for up to $2,500 in damages.',
  },
  {
    label: "Cliff's Notes (not legally binding)",
    description:
      'If you cancel late (under 24 hours’ notice), you may lose up to two hours of pay. If your client cancels late, you may receive up to two hours of pay. We make exceptions for illness and inclement weather.',
  },
  {
    label: "Cliff's Notes (not legally binding)",
    description:
      'If things go really wrong between us, we will go to arbitration. There will be no jury trials.',
  },
]

export const RATE_TIPS = [
  {
    label: '',
    description:
      'On average, providers earn between $18 - $30 on our platform depending on years of experience, certifications and number of reviews.',
  },
  {
    label: '',
    description:
      'Give yourself a raise anytime. Simply give us the new rate and it will apply to new bookings.',
  },
  {
    label: '',
    description:
      'We recommend starting at a lower rate first - once you pick up more bookings and great reviews, you can increase it later.',
  },
  {
    label: '',
    description:
      'We automatically add $5/hr for twins under 2 years old, and each additional child in a nanny share.',
  },
  {
    label: '',
    description:
      "Don't forget - you will be responsible for paying your own taxes as an independent contractor.",
  },
  {
    label: '',
    description:
      'Bonus: Each booking comes with late cancellation protection, client management support, and automated tip collection for FREE!',
  },
]

// https://docs.google.com/document/d/1WUq3EYAlgVT7o2Mycqh4Oyz5NJZTv20nLEz-ajh7G08/edit

export const ABOUT_SECTION_LABELS = {
  childcare: {
    heading: 'Share what makes you a great sitter or nanny',
    subHeading: 'Start with 1-2 sentences. You can always update it later!',
    placeholder:
      'I have a masters degree in Early Childhood Education. I have worked as a camp counselor and now teach K-2.',
  },
  childcarePlus: {
    heading: 'Share what makes you a great tutor',
    subHeading: 'Start with 1-2 sentences. You can always update it later!',
    placeholder:
      'I have a masters degree in Early Childhood Education, and have worked as a teacher in a Montessori school in downtown Manhattan.',
  },
  nightNurse: {
    heading: 'Share what makes you a great night nanny',
    subHeading: 'Start with 1-2 sentences. You can always update it later!',
    placeholder:
      "I am a professional newborn care specialist with a gentle demeanor and a child at heart. I have a Bachelor's degree in nursing specializing in pediatric and maternal care.",
  },
  housekeeping: {
    heading: 'Share what makes you a great housekeeping',
    subHeading: 'Start with 1-2 sentences. You can always update it later!',
    placeholder: '',
  },
}

export const VALIDATION_SCHEMA = [
  // ACCOUNT_CREATION
  Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .matches(/^[a-zA-Z0-9]*$/, 'Please enter alphanumeric characters only'),
    lastName: Yup.string()
      .required('Last name is required')
      .matches(/^[a-zA-Z0-9]*$/, 'Please enter alphanumeric characters only'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]{10}$/, 'Must be exactly a 10-digit Number.'),
    email: Yup.string()
      .email('Please enter valid e-mail address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'At least 8 characters are required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  }),

  // WELCOME
  Yup.object().shape({}),

  // EXPERIENCE
  Yup.object().shape({
    interests: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one interest area is required')
      .max(4),
    experience: Yup.number().required('Experience is required'),
    authorizedToWorkInUS: Yup.boolean().required('Answer is required'),
  }),

  // ADDRESS
  Yup.object().shape({
    address: Yup.object().shape({
      streetAddress: Yup.string().required('Street Address is required'),
      city: Yup.string().required('City is required'),
      // state: Yup.string().required('We are not able to identify your zip code'),
      zipcode: Yup.string()
        .required('Zip is required')
        .matches(/^[0-9]{5}$/, 'Must be exactly a 5-digit Number.')
        .test('State', 'We are not able to identify your zip code', value =>
          Boolean(getStateCodeByZipcode(value)),
        ),
      country: Yup.string().required('Country is required'),
    }),
    desirableCommutingTime: Yup.string().required('Answer is required'),
    hasCar: Yup.boolean().required('Answer is required'),
  }),

  // BREEZING
  Yup.object().shape({}),

  // ABOUT_YOURSELF
  Yup.object().shape({
    quickIntro: Yup.string(),
    about: Yup.object().shape({}),
    extraHelp: Yup.string(),
  }),

  // FUN_FACTS
  Yup.object().shape({
    certificates: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        date: Yup.date().nullable(true),
      }),
    ),
  }),

  // PRICES
  Yup.object().shape({
    prices: Yup.mixed(),
  }),

  // PREFERENCES
  Yup.object().shape({
    childcareAgeRange: Yup.object().shape({
      min: Yup.number().required('Min value is required'),
      max: Yup.number().required('Max value is required'),
    }),
    specialNeeds: Yup.boolean().required('Answer is required'),
    specialNeedsExperience: Yup.string()
      .ensure()
      .when('specialNeeds', {
        is: true,
        then: Yup.string().required(
          'Whoops, you forgot to write something here.',
        ),
      }),
    //multipleSiblingsExperience: Yup.string(),
    catsAndDogs: Yup.string().required('Answer is required'),
    knowsOtherLanguage: Yup.boolean().required('Answer is required'),
    otherLanguages: Yup.mixed().when('knowsOtherLanguage', {
      is: true,
      then: Yup.array()
        .of(Yup.string().required())
        .min(1, 'Select at least one language')
        .required('Select at least one language'),
      otherwise: Yup.mixed().notRequired(),
    }),
  }),

  // UPLOADS
  Yup.object().shape({
    profilePic: Yup.mixed()
      .required('Please upload your photo')
      .test(
        'fileType',
        'Unsupported File Format',
        value =>
          IMAGE_FORMATS.includes(value?.type) || typeof value === 'string',
      ),
    resume: Yup.string().nullable(true),
    CPRCertificate: Yup.string().nullable(true),
  }),

  // SO_CLOSE
  Yup.object().shape(),

  // REFERENCES
  Yup.object().shape({
    references: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().test(
            'reference-name-test',
            'Name is required',
            function(value) {
              console.log(value)
              console.log(this.path)
              return this.path === 'references[2].name' || value
            },
          ),
          phoneNumber: Yup.string()
            .test('reference-phone-test', 'Phone number is required', function(
              value,
            ) {
              console.log(value)
              console.log(this.path)
              return this.path === 'references[2].phoneNumber' || value
            })
            .matches(/^[0-9]{10}$/, 'Must be exactly a 10-digit Number.'),
          email: Yup.string().email('Invalid email address'),
        }),
      )
      .length(3),
  }),

  // IDENTIFICATION
  Yup.object().shape({
    identification: Yup.mixed()
      .required('Please upload picture identification')
      .test(
        'fileType',
        'Unsupported File Format',
        value =>
          IMAGE_FORMATS.includes(value?.type) || typeof value === 'string',
      ),
  }),

  // ZELLE
  Yup.object().shape({
    zelleEmail: Yup.string().nullable(true),
    zellePhoneNumber: Yup.string().nullable(true),
    // even with nullable() or optional() this always triggers.
    // .matches(
    //   /^([0-9]{10}$/,
    //   'Must be exactly a 10-digit Number.'
    // ),
  }),

  // TERMS
  Yup.object().shape({}),

  // BACKGROUND_CHECK
  Yup.object().shape({}),

  // CONGRATS
  Yup.object().shape({}),
]

export const CERTIFICATE_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  date: Yup.string()
    .nullable(true)
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
      'Invalid date',
    ),
})

export const CERTIFICATE_INITIAL_VALUES = {
  name: '',
  date: null,
}

export const getExperienceSince = duration => {
  return dateToTimestamp(subYears(new Date(), duration))
}

export const getExperienceYears = timestamp => {
  return differenceInYears(new Date(), new Date(timestamp))
}

export const setAboutKeysOrder = services => {
  const about = {}
  if (services.includes('night_nurse')) {
    about.nightNurse = ''
  }
  if (services.includes('childcare')) {
    about.childcare = ''
  }
  if (services.includes('childcarePlus')) {
    about.childcarePlus = ''
  }
  if (services.includes('housekeeping')) {
    about.housekeeping = ''
  }
  return about
}

const getReferencesData = references => {
  const actualData = references.filter(item => item.name && item.phoneNumber)
  if (!actualData.length) {
    return null
  }
  return actualData
}

const setReferencesData = references => {
  if (!references || !references.length) {
    return INITIAL_VALUES.references
  }
  const formReferences = []
  for (let i = 0; i < 3; i++) {
    if (references[i]) {
      formReferences.push({
        name: references[i].name || '',
        email: references[i].email || '',
        phoneNumber: references[i].phoneNumber || '',
      })
    } else {
      formReferences.push({
        name: '',
        email: '',
        phoneNumber: '',
      })
    }
  }
  return formReferences
}

const getCatsAndDogsValue = ({ allowsCats, allowsDogs }) => {
  if (allowsCats && allowsDogs) {
    return 'all'
  }
  if (allowsCats) {
    return 'noDogs'
  }
  if (allowsDogs) {
    return 'noCats'
  }
  return ''
}

const formatCerts = certs => {
  return certs.map(cert => ({
    name: cert.name,
    date:
      cert.timestamp && cert.timestamp.length > 0
        ? format(cert.timestamp, 'MM/dd/yyyy')
        : null,
  }))
}

export const setInitialValues = user => {
  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    phoneNumber: user.phoneNumber
      ? unsanitizePhoneNumber(user.phoneNumber)
      : '',
    email: user.email || '',
    password: user.id ? '********' : '',
    confirmPassword: user.id ? '********' : '',
    interests: user.services || [],
    experience: user.experienceSince
      ? getExperienceYears(user.experienceSince)
      : '',
    authorizedToWorkInUS: user.services?.length ? true : '',
    address:
      user.address && user.address.zipcode
        ? user.address
        : {
            streetAddress: '',
            city: '',
            state: '',
            country: 'US',
            zipcode: '',
          },
    desirableCommutingTime: user.desirableCommutingTime || '60',
    hasCar: user.hasCar !== null ? user.hasCar : '',
    quickIntro: user.quickIntro || '',
    about: user.about || {},
    extraHelp: user.extraService || '',
    aboutYourself: user.funFacts || '',
    certificates:
      user.certificates && user.certificates.length
        ? formatCerts(user.certificates)
        : [],
    prices: user.prices || {},
    childcareAgeRange: user.childcareAgeRange || { min: '', max: '' },
    specialNeeds:
      user.hasSpecialNeedsExperience !== null
        ? user.hasSpecialNeedsExperience
        : '',
    specialNeedsExperience: user.specialNeeds || '',
    multipleSiblingsExperience:
      user.multipleSiblingsExperience !== null
        ? user.multipleSiblingsExperience
        : null,
    catsAndDogs: getCatsAndDogsValue(user),
    otherLanguages: user.speaksLanguages || [],
    knowsOtherLanguage: user.speaksLanguages?.length
      ? true
      : user.multipleSiblingsExperience !== null
      ? false
      : '',
    profilePic: user.profilePic || null,
    resume: user.resume || null,
    CPRCertificate: user.CPRCertificate || null,
    references: setReferencesData(user.references),
    identification: user.identificationPhoto || '',
    zelleEmail: user.zelleEmail || '',
    zellePhoneNumber: user.zellePhoneNumber || '',
  }
}

export const prepareProviderData = (formData, id, step) => {
  let stepNames = {}
  Object.keys(STEPS).forEach(x => {
    stepNames[STEPS[x]] = x
  })
  const becomingProviderLastStep = stepNames[step + 1]

  const requestBody = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    hasCar: formData.hasCar !== '' ? formData.hasCar : null,
    profilePic: formData.profilePic || null,
    address: formData.address,
    funFacts: formData.aboutYourself || null,
    services: formData.interests,
    certificates: formData.certificates?.length
      ? formData.certificates.map(cert => ({
          name: cert.name,
          timestamp: dateToTimestamp(cert.date),
        }))
      : [],
    experienceSince: formData.experience
      ? getExperienceSince(formData.experience)
      : null,
    hasSpecialNeedsExperience:
      formData.specialNeeds !== '' ? formData.specialNeeds : null,
    specialNeeds: formData.specialNeedsExperience || null,
    numberOfReferences: getReferencesData(formData.references)?.length || null,
    desirableCommutingTime: formData.desirableCommutingTime,
    allowsCats: formData.catsAndDogs?.length
      ? formData.catsAndDogs !== 'noCats' && formData.catsAndDogs !== 'neither'
      : null,
    allowsDogs: formData.catsAndDogs?.length
      ? formData.catsAndDogs !== 'noDogs' && formData.catsAndDogs !== 'neither'
      : null,
    speaksLanguages: formData.otherLanguages?.length
      ? formData.otherLanguages
      : null,
    about: formData.about || null,
    references: getReferencesData(formData.references),
    resume: formData.resume || null,
    CPRCertificate: formData.CPRCertificate || null,
    multipleSiblingsExperience: formData.multipleSiblingsExperience,
    identificationPhoto: formData.identification || null,
    quickIntro: formData.quickIntro || null,
    extraService: formData.extraHelp || null,
    zelleEmail: formData.zelleEmail || null,
    zellePhoneNumber: formData.zellePhoneNumber || null,
    childcareAgeRange:
      formData.childcareAgeRange && formData.childcareAgeRange.min !== ''
        ? formData.childcareAgeRange
        : null,
    becomingProviderLastStep: becomingProviderLastStep,
  }
  if (formData.stripeToken) {
    requestBody.billingMode = 'cc'
    requestBody.stripeToken = formData.stripeToken
  }
  if (step === STEPS.PRICES) {
    requestBody.prices = Object.keys(formData.prices).length
      ? formData.prices
      : null
  }

  if (step === STEPS.BACKGROUND_CHECK) {
    // actually accepted on TERMS but must have passed through that to get here
    // and acceptedProviderTerms is the more important long term indicator that they're approved.
    // If this flag is set then user should be redirected to '/account' page.
    requestBody.acceptedProviderTerms = true

    // Used by apiari-user to determine flow is complete and email/slack notifications should be sent
    // is deleted from user before saving.
    requestBody.becomingProviderComplete = true
  }

  if (!id) {
    return {
      email: formData.email,
      password: formData.password,
      userRole: 'provider',
      isActive: 0,
      ...requestBody,
    }
  }

  return requestBody
}

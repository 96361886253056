import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Bulb from '../../assets/blub.svg'

const useStyles = makeStyles(theme => ({
  accountWrapper: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    background: theme.palette.background.lightPink,
    padding: '18px',
    borderRadius: '9px',
    '& > span': {
      marginLeft: '14px',
      display: 'flex',
      fontSize: '12px',
      lineHeight: '1.33',
      flexDirection: 'column',
      '& > span': {
        fontFamily: 'RobotoBold',
        marginBottom: '5px',
      },
    },
  },
}))

const TipBox = props => {
  const { heading, subHeading } = props
  const classes = useStyles()

  return (
    <div className={classes.accountWrapper}>
      <img src={Bulb} alt="Bulb" />

      <span>
        {' '}
        <span>{heading}</span>
        {subHeading}
      </span>
    </div>
  )
}

export default React.memo(TipBox)

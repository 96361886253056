import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Action from '../models/action'
import ChatDatePicker from '../components/actions/chat-date-picker'
import ChatInputForm from '../components/actions/chat-input'
import ChatLogin from '../components/actions/chat-login'
import ChatRegister from '../components/actions/chat-register'
import ChatSelect from '../components/actions/chat-select'
import ChatTimePicker from '../components/actions/chat-time-picker'
import StripeChatPayment from '../components/actions/stripe-chat-payment'
import MessageText from '../components/messages/message-text'
import ChatSummaryCard from '../components/actions/chat-summary-card'
import { hideCharacters } from '../../../utils/utils'
import ChatPagination from '../components/actions/chat-pagination'
import ChatAppointmentPagination from '../components/actions/chat-appointment-pagination'
import ChildSelector from '../components/actions/child-selector'
import ChatAddSession from '../components/actions/chat-add-session'

const ChatBotActions = ({ action, onSend, onUndo }) => {
  const handleChange = input => onSend({ input })

  if (!action) return null

  return (
    <>
      {action.displayChatInput && (
        <Box mb={10}>
          <ChatInputForm
            onSubmit={handleChange}
            type={action.type}
            buttons={action.buttons}
            {...(action.type === 'textarea' && {
              initValue: action.data ? action.data.default : '',
              rows: action.data ? action.data.size : 3,
            })}
          />
        </Box>
      )}
      {action.displayChatSelect && (
        <ChatSelect
          isMulti={action.isMulti}
          isRequired={action.isRequired}
          options={action.buttons}
          onChange={handleChange}
        />
      )}
      {action.displayDatePicker && (
        <MessageText
          isFromBot
          text={
            <ChatDatePicker
              onChange={handleChange}
              buttons={action.buttons}
              includePast={action.data && action.data.includePast}
              includeFlex={action.data && action.data.includeFlex}
            />
          }
        />
      )}
      {action.displayTimePicker && (
        <MessageText
          isFromBot
          text={
            <ChatTimePicker
              onChange={handleChange}
              times={action.timeSlots}
              buttons={action.buttons}
            />
          }
        />
      )}
      {action.displayDateTimePicker && (
        <ChatAddSession
          onSave={handleChange}
          onCancel={onUndo}
          title={action.message}
          {...action.data}
        />
      )}
      {action.displaySummaryCard && (
        <ChatSummaryCard {...action.data} onChange={handleChange} />
      )}
      {action.displayAsPagination && (
        <>
          {action.data?.[0]?.type === 'providerCarouselItem' && (
            <ChatPagination items={action.data} onChange={handleChange} />
          )}
          {action.data?.[0]?.type === 'providerCarouselItem-likable' && (
            <ChatPagination
              items={action.data}
              onChange={handleChange}
              isLikable
            />
          )}
          {action.data?.[0]?.type === 'appointmentCarouselItem' && (
            <ChatAppointmentPagination items={action.data} />
          )}
        </>
      )}
      {action.displayRegisterComponent && (
        <ChatRegister
          onSubmit={data => {
            onSend({
              input: {
                type: 'signup',
                message: 'signup',
                rawText: `${data.firstName} ${data.lastName}
                    ${data.email}
                    ${data.phoneNumber}
                    ${hideCharacters(data.password)}`,
              },
              user: data,
            }) // TODO: cleanup
          }}
        />
      )}
      {action.displayLoginComponent && (
        <ChatLogin
          onSubmit={data => {
            onSend({
              input: {
                type: 'login',
                message: 'login',
                rawText: `${data.email}\n${hideCharacters(data.password)}`,
              },
              user: data,
            }) // TODO: cleanup
          }}
        />
      )}
      {action.displayStripePayment && (
        <StripeChatPayment onPaymentSuccess={handleChange} />
      )}
      {action.displayChildSelector && <ChildSelector onSubmit={handleChange} />}
    </>
  )
}

ChatBotActions.propTypes = {
  action: PropTypes.instanceOf(Action),
  onSend: PropTypes.func.isRequired,
}

export default ChatBotActions

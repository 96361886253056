import React from 'react'
import { useObserver } from 'mobx-react-lite'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Header from './header/header'
import Footer from './footer/footer'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import storeContext from '../stores'
import Routes from '../routes/routes'
import { Match, navigate, globalHistory } from '@reach/router'
import LoadingScreen from '../components/loading-wrapper/loading-wrapper'
import logo from '../assets/images/apiari_logo.png'
import poweredByApiariLogo from '../assets/images/powered_by_apiari.png'

export const useGlobalStyles = makeStyles({
  '@global': {
    'a:any-link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.8)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    'input[readonly]': {
      backgroundColor: 'transparent',
    },
  },
})

const useStyles = makeStyles({
  '@global': {
    '.apiari-business .only-apiari-classic': {
      display: 'none',
    },
    '.apiari-classic .only-apiari-business': {
      display: 'none',
    },
    'html, body, #root': {
      height: '100%',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      '& > header, & > footer': {
        flexGrow: 0,
      },
      '& > main': {
        position: 'relative',
        flex: 1,
        '&.apiari-business, &.become-a-provider': {
          padding: 0,
        },
        '&.bot + footer, &.become-a-provider + footer': {
          display: 'none',
        },
        '& > div': {
          height: '100%',
        },
      },
    },
  },
})

const Layout = () => {
  useGlobalStyles()
  useStyles()
  const store = React.useContext(storeContext)

  React.useEffect(() => {
    const locationObserver = globalHistory.listen(({ location }) => {
      if (window.ga) {
        window.ga('set', 'page', location.pathname)
        window.ga('send', 'pageview')
      }
    })
    return () => locationObserver()
  }, [])

  const apiariBusiness =
    window.location.host.indexOf('apiari.net') !== -1 ||
    window.location.hash.indexOf('apiari.net') !== -1
  return useObserver(() => {
    if (store.ui.reAuthenticating) {
      return (
        <LoadingScreen logo={apiariBusiness ? poweredByApiariLogo : logo} />
      )
    }

    return (
      <>
        <Match path="/chat-bot">
          {({ match: isBot }) => (
            <Match path="/new-session">
              {({ match: isSessionBot }) => (
                <Match path="/user/*">
                  {({ match: isUserProfile }) => (
                    <Match path="/calendar/:alias">
                      {({ match: isCalendar }) => (
                        <Match path="/become-a-provider">
                          {({ match: isProviderFlow }) => (
                            <>
                              {isProviderFlow ||
                              (apiariBusiness &&
                                (isCalendar || isUserProfile)) ? (
                                <></>
                              ) : (
                                <Header
                                  isBot={isBot}
                                  onLogout={() => navigate('/login')}
                                  isApiariBusiness={apiariBusiness}
                                />
                              )}
                              <Box
                                {...(!store.auth.isAuthenticated &&
                                  !isBot &&
                                  !isSessionBot && { bgcolor: 'greyBlue.100' })}
                                {...(!isBot && !isSessionBot && { pt: 4 })}
                                {...(isProviderFlow && { bgcolor: 'white' })}
                                component="main"
                                className={
                                  isProviderFlow
                                    ? 'become-a-provider'
                                    : isSessionBot || isBot
                                    ? 'bot apiari-classic'
                                    : apiariBusiness
                                    ? 'apiari-business'
                                    : 'apiari-classic'
                                }
                              >
                                <Container
                                  maxWidth={false}
                                  disableGutters={Boolean(
                                    !!isBot ||
                                      !!isSessionBot ||
                                      !!apiariBusiness ||
                                      isProviderFlow,
                                  )}
                                >
                                  <Routes />
                                </Container>
                              </Box>
                              <Footer
                                isApiariBusiness={apiariBusiness}
                                isPublicPage={
                                  apiariBusiness &&
                                  (isCalendar || isUserProfile)
                                }
                              />
                            </>
                          )}
                        </Match>
                      )}
                    </Match>
                  )}
                </Match>
              )}
            </Match>
          )}
        </Match>
      </>
    )
  })
}

export default Layout

import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const SubTitle = ({ icon, text, ...rest }) => (
  <Box
    fontFamily="camptonBold"
    fontSize={18}
    display="flex"
    alignItems="center"
    {...rest}
  >
    {icon ? (
      <Box color="secondary.main" mr={1.5} display="flex" alignItems="center">
        {icon}
      </Box>
    ) : null}
    {text}
  </Box>
)

SubTitle.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
}

export default SubTitle

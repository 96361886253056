import React, { useState } from 'react'
import { navigate } from '@reach/router'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import ApiariButton from '../../../components/inputs/button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import storeContext from '../../../stores'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { subscribeUserApi } from '../../../api/provider-api'
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card.svg'

const useStyles = makeStyles(theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
  },
  buttonYellow: {
    width: '80%',
    color: theme.palette.text.grey,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: 500,
    marginTop: '1rem',
    marginBottom: '0.5rem',
    fontFamily: 'Roboto',
    textTransform: 'none',
    minWidth: '200px',
    lineHeight: '45px',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      minWidth: '80px',
      padding: '0 20px',
    },
    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  subHead: {
    display: 'block',
    width: '80%',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '-0.07px',
    lineHeight: '20px',
    fontFamily: 'Roboto',
    margin: '5px',
    '& a:any-link': {
      textDecoration: 'underline',
    },
  },
  creditCardwrapper: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '16px 20px',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#111b47',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardWrapper: {
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '16px 20px',
    fontWeight: '600',
  },
  termsLabel: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '5px',
    '& .MuiCheckbox-root': {
      padding: '2px',
    },
    '& a:any-link': {
      textDecoration: 'underline',
    },
  },
  mainCardContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  cvcContainer: {
    width: '50%',
    display: 'flex',
    background: theme.palette.background.light,
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '1rem',
      placeContent: 'space-between',
      background: '#fff',
      borderRadius: '0px',
    },
  },
  number: {
    width: '50%',
  },
  cvcItems: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      borderRadius: '5px',
    },
  },
}))
const STRIPE_CARD_NUMBER_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      width: '100%',
      paddingLeft: '1rem',
      width: '100%', // Set the desired width here
      paddingLeft: '0.5rem',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
  placeholder: 'Credit Card Number',
}
const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
}

const validationSchema = Yup.object().shape({
  checkbox1: Yup.bool()
    .oneOf([true], 'You must check both terms')
    .required(),
  checkbox2: Yup.bool()
    .oneOf([true], 'You must check both terms')
    .required(),
})

const StripeCardElement = () => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  const [isInProgress, setIsInProgress] = useState(false)

  const checkCard = async () => {
    if (stripe && elements) {
      const cardElement = elements.getElement(CardNumberElement)
      if (cardElement) {
        const { token, error } = await stripe.createToken(cardElement)
        if (token) {
          return { token }
        } else if (error) {
          console.error(error)
          return { error }
        }
      }
    }
  }

  const chargeCard = async (values, { setSubmitting, setErrors }) => {
    setIsInProgress(true)
    if (!values.checkbox1 || !values.checkbox2) {
      setErrors({
        checkbox1: 'You must check both terms',
        checkbox2: 'You must check both terms',
      })
    } else {
      const { error, token } = await checkCard()
      if (error) {
        setErrors({ cardError: error.message })
      }
      if (token) {
        try {
          const { data, error } = await subscribeUserApi({
            shortUserId: user?.shortUserId,
            token: token.id,
          })
          if (!data.error) {
            navigate('/my-membership')
          }
          setErrors({ cardError: data.error })
        } catch (e) {
          console.log('chargeCard catched err', e)
          setErrors({ cardError: e.message })
        }
      }
    }
    setSubmitting(false)
    setIsInProgress(false)
  }

  const {
    auth: { user },
  } = React.useContext(storeContext)

  return (
    <Formik
      initialValues={{ checkbox1: false, checkbox2: false }}
      validationSchema={validationSchema}
      onSubmit={chargeCard}
    >
      {({ errors }) => (
        <Form>
          <Box
            height="100%"
            padding={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Box
                px={1}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                fontSize={{ xs: 16, md: 20 }}
                fontFamily="RobotoBlack"
                color="text.darkGrey"
                display="block"
              >
                <Box className={classes.mainCardContainer}>
                  <Box className={classes.creditCardwrapper}>
                    <CreditCardIcon fill={'red'} />
                    <Box style={{ width: '100%', paddingLeft: '0.5rem' }}>
                      <CardNumberElement options={STRIPE_CARD_NUMBER_OPTIONS} />
                    </Box>
                  </Box>
                  <Box className={classes.cvcContainer}>
                    <Box className={classes.cvcItems}>
                      <CardExpiryElement
                        className={classes.cardWrapper}
                        options={STRIPE_CARD_ELEMENT_OPTIONS}
                      />
                    </Box>
                    <Box className={classes.cvcItems}>
                      <CardCvcElement
                        className={classes.cardWrapper}
                        options={STRIPE_CARD_ELEMENT_OPTIONS}
                      />
                    </Box>
                  </Box>
                </Box>
                {errors.cardError && (
                  <Typography component="p" className={classes.errorText}>
                    {errors.cardError}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={1}>
                <label className={classes.termsLabel}>
                  <Field
                    type="checkbox"
                    name="checkbox1"
                    as={Checkbox}
                    color="primary"
                    style={{ marginRight: '0.5rem' }}
                  />
                  <span>
                    I agree that my subscription will automatically renew, and
                    authorize charges for the above subscription fee.
                  </span>
                </label>
                {errors.checkbox1 && (
                  <Typography component="p" className={classes.errorText}>
                    {errors.checkbox1}
                  </Typography>
                )}

                <label
                  className={classes.termsLabel}
                  style={{ marginTop: '1rem' }}
                >
                  <Field
                    type="checkbox"
                    name="checkbox2"
                    as={Checkbox}
                    color="primary"
                    style={{ marginRight: '0.5rem' }}
                  />
                  <span>
                    I have read and agree to the{' '}
                    <a href="https://theapiari.com/providerterms">
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a href="https://theapiari.com/privacy">Privacy Policy</a>.
                  </span>
                </label>
                {errors.checkbox2 && (
                  <Typography component="p" className={classes.errorText}>
                    {errors.checkbox2}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Box display="flex" flexDirection="column" alignItems="center">
              <ApiariButton
                fullWidth
                type="submit"
                className={classes.buttonYellow}
                busy={isInProgress}
              >
                Subscribe
              </ApiariButton>
              <Typography className={classes.subHead}>
                You can cancel your subscription at any time by emailing us{' '}
                <a href="mailto:hello@theapiari.com">here</a>. No partial
                refunds.
              </Typography>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default StripeCardElement

import React from 'react'
import * as PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import { DATE_FORMATS } from '../../../../utils/config'
import ChatSelect from './chat-select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'

const ChatDatePicker = ({
  onChange,
  initialValue,
  buttons,
  includePast,
  includeFlex,
}) => {
  let initDate = initialValue
  if (includeFlex && !initDate) initDate = format(new Date(), DATE_FORMATS.DATE)
  const [selectedDate, handleDateChange] = React.useState(initDate)
  const [flexibleStart, handleFlexibleStartChange] = React.useState(false)
  // console.log(buttons);
  return (
    <div>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        variant="static"
        onAccept={date =>
          includeFlex ||
          onChange({ type: 'date', message: format(date, DATE_FORMATS.DATE) })
        }
        autoOk
        disablePast={!includePast}
        disableToolbar
      />
      {includeFlex && (
        <FormControlLabel
          name="acceptedTos"
          style={{ marginLeft: -7, marginTop: -20 }}
          control={
            <Checkbox
              color="primary"
              onChange={e => {
                e.persist()
                handleFlexibleStartChange(() => !flexibleStart)
              }}
            />
          }
          label={
            <Box fontWeight="bold">
              <Box component="span" fontFamily="campton">
                &plusmn;
              </Box>{' '}
              1 week
            </Box>
          }
        />
      )}
      {includeFlex && (
        <ChatSelect
          options={[{ text: 'Next', value: 'done' }]}
          onChange={d =>
            onChange({
              data: { flexibleStart },
              type: 'date',
              message: format(selectedDate, DATE_FORMATS.DATE),
            })
          }
        />
      )}

      {buttons && <ChatSelect options={buttons} onChange={onChange} />}
    </div>
  )
}

ChatDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  initialValue: PropTypes.instanceOf(Date),
}

export default ChatDatePicker

import { useContext, useState, useEffect } from 'react'
import { useNavigate } from '@reach/router'
import { isAfter } from 'date-fns'
import { useObserver } from 'mobx-react-lite'
import { Close, Menu } from '@material-ui/icons'
import { Box, Grid, Hidden, Drawer, AppBar } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import HeaderRoutes from './header-routes'
import HeaderProfile from './header-profile'
import apiariLogo from '../../assets/images/apiari_logo.png'
import apiariBusinessLogo from '../../assets/images/apiariBusinessLogo.png'
import storeContext from '../../stores'
import { getMembershipStatus } from '../../api/provider-api'

import Banner from '../../components/banner'
import NavLink from '../../components/nav-link/nav-link'
import { WhiteApiariButton } from '../../components/inputs/button'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    background: theme.palette.text.grey,
    textTransform: 'uppercase',
    marginTop: 63, // header height
  },
  toggle: {
    cursor: 'pointer',
  },
  logo: {
    width: 149,
  },
}))

const Header = ({ isApiariBusiness, onLogout, isBot }) => {
  const navigate = useNavigate()
  const store = useContext(storeContext)
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const user = store.auth.user

  const [membershipInfo, setMembershipInfo] = useState(null)
  const [isMembershipStatusLoading, setIsMembershipStatusLoading] = useState(
    false,
  )

  const isProvider = user?.role === 'provider'
  const isBecomingProviderComplete = Boolean(user?.becomingProviderComplete)

  const isUserActive = Boolean(user?.isActive)
  const isSubscriptionActive = membershipInfo?.status === 'active'
  const isLegacyUser =
    user?.lastBackgroundCheck &&
    isAfter(new Date(user?.lastBackgroundCheck), new Date(2017, 0, 1)) &&
    !isSubscriptionActive
  const showJoinPremiumBanner =
    Boolean(isBot) &&
    isProvider &&
    !isMembershipStatusLoading &&
    ((!isUserActive && isBecomingProviderComplete && !isSubscriptionActive) ||
      isLegacyUser)

  const fetchMembership = async () => {
    try {
      setIsMembershipStatusLoading(true)
      const { data } = await getMembershipStatus({ email: user?.email })
      setIsMembershipStatusLoading(false)
      if (data.success) {
        const subscriptionStartDate = new Date(
          data.membershipInfo.autoRenew * 1000,
        )
        setMembershipInfo({
          ...data.membershipInfo,
          status: data.status,
          date: subscriptionStartDate,
        })
      }
    } catch {
      setIsMembershipStatusLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      user && fetchMembership()
    })()
  }, [user])

  return useObserver(() => (
    <AppBar color="inherit" position="sticky" elevation={0}>
      {showJoinPremiumBanner && (
        <Banner
          title="Join our Premium Matching Platform and get matched with new clients!"
          button={
            <WhiteApiariButton onClick={() => navigate('/subscribe')}>
              Join Premium
            </WhiteApiariButton>
          }
        />
      )}
      {Boolean(isBot) &&
        isProvider &&
        !isBecomingProviderComplete &&
        !isUserActive &&
        !isLegacyUser && (
          <Banner
            title="Get matched"
            text="It looks like your profile is not completed yet. Complete your profile to get matched with new clients."
            button={
              <WhiteApiariButton onClick={() => navigate('/become-a-provider')}>
                Complete Profile
              </WhiteApiariButton>
            }
          />
        )}
      <Box
        fontSize="12px"
        fontFamily="camptonBold"
        color="text.grey"
        fontWeight={700}
        px={3}
        py={1}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs="auto">
            <a
              href={
                isApiariBusiness
                  ? 'https://www.apiari.net'
                  : 'https://theapiari.com/'
              }
            >
              <img
                src={isApiariBusiness ? apiariBusinessLogo : apiariLogo}
                alt="logo"
                className={classes.logo}
              />
            </a>
          </Grid>
          <Grid item xs container justify="flex-end" alignItems="center">
            <Hidden smDown>
              <HeaderRoutes
                display="inline"
                isAuthenticated={store.auth.isAuthenticated}
                role={store.auth.user && store.auth.user.role}
                hasFavorites={!!store.auth.favorites.length}
                isApiariBusiness={isApiariBusiness}
                isActiveUser={store.auth.user && store.auth.user.isActive}
              />
              {store.auth.isAuthenticated && (
                <Box component={Grid} pl={2} item>
                  <HeaderProfile
                    onLogout={onLogout}
                    role={store.auth.user && store.auth.user.role}
                  />
                </Box>
              )}
            </Hidden>
            <Hidden mdUp>
              <Box color="secondary.main" className={classes.toggle}>
                {open ? (
                  <Close fontSize="large" onClick={() => setOpen(false)} />
                ) : (
                  <Menu fontSize="large" onClick={() => setOpen(true)} />
                )}
              </Box>
              <Drawer
                open={open}
                anchor="top"
                style={{ zIndex: 1099 }}
                classes={{ paper: classes.drawerPaper }}
                elevation={0}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(false)}
              >
                <Box
                  color="white"
                  p={4}
                  fontSize="18px"
                  fontFamily="camptonBold"
                >
                  <HeaderRoutes
                    display="block"
                    isAuthenticated={store.auth.isAuthenticated}
                    role={store.auth.user && store.auth.user.role}
                    hasFavorites={!!store.auth.favorites.length}
                    isActiveUser={store.auth.user && store.auth.user.isActive}
                  />
                  {store.auth.isAuthenticated && (
                    <>
                      <Box
                        component={NavLink}
                        display="block"
                        to="/account"
                        px={1.5}
                        ml={1}
                        py={1.5}
                      >
                        My Profile
                      </Box>
                      {store?.auth?.user?.role === 'provider' && (
                        <Box
                          component={NavLink}
                          display="block"
                          to="/my-membership"
                          px={1.5}
                          ml={1}
                          py={1.5}
                        >
                          My Membership
                        </Box>
                      )}
                      <Box
                        px={1.5}
                        ml={1}
                        py={1.5}
                        onClick={() => {
                          store.auth.logOutAction()
                          onLogout()
                        }}
                      >
                        Logout
                      </Box>
                    </>
                  )}
                </Box>
              </Drawer>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  ))
}

export default Header

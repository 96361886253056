import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
//import WelcomeImage from '../../../assets/welcome.svg'
import HexagonBullet from '../../../components/hexagon-bullet/hexagon-bullet'
import closeLogo from '../../../assets/images/so-close.png'

const useStyles = makeStyles(theme => ({
  pageHeading: {
    color: theme.palette.text.darkGrey,
    //textTransform: 'uppercase',
    //letterSpacing: '0.8px',
    //lineHeight: '2',
  },

  cta: {
    color: theme.palette.text.darkGrey,
    letterSpacing: '0.8px',
    lineHeight: '2.5',
  },

  imageBox: {
    textAlign: 'center',
  },

  pageContainer: {
    //textAlign: 'center',

    '& img': {
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '300px',
      },
    },
  },

  list: {
    '& li': {
      listStyleType: 'none',
    },
  },
}))

const Welcome = () => {
  const classes = useStyles()

  return (
    <Box maxWidth={600} m="0 auto">
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12}>
          <Box px={7} mt={-5} mb={3.5} className={classes.imageBox}>
            <img src={closeLogo} alt="Welcome" />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            px={1}
            fontSize={20}
            fontFamily="RobotoBlack"
            className={classes.pageHeading}
            mb={1}
          >
            Since 2018, our providers have earned $2,147,958.90 with jobs
            managed by Apiari.
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box px={1} fontSize={18} fontFamily="Roboto" mb={2}>
            Here's what we'll ask:
          </Box>
          <Box mb={-1}>
            <HexagonBullet
              content="1"
              text="Tell us about your yourself and your job prefrences."
            />
            <HexagonBullet content="2" text="Set your own rate." />
            <HexagonBullet
              content="3"
              text="Pass a reference check and background check. ✔️"
            />
          </Box>
          <Box
            px={1}
            fontSize={18}
            fontFamily="RobotoBlack"
            className={classes.cta}
            mb={1}
          >
            Get matched and accept jobs that meet your preferences!
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(Welcome)

import { flow, types } from 'mobx-state-tree'
import { getUserApi } from '../../api/auth-api'
import UserModel from './user-model'

const UsersModel = types
  .model('UsersModel', {
    entries: types.map(UserModel),
  })
  .actions(self => ({
    setUser(user) {
      const model = {
        ...user,
        id: String(user.shortUserId),
        role: user.userRole,
        phoneNumber: user.phoneNumber,
        becomingProviderComplete: user.becomingProviderLastStep === 'CONGRATS',
      }
      self.entries.set(user.shortUserId, model)

      // Ivan, please fix if there's a better way to do this!
      if (user.alias) {
        self.entries.set(user.alias, { ...model, id: user.alias })
      }
    },
    getMissingUsersIds(usersIds) {
      return usersIds.filter(id => id && !self.entries.has(id))
    },
    getUser: flow(function* getUser(id) {
      const user = self.entries.get(id)
      if (user) return user
      const { data } = yield getUserApi(id)
      if (data.reliabilityScore == undefined || data.reliabilityScore == '')
        data.reliabilityScore = 0
      else data.reliabilityScore = parseFloat(data.reliabilityScore, 10)
      self.setUser(data)
    }),
    reset() {
      self.entries = {}
    },
  }))

export default UsersModel

import React from 'react'
import { Link } from '@reach/router'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ApiariButton from '../../components/inputs/button'
import Hidden from '@material-ui/core/Hidden'
import storeContext from '../../stores'
import { useObserver } from 'mobx-react-lite'
import ListItem from '../../components/responsive-table/components/list-item'
import ListItemField from '../../components/responsive-table/components/list-item-field'
import ListItemAction from '../../components/responsive-table/components/list-item-action'
import format from 'date-fns/format'
import { DATE_FORMATS } from '../../utils/config'
import Avatar from '@material-ui/core/Avatar'
import Service from '../../components/service/service'

const MyBookingsPage = () => {
  const store = React.useContext(storeContext)
  return useObserver(() => (
    <>
      <Box
        pb={4}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ md: 'center' }}
      >
        <Box fontSize="1.6rem" fontFamily="camptonBold">
          My Bookings
        </Box>
        <Box alignSelf={{ xs: 'flex-end' }} mt={{ xs: 2, md: 0 }} color="white">
          <ApiariButton component={Link} to="/new-session">
            add new session
          </ApiariButton>
        </Box>
      </Box>
      <Hidden smDown>
        <Box display="flex" fontWeight={700} fontFamily="camptonBold" mb={1}>
          <Box px={2} width="10%">
            DATE
          </Box>
          <Box px={2} width="10%">
            SERVICE
          </Box>
          <Box px={2} width="10%">
            STATUS
          </Box>
          <Box px={2} width="20%">
            NAME
          </Box>
          <Box px={2} width="10%">
            TIME
          </Box>
          <Box px={2} width="15%">
            PROVIDER N.
          </Box>
          <Box px={2} width="15%">
            NOTES
          </Box>
          <Box px={2} width="10%" />
        </Box>
      </Hidden>
      {store.events.loggedUserEvents.map((event, i) => (
        <ListItem
          key={event.instanceId}
          first={i === 0}
          only={store.events.entries.size === 1}
          last={i === store.events.entries.size - 1}
        >
          <Box width={{ md: '10%' }}>
            <ListItemField title="DATE">
              <Typography variant="body2" noWrap>
                {format(event.start, DATE_FORMATS.DATE)}
              </Typography>
            </ListItemField>
          </Box>
          <Box width={{ md: '10%' }}>
            <ListItemField title="SERVICE" px={2} py={1}>
              <Service service={event.serviceName?.toUpperCase() ?? 'N/A'} />
            </ListItemField>
          </Box>
          <Box width={{ md: '10%' }}>
            <ListItemField title="STATUS">
              <Typography variant="body2" noWrap>
                {event.status.toUpperCase()}
              </Typography>
            </ListItemField>
          </Box>
          <Box width={{ md: '20%' }} position="relative">
            <ListItemField
              title="NAME"
              component={Link}
              to={`/user/${event.provider.id}?service=${event.serviceName}`}
            >
              <Box
                height={40}
                width={40}
                mr={1}
                flex="none"
                position="absolute"
                top="6px"
              >
                <Avatar alt="avatar" src={event.provider.profilePic}>
                  {event.provider.initials}
                </Avatar>
              </Box>
              <Box pl={7}>
                <Typography variant="body2" noWrap>
                  {event.provider.lastInitials}
                </Typography>
              </Box>
            </ListItemField>
          </Box>
          <Box width={{ md: '10%' }}>
            <ListItemField title="TIME">
              <Typography variant="body2" noWrap>
                {event.eventDuration}
              </Typography>
            </ListItemField>
          </Box>
          <Box width={{ md: '15%' }}>
            <ListItemField title="PROVIDER N.">
              <Typography variant="body2" noWrap>
                {event.provider.phoneNumber}
              </Typography>
            </ListItemField>
          </Box>
          <Box width={{ md: '15%' }}>
            <ListItemField title="NOTES">
              <Typography variant="body2" noWrap>
                {event.notes}
              </Typography>
            </ListItemField>
          </Box>
          <Box width={{ md: `10%` }}>
            <ListItemField last>
              <ListItemAction instance={event} />
            </ListItemField>
          </Box>
        </ListItem>
      ))}
    </>
  ))
}

export default MyBookingsPage

import { format } from 'date-fns'

export function serviceNameToHuman(service) {
  if (!service) return ''
  if (service === 'deepCleaning') return 'Deep cleaning'
  else if (service === 'childcarePlus') return 'Enrichment'
  else if (service === 'night_nurse') return 'Night Nurse'
  return service.charAt(0).toUpperCase() + service.slice(1)
}
const Profile = profile => {
  return {
    ...profile,
    getShortName() {
      return `${profile.firstName} ${profile.lastName.slice(0, 1)}.`
    },
    getServiceNamesHuman() {
      return (profile.services || []).map(s => serviceNameToHuman(s))
    },
    getQualifications(service) {
      const diff =
        (new Date(profile.experienceSince).getTime() - new Date().getTime()) /
        1000 /
        60 /
        60 /
        24
      const yearsExperience = Math.abs(Math.round(diff / 365.25))
      let humanServiceName = serviceNameToHuman(service)
      const qualifications = [
        {
          title: `${yearsExperience} Years Experience`,
          subTitle: humanServiceName,
        },
        {
          title: 'Background Check',
          subTitle: new Date(profile.lastBackgroundCheck).toLocaleDateString(
            'en-US',
          ),
        },
      ]
      return qualifications
    },
    getBio(service) {
      return profile.about[service === 'night_nurse' ? 'nightNurse' : service] // sorry for the hack -Adam
    },
    getPrice(service) {
      return profile.prices ? profile.prices[service] : null
    },
    getReviews() {
      return profile.reviews.map(review => ({
        from: review.clientUserName,
        text: review.review,
        date: format(review.createdTimestamp, 'MMMM dd, yyyy'),
        createdTimestamp: review.createdTimestamp,
      }))
    },
  }
}

export default Profile

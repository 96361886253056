import React from 'react'
import * as PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const ProviderNameRate = ({ name, rate }) => (
  <>
    {name}
    <span className="only-apiari-classic">
      {rate && (
        <>
          <Box component="span" fontFamily="fontFamily" mx={1}>
            •
          </Box>
          ${rate}/hr
        </>
      )}
    </span>
  </>
)

ProviderNameRate.propTypes = {
  name: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
}

export default ProviderNameRate

import React from 'react'

const getQuery = search => {
  const params = new URLSearchParams(search.substr(1))
  const query = {}
  for (let [key, value] of params.entries()) {
    query[key] = value
  }
  return query
}

export default function useQueryString(search) {
  const [params, setParams] = React.useState(() => getQuery(search))

  React.useEffect(() => {
    setParams(getQuery(search))
  }, [search, setParams])

  return params
}

import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SubTitle from '../../booking-details/components/booking-details-sub-title'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card.svg'
import { ReactComponent as BulletIcon } from '../../../assets/bullet.svg'
import { Typography } from '@material-ui/core'
import storeContext from '../../../stores'
import { updateCreditCard } from '../../../api/provider-api'

const useStyles = makeStyles(theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
  },
  cardWrapper: {
    marginTop: '20px',
    marginBottom: '20px',
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '8px 10px',
    fontWeight: '600',
  },
  creditCardwrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '8px 10px',
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#111b47',
  },
  cardWrapper: {
    background: theme.palette.background.light,
    border: '1px solid',
    borderColor: theme.palette.border.light,
    borderRadius: '6px',
    padding: '8px 10px',
  },
  mainCardContainer: {
    display: 'block',
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '1rem',
    },
  },
  cvcContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '1rem',
    placeContent: 'space-between',
    background: '#fff',
    borderRadius: '0px',
  },
  number: {
    width: '50%',
  },
  cvcItems: {
    width: '30%',
    borderRadius: '5px',
  },
  customInput: {
    fontSize: '12px',
    minHeight: '30px',
    '&::placeholder': {
      color: '#000',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },

  nameContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '1rem',
    marginBottom: '1rem',
    placeContent: 'space-between',
    background: '#fff',
    borderRadius: '0px',
  },
  nameItems: {
    width: '48%',
    borderRadius: '5px',
  },
}))

const STRIPE_CARD_NUMBER_OPTIONS = {
  style: {
    base: {
      fontSize: '12px',
      fontFamily: 'Roboto',
      paddingLeft: '0.5rem',
      color: '#111b47',
      minHeight: '30px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
  placeholder: 'Credit Card Number',
}
const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '12px',
      color: '#111b47',
      minHeight: '30px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
}

const UpdateCard = ({
  setEditing,
  submitUpdateCard,
  setSubmitUpdateCard,
  fetchMembership,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()

  const {
    auth: { user },
  } = React.useContext(storeContext)

  const [error, setError] = useState(null)

  const checkCard = async () => {
    try {
      const firstName = document.getElementById('firstName')?.value
      const lastName = document.getElementById('lastName')?.value
      const zipcode = document.getElementById('zipcode')?.value

      if (stripe && elements) {
        const cardElement = elements.getElement(CardNumberElement)
        if (cardElement) {
          const { token, error } = await stripe.createToken(cardElement, {
            name: firstName + ' ' + lastName,
            address_zip: zipcode,
          })
          if (token) {
            return { token }
          } else if (error) {
            console.error(error)
            return { error }
          }
        }
      }
    } catch (e) {
      console.log(e)
      return { error: { message: e.message } }
    }
  }

  const handleSubmit = async () => {
    const { token, error } = await checkCard()
    if (token) {
      setError(null)
      const { data } = await updateCreditCard({
        shortUserId: user.shortUserId,
        cardToken: token.id,
      })
      if (data?.success) {
        fetchMembership()
        setEditing(false)
      } else {
        setError(data?.error || 'Something went wrong')
      }
    } else {
      console.log(error)
      setError(error?.message || 'Something went wrong')
    }

    setSubmitUpdateCard(false)
  }

  useEffect(() => {
    if (submitUpdateCard) {
      handleSubmit()
    }
  }, [submitUpdateCard])

  return (
    <Box pl={3.6} mt={2}>
      <Grid
        item
        container
        component={Box}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Grid component={Box} item xs="auto" width={200} mb={{ xs: 1, md: 0 }}>
          <Box fontFamily="camptonBold">Card Details</Box>
        </Grid>
        <Box component={Grid} item xs style={{ marginTop: '-18px' }}>
          <form onSubmit={handleSubmit}>
            <Box className={classes.mainCardContainer}>
              <Box className={classes.nameContainer}>
                <Box className={classes.nameItems}>
                  <input
                    style={{ width: '100%' }}
                    placeholder="First Name"
                    id="firstName"
                    className={classes.cardWrapper + ' ' + classes.customInput}
                    options={STRIPE_CARD_ELEMENT_OPTIONS}
                  />
                </Box>
                <Box className={classes.nameItems}>
                  <input
                    style={{ width: '100%' }}
                    placeholder="Last Name"
                    id="lastName"
                    className={classes.cardWrapper + ' ' + classes.customInput}
                    options={STRIPE_CARD_ELEMENT_OPTIONS}
                  />
                </Box>
              </Box>
              <Box className={classes.creditCardwrapper}>
                <CreditCardIcon fill={'red'} />
                <Box style={{ width: '100%', paddingLeft: '0.5rem' }}>
                  <CardNumberElement options={STRIPE_CARD_NUMBER_OPTIONS} />
                </Box>
              </Box>
              <Box className={classes.cvcContainer}>
                <Box className={classes.cvcItems}>
                  <CardExpiryElement
                    className={classes.cardWrapper}
                    options={STRIPE_CARD_ELEMENT_OPTIONS}
                  />
                </Box>
                <Box className={classes.cvcItems}>
                  <input
                    style={{ width: '100%' }}
                    placeholder="Zip Code"
                    id="zipcode"
                    className={classes.cardWrapper + ' ' + classes.customInput}
                    options={STRIPE_CARD_ELEMENT_OPTIONS}
                  />
                </Box>
                <Box className={classes.cvcItems}>
                  <CardCvcElement
                    className={classes.cardWrapper}
                    options={STRIPE_CARD_ELEMENT_OPTIONS}
                  />
                </Box>
              </Box>
            </Box>
            {error && (
              <Typography component="p" className={classes.errorText}>
                {error}
              </Typography>
            )}
          </form>
        </Box>
      </Grid>
    </Box>
  )
}

export default UpdateCard

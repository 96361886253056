import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useScript from '../../../../hooks/use-script'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ApiariButton from '../../../../components/inputs/button'
import { isObjectEmpty } from '../../../../utils/utils'
import storeContext from '../../../../stores'

const style = {
  base: {
    color: 'black',
    fontWeight: 300,
    fontSize: '16px',
  },
}

const FORM_ERRORS = {
  cardNumber: 'Please enter correct card number',
  cardExpiry: 'Invalid expiry date',
  cardCvc: 'Invalid CVC number',
  zipCode: 'Invalid Zip Code',
  acceptTos: 'You need to accept Terms of Service',
}

const labelProps = {
  fontSize: 12,
  component: 'label',
}

const useStyles = makeStyles(theme => ({
  input: {
    ...theme.overrides.MuiInputBase.input,
    minHeight: 40,
    width: '100%',
    fontSize: '1rem',
    border: 0,
    outline: 0,
    cursor: 'text',
  },
}))

const StripeChatPayment = ({ onPaymentSuccess }) => {
  const store = React.useContext(storeContext)
  const { user } = store.auth
  const skipTos = user && user.tosHistory && user.tosHistory.length

  const [loaded] = useScript('https://js.stripe.com/v3/')
  const [stripe, setStripe] = React.useState(null)
  const [error, setError] = React.useState(FORM_ERRORS)
  const [globalError, setGlobalError] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  /** @type {object} @property {HTMLElement} current */
  const card = React.useRef(null)
  const classes = useStyles()

  const getLabelColor = name =>
    error[name] && submitted ? 'error.main' : 'text.grey'
  const getFieldError = name => (!submitted ? '' : error[name])

  React.useEffect(() => {
    if (!loaded) return
    setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PUB_KEY))
  }, [loaded, setStripe])

  React.useEffect(() => {
    if (!stripe) return
    const elements = stripe.elements()

    const setStripeError = name => data => {
      setError(o => ({
        ...o,
        [name]: data.error && FORM_ERRORS[name],
      }))
    }

    const mountStripeElement = (name, id) => {
      const element = elements.create(name, { style })
      element.on('change', setStripeError(name)).mount(id)
      if (name === 'cardNumber') element.on('ready', () => element.focus())
      return element
    }

    card.current = mountStripeElement('cardNumber', '#card-number-element')
    mountStripeElement('cardExpiry', '#card-expiry-element')
    mountStripeElement('cardCvc', '#card-cvc-element')
  }, [stripe])

  const handlePaymentSubmit = async e => {
    e.preventDefault()
    setGlobalError('')
    setSubmitted(true)
    if (!isObjectEmpty(error)) return
    const extraData = {
      address_zip: e.target.elements.namedItem('postal-code').value,
    }
    const args = [card.current, extraData]
    setSubmitting(true)
    const { token, error: stripeError } = await stripe.createToken(...args)
    if (token) {
      onPaymentSuccess({
        type: 'stripeToken',
        message: JSON.stringify({
          token: token.id,
          acceptedTos: !skipTos,
        }),
        rawText: `**** **** **** ${token.card.last4}`,
      })
    }
    if (stripeError) {
      setGlobalError(stripeError.message)
    }
    setSubmitting(false)
  }

  if (skipTos && error.acceptTos) {
    setError(o => ({
      ...o,
      acceptTos: null,
    }))
  }

  return (
    <Box width={350} p={2}>
      <Box mb={3} fontFamily="camptonBold" fontSize={22} textAlign="center">
        Enter Credit Card Details
      </Box>
      <form onSubmit={handlePaymentSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box {...labelProps} color={getLabelColor('cardNumber')}>
              Credit Card Number
              <div id="card-number-element" className={classes.input} />
            </Box>
            <Box color="error.main" fontSize={10} position="absolute">
              {getFieldError('cardNumber')}
            </Box>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs>
              <Box {...labelProps} color={getLabelColor('cardExpiry')}>
                <span>Expiration</span>
                <div id="card-expiry-element" className={classes.input} />
              </Box>
              <Box color="error.main" fontSize={10} position="absolute">
                {getFieldError('cardExpiry')}
              </Box>
            </Grid>
            <Grid item xs>
              <Box {...labelProps} color={getLabelColor('zipCode')}>
                <span>Zip Code</span>
                <input
                  id="postal-code"
                  name="postal_code"
                  placeholder="Zip Code"
                  className={classes.input}
                  onChange={e => {
                    e.persist()
                    setError(o => ({
                      ...o,
                      zipCode: !e.target.value && FORM_ERRORS.zipCode,
                    }))
                  }}
                />
                <Box color="error.main" fontSize={10} position="absolute">
                  {getFieldError('zipCode')}
                </Box>
              </Box>
            </Grid>
            <Grid item xs>
              <Box {...labelProps} color={getLabelColor('cardCvc')}>
                <span>CVC</span>
                <div id="card-cvc-element" className={classes.input} />
              </Box>
              <Box color="error.main" fontSize={10} position="absolute">
                {getFieldError('cardCvc')}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {skipTos ? (
          <></>
        ) : (
          <Box mt={2} textAlign="center">
            <FormControlLabel
              name="acceptedTos"
              control={
                <Checkbox
                  color="primary"
                  onChange={e => {
                    e.persist()
                    setError(o => ({
                      ...o,
                      acceptTos: !e.target.checked && FORM_ERRORS.acceptTos,
                    }))
                  }}
                />
              }
              label={
                <span>
                  I agree to Apiari’s{' '}
                  <a
                    href="https://theapiari.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service (TOS)
                  </a>
                </span>
              }
            />
            <Box
              color="error.main"
              fontSize={10}
              position="absolute"
              mt={-1}
              ml={2}
            >
              {getFieldError('acceptTos')}
            </Box>
          </Box>
        )}
        <Box color="error.main">{globalError}</Box>
        <Box mt={3} mx="auto" width={200}>
          <ApiariButton fullWidth type="submit" disabled={submitting}>
            Confirm credit card
          </ApiariButton>
        </Box>
      </form>
    </Box>
  )
}

export default StripeChatPayment
